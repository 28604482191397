import { createSelector, createSlice } from '@reduxjs/toolkit'

export const StatusFilters = {
    All: 'all',
    New: 'new'
}

const initialState = {
    status: StatusFilters.All
}

const filtersSlice = createSlice({
    name: 'notificationFilter',
    initialState,
    reducers: {
        statusFilterChangedAll(state, action) {
            state.status = StatusFilters.All
        },
        statusFilterChangedNew(state, action) {
            state.status = StatusFilters.New
        }

    }
})

export const { statusFilterChangedAll, statusFilterChangedNew } = filtersSlice.actions

export default filtersSlice.reducer

export const getFilter = createSelector(state =>state.notificationFilter, state => state.status)