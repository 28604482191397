import ElementNotification from "./subElements/elementNotification";
import './mainNotification.css'
import { useDispatch, useSelector } from "react-redux";


import { getAlertAlgoritmFilter, deactiveAlerAlgoritmForNotification } from "../../structures/root/slices/alertAlgoritmSlice";
import { getInterventionFilter, deactiveInterventionForNotification } from "../../structures/root/slices/interventionSlice";
import { getFilter, statusFilterChangedAll, statusFilterChangedNew } from "../../structures/root/slices/filterNotificationSlice";
import { deactiveReportForNotification, getReportFilter, selectAllReport } from "../../structures/root/slices/reportSlice";



const Notification = () => {
    const listAlertAlg = useSelector(getAlertAlgoritmFilter)
    const listIntervention = useSelector(getInterventionFilter)
    const nofiticationFilter = useSelector(getFilter)
    const listReport = useSelector(getReportFilter)

    const dispatch = useDispatch()
    /**
     * andranno definiti diversi dispatch in funzione della specifica notifica da deattivare
     * 
     * consideriamo anche che la deattivazione può essere gestita da locale utilizzando i dati presenti sul pc di chi l'ha visionata e quindi deattivata 
     */
    const deactiveAlert = (id) => dispatch(deactiveAlerAlgoritmForNotification({ id: id }))
    const deactiveReport = id => dispatch(deactiveReportForNotification({ id: id }))
    const deactiveIntervention = id => dispatch(deactiveInterventionForNotification({ id: id }))
    const changeFilterAll = () => dispatch(statusFilterChangedAll())
    const changeFilterNew = () => dispatch(statusFilterChangedNew())


    return (
        <ElementNotification
            listAlert={listAlertAlg}
            listIntervention={listIntervention}
            listReport={listReport}
            listTicket={[]}
            deactiveReport={deactiveReport}
            deactiveAlert={deactiveAlert}
            deactiveIntervention={deactiveIntervention}
            filter={nofiticationFilter}
            changeFilterAll={changeFilterAll}
            changeFilterNew={changeFilterNew}
        />
    )
}

export default Notification