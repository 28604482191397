import { configureStore } from '@reduxjs/toolkit'
import plants, { plantAdded, plantMoreAdded } from './slices/plantSlice'
import dataPlant, { dataPlantAddMany } from './slices/dataPlantSlice'
import alertAlgoritm from './slices/alertAlgoritmSlice'
import intervention, { interventionAddOne, interventionLoad } from './slices/interventionSlice'
//import dati fittizzi
import {
    datiTarga,
    docDatiTargaData,
    familyData,
    faultDataMachinery,
    faultFamily,
    listaMacchinari
} from '../../configurations/strutturaDati/datiMacchine'
import users from './slices/usersSlice'
import notificationFilter from './slices/filterNotificationSlice'
import schedaMachineDetail from './slices/schedeMachineDetailSlice'
import listMachinery, { addMachinery, addOneMachinery } from './slices/listMachinerySlice'
import listLine, { addOneLine } from './slices/listLineSlice'
import listBudgets, { addOneBudget } from './slices/budgetSlice'
import listDepartment, { addOneDepartment } from './slices/listDepartmentSlice'
import fault, { addManyFault } from './slices/faultSlice'
import family, { translaterAddMany } from './slices/traslaterFamilyMachinery'
import datiTargaMachinery, { addOneDatiTargaMachinery } from './slices/datiTargaMachinerySlice'
import dataFaultMachinery, { dataFaultMachineryAddMany } from './slices/dataFaultMachinerySlice'
import docDatiTarga, { docDatiTargaAddMany } from './slices/docDatiTargaSlice'
import report, { reportAddMany, reportAddOne } from './slices/reportSlice'
import ticket, { ticketAddMany } from './slices/ticketSlice'
import datiUtente from './slices/datiUtenteSlice'
import dayCalendar from './slices/dayCalendarSlice'
import selectionMetrics from './slices/selectionForMetricheSlice'
import metricValue from './slices/metricValueSlice'

import { PersistentMiddleware } from './persistentMiddleware'
import { preloadStateDefault } from '../../configurations/setting'


const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(PersistentMiddleware),
    reducer: {
        plants,
        dataPlant,
        alertAlgoritm,
        intervention,
        notificationFilter,
        schedaMachineDetail,
        listMachinery,
        listLine,
        listDepartment,
        listBudgets,
        fault,
        family,
        datiTargaMachinery,
        dataFaultMachinery,
        docDatiTarga,
        report,
        ticket,
        datiUtente,
        dayCalendar,
        selectionMetrics,
        metricValue,
        users
    },

    preloadedState: JSON.parse(window.localStorage.getItem("schedeAperte")) ? { ...preloadStateDefault, schedaMachineDetail: JSON.parse(window.localStorage.getItem("schedeAperte")) }
        :
        preloadStateDefault  //VA RIORGANIZZATA L'INIALIZZAZIONE PER NUOVE INSTALLAZIONI

})



store.dispatch(addManyFault(faultFamily.map(el => {
    return {
        ...el,
        createdAt: "2023-06-20T14:39:25.925Z",
        updatedAt: "2023-06-20T14:39:25.925Z"
    }
})))

store.dispatch(translaterAddMany(familyData.map(el => {
    return {
        ...el,
        createdAt: "2023-06-20T14:39:25.925Z",
        updatedAt: "2023-06-20T14:39:25.925Z"
    }
})))





store.dispatch(dataFaultMachineryAddMany(faultDataMachinery.map(el => {
    return {
        ...el,
        createdAt: "2023-06-20T14:39:25.925Z",
        updatedAt: "2023-06-20T14:39:25.925Z"
    }
})))







export default store