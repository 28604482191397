import { pointToeconomicalValue } from "../../../functions/generiche"

export const AttivitaMetriche = ({ attivitaMacro, intervento, tseManIp }) => {

    return <li
        key={attivitaMacro.id}
        style={{ color: "black" }}
    >
        <div style={{ display: "flex" }}>
            <span
                style={{ flex: "1" }}
            >{attivitaMacro.nomeAttivita}</span>
            <div style={{ flex: "2" }}>

                <span style={{ margin: "0vw 1vw" }}>
                    rtste: {
                        tseManIp
                            .find(tse => tse?.id === intervento.id)?.attivita ?
                            isNaN(


                                tseManIp
                                    .find(tse => tse?.id === intervento.id)?.attivita
                                    .find(a => a.idAtt === attivitaMacro.id).RTSTE) ?
                                " no data"
                                :

                                Math.round(tseManIp.find(tse => tse?.id === intervento.id)?.attivita.find(a => a.idAtt === attivitaMacro.id).RTSTE * 100) + " %"
                            :
                            "no data"
                    }
                </span>

            </div>
        </div>
    </li>
}


export const InterventionMetriche = ({ intervento, otdManIp, tseManIp }) => {
    return <li key={intervento.id} style={{ margin: "1vw 1vw", color: "purple" }}>
        <div style={{ display: "flex" }}>
            <span style={{ flex: "3" }}>{intervento.title}</span>
            <div style={{ flex: "5" }}>
                <span style={{ margin: "0vw 1vw" }}>
                    On Time Delivery: {isNaN(
                        otdManIp
                            .find(el => el.id === intervento.id)?.rapportoODT)
                        ?
                        "no data"
                        :
                        otdManIp.find(el => el.id === intervento.id)?.rapportoODT + " %"
                    }
                </span>
                <span style={{ margin: "0vw 1vw" }}>
                    RTSTE: {tseManIp
                        .find(tse => tse?.id === intervento.id)?.RTSTE
                        ?
                        Math.round(tseManIp.find(tse => tse?.id === intervento.id)?.RTSTE * 100) + " %"
                        :
                        " no data"}
                </span>
                <span style={{ margin: "0vw 1vw" }}>
                    RTSTE-attività: {tseManIp
                        .find(tse => tse?.id === intervento.id)?.RTSTEa
                        ?
                        Math.round(tseManIp.find(tse => tse?.id === intervento.id)?.RTSTEa * 100) + " %"
                        :
                        intervento?.attivita.length === 0 ?
                            " non ha attività"
                            :
                            " no data"}
                </span>

            </div>
        </div>
        <ul>
            {intervento?.attivita.map(attivitaMacro => {
                return <AttivitaMetriche
                    key={attivitaMacro.id + intervento.id}
                    attivitaMacro={attivitaMacro}
                    intervento={intervento}
                    tseManIp={tseManIp}
                />
            })}
        </ul>
    </li>
}



export const AssetMetriche = ({ ass, listPeriodicIntervention, otdManIp, tseManIp, costiPerAsset, tManAss, tpManAss, ieManAss, otdManAss, tseManAss }) => {
    return <li key={ass.id} style={{ padding: "1vw 1vw", color: "grey" }}>
        <div style={{ display: "flex" }}>
            <span style={{ flex: "1" }}>{ass.nome} - {ass.identityCode}</span>
            <div style={{ flex: "5" }}>
                <span style={{ margin: "0vw 1vw" }}>
                    costo totale interventi: {pointToeconomicalValue(costiPerAsset.find(el => el.id === ass.id)?.costiInterventi)} €
                </span>
                <span style={{ margin: "0vw 1vw" }}>
                    tempo totale manutenzione: {tManAss.find(el => el.id === ass.id)?.tempi} minuti
                </span>
                <span style={{ margin: "0vw 1vw" }}>
                    PMP: {
                        isNaN(tpManAss.find(el => el.id === ass.id)?.tempEff
                            /
                            tManAss.find(el => el.id === ass.id)?.tempi)
                            ?
                            0
                            :
                            Math.round((tpManAss.find(el => el.id === ass.id)?.tempEff
                                /
                                tManAss.find(el => el.id === ass.id)?.tempi) * 100)

                    } %
                </span>
                <span style={{ margin: "0vw 1vw" }}>
                    PMC: {ieManAss.find(el => el.id === ass.id)?.completeIntervention} / {ieManAss.find(el => el.id === ass.id)?.allIntervention}
                </span>
                
                <span style={{ margin: "0vw 1vw" }}>
                    On Time Delivery: {isNaN(otdManAss.find(el => el.id === ass.id)?.rapportoODT)
                        ?
                        "no data"
                        :
                        otdManAss.find(el => el.id === ass.id)?.rapportoODT + " %"
                    }
                </span>
                <br></br>
                <span style={{ margin: "0vw 1vw" }}>
                    RTSTE: {isNaN(tseManAss.find(e => ass.id === e?.id)?.sR / tseManAss.find(e => ass.id === e?.id)?.nR) ?
                        " no data"
                        :
                        Math.round((tseManAss.find(e => ass.id === e?.id)?.sR / tseManAss.find(e => ass.id === e?.id)?.nR) * 100) + " %"}
                </span>
                <span style={{ margin: "0vw 1vw" }}>
                    RTSTE-a: {isNaN(tseManAss.find(e => ass.id === e?.id)?.sRa / tseManAss.find(e => ass.id === e?.id)?.nRa) ?
                        " no data"
                        :
                        Math.round((tseManAss.find(e => ass.id === e?.id)?.sRa / tseManAss.find(e => ass.id === e?.id)?.nRa) * 100) + " %"}
                </span>

            </div>
        </div>
        <ul>
            {
                listPeriodicIntervention.filter(interF => interF.idMachinery === ass.id)
                    .map(intervento => {
                        return <InterventionMetriche
                            key={intervento.id}
                            intervento={intervento}
                            otdManIp={otdManIp}
                            tseManIp={tseManIp}
                        />

                    })
            }
        </ul>
    </li>
}