import './mainMachineryInterventions.css'
import PersMachineryInterventions from './subElements/persMachineryInterventions'
import { useSelector } from 'react-redux'
import { getReportForMachineSelected } from '../../structures/root/slices/reportSlice'
import { getInterventionForMachinerySelected, selectAllIntervention } from '../../structures/root/slices/interventionSlice'
import { getTicketForMachinerySelected } from '../../structures/root/slices/ticketSlice'
import { selectFaultFamilyForSelectedMachienry } from '../../structures/root/slices/faultSlice'
import { getDataFromSelectedMachinery } from '../../structures/root/slices/listMachinerySlice'
import { ricercaInterventiFogliaEArricchimento } from '../../functions/interventiPeriodici'

const MachineryInterventions = () => {
    const listReport = useSelector(getReportForMachineSelected)
    const listIntervention = useSelector(getInterventionForMachinerySelected)
    const listTicket = useSelector(getTicketForMachinerySelected)
    const dataFault = useSelector(selectFaultFamilyForSelectedMachienry)
    const assetSelezionato = useSelector(getDataFromSelectedMachinery)
    const listAllInter = useSelector(selectAllIntervention)
    return (
        <PersMachineryInterventions
            listIntervention={ricercaInterventiFogliaEArricchimento(listIntervention ? listIntervention : [], listAllInter, listReport)}
            listReport={listReport}
            listTicket={listTicket}
            dataFault={dataFault}
            assetSelected={assetSelezionato}
        />
    )
}

export default MachineryInterventions