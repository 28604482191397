import { useEffect, useState } from "react"

import MainMainteneanceStory from "../../mainteneanceStory/mainManteneanceStory"
import { selectAllLine } from "../../../structures/root/slices/listLineSlice"
import { selectAllDepartment } from "../../../structures/root/slices/listDepartmentSlice"
import { selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice"
import { useSelector } from "react-redux"
import { selectPlant } from "../../../structures/root/slices/plantSlice"

const ElementListReportComplete = ({ report }) => {
    const [isOpenAction, setIsOpen] = useState(false)
    //const intervent = useSelector(selectAllIntervention).find(el => el.id === report.idIntervention)
    const setSingleIsOpenActionComponent = () => {
        setIsOpen(!isOpenAction)
    }

    const [plant, setDataPlant] = useState({})
    const [depart, setDepart] = useState({})
    const [linea, setLinea] = useState()

    const dataDepartment = useSelector(selectAllDepartment)
    const dataLinee = useSelector(selectAllLine)
    const listMachine = useSelector(selectAllMachinery)
    const listaImpianti = useSelector(selectPlant)
    const [datiMachine, setDatiMachine] = useState({})



    useEffect(() => {

        setDatiMachine(listMachine.find(el => el.id === report.idMachinery
        ))
    }, [report])

    useEffect(() => {
        setDataPlant(listaImpianti.find(el => el.id === datiMachine.idPlant))
        setLinea(dataLinee.find(el => el.id === datiMachine.idLinea))

    }, [datiMachine])

    useEffect(() => {
        if (linea) { setDepart(dataDepartment.find(el => el.id === linea.idDepartment)) }
    }, [linea])



    //download dei dati intervento  se non presenti

    useEffect(() => {
        /**
         * se manca il campo operatore vuol dire che non sono stati scaricati i dati dell'intervento di conseguenza efffettuo una chiamata al back end per recuperarli
         */
        if (report ? !report.operatore : false) {
            // dispatch(getDataSpecificIntervention({ id: report.id, idAsset: report.idMachinery }))
        }
    }, [])

    return (
        <li

            className="boxLiElementListStandard elementReportComplateListActivity"
            key={report.id}
        >
            <div style={{ "flex": "3" }}>
                <div className="boxTitleEventList">
                    <h5
                        className="titleEventListTimeEvent ">
                        {report.title}
                    </h5>


                </div>
                <div
                    className="corpoEvent"
                >
                    <span className="elementSpecificDataToInterventionLittle">Impianto: {plant?.nome }</span>                    
                    <span className="elementSpecificDataToInterventionLittle">Reparto: {depart?.name }</span>                 
                    <span className="elementSpecificDataToInterventionLittle">Linea: {linea?.name }</span>                   
                    <span className="elementSpecificDataToInterventionLittle">Macchina: {report?.operatore }</span>
                </div>
            </div>
            <div
                style={{ "flex": "1" }}
            >
                <button
                    onClick={() => setSingleIsOpenActionComponent()}
                >Visualizza</button>
                {
                    isOpenAction ?
                        //qui va implementato il sistema di visualizzazione
                        <MainMainteneanceStory
                            datiIntervento={report}
                            isOpen={!isOpenAction}
                            closeScheda={setSingleIsOpenActionComponent}
                        />
                        :
                        <span></span>
                }
            </div>

        </li>
    )
}

export default ElementListReportComplete