import PersReportMainteneanceForm from "./subElements/persReportMainteneaceForm"
import './mainReportMainteneanceForm.css'

const ReportMainteneanceForm = ({ isOpen, datiIntervento, closeScheda, mode, reportToModify }) => {
    return (
        <PersReportMainteneanceForm
            datiIntervento={datiIntervento}
            closeScheda={closeScheda}
            isOpen={isOpen}
            mode={mode}
            reportToModify={reportToModify}
        />
    )
}
export default ReportMainteneanceForm