import { createSelector, createSlice } from '@reduxjs/toolkit'


const selectionMetricsSlice = createSlice({
    name: "selectionMetrics",
    initialState: {
        idStructure: "0",
        period: 2
    },
    reducers: {
        selectStructure(state, action) {

            state.idStructure = action.payload.idStructure


        },
        selectPeriod(state, action) {
            state.period = action.payload.period
        }
    }
})


export const { selectStructure, selectPeriod    } = selectionMetricsSlice.actions

export default selectionMetricsSlice.reducer

export const getSelectionMetrics = createSelector(state => state.selectionMetrics, state => state)