import "./mainOverview.css";
import TripleDonatChart from "../../components/tripleDonatChart/mainTripleDonatChart";
import Mappa from "../../components/map/mainMap";
import AlertListOverview from "../../components/alertListOverview/mainAlertListOverview";
import React, { useState, useEffect, useRef } from "react";
import LinearGraph from "../../components/lineargraph/mainLinearGraph";
import { titleColor } from "../../configurations/generalStye";
import { getSelectPlant } from "../root/slices/plantSlice";
import {
    getSelectedDataPlant,
    selectDataPlant,
} from "../root/slices/dataPlantSlice";

import { useDispatch, useSelector } from "react-redux";
import { getAlertAlgoritmForSelectedPlant } from "../root/slices/alertAlgoritmSlice";
import '../../components/lineargraph/mainLinearGraph.css'
import { metrichePerSpecificaStruttura } from "../root/slices/metricValueSlice";
import Dendogram from "../../components/Dendogram/mainDendogram";


const Overview = () => {
    //solo accesso all'impianto selezionato
    const [resolution, setResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const selectedPlant = useSelector(getSelectPlant);
    const selectedDataPlant = useSelector(getSelectedDataPlant);
    const selectedAlertAlgoritm = useSelector(getAlertAlgoritmForSelectedPlant)
    const parametri = useSelector(metrichePerSpecificaStruttura)
    const resizeObserverRef = useRef(null);
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setResolution({ width, height });
    };
    /**
     * va aggiunto il controllo del id del macchinario e renderizare a video l'identity code inserito
     * all'atto di caricamento del macchinario e il nome del macchinario
     */

    const dispatch = useDispatch();
    const selectionDataPlant = (plantId, period) => {
        //imposto la selezione dei dati di impianto da visualizzare in funzione dell'impianto selezionato
        return dispatch(selectDataPlant({ plantId: plantId, period: period }));
    };
    //seleziono gli alert Algoritmo da visualizzare in funzione dell'impianto selezionato

    useEffect(() => {
        selectionDataPlant(selectedPlant?.id, 0);
    }, [selectedPlant]);



    /* Gestione usata per scalare il grafico Lineare*/
    // il divisore (2.86 per larghezza,3.8 per l'altezza) è calcolato a partire
    // dalla risoluzione e dalle proporzioni di figma.
    // Il calcolo è questo: Su figma la larghezza dell'intera finestra è 1440 px
    // mentre la larghezza della finestra relativa al grafico è 502px
    // 502px è contenuto 2.86 volte in 1440px.
    // Per mantenere queste proporzioni anche su altri schermi, vado a calcolare
    // dinamicamente larghezza e altezza della finestra con l'observer
    // e poi lo adatto divido per il divisore <<2.86>> trovato).
    useEffect(() => {
        resizeObserverRef.current = new ResizeObserver(handleResize);
        // Aggiungo l'elemento da osservare (in questo caso, l'intera finestra)
        resizeObserverRef.current.observe(document.documentElement);

    }, []);

    return (
        <div className="bodyOverview">
            <div style={{ display: "flex" }}>
                <div style={{ flex: "1" }}>
                    <TripleDonatChart
                        plant={selectedPlant}
                        title1={"Average Health Indicator"}
                        title2={"Availability rate"}
                        title3={"Saved Interventions"}
                        value1={
                            selectedDataPlant
                                ? selectedDataPlant.AverangeHealthIndicatorImpianto
                                : 0
                        }
                        value2={
                            selectedDataPlant
                                ? selectedDataPlant.tassoDiDisponibilitaImpianto
                                : 0
                        }
                        value3={selectedDataPlant ? selectedDataPlant.interventiEvitati : 0}
                        funzioneCambioStato={selectionDataPlant}
                    />
                </div>
                <div style={{ flex: "3" }}>
                    <Mappa></Mappa>
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ flex: "1" }}>
                    <AlertListOverview
                        listAlert={selectedAlertAlgoritm}
                        plant={selectedPlant?.nome}
                    />
                </div>

                <div style={{ flex: "3" }} className="bodyGraficoLienare">
                   
                    <Dendogram></Dendogram>
                  

                </div>
            </div>
        </div>
    );
};

export default Overview;
