import React from "react";
import './mainUserSettings.css'
import Input from "../../../components/userManagement/input/Input";
import Button from "../../../components/userManagement/button/Button";

const UserSettings = () => {

    return (
        <div className="mainUserSettingsBody">
            <div className={"mainUserSettingsHeader"}>
                <div className={"mainUserSettingsHeaderTitle"}> Password</div>
                <div className={"mainUserSettingsHeaderSubTitle"}> Please the new password.</div>
                <div className="divider" />

            </div>

            <div className={"mainUserForm"}>
                <form className={"form"}>
                    <div className="form-group">
                        <div className={"form-double"}>
                        </div>
                       
                    </div>

                    <div className="form-group">
                        <div className="form-label">
                            <label>New Password</label>
                        </div>
                        <div className={"form-single"}>
                            <Input
                                type="password"
                                placeholder="Enter New Password"
                                disabled={true}
                            />
                        </div>
                        <div className="divider" />

                    </div>

                    <div className="form-group">
                        <div className="form-label">
                            <label>Confirm new password</label>
                        </div>
                        <div className={"form-double"}>
                            <Input
                                type="password"
                                placeholder="Confirm New Password"
                                disabled={true}
                            />
                        </div>
                    </div>
                </form>

            </div>
            <div className="mainUserSettingsFooter">
                <div className="divider" />
                <div className="mainUserSettingsFooterContent">
                    <Button
                        style={{
                            marginRight: "1vw"
                        }}
                        className={"button cancel"}>
                        Cancel
                    </Button>
                    <Button className={"button save"}>
                        Update password
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default UserSettings;