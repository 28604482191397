import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { getMachineSelected } from './schedeMachineDetailSlice';


const docDatiTargaAdapter = createEntityAdapter({
    selectId: (docDatiTarga) => docDatiTarga.id
})


const docDatiTargaSlice = createSlice({
    name: "docDatiTarga",
    initialState: docDatiTargaAdapter.getInitialState(),
    reducers: {
        docDatiTargaAddMany: docDatiTargaAdapter.addMany
    }
})


export default docDatiTargaSlice.reducer

export const {
    docDatiTargaAddMany
} = docDatiTargaSlice.actions

export const { selectAll: selectAlldocDatiTarga } = docDatiTargaAdapter.getSelectors(state => state.docDatiTarga)

export const getDocDatiTargaForMachineSelected = createSelector(
    [selectAlldocDatiTarga, getMachineSelected],
    (state, select) => {
        if (select) {
           
            return state.filter(el => el.idMachinery === select.idMachinery)
        }

    })