import React from "react";
import { Link } from "react-router-dom";




const ElementTitoloScheda = ({pathID, link, titolo, indice, removeScheda, active}) => {
   
    return (        
        <div
            className={`bodyElementTitoloSchede 
                ${active ?
                    "activeSchedaTitoloElementTitoloSchede"
                    :
                    ""}`}
        >
            <Link
                className={`boxElementTitoloSchedeLink `}
                to={link}
            >
                {titolo}
            </Link>
            <div
                onClick={() => removeScheda(indice)}
            >
                x
            </div>
        </div>
    )

}

export default ElementTitoloScheda