import { useState, useEffect } from "react"
import { setBasicCalendar } from "../../../../functions/dateTime"
import DayHomeMadeCalendar from "./dayHomeMadeCalendar"
import { getDataSpecificIntervention, selectAllIntervention } from "../../../../structures/root/slices/interventionSlice"
import { useDispatch, useSelector } from "react-redux"
import { selectAllReport } from "../../../../structures/root/slices/reportSlice"
import { arricchimentoVirtuali, eventiPeriodiciRicadentiNelMeseSelezionato, ricercaInterventiFogliaEArricchimento } from "../../../../functions/interventiPeriodici"
import { getselectedDayCalendar } from "../../../../structures/root/slices/dayCalendarSlice"

const BodyHomeMadeCalendar = ({ listEvent, dateCalendar }) => {

    const [dayForMonth, setDayForMonth] = useState([])
    const [interventiDaVisualizzare, setInterventiDaVisualizzare] = useState([])
    const daySelect = useSelector(getselectedDayCalendar)
    const listIntervention = useSelector(selectAllIntervention)
    const listReport = useSelector(selectAllReport)
    const listaInterventiNonPeriodici = listIntervention.filter(elem => elem.period === "" || elem.period === "0" || elem.period === 0)
    const listInterventionOutReportNONPERIODIC = listaInterventiNonPeriodici.filter(el => !listReport.find(element => element.idIntervention === el.id))



    const [listaEventiVirtualiPeriodici, setListaEventiVirtuali] = useState([])

    const dispatch = useDispatch()



    /**
     * impostiamo i giorni del calendario
     */
    useEffect(() => {
        if (dateCalendar) {
            setDayForMonth(setBasicCalendar(dateCalendar))
        } else {
            setDayForMonth(setBasicCalendar(new Date()))
        }


    }, [dateCalendar])


    useEffect(() => {

        if (dayForMonth.length !== 0) {

            setListaEventiVirtuali(arricchimentoVirtuali(
                eventiPeriodiciRicadentiNelMeseSelezionato(
                    listIntervention,
                    `${dayForMonth[0].year}-${dayForMonth[0].month < 10 ? `0${dayForMonth[0].month}` : dayForMonth[0].month}-${dayForMonth[0].day}`,
                    `${dayForMonth[dayForMonth.length - 1].year}-${dayForMonth[dayForMonth.length - 1].month < 10 ? `0${dayForMonth[dayForMonth.length - 1].month}` : dayForMonth[dayForMonth.length - 1].month}-${dayForMonth[dayForMonth.length - 1].day}`
                )
                    //filtro gli elementi per la quale esiste un intervento che ha lo stesso riferimento all'intervento periodico e che è posto nella stessa giornata
                    .filter(elemt => !listaInterventiNonPeriodici.find(el => el.idPrevIntervention === elemt.idPrevIntervention && el.date === elemt.date)
                    )
            )

            )

            /**
                * ricerca degli interventi da visualizzare secondo la logica descritta successivamente
                * 
                */
            //lista interventi che ricadono nel mese
            const listInterventionForCalendarPeriod = listaInterventiNonPeriodici
                .filter(el =>
                    el.date > `${dayForMonth[0].year}-${dayForMonth[0].month < 10 ? `0${dayForMonth[0].month}` : dayForMonth[0].month}-${dayForMonth[0].day}`
                    &&
                    el.date <= `${dayForMonth[dayForMonth.length - 1].year}-${dayForMonth[dayForMonth.length - 1].month < 10 ? `0${dayForMonth[dayForMonth.length - 1].month}` : dayForMonth[dayForMonth.length - 1].month}-${dayForMonth[dayForMonth.length - 1].day}`
                )


            setInterventiDaVisualizzare(ricercaInterventiFogliaEArricchimento(listInterventionForCalendarPeriod, listIntervention, listReport))

        }
    }, [dayForMonth, listIntervention, listReport])




    return (
        <div className='boxHomeMadeCalendar boxHomeMadeCalendarRows'>
            {
                /**
                 * Nei giorni devo inserire i report presenti nello specifico gli interventi sono di due tipi: quelli derivanti da un intervento periodico e quelli che non derivano da uno periodico.
                 * un intervento periodico genera una serie di interventi che definisco virtuali poiché non sono presenti in backend ma che vengo mostrati in front end. Nel momento che viene registrato un report l'evento virtuale viene registrato come intervento straordinario con il riferimento all'intervento periodico
                 * 
                 * nel momento che un intervento viene riprogrammato viene generato un nuovo intervento con un riferimento all'intervento dalla quale è avvenuta la riprogrammazione: se questo era virtuale, viene generato l'attuativo e successivamente viene implementato anche il suo intervento relativo
                 * 
                 * Un altra situazione è il caso in cui abbiamo un intervento straordinario. in questa situazione abbiamo che l'intervento è reale e salvato in backend ma senza un riferimento alla sua controparte reale in quel caso abbiamo un ramo senza base comune (senza un riferimento ad un intervento precedente ) chè ha lo stesso comportamento di un intervento attuativo.
                 * 
                 * in generale abbiamo una struttara del genere:
                 * 
                 * intervento peridico
                 * |
                 * |                    eventuali report collegato        eventuali report
                 * |                                |                           |
                 * - intervento virtuale        -> attuativo       -> riprogrammato
                 * |
                 * |                                        report
                 * |                                            |
                 * - intervento virtule -> attuativo  -> riprogrammato -> riprogrammato -> riprogrammato -> riprogrammato ...
                 * 
                 * 
                 * intervento straordinario
                 * 
                 */
                dayForMonth.map((el, index) => <DayHomeMadeCalendar
                    key={index}
                    day={el}

                    interventionForDay={[
                        //interventi periodici virtuali alla quale sono stati eliminati gli interventi che ricadono nello stesso giorno e hanno come riferimento lo stesso evento periodico OK
                        ...listaEventiVirtualiPeriodici.filter(element => element.date === `${el.year}-${el.month < 10 ? `0${el.month}` : el.month}-${el.day < 10 ? `0${el.day}` : el.day}`),
                        //interventi non periodici
                        ...interventiDaVisualizzare.filter(element => element.date === `${el.year}-${el.month < 10 ? `0${el.month}` : el.month}-${el.day < 10 ? `0${el.day}` : el.day}`)


                    ]}


                />)
            }
        </div>
    )
}

export default BodyHomeMadeCalendar