import { useEffect, useState } from "react"
import { OTDForPeriodicIntervention } from "../../functions/metriche/OTD"
import { getFirstLevelActuative, getPeriodicInterventions } from "../../functions/metriche/filterList"
import { useSelector } from "react-redux"
import { getInterventionForMachinerySelected } from "../../structures/root/slices/interventionSlice"
import { getReportForMachineSelected } from "../../structures/root/slices/reportSlice"
import { RTSTEAttivitaIntervento } from "../../functions/metriche/RTSTE"
import { InterventionMetriche } from "../../structures/metriche/subElements/elementMetriche"

import './mainMachineMetriche.css'

const MachineMetriche = () => {
    const dateStart = "2023-01-01"
    const intervention = useSelector(getInterventionForMachinerySelected);
    const report = useSelector(getReportForMachineSelected)

    const [listPeriodicIntervention, setListPer] = useState([])
    useEffect(() => {
        setListPer(getPeriodicInterventions(intervention))
    }, [intervention])

    const [listInterventioAttuative, setListAtt] = useState([])
    useEffect(() => {
        setListAtt(getFirstLevelActuative(
            intervention,
            listPeriodicIntervention,
            dateStart,
            new Date().toISOString().slice(0, 10)))
    }, [listPeriodicIntervention])

    const [odtInt, setOdtInt] = useState([])
    useEffect(() => {
        setOdtInt(OTDForPeriodicIntervention(listPeriodicIntervention, intervention, listInterventioAttuative, report))
    }, [listInterventioAttuative])

    const [tseMainIp, settseManIp] = useState([])
    useEffect(() => {
        if (listInterventioAttuative.length !== 0) {
            settseManIp(RTSTEAttivitaIntervento(intervention, intervention, listInterventioAttuative, report))
        }
    }, [listInterventioAttuative])


    return (
        <ul className="bodyMachineMetriche">
            {
                listPeriodicIntervention.map(inter => <InterventionMetriche
                    key={inter.id}
                    intervento={inter}
                    otdManIp={odtInt}
                    tseManIp={tseMainIp}
                />)
            }

        </ul>
    )
}

export default MachineMetriche