import React, { useState } from "react";
import './mainPlanning.css'

import Calendar from '../../components/calendar/mainCalendar'
import ListTimeEvent from "../../components/listTimeEvent/mainListTimeEvent";

import {listaEventiManutentivi} from '../../configurations/strutturaDati/datiInterventi'
import { useSelector } from "react-redux";
import { selectAllIntervention } from "../root/slices/interventionSlice";
import { selectAllTicket } from "../root/slices/ticketSlice";
import Modal from "../../components/DialogModal/mainDialogModal";
import FormNewEvent from "../../components/formNewEvent/mainFormNewEvent";

const Planning  =()=> {

    const Interventions = useSelector(selectAllIntervention)
    
    const ticket = useSelector(selectAllTicket)

        
        return (
            <div className="bodyPlanning">
                <div
                    style={{ "display": "flex" }}
                >
                    <div
                        style={{ "flex": 4,"maxWidth": '63vw'}}
                    >
                        <Calendar
                            Event={Interventions}
                        />
                    </div>

                    <div
                        style={{ "flex": 2 }}
                    >
                        <ListTimeEvent
                            Events={Interventions}
                            ticket={ticket}
                        />
                    </div>
                </div>
            </div>
        )
    


}

export default Planning