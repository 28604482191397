import React, { useState } from 'react';
import './Tabs.css'; // Assicurati di importare il tuo file CSS

/**
 * Componente Tabs per la creazione di una serie di tab.
 *
 * @param {string} defaultActiveKey - Chiave della tab attiva di default.
 * @param {Array} items - Array di oggetti rappresentanti le tab.
 * @param {Function} onChange - Funzione di callback chiamata quando una tab viene cambiata.

 */

const Tabs = ({ defaultActiveKey, items, onChange }) => {
    const [activeKey, setActiveKey] = useState(defaultActiveKey);

    /**
     * Funzione per gestire il cambio della tab.
     *
     * @param {string} key - Chiave della tab.
     */
    const handleTabChange = (key) => {
        setActiveKey(key);
        if (onChange) {
            onChange(key);
        }
    };

    return (
        <div className="tabs">
            {items.map((item, key) => (
                <div key={key} className={`tab`}>
                    <input
                        id={`tab-${item.key}`}
                        name="tabs"
                        type="radio"
                        checked={item.key === activeKey}
                        onChange={() => handleTabChange(item.key)}
                    />
                    <label htmlFor={`tab-${item.key}`}>{item.label}</label>
                </div>
            ))}
            <div className="divider" />
        </div>
    );
};

export default Tabs;
