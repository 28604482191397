import TableListAlertOverview from "./subElements/tableAlertListOverview";

import './mainAlertListOverview.css'

const AlertListOverview = ({ listFaultFamily, listAlert, plant }) => {

    
    return (
        <TableListAlertOverview
            plant={plant}
            listFaultFamily={listFaultFamily}
            listAlert={listAlert ? listAlert : [{
                data: "2022-11-26",
                name: "disallineamento",
                typeAlert: "algoritmo",
                idMacchinario: "85eu93",
                healthIndicatorComponent: 50,
                note: "break in 3 month"
            }]}
        />
    )
}

export default AlertListOverview