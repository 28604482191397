import { SupervisedUserCircleSharp } from '@mui/icons-material';
import ApexCharts from 'apexcharts'
import React, { useEffect, useState, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';

import "../mainMultipleVariableDonut.css"

const DesktopMultiDonut = ({ val1, val2, max1, max2, labelsCenter, threshold1, threshold2, colorCircle1, colorCircle2, fontSizeTitle, fontSizeValue }) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);



    const [series, setSeries] = useState([])
    const [colors, setColors] = useState(['#008f39', '#FF0000']);

    //Soglie di default nel caso in cui non si dovessero inserire
    const thresholdDefaultFract = [0.2, 0.4]
    const thresholdDefaultPerc = [30, 65]

    //Label di default nel caso non si dovessero inserire
    const [labels, setLabels] = useState(['', ''])


  

    useEffect(() => {


        if (labelsCenter) { setLabels(labelsCenter) }
        else {
            setLabels(["", ""])
        }


        setOptions({
            chart: {
                events: {
                    click: function (event, chartContext, config) {
                        //Nel caso labelsCenter fosse undefined o null viene fatto un controllo su labels
                        //Poi si gestisce l'alternanza delle circonferenze al click
                        if ((labelsCenter?.length > 1 || labels?.length > 1) && val1 !== undefined && val2 !== undefined) {
                            setSeries((prevSeries) => prevSeries.slice().reverse());
                            setColors((prevColors) => prevColors.slice().reverse());
                            setLabels((prevLabels) => prevLabels.slice().reverse());
                        }
                    },

                },
                //L'altezza si prende il 100% del suo contenitore
                height: "100%",
                type: 'radialBar',
            },

            plotOptions: {


                radialBar: {
                    hollow: {
                        margin: 1,
                        size: '55%',
                        background: 'transparent',
                        position: 'front',

                    },
                    track: {
                        show: true,

                        background: '#f2f2f2',
                        strokeWidth: '100%',
                        opacity: 1,
                        margin: 2,

                    },
                    dataLabels: {
                        name: {
                            show: true,
                            fontWeight: '500',
                            color: '#475467',
                            fontFamily: 'Inter',


                        },
                        value: {
                            color: '#101828',
                            fontSize: fontSizeValue ? fontSizeValue : '2.08vw',
                            fontWeight: 'bold',
                            show: true,

                            formatter: function (w) {
                                //Qui si fa il calcolo del valore centrale quando si fa il mouseOver su una specifica circonferenza
                                //Si discriminano i casi in cui bisogna restituire una frazione
                                //dai casi in cui bisogna restituire un valore in percentuale
                                if (val1 !== undefined && max1 == undefined && val2 !== undefined && max2 !== undefined) {

                                    if (val1 == w) {
                                        return w + "%"
                                    } else {
                                        //return (decimalToFraction(w / 100))
                                        return `${val2}/${max2}`
                                    }

                                } else if (val1 !== undefined && max1 !== undefined && val2 !== undefined && max2 === undefined) {


                                    if (val2 == w) {

                                        return w + "%"

                                    } else {
                                        //return (decimalToFraction(w / 100))
                                        return `${val1}/${max1}`

                                    }
                                } else if (val1 !== undefined && max1 !== undefined && val2 !== undefined && max2 !== undefined) {

                                    //return (decimalToFraction(w / 100))
                                    if (labelsCenter != undefined) {
                                        if (parseFloat((val1 / max1).toFixed(2)) === parseFloat((w.globals.series[0] / 100).toFixed(2)) && w.globals.seriesNames[0] === labelsCenter[0]) {
                                            return `${val1}/${max1}`
                                        } else if (parseFloat((val2 / max2).toFixed(2)) === parseFloat((w.globals.series[0] / 100).toFixed(2))) {
                                            return `${val2}/${max2}`
                                        }
                                    } else {
                                        if (parseFloat((val1 / max1).toFixed(2)) === parseFloat((w.globals.series[0] / 100).toFixed(2))) {
                                            return `${val1}/${max1}`
                                        } else if (parseFloat((val2 / max2).toFixed(2)) === parseFloat((w.globals.series[0] / 100).toFixed(2))) {
                                            return `${val2}/${max2}`
                                        }
                                    }
                                } else if (val1 !== undefined && max1 === undefined && val2 !== undefined && max2 === undefined) {

                                    return w + "%"
                                } else if (val1 !== undefined && max1 === undefined && val2 === undefined && max2 === undefined) {

                                    return w + "%"
                                } else if (val2 !== undefined && max2 === undefined && val1 === undefined && max1 === undefined) {

                                    return w + "%"
                                } else if (val1 !== undefined && max1 !== undefined && val2 === undefined && max2 === undefined) {

                                    //return (decimalToFraction(w / 100))

                                    return `${val1}/${max1}`
                                } else if (val2 !== undefined && max2 !== undefined && val1 === undefined && max1 === undefined) {

                                    //return (decimalToFraction(w / 100))
                                    return `${val2}/${max2}`
                                }
                            },
                        },
                        total: {
                            fontSize: fontSizeTitle ? fontSizeTitle : '0.97vw',
                            fontWeight: '500',
                            color: '#475467',
                            fontFamily: 'Inter',
                            show: true,

                            formatter: function (w) {

                                //Qui si fa il calcolo del valore centrale quando si clicca su qualsiasi zona del grafico
                                //Si discriminano i casi in cui bisogna restituire una frazione
                                //dai casi in cui bisogna restituire un valore in percentuale
                                if (val1 !== undefined && max1 == undefined && val2 !== undefined && max2 !== undefined) {

                                    if (val1 == w.globals.series[0]) {
                                        return w.globals.series[0] + "%"
                                    } else {
                                        // return (decimalToFraction(w.globals.series[0] / 100))
                                        return `${val2}/${max2}`
                                    }
                                } else if (val1 !== undefined && max1 !== undefined && val2 !== undefined && max2 === undefined) {



                                    if (val2 == w.globals.series[0]) {


                                        return w.globals.series[0] + "%"
                                    } else {
                                   

                                        return `${val1}/${max1}`
                                    }
                                } else if (val1 !== undefined && max1 !== undefined && val2 !== undefined && max2 !== undefined) {

                                    if (labelsCenter != undefined) {
                                        if (parseFloat((val1 / max1).toFixed(2)) === parseFloat((w.globals.series[0] / 100).toFixed(2)) && w.globals.seriesNames[0] === labelsCenter[0]) {
                                            return `${val1}/${max1}`
                                        } else if (parseFloat((val2 / max2).toFixed(2)) === parseFloat((w.globals.series[0] / 100).toFixed(2))) {
                                            return `${val2}/${max2}`
                                        }
                                    } else {
                                        if (parseFloat((val1 / max1).toFixed(2)) === parseFloat((w.globals.series[0] / 100).toFixed(2))) {
                                            return `${val1}/${max1}`
                                        } else if (parseFloat((val2 / max2).toFixed(2)) === parseFloat((w.globals.series[0] / 100).toFixed(2))) {
                                            return `${val2}/${max2}`
                                        }
                                    }

                                    //return (decimalToFraction(w.globals.series[0] / 100))
                                } else if (val1 !== undefined && max1 === undefined && val2 !== undefined && max2 === undefined) {

                                    return w.globals.series[0] + "%"
                                } else if (val1 !== undefined && max1 === undefined && val2 === undefined && max2 === undefined) {

                                    return w.globals.series[0] + "%"
                                } else if (val2 !== undefined && max2 === undefined && val1 === undefined && max1 === undefined) {

                                    return w.globals.series[0] + "%"
                                } else if (val1 !== undefined && max1 !== undefined && val2 === undefined && max2 === undefined) {
                               
                                    return `${val1}/${max1}`
                                } else if (val2 !== undefined && max2 !== undefined && val1 === undefined && max1 === undefined) {

                                    return `${val2}/${max2}`
                                }
                            },
                        }

                    },
                }
            },
            stroke: {
                curve: 'smooth',
                lineCap: 'round',
                width: -4


            },
            fill: {
                type: 'solid',
                opacity: 0.9
            },
            labels: labels,
            colors: colors,
        })


    }, [val1, val2, max1, max2])



    //Funzione per scegliere il colore in base alla soglia
    function getColor(val, max, soglia, colore) {

        //Definisco una soglia di default nel caso in cui non venga impostata
        let sogliaFinale = soglia




        //Logica del colore relativo al range impostato dalla soglia
        if (max == undefined) {
            if (sogliaFinale === undefined) sogliaFinale = thresholdDefaultPerc
            if (val <= sogliaFinale[0]) {
                return colore !== undefined ? colore[0] : '#FF6600';
            } else if (val > sogliaFinale[0] && val <= sogliaFinale[1]) {
                return colore !== undefined ? colore[1] : '#008f39';
            } else {
                return colore !== undefined ? colore[2] : '#FF0000';
            }
        } else {

            if (sogliaFinale === undefined) sogliaFinale = thresholdDefaultFract
            if (val / max <= sogliaFinale[0]) {
                return colore !== undefined ? colore[0] : '#FF6600';
            } else if (val / max > sogliaFinale[0] && val / max <= sogliaFinale[1]) {
                return colore !== undefined ? colore[1] : '#008f39';
            } else {
                return colore !== undefined ? colore[2] : '#FF0000';
            }
        }

    }


    //Funzione per trasformare il numero decimale in frazione
    function decimalToFraction(decimal) {
        const tolerance = 1.0E-9; // Tolleranza per la precisione dei calcoli

        let numerator = 1;
        let denominator = 1;

        while (Math.abs(decimal - numerator / denominator) > tolerance) {
            if (decimal > numerator / denominator) {
                numerator++;
            } else {
                denominator++;
            }
        }

        return `${numerator}/${denominator}`;
    }

    //Definizione iniziale del grafico
    const [options, setOptions] = useState({
        chart: {
            events: {
                click: function (event, chartContext, config) {
                    //Nel caso labelsCenter fosse undefined o null viene fatto un controllo su labels
                    //Poi si gestisce l'alternanza delle circonferenze al click
                    if ((labelsCenter?.length > 1 || labels?.length > 1) && val1 !== undefined && val2 !== undefined) {
                        setSeries((prevSeries) => prevSeries.slice().reverse());
                        setColors((prevColors) => prevColors.slice().reverse());
                        setLabels((prevLabels) => prevLabels.slice().reverse());
                    }
                },

            },
            //L'altezza si prende il 100% del suo contenitore
            height: "100%",
            type: 'radialBar',
        },
        stroke: {
            curve: 'smooth',
            lineCap: 'round',
            width: -4


        },
        fill: {
            type: 'solid',
            opacity: 0.9
        },
        labels: labels,
        colors: colors,
    })



    useEffect(() => {
        // Funzione di gestione del ridimensionamento della finestra
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Aggiungi un listener per il ridimensionamento della finestra
        window.addEventListener('resize', handleResize);

        // Pulisci il listener quando il componente viene smontato
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        // Aggiorna i colori e le labels quando cambia lo stato dei colori o delle labels
        setOptions((prevOptions) => ({
            ...prevOptions,
            colors: colors,
            labels: labels,
            plotOptions: {
                ...prevOptions.plotOptions,
                radialBar: {
                    ...prevOptions.plotOptions.radialBar,
                    dataLabels: {
                        ...prevOptions.plotOptions.radialBar.dataLabels,
                        total: {
                            ...prevOptions.plotOptions.radialBar.dataLabels.total,
                            label: labels[0]
                        },
                    },
                },
            },
        }));
    }, [colors, labels]);



    //Resizing in base alla dimensione della larghezza per fissare l'offset del value
    useEffect(() => {

        const valueOffsetY = fontSizeValue ? (parseFloat(fontSizeValue) * (window.innerWidth) / 100) : 0;

        setOptions((prevOptions) => ({
            ...prevOptions,
            responsive: [
                {
                    breakpoint: 500,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : (valueOffsetY * 0.1)
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },
                {
                    breakpoint: 850,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : (valueOffsetY * 0.2)
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },
                {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : (valueOffsetY * 0.5)
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },
                {
                    breakpoint: 1250,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : (valueOffsetY * 0.5)
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },
                {
                    breakpoint: 1500,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : (valueOffsetY * 0.7)
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },
                {
                    breakpoint: 2000,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : valueOffsetY
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },
                {
                    breakpoint: 3000,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : valueOffsetY
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },
                {
                    breakpoint: 4000,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : valueOffsetY,
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },
                {
                    breakpoint: 5000,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        offsetY: !labelsCenter ? -8 : valueOffsetY,
                                    },

                                }
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                },



            ],
        }))
    }, [windowWidth])

    //In questo UseEffect si impostano i valori iniziali derivanti dal componente chiamante
    //per i colors, per le labels e per le series
    //se non viene passato niente ai colors, il grafico prende dei colori a sua scelta
    //se non viene passato niente a labelsCenter, il grafico si prende labels che è settato sopra nello UseState

    useEffect(() => {


        colors[0] = getColor(val1, max1, threshold1, colorCircle1)
        colors[1] = getColor(val2, max2, threshold2, colorCircle2)









        if (labelsCenter) setLabels(labelsCenter)
        // Inizializza la serie in base ai props
        setSeries(

            // var1 percentuale var2 frazionaria
            val1 !== undefined && max1 == undefined && val2 !== undefined && max2 !== undefined ? [val1, (val2 / max2) * 100] :

                // var 1 frazionaria var2 percentuale
                val1 !== undefined && max1 !== undefined && val2 !== undefined && max2 === undefined ? [(val1 / max1) * 100, val2] :

                    //Entambe le variabili frazionarie
                    val1 !== undefined && max1 !== undefined && val2 !== undefined && max2 !== undefined ? [((val1 / max1) * 100), ((val2 / max2) * 100)] :

                        //Entrambe le variabili percentuali
                        val1 !== undefined && max1 === undefined && val2 !== undefined && max2 === undefined ? [val1, val2] :

                            //Entrambe le variabili possono essere usate come unica variabile percentuale
                            val1 != undefined && max1 === undefined && val2 === undefined && max2 === undefined ? [val1] :
                                val2 != undefined && max2 === undefined && val1 === undefined && max1 === undefined ? [val2] :

                                    //Entrambe le variabili possono essere usate come unica variabile frazionaria
                                    val1 != undefined && max1 !== undefined && val2 === undefined && max2 === undefined ? [(val1 / max1) * 100] :
                                        val2 != undefined && max2 !== undefined && val1 === undefined && max1 === undefined ? [(val2 / max2) * 100] :
                                            []

        );


    }, [val1, val2, max1, max2]);
    return (


        <ReactApexChart options={options} series={series} type="radialBar" height={options.chart.height} />

    )
}

export default DesktopMultiDonut