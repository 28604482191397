import { useSelector } from 'react-redux'
import './mainMachineFaultList.css'
import PersMachineFaultList from './subElements/persMachineFaultList'
import { selectFaultFamilyForSelectedMachienry } from '../../structures/root/slices/faultSlice'
import { useEffect, useState } from 'react'
import { getDatiFaultForMachineSelected } from '../../structures/root/slices/dataFaultMachinerySlice'
import { getTicketForMachinerySelected } from '../../structures/root/slices/ticketSlice'
import { getInterventionForMachinerySelected } from '../../structures/root/slices/interventionSlice'
import { getMachineSelected } from '../../structures/root/slices/schedeMachineDetailSlice'
import { dataRotturaRobotMobile } from '../../configurations/strutturaDati/datiMacchine'


const MachineFaultList = () => {
   //lista fault per specifica famiglia di componente selezionato
   //dati sui fault calcolati per specifico macchinario
   const familyMachinerySelected = useSelector(getMachineSelected)?.family

   const faultForSpecificMachinery = useSelector(selectFaultFamilyForSelectedMachienry)
   const dataFault = useSelector(getDatiFaultForMachineSelected)
   //interventi e ticket presentati per macchianrio ancora da risolvere o pianificare
   const interventionsMachinerySelected = useSelector(getInterventionForMachinerySelected)
   const ticketForMachine = useSelector(getTicketForMachinerySelected)




   const defineActionForPredictiveFault = (idFault, listTicket, listIntervention) => {
      /**
       * questa funzione deve restituire per ogni fault che gli viene passato, se questo ha già un
       * intervento in programma o un ticket abilitato da un operatore per quello specifico fault.
       * verrà gestito in futuro anche il comportamento per un eventuale operazione effettuata con la 
       * possibilità di visionare il report generato dall'operazione
       * 
       * innattivo : se non ci sono ticket o interventi
       * da programmare : se attivo un ticket
       * programmato : se è attivo un intervento
       */
      if (listIntervention) {
         if (listIntervention.filter(el => el.fault === idFault).length !== 0) {

            return 2
         } else {
            if (listTicket) {
               if (listTicket.filter(el => el.fault === idFault).length !== 0) {
                  return 1
               }
            }
            return 0
         }
      }

   }


   const [listaFault, setListaFault] = useState([])
   useEffect(() => {

      setListaFault(
         dataFault?.length === 0 && dataFault === undefined
            ?
            dataFault.map(el => {
               return {
                  nome: faultForSpecificMachinery.filter(fault => fault.id === el.idFault)[0].nome,
                  desc: faultForSpecificMachinery.filter(fault => fault.id === el.idFault)[0].desc,
                  comp: faultForSpecificMachinery.filter(fault => fault.id === el.idFault)[0].component,
                  hi: el.hi,
                  RUL: el.RUL,
                  state: defineActionForPredictiveFault(el.idFault, ticketForMachine, interventionsMachinerySelected)
               }
            })
            :
            familyMachinerySelected === "rmh0p"
               ?
               dataRotturaRobotMobile.map((elem) => {
                  return {
                     nome: faultForSpecificMachinery.filter(fault => fault.id === elem.idFault)[0].nome,
                     desc: faultForSpecificMachinery.filter(fault => fault.id === elem.idFault)[0].desc,
                     comp: faultForSpecificMachinery.filter(fault => fault.id === elem.idFault)[0].component,
                     hi: elem.hi,
                     RUL: elem.RUL,
                     state: defineActionForPredictiveFault(elem.idFault, ticketForMachine, interventionsMachinerySelected)
                  }
               })

               :
               []
      )
   }, [dataFault])

   return <PersMachineFaultList
      listaFault={listaFault}
   />
}

export default MachineFaultList