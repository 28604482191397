import React from "react";
import './Button.css';

/**
 * Componente Button per la creazione di pulsanti.
 *
 * @param {string|JSX.Element} children - Contenuto del pulsante.
 * @param {string} className - Classe CSS aggiuntiva per il pulsante.
 * @param {string} type - Tipo di pulsante (es. "submit", "reset", "button").
 * @param {function} onClick - Funzione di callback chiamata quando viene cliccato il pulsante.
 * @param {boolean} disabled - Indica se il pulsante è disabilitato o meno.
 * @param {string} title - Testo che appare quando si passa il mouse sopra il pulsante.
 * @param {Object} style - Stile inline per il pulsante.
 */

export default function Button({
                                 children,
                                 className = "button",
                                 type,
                                 onClick,
                                 disabled = false,
                                 title,
                                 style,
                               }) {
  return (
      <button
          className={className}
          type={type}
          onClick={onClick}
          disabled={disabled}
          title={title}
          style={style}
      >
        {children}
      </button>
  );
}
