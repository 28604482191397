import React from 'react';
import "./mainMultipleVariableDonut.css"
import DesktopMultiDonut from './subElement/desktopMultiDonut';
/**
 * Componente MainMultiDonut
 * @param {Array<String>} labelsCenter - Indicare le labels da settare per il grafico.
 * @param {Array<String>} colorsCircle1 - Indicare i colori in esadecimale per le soglie del primo grafico; (verranno visualizzati nell'ordine in cui li inserisci)
 * @param {Array<String>} colorsCircle2 - Indicare i colori in esadecimale per le soglie del secondo grafico; (verranno visualizzati nell'ordine in cui li inserisci)
 * @param {Array<String>} threshold1 - Indicare i valori per le soglie del primo grafico;
 * @param {Array<String>} threshold2 - Indicare i valori per le soglie del secondo grafico;
 * @param {String} val1 - Indicare il valore della prima variabile.
 * @param {string} val1 - Indicare il valore della seconda variabile.
 * @param {intero} max1 - Indicare se si vuole rendere la prima variabile frazionare.
 * @param {intero} max2 - Indicare se si vuole rendere la seconda variabile frazionare.
 * @param {String} fontSizeTitle - Indicare il valore in vw del titolo;  esempio '2vw'.
 * @param {String} fontSizeValue - Indicare il valore in vw del valore; esempio '4vw'.
 */
const MainMultiDonut = ({ val1, val2, max1, max2, labelsCenter, threshold1, threshold2, colorCircle1, colorCircle2, fontSizeTitle, fontSizeValue }) => {

    //HOW TO USE
    //labelsCenter={['h1','fn']} Esempio di chiamata di labelsCenter;
    //colorsCircle={['#008f39','#FF0000']} Esempio di chiamata di colorsCircle;
    //Se vuoi passare i valori in percentuale basta valorizzare o val1 o val2 (oppure entrambi);
    //Se vuoi passare i valori in frazione (es. val1) allora devi valorizzare max1.

    //CSS
    //Il componente si adatta in altezza e larghezza al contenitore che, appunto, lo contiene.
    //Per deciderne la grandezza, quindi, basta inserirlo in un contenitore e andare a ridimensionare quello.
   
    return (

        (val1 || val2 || max1 || max2) ?
            <DesktopMultiDonut
                val1={val1}
                val2={val2}
                max1={max1}
                max2={max2}
                labelsCenter={labelsCenter}
                threshold1={threshold1}
                threshold2={threshold2}
                colorCircle1={colorCircle1}
                colorCircle2={colorCircle2}
                fontSizeTitle={fontSizeTitle}
                fontSizeValue={fontSizeValue}


            />
            :
            <div className="spinner"></div>


    )

}

export default MainMultiDonut