import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { getMachineSelected } from './schedeMachineDetailSlice';


import { StatusFilters } from './filterNotificationSlice'

import * as subscriptions from '../../../graphql/subscriptions'


import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'


import { createMainteneanceReports, updateInterventions, updateMainteneanceReports } from '../../../graphql/mutations'
import { listMainteneanceReports } from '../../../graphql/queries';

const reportAdapter = createEntityAdapter({
    selectId: (report) => report.id
})


export const subscribeCrteateMainteneanceReport = createAsyncThunk(
    'report/subscriptionCreateMainteneanceReport',
    async (_, { dispatch }) => {
        const subscription = await API.graphql({
            query: subscriptions.onCreateMainteneanceReports,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }).subscribe({
            next: ({ provider, value }) => {

                dispatch(reportAddOne({
                    idIntervention: value.data.onCreateMainteneanceReports.idIntervention,
                    idMachinery: value.data.onCreateMainteneanceReports.idAsset,
                    dataIntervento: value.data.onCreateMainteneanceReports.data,
                    id: value.data.onCreateMainteneanceReports.id,
                    operatore: value.data.onCreateMainteneanceReports.operatore,
                    valoriLettura: value.data.onCreateMainteneanceReports.valoriLetture,
                    costo: value.data.onCreateMainteneanceReports.costo,
                    operazione: value.data.onCreateMainteneanceReports.operation,
                    durata: value.data.onCreateMainteneanceReports.durata,
                    note: value.data.onCreateMainteneanceReports.note,
                    link: "/planning",
                    active: true,
                    attivita: JSON.parse(value.data.onCreateMainteneanceReports.activity),
                    createdAt: value.data.onCreateMainteneanceReports.createdAt,
                    updatedAt: value.data.onCreateMainteneanceReports.updatedAt
                }))
            },
            error: (error) => {
                console.error('Subscription error:', error);
            },
        })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);

export const subscribeModifyMainteneanceReport = createAsyncThunk(
    'report/subscriptionModifyMainteneanceReport',
    async (_, { dispatch }) => {
        const subscription = await API.graphql({
            query: subscriptions.onUpdateMainteneanceReports,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }).subscribe({
            next: ({ provider, value }) => {

                dispatch(reportModifyOne({
                    id: value.data.onUpdateMainteneanceReports.id,
                    //changes: {
                    idIntervention: value.data.onUpdateMainteneanceReports.idIntervention,
                    idMachinery: value.data.onUpdateMainteneanceReports.idAsset,
                    dataIntervento: value.data.onUpdateMainteneanceReports.data,
                    //id: value.data.onUpdateMainteneanceReports.id,
                    operatore: value.data.onUpdateMainteneanceReports.operatore,
                    valoriLettura: value.data.onUpdateMainteneanceReports.valoriLettura,
                    costo: value.data.onUpdateMainteneanceReports.costo,
                    operazione: value.data.onUpdateMainteneanceReports.operation,
                    durata: value.data.onUpdateMainteneanceReports.durata,
                    note: value.data.onUpdateMainteneanceReports.note,
                    link: "/planning",
                    active: true,
                    attivita: JSON.parse(value.data.onUpdateMainteneanceReports.activity),
                    createdAt: value.data.onUpdateMainteneanceReports.createdAt,
                    updatedAt: value.data.onUpdateMainteneanceReports.updatedAt,
                    isDeleted: value.data.onUpdateMainteneanceReports.isDeleted,
                    // }

                }))
            },
            error: (error) => {
                console.error('Subscription error:', error);
            },
        })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);
export const newReport = createAsyncThunk("report/newReport", async ({
    idAsset, idIntervention, data, operatore, valoriLettura, costo, operation, durata, note, activity, isDeleted
}) => {
    const response = await API.graphql({
        query: createMainteneanceReports,
        variables: {
            input: { idAsset, idIntervention, data, operatore, valoriLettura, costo, operation, durata, note, activity, isDeleted }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return {
        costo: response.data.createMainteneanceReports.costo,
        attivita: JSON.parse(response.data.createMainteneanceReports.activity[0]),
        createdAt: response.data.createMainteneanceReports.createdAt,
        updatedAt: response.data.createMainteneanceReports.updatedAt,
        dataIntervento: response.data.createMainteneanceReports.data,
        durata: response.data.createMainteneanceReports.durata,
        id: response.data.createMainteneanceReports.id,
        idMachinery: response.data.createMainteneanceReports.idAsset,
        idIntervention: response.data.createMainteneanceReports.idIntervention,
        note: response.data.createMainteneanceReports.note,
        operazione: response.data.createMainteneanceReports.operation,
        operatore: response.data.createMainteneanceReports.operatore,
        valoriLettura: response.data.createMainteneanceReports.valoriLettura,
        isDeleted: response.data.createMainteneanceReports.isDeleted,
    }
})


export const updateReport = createAsyncThunk('report/updateReport', async ({ id, idAsset, idIntervention, data, valoriLettura, costo, operation, durata, note, activity }) => {
    const response = await API.graphql({
        query: updateMainteneanceReports,
        variables: {
            input: {
                id, idAsset, idIntervention, data, valoriLettura, costo, operation, durata, note, activity
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return {
        costo: response.data.updateMainteneanceReports.costo,
        attivita: JSON.parse(response.data.updateMainteneanceReports.activity[0]),
        createdAt: response.data.updateMainteneanceReports.createdAt,
        updatedAt: response.data.updateMainteneanceReports.updatedAt,
        dataIntervento: response.data.updateMainteneanceReports.data,
        durata: response.data.updateMainteneanceReports.durata,
        id: response.data.updateMainteneanceReports.id,
        idMachinery: response.data.updateMainteneanceReports.idAsset,
        idIntervention: response.data.updateMainteneanceReports.idIntervention,
        note: response.data.updateMainteneanceReports.note,
        operazione: response.data.updateMainteneanceReports.operation,
        operatore: response.data.updateMainteneanceReports.operatore,
        valoriLettura: response.data.updateMainteneanceReports.valoriLettura,
        isDeleted: response.data.updateMainteneanceReports.isDeleted,
    }
})
export const deleteReport = createAsyncThunk('report/deleteReport', async ({ idMachinery, id, isDeleted }) => {
    const idAsset = idMachinery
    const response = await API.graphql({
        query: updateMainteneanceReports,
        variables: {
            input: {
                idAsset, id, isDeleted
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return {
        costo: response.data.updateMainteneanceReports.costo,
        attivita: JSON.parse(response.data.updateMainteneanceReports.activity[0]),
        createdAt: response.data.updateMainteneanceReports.createdAt,
        updatedAt: response.data.updateMainteneanceReports.updatedAt,
        dataIntervento: response.data.updateMainteneanceReports.data,
        durata: response.data.updateMainteneanceReports.durata,
        id: response.data.updateMainteneanceReports.id,
        idMachinery: response.data.updateMainteneanceReports.idAsset,
        idIntervention: response.data.updateMainteneanceReports.idIntervention,
        note: response.data.updateMainteneanceReports.note,
        operazione: response.data.updateMainteneanceReports.operation,
        operatore: response.data.updateMainteneanceReports.operatore,
        valoriLettura: response.data.updateMainteneanceReports.valoriLettura,
        isDeleted: response.data.updateMainteneanceReports.isDeleted,
    }
})
export const getListReport = createAsyncThunk("report/listReport", async (a, { getState }) => {
    /*
    const dataAttuale = new Date().toISOString()
    const dateLastElementString = Object.values(getState().report.entities).sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))[0].updatedAt

    const dateLastElement = new Date(Date.parse(dateLastElementString) + 1000).toISOString()
    */
    const response = await API.graphql({
        query: listMainteneanceReports,
        /*
        variables: {
            filter: {
                updatedAt: {
                    between: [dateLastElement, dataAttuale],

                }
            }
        },
        */
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    const listReport = response.data.listMainteneanceReports.items.map(el => {
        return {

            idMachinery: el.idAsset,
            dataIntervento: el.data,
            id: el.id,
            idIntervention: el.idIntervention,
            operatore: el.operatore,
            valoriLettura: el.valoriLettura,
            costo: el.costo,
            operazione: el.operation,
            attivita: JSON.parse(el.activity),
            durata: el.durata,
            note: el.note,
            link: "/planning",
            active: true,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt,
            isDeleted: el.isDeleted,

        }
    })
    return listReport
})


const reportSlice = createSlice({
    name: "report",
    initialState: reportAdapter.getInitialState(),
    reducers: {
        reportAddMany: reportAdapter.upsertMany,
        reportAddOne: reportAdapter.upsertOne,
        reportModifyOne: reportAdapter.upsertOne,
        //successivamente da sostituire con una promise ed effettuare un salvataggio in locale per minimizzare i dati da salvare su server
        deactiveReportForNotification(state, action) {
            const { id } = action.payload

            const value = state.entities[id.id]
            value.active = false

        },
    },
    extraReducers: bulder =>
        bulder.addCase(newReport.fulfilled, reportAdapter.upsertOne)
            .addCase(newReport.rejected, (state, action) => console.log(state, action))
            .addCase(getListReport.fulfilled, reportAdapter.upsertMany)
            .addCase(getListReport.rejected, (state, action) => console.log(state, action))
            .addCase(updateReport.fulfilled, reportAdapter.upsertOne)
            .addCase(deleteReport.fulfilled, reportAdapter.upsertOne)

})


export default reportSlice.reducer

export const {
    reportAddMany,
    reportAddOne,
    deactiveReportForNotification,
    reportModifyOne
} = reportSlice.actions


export const { selectAll: selectAllReport } = reportAdapter.getSelectors(state => state.report)


export const getReportForMachineSelected = createSelector(
    [selectAllReport, getMachineSelected],
    (state, select) => {
        if (select) {
            return state.filter(el => el.idMachinery === select.idMachinery)
        }
    }
)


export const getReportFilter = createSelector(
    //passiamo lo stato delle notifiche
    selectAllReport,
    //passiamo lo stato delle notifiche
    state => state.notificationFilter,
    //definiamo la funzione che restituisce gli alert in funzioen dello stato delle notifiche
    (state, filt) => {
        if (filt.status === StatusFilters.All) {
            return state
        }
        else {
            return state.filter(el => el.active === true)
        }
    })

export const getReportIncompleteForDaySelected = createSelector(
    selectAllReport,
    state => state.dayCalendar,
    //da verificare se ci sono diversi report durante la giornata che vanno modificati
    (state, dayCalendar) => state
        .filter(el => {
            if (el.dataIntervento ) {
                return el.dataIntervento?.slice(0, 10) === dayCalendar.date
            }
        })
        .filter(el => el.attivita ?
            el.attivita.find(elemento => elemento.complated === false)
            :
            false
        )
)


export const getReportCompleteForDaySelected = createSelector(selectAllReport,
    state => state.dayCalendar,
    (state, dayCalendar) => state
        .filter(el => {
            if (el.dataIntervento) {
                return el.dataIntervento.slice(0, 10) === dayCalendar.date
            }
        }
        )

        .filter(el => el.attivita ?
            !el.attivita.find(elemento => elemento.complated === false)
            :
            true)
)