
//export const mesiAnno = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
export const mesiAnno = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]

//export const dayWeek = ["Sun", "Mon", "Tues", "Wed", "thurs", "Fri", "Sat"]
export const dayWeek = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"]

const giorno = 86400000

export const meseEsteso = (mese) => {
    switch (mese) {
        case "Jan":
            return mesiAnno[0]
        case "Feb":
            return mesiAnno[1]
        case "Mar":
            return mesiAnno[2]
        case "Apr":
            return mesiAnno[3]
        case "May":
            return mesiAnno[4]
        case "Jun":
            return mesiAnno[5]
        case "Jul":
            return mesiAnno[6]
        case "Aug":
            return mesiAnno[7]
        case "Sep":
            return mesiAnno[8]
        case "Oct":
            return mesiAnno[9]
        case "Nov":
            return mesiAnno[10]
        case "Dec":
            return mesiAnno[11]
        default:
            return "no mese"
    }
}




export const ordinaData = (listaEventi) => {

    /*
    //Sto cavolo di sort su chrome non funziona o cmq è una cagata
    listaEventi.sort((a, b)=> {
        if(a.date>=b.date){
            return 1
        }else{
            return 0
        }
    })
    */

    //bubble sort non ottimizzato
    let arrSupport = [...listaEventi]
    for (let i = 0; i < arrSupport.length; i++) {
        for (let j = 0; j < arrSupport.length - 1; j++) {
            if (arrSupport[j].date > arrSupport[j + 1].date) {
                let temp = arrSupport[j]
                arrSupport[j] = arrSupport[j + 1]
                arrSupport[j + 1] = temp
            }
        }
    }

    return arrSupport
}


export const ricercaMeseNumero = (mese) => {
    switch (mese) {
        case "01":
            return mesiAnno[0]
        case "02":
            return mesiAnno[1]
        case "03":
            return mesiAnno[2]
        case "04":
            return mesiAnno[3]
        case "05":
            return mesiAnno[4]
        case "06":
            return mesiAnno[5]
        case "07":
            return mesiAnno[6]
        case "08":
            return mesiAnno[7]
        case "09":
            return mesiAnno[8]
        case "10":
            return mesiAnno[9]
        case "11":
            return mesiAnno[10]
        case "12":
            return mesiAnno[11]
        default:
            return "no mese"
    }
}

export const ricercaLunedi = () => {
    /**
     * la funzione restituisce un valore a 0 a 6 in funzione del giorno della settimana
     * 0 domenica
     * 1 lunedi 
     * ecc
     */
    let giornoSettimana = new Date().getDay()
    let lunediSettimana = new Date(new Date().getTime() - ((giornoSettimana - 1) * giorno))
    return lunediSettimana
}

export const stessoGiornoProssimaSettimana = (data) => {
    return new Date(data.getTime() + 7 * giorno)
}

export const stessoGiornoPassataSettimana = (data) => {
    return new Date(data.getTime() - 7 * giorno)
}

/**
 * Funzione che restituisce un vettore di 42 elementi che rappresentano i giorni del mese
 * più i precedenti e i successivi dei mesi limitrofi ponendo il 1 del mese corrente nella 
 * prima riga incasellato nel giorno effettivo della settimana e a riempimento il resto
 * forse solo febbraio salta questa casisitica ma dato che lo sto sviluppando in questo mese
 * sembra cadere a fagiuolo
 * 
 * prende in ingresso la Date()
 */
export const setBasicCalendar = (argDate) => {
    let oggi = new Date()
    //primo giorno del mese corrente alle ore 00:00
    let dataMeseAttuale = new Date(argDate.getFullYear(), argDate.getMonth(), 1)

    let valoriDaInizializzareInizialmente = dataMeseAttuale.getDay()
    let vettoreCalendario = []
    //mi prendo gli ultimi giorni del mese precedente da inserire nel calendario
    let dataMesePrecedente = new Date(argDate.getFullYear(), argDate.getMonth(), 0)


    //mi prendo gli ultimi giorni del mese precedenti
    for (let i = 1; i <= valoriDaInizializzareInizialmente; i++) {
        vettoreCalendario.push({
            day: dataMesePrecedente.getDate() - valoriDaInizializzareInizialmente + i,
            month: argDate.getMonth() === 0 ? 12 : argDate.getMonth(),
            year: argDate.getMonth() === 0 ? argDate.getFullYear() - 1 : argDate.getFullYear(),
            colorDayNumber: `lightgray`,
            colorBackground: "transparent"
        })
    }
    let ultimoGiornoMeseAttuale = new Date(argDate.getFullYear(), argDate.getMonth() + 1, 0).getDate()
    /**
     * for per la creazione del vetore che andrà a riempire il calendario
     */
    for (let i = 1; i <= ultimoGiornoMeseAttuale; i++) {
        /**
         * se si sta visualizzando i mesi precedenti rispetto a quello attuale allora 
         * tutte le date devono essere considerate vecchie 
         * in questo caso valuto tutte le date precedenti alle 00:00 del giorno attuale fino a che 
         * non arrivo al giorno odierno
         */

        if (argDate.getTime() < new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime()) {

            vettoreCalendario.push({
                day: i,
                month: argDate.getMonth() + 1,
                year: argDate.getFullYear(),
                colorDayNumber: `black`,
                colorBackground: "transparent"
            })

        } else {
            /**
             * se il primo gionro del mese successivo a quello attuale è inferioriore o uguale a  
             * quello che vogliamo vedere allora deve essere tut
             */

            if (new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).getTime() <= argDate.getTime()) {
                vettoreCalendario.push({
                    day: i,
                    month: argDate.getMonth() + 1,
                    year: argDate.getFullYear(),
                    colorDayNumber: `black`,
                    colorBackground: "transparent"
                })
            } else {
                if (argDate.getDate() >= i) {
                    vettoreCalendario.push({
                        day: i, month: argDate.getMonth() + 1,
                        year: argDate.getFullYear(),
                        colorDayNumber: `black`,
                        colorBackground: "transparent"
                    })
                } else {
                    //aggiungere controllo sulla data quelle precedenti alla data attuale devono essere sempre rosse
                    vettoreCalendario.push({
                        day: i,
                        month: argDate.getMonth() + 1,
                        year: argDate.getFullYear(),
                        colorDayNumber: `black`,
                        colorBackground: "transparent"
                    })
                }
            }


        }

    }

    //carico i giorni mancanti del prossimo mese per completare la scacchiera
    let giornoSettimanaDellUltimoGiornoMeseAttuale = new Date(argDate.getFullYear(), argDate.getMonth() + 1, 0).getDay()

    for (let i = 1; i < 7 - giornoSettimanaDellUltimoGiornoMeseAttuale; i++) {
        vettoreCalendario.push({
            day: i,
            month: argDate.getMonth() + 2 === 13 ? 1 : argDate.getMonth() + 2,
            year: argDate.getMonth() + 2 === 13 ? argDate.getFullYear() + 1 : argDate.getFullYear(),
            colorDayNumber: `lightgray`,
            colorBackground: "transparent"
        })
    }

    /**
     * verifichiamo se il mese visualizzato è quello attuale e nel caso andiamo a selezionare la 
     * data odierna per successivamente andare ad aggiungere una formattazione dedicata
     */
    /**
     *  DA VERIFICARE PER FEBBRAIO CHE HA 28 GIORNI AL POSTO DI 30/31
     */

    if (!(new Date(oggi.getFullYear(), oggi.getMonth(), 1) > dataMeseAttuale || new Date(oggi.getFullYear(), oggi.getMonth(), 1) < dataMeseAttuale)) {

        for (let i = valoriDaInizializzareInizialmente; i < vettoreCalendario.length; i++) {
            if (vettoreCalendario[i].day === oggi.getDate()) {
                if (i < 15 + valoriDaInizializzareInizialmente && oggi.getDate() < 15) {

                    vettoreCalendario[i].colorBackground = "rgba(255,220,40,.15)"
                }
                if (i > 15 + valoriDaInizializzareInizialmente && oggi.getDate() > 15) {
                    vettoreCalendario[i].colorBackground = "rgba(255,220,40,.15)"
                }

            }
        }
    }
    return (vettoreCalendario)
}




export const dataFormatoGMA=(stringa="2000-12-21")=>{
    return stringa.slice(8, 10) + "-" + stringa.slice(5, 7) + "-" + stringa.slice(0, 4)
}



export const giorniInizioAnno=()=>{
    const millisecAttuali = new Date().getTime()

    const millisecInizioAnno = new Date(new Date().getFullYear(), "00", "01").getTime()

    const giorniInizioAnno = (millisecAttuali - millisecInizioAnno) / giorno

    return giorniInizioAnno

}