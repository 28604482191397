
import { useSelector } from "react-redux"

import { useEffect, useState } from "react"

import { selectAllReport } from "../../../structures/root/slices/reportSlice"
import Modal from "../../DialogModal/mainDialogModal"
import { selectAllIntervention } from "../../../structures/root/slices/interventionSlice"
import { ElementGeneralInfoIntervention, ElementoIntervento, ElementoReportVisualizzazione } from "./elementiReportStory"
import { listaInterventiReportStorico } from "../../../functions/interventiPeriodici"


const PersMainteneanceStory = ({ isOpen, datiIntervento, closeScheda }) => {



    const listaInterveti = useSelector(selectAllIntervention)
    const listaReport = useSelector(selectAllReport)


    const [isOpenStoricoInside, setIsOpenStoricoInside] = useState(isOpen)

    const [listElement, setListElement] = useState([])
    /**
     * recupero di tutte le informaizoni relative all'intervento aperto: se è il primo intervento oppure è un intervento relativo ad uno periodico e tutti gli altri interventi di quella specifica lavorazione in oltre vanno ricercati per ogni id intervento tutti i report relativi.
     *
     * In fine inseriamo tutto in un vettore lo ordiniamo per date e creiamo dei render in funzione della tipologia di elemento presente nel vettore
     */

    useEffect(() => {

        setListElement(listaInterventiReportStorico(datiIntervento, listaInterveti, listaReport))



    }, [listaInterveti, datiIntervento])




    return (
        <Modal
            show={!isOpenStoricoInside}
        >
            <div className="bodyMainReportMainteneanceStory">
                <div
                    className="boxTitleReportMainteneanceStory"
                >
                    <h4
                        className="elementTitleReportMainteneanceStory"
                    >{listElement[0] ? listElement[0].title : ""}</h4>
                    <h3
                        className="elementClosignSchedaReportMainteneanceStory"
                        onClick={() => closeScheda()}
                    >x</h3>
                </div>
                <ul>

                    {
                        /**
                         * qui verrà aggiunta la funzione per discriminare quale elemento deve essere visualizzato
                         */
                        listElement[0]?.period !== "" ?
                            listElement
                                .filter((element) => element.isDeleted !== true)
                                .map((element, index) => {


                                    if (element.title && index === 0) {
                                        //elemento di tipo intervento primo creato
                                        return <ElementGeneralInfoIntervention
                                            intervento={{
                                                ...element,
                                                discendente: element.period === "" ? "p" : ""
                                            }}
                                            index={index}
                                            key={index}

                                            closeBackground={setIsOpenStoricoInside}
                                        />

                                    }
                                    if (element.title && index !== 0) {
                                        //ritorna elemento riprogrammato
                                        return <ElementoIntervento
                                            intervento={element}
                                            primoIntervento={listElement[0]}
                                            index={index}
                                            key={index}
                                        />
                                    }
                                    if (element.idIntervention) {
                                        //ritorna report salvato
                                        return <ElementoReportVisualizzazione
                                            report={element}
                                            listElement={listElement}
                                            primoIntervento={listElement[0]}
                                            index={index}
                                            key={index}
                                        />
                                    }
                                })
                            :
                            listElement.filter((element) => element.isDeleted !== true).map((element, index) => {
                                if (element.title && index === 0) {
                                    //elemento di tipo intervento primo creato
                                    return <ElementGeneralInfoIntervention
                                        intervento={element}
                                        index={index}
                                        key={index}

                                        closeBackground={setIsOpenStoricoInside}
                                    />

                                }
                                if (element.title && index !== 0) {
                                    //ritorna elemento riprogrammato
                                    return <ElementoIntervento
                                        intervento={element}
                                        primoIntervento={listElement[0]}
                                        index={index}
                                        key={index}
                                    />
                                }
                                if (element.idIntervention) {
                                    //ritorna report salvato
                                    return <ElementoReportVisualizzazione
                                        report={element}
                                        listElement={listElement}
                                        primoIntervento={listElement[0]}
                                        index={index}
                                        key={index}
                                    />
                                }
                            })
                    }



                </ul>


            </div>




        </Modal>
    )
}

export default PersMainteneanceStory


