import { meseEsteso, dayWeek } from '../../../../functions/dateTime'

const HeaderHomeMadeCalendar = () => {

    return (
        <div className='boxHomeMadeCalendar boxHeaderHomeMadeCalendar'>
            {
                dayWeek.map((el, index) => <span
                    key={index}
                    className="elementHeaderHomeMadeCalendar"
                >{el}</span>
                )
            }
        </div>
    )
}
export default HeaderHomeMadeCalendar