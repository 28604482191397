
import { NextWeek } from "@mui/icons-material";
import React, { useState } from "react";

import { titleColor } from "../../../configurations/generalStye";
import { ricercaLunedi, stessoGiornoProssimaSettimana, stessoGiornoPassataSettimana } from "../../../functions/dateTime";
import { selezioneColore } from "../../../functions/generiche";
import PersOpenTicket from "./persOpenTicket";
import { HeaderRulPersMachineFaultList, ElementRulPersMachineFaultList } from "./rulPersMachineFaultList";

const PersMachineFaultList = ({ listaFault }) => {
    
   
    const [selectWeek, setSelectWeek] = useState(JSON.stringify(ricercaLunedi()))

    const updateNextWeek = (dataString) => {
        let dataProssimaSettimana = stessoGiornoProssimaSettimana(new Date(JSON.parse(dataString)))
        setSelectWeek(JSON.stringify(dataProssimaSettimana))
    }

    const updatePastWeek = (dataString) => {
        let dataProssimaSettimana = stessoGiornoPassataSettimana(new Date(JSON.parse(dataString)))
        setSelectWeek(JSON.stringify(dataProssimaSettimana))
    }

    // da sostituire quando implemento redux e va ad avviare la routine di ticketing
    const cambioStatoProvvisorio = () => {
    }

    return (
        <div className="bodyDesktopMachineFaultList" >
            <h3
                className="TitoloDesktopMachineFaultList"
                style={{ "color": titleColor }}
            >Faults List</h3>
            <div className="headTableDesktopMachineFaultList">
                <h5 className="ElemntStandardDesktopMachineFaultList"
                    style={{ flex: "2" }}
                >Name</h5>

                <h5 className="ElemntStandardDesktopMachineFaultList"
                    style={{ flex: "2" }}
                >Components</h5>
                <h5 className="ElemntStandardDesktopMachineFaultList"
                    style={{ flex: "2" }}
                >Description</h5>
                <h5 className="ElemntStandardDesktopMachineFaultList"
                    style={{ flex: "1" }}
                >HI</h5>

                <div className="ElemntLongDesktopMachineFaultList">
                    <HeaderRulPersMachineFaultList
                        selectedDate={selectWeek}
                        funProssW={updateNextWeek}
                        funPassW={updatePastWeek}
                    />
                </div>
                <h5 className="ElemntStandardDesktopMachineFaultList"
                    style={{ flex: "2" }}
                >
                    State
                </h5>
            </div>
            <div className="RowDesktopMachineFaultList">
                {listaFault.map((el, index) => {
                    return (
                        <div
                            className={`RowTableDesktopMachineFaultList ${index % 2 ? "backgroundWhite" : "backgroundBlue"}`}
                            key={index}
                        >
                            <h6
                                className="ElemntStandardDesktopMachineFaultList testoStandardDesktopMachineFaultList"
                                style={{ flex: "2" }}
                            >{el.nome}</h6>

                            <h6
                                className="ElemntStandardDesktopMachineFaultList testoStandardDesktopMachineFaultList"
                                style={{ flex: "2" }}
                            >{el.comp}</h6>
                            <h6
                                className="ElemntStandardDesktopMachineFaultList testoStandardDesktopMachineFaultList"
                                style={{ flex: "2" }}
                            >{el.desc}</h6>
                            <h6
                                className="ElemntStandardDesktopMachineFaultList"
                                style={{ color: `${selezioneColore(el.hi)}`, flex: "1" }}
                            >{el.hi} %</h6>
                            <div className="ElemntLongDesktopMachineFaultList">
                                <ElementRulPersMachineFaultList
                                    selectedDate={selectWeek}
                                    defeatDate={el.RUL}
                                />
                            </div>
                            <div className="ElemntStandardDesktopMachineFaultLis"
                                style={{ flex: "2" }}
                            >
                                
                                <PersOpenTicket
                                    stateTicket={el.state}
                                    changeState={cambioStatoProvvisorio}
                                />
                            </div>

                        </div>)
                })
                }
            </div>
        </div>
    )
}
export default PersMachineFaultList