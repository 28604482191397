import React from "react";

import { DataGrid } from '@mui/x-data-grid';
import { Input } from '@mui/material';

import LogoFiltro from '../../../icon/filter.svg';
import { titleColor } from '../../../configurations/generalStye';



export default class DesktopMaterialExtendTableAlert extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            columns: [
                { field: 'id', headerName: 'ID', width: 40 },
                { field: 'name', headerName: 'Name', width: 130 },
                { field: 'description', headerName: 'Description', width: 300 },
                {
                    field: 'stat',
                    headerName: 'Status',
                    width: 120,
                },
                /*
                {
                  field: 'fullName',
                  headerName: 'Full name',
                  description: 'This column has a value getter and is not sortable.',
                  sortable: false,
                  width: 160,
                  valueGetter: ( GridValueGetterParams) =>`${GridValueGetterParams.row.firstName || ''} ${GridValueGetterParams.row.lastName || ''}`
                },
                */
                {
                    field: 'date',
                    headerName: 'Date',
                    width: 100,
                },
                {
                    field: 'risk',
                    headerName: 'Risk',
                    type: 'number',
                    width: 40,
                },
                {
                    field: 'machineId',
                    headerName: 'Machine ID ',
                    width: 90,
                },
                { field: 'note', headerName: 'Note', width: 300 },
            ],




        }
    }


    render() {
        
        return (
            <div className="bodyMaterialExtendTableAlert">
                <div className="titleMaterialExtendTableAlert">
                    <h3
                        style={{ "color": `${titleColor}` }}
                    >
                        Alert list
                    </h3>
                    <div style={{ "display": "flex", "alignItems": "center" }}>
                        <img
                            src={LogoFiltro}
                            width={20}
                            style={{ "marginRight": "10px" }}
                            alt="logo"
                        />
                        <Input />
                    </div>
                </div>
                <div className="bodyTableMaterialExtendTableAlert">
                    <DataGrid
                        rows={this.props.rows}
                        columns={this.state.columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        onRowClick={(a) => this.props.letturaRowId(a.id)}
                        density="compact"
                    />
                </div>
            </div>
        )
    }
}