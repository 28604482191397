
export const eventiPeriodiciRicadentiNelMeseSelezionato = (listIntervention, startPeriod = "2023-01-01", endPeriod = "2023-01-15") => {
    const GIORNO = 86400000 //millisecondi in un giorno

    //lista interventi periodici escludendo quelli soft eliminated
    const listInterventionPeriodici = listIntervention.filter(elem => elem.period !== "").filter(el => el.isDeleted !== true)
    //millisendi della prima data presente in calendario
    const millisecondiDelPrimoGiornoAttualeCalendario = new Date(startPeriod).getTime()
    //millisendi dell'ultima' data presente in calendario
    const millisecondiDellUltimoGiornoCalendarioAttuale = new Date(endPeriod).getTime() + GIORNO - 10

    let envent = []
    /**
     * per tutti gli interventi periodici presenti devo verificare che tali interventi ricadano nel mese selezionato nessuna una o più volte
     */
    listInterventionPeriodici.forEach(el => {
        //verifica ulteriore in caso di elementi non filtrati precedentemente
        if (el.period === 0 || el.period === "0") {
        } else {
            //qui sono presenti solo elelemti che hanno un valore interero maggiore di 0 che indica il periodo di periodicità
            //calcolo il tempo che intercorre tra la data dell'intervento programmato e l'inizio del periodo visualizzato nel calendario e lo divido per l'intervello tra due eventi uguali programmati 
            const nInterventiPrecedentiDataInizioMese =
                Math.ceil((millisecondiDelPrimoGiornoAttualeCalendario / GIORNO - new Date(el.date).getTime() / GIORNO)
                    / (Number(el.period)))
            //il valore è sempre un multiplo di giorni poiche consideriamo il posizionamento giornaliero escludendo l'ora dell'intervento
            //se il valore è negativo allora l'intervento periodico è stato creato nel mese
            if (nInterventiPrecedentiDataInizioMese < 0) {

                const periodTimeAll = millisecondiDellUltimoGiornoCalendarioAttuale - new Date(el.date).getTime()

                const numeroDiInterventiCalendario = Math.floor(periodTimeAll / (Number(el.period) * GIORNO))

                let i = 0
                do {


                    envent.push({
                        ...el,
                        date: new Date(new Date(el.date).getTime() + (i * GIORNO * Number(el.period))).toISOString().slice(0, 10),
                        id: `virtual-${i}`,
                        idPrevIntervention: el.id,
                        period: ""
                    })
                    i = i + 1
                } while (i < numeroDiInterventiCalendario + 1)


            } else {
                //intervento definito prima della data del calendario selezionata
                const periodTimeAll = millisecondiDellUltimoGiornoCalendarioAttuale - millisecondiDelPrimoGiornoAttualeCalendario
                const numeroDiInterventiCalendario = Math.floor(periodTimeAll / (Number(el.period) * GIORNO))

                let i = 0
                while (i < numeroDiInterventiCalendario + 1) {
                    envent.push({
                        ...el,
                        date: new Date(new Date(el.date).getTime() + (GIORNO * Number(el.period) * (nInterventiPrecedentiDataInizioMese + i)))
                            .toISOString().slice(0, 10),
                        id: `virtual-${Math.ceil(nInterventiPrecedentiDataInizioMese) + i}`,
                        idPrevIntervention: el.id,
                        period: ""
                    })
                    i = i + 1
                }
            }


        }

    })

    return envent


}


export const ricercaInterventiFogliaEArricchimento = (listInterventionToSearch, listIntervention, listaReport) => {
    /**
     * a partire da una lista di interventi restituisce solo gli interventi "foglia" cioè gli interventi che non hanno alcun riferimento con interventi successivi, in pratica sono gli ultimi interventi a valle di riprogrammazione
     */
    let listLeaf = []

    const listInterventionNotDeleted = listInterventionToSearch.filter(el => el.isDeleted !== true)
    const listInterventionToResearch = listIntervention.filter(el => el.isDeleted !== true)

    listInterventionNotDeleted.forEach(el => {
        const tempRicerca = listInterventionToResearch.find(eleme => el.id === eleme.idPrevIntervention)
        //con questa condizione verifico che l'elemento è una foglia
        if (!tempRicerca) {
            //verifico di avere tutti i dati a diposizione sullo specifico intervento

            //arricchisco la foglia con i dati del report se esiste 
            //mi ricavo tutti i report e gli interventi collegato a questo che stiamo analizzando
            const reportCorrelati = listaInterventiReportStorico(el, listIntervention, listaReport)
                //filtro quelli che sono stati eiminati
                .filter(a => a.isDeleted !== true)
                //filtro i report
                .filter(a => a.idIntervention !== undefined)

            const elenchiAttivita = reportCorrelati.map(a => a.attivita)

            const sommaAttivita = el.attivita ?
                el.attivita.length !== 0
                    ?
                    el.attivita.map(a => {
                        //per ogni vettore di elenchiattività devo prendere gli elementi che hanno come attività la specifica che stiamo analizzando
                        const elencoDeiDatiSingolaAttivita = elenchiAttivita
                            //per ogni vettore di attività dati dagli eventuali vari report recupero solo l'attività in analisi in questo specifico momento ottendo un vettore di attività tutti con lo stesso id
                            .map(b => {
                                return { ...b.find(c => c.id === a.id) }
                            })
                        const elementoCompletato = elencoDeiDatiSingolaAttivita.find(b => b.complated === true)
                        if (elementoCompletato) {
                            return {
                                ...a,
                                complated: true,
                                timeEff: elementoCompletato.timeEff
                            }
                        } else {
                            return {
                                ...a,
                                complated: false,
                                timeEff: ""
                            }
                        }
                    }
                    )
                    :
                    // se ci sono report allora devo dire che l'attività non è stata completata, in realtà questa codizione è posta solo come sicurezza per eventuali bug in teoria il codice non dovrebbe mai analizzare questa possibilità
                    reportCorrelati.length !== 0 ?
                        [{ complated: true }]
                        :
                        [{ complated: false }]
                :
                reportCorrelati.length !== 0 ?
                    [{ complated: true }]
                    :
                    [{ complated: false }]

            listLeaf.push({
                ...el,
                attivita: sommaAttivita
            })


        }
    })


    return listLeaf
}

/**
 * prende in ingresso un vettore di interventi con i dati dei report e li ordina in funzione dello stato: prima gli interventi che non hanno un report collegato e che quindi non sono stati ancora eseguiti nemmeno parzialmente; poi gli interventi parziali ed in fine quelli completati
 */


export const arricchimentoVirtuali = (list) => {
    let elaborata = []
    list.forEach(el => {
        if (el.attivita.length !== 0) {
            elaborata.push({
                ...el,
                attivita: el.attivita.map(element => {
                    return {
                        ...element,
                        complated: false
                    }
                })
            })
        } else {
            elaborata.push({
                ...el,
                attivita: [{
                    complated: false
                }]
            })
        }
    })

    return elaborata
}

export const ordinamentoInterventiArricchiti = (interventi) => {
    /**
     * Gli interventi che vengono arricchiti devono essere ordinati in funzione del loro stato di completamento
     * per primi devono essere visualizzati quelli non completi e successivamente quelli che che sono stati completati
     */
    return interventi.sort((a, b) => {
        /**
         * se la ricerca di un elemento in attività risulta essere falso allora non devo effettuare la trasposzione
         */
        if (a.attivita.find(el => el.complated === false) === undefined) {
            return 1
        } else if (b.attivita.find(el => el.complated === false) === undefined) {
            return -1
        }
        return 0
    })

}


/**
 * funzione per ricavare tutti gli interventi e report correlati ad uno specifico intervento 
 */

export const listaInterventiReportStorico = (datiIntervento, listaInterveti, listaReport) => {
    //recupero tutti interventi relativi a questo selezionato
    if (datiIntervento.id.slice(0, 7) === "virtual") {
        //se l'intervento è virtual allora ha un unico collegamento con l'intervento periodico e non ha report
        return [listaInterveti.find(el => el.id === datiIntervento.idPrevIntervention), datiIntervento]
    } else {
        //PARTE PER INTERVENTI NON VIRTUALI
        if (datiIntervento.idIntervention !== undefined) {

            let listPrev = [listaInterveti.find(el => el.id === datiIntervento.idIntervention)]
            let finishPrevInterv = true
            while (finishPrevInterv) {

                const prevInt = listaInterveti.find(el => el.id === listPrev[listPrev.length - 1].idPrevIntervention)
                if (prevInt) {
                    listPrev.push(prevInt)
                } else {
                    finishPrevInterv = false
                }
            }

            let listPost = [listPrev[0]]
            let finishPostInter = true
            while (finishPostInter) {
                const postInt = listaInterveti.find(el => el.idPrevIntervention === listPost[listPost.length - 1].id)
                if (postInt) {
                    listPost.push(postInt)
                } else {
                    finishPostInter = false
                }
            }

            let listInt = []
            if (listPrev.length === 1) {
                listInt = listPost
            } else {
                listInt = [...listPrev, ...listPost.slice(1, listPrev.length - 1)]
            }



            let listRep = []
            listInt.forEach(el => {
                const rep = listaReport.filter(elemento => elemento.idIntervention === el.id)
                if (rep) {
                    listRep = [...listRep, ...rep]
                }//da finire con l'inserimento in una lista unica e rimozione dei doppioni
            })

            //ordino per id ed elimino tutti gli elementi con id uguali e successivamente lo ordino per data
            const listElem = [...listInt, ...listRep]
                .sort((a, b) => a.id < b.id)
            const listOrder = listElem
                .filter((item, pos, ary) => !pos || item.id != ary[pos - 1].id)
            const listDateOrder = listOrder
                .sort((a, b) => a.createdAt.localeCompare(b.createdAt))


            //richiesta dei dati relativi agli interventi richiesti
            return listDateOrder
        } else {
            // RECUPERO I DATI A PARTIRE DA UN INTERVENTO
            let listPrev = [datiIntervento]

            //salvo tutti gli interventi precedente all'attuale
            let finishPrevInterv = true
            while (finishPrevInterv) {
                const prevInt = listaInterveti.find(el => el.id === listPrev[listPrev.length - 1].idPrevIntervention)

                if (prevInt) {

                    listPrev.push(prevInt)
                } else {
                    finishPrevInterv = false
                }
            }
            let listPost = [datiIntervento]
            //salvo tutti gli interventi successivi all'attuale

            let finishPostInter = true
            while (finishPostInter) {
                const postInt = listaInterveti.find(el => el.idPrevIntervention === listPost[listPost.length - 1].id)
                if (postInt) {
                    listPost.push(postInt)
                } else {
                    finishPostInter = false
                }
            }

            let listInt = []
            if (listPrev.length === 1) {
                //se la lista dei precedenti contiene un solo elemento allora in questo caso abbiamo solo l'intervento che stiamo visualizzando
                
                listInt = listPost
            } else {
                listInt = [...listPrev.slice(1, listPrev.length), ...listPost]
            }

            let listRep = []
            listInt.forEach(el => {
                const rep = listaReport.filter(elemento => elemento.idIntervention === el.id)
                if (rep) {
                    listRep = [...listRep, ...rep]
                }//da finire con l'inserimento in una lista unica e rimozione dei doppioni
            })

            //ordino per id ed elimino tutti gli elementi con id uguali e successivamente lo ordino per data
            const listElem = [...listInt, ...listRep]
                .sort((a, b) => a.id < b.id)
            const listOrder = listElem
                .filter((item, pos, ary) => !pos || item.id != ary[pos - 1].id)
            const listDateOrder = listOrder
                .sort((a, b) => a.createdAt.localeCompare(b.createdAt))

            //richiesta dei dati relativi agli interventi in elenco
            return listDateOrder
        }

    }
}