export const dataOdierna = new Date()
// ogni giorno sono 86400000

//new Date().toISOString().slice(0, 10)
export const giorno = 86400000

/**
 * 
 * nella lista degli eventi di manutenzione valutare di inserire un flag di completamento,
 * questo flag viene utilizzato per non scaricare l'evento sul front end  
 * l'id dell'evento viene poi utilizzato per l'id del report quindi da backend sarà possibile controntare tutti gli id dall'
 * alert fatto dall'algoritmo o dall'operatore alla programmazione dell'intervento fino al report
 */
export const listaEventiManutentivi = [
    /*
    {
        id: "1",
        title: "",
        date: "",
        durata: "3h 15m",
        //idPlant e reparto sono recuperabili dai macchinari
        idPlant: "A1",
        reparto: "laminazioni",
        idMachinery: "123abc",
        backgroundColor: "white",
        borderColor: "white",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        // idDataFault è per collegarlo ad un fault identificato dagli algoritmi
        idDataFault: "",
        // se è un codice viene tradotto con uno dei fault da noi identificati altrimenti è un indicazione dell'operatore che ha richiesto l'intervento
        fault: "acm0-cec"

    }
    */
    /*
    ,
    {
        id: "11",
        title: "cambio olio bis",
        date: new Date(dataOdierna.getTime() + 3 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "123abc",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"acm0-c0c"
    },
    {
        id: "21",
        title: "cambio olio bis",
        date: new Date(dataOdierna.getTime() + 3 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "123abc",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"acm0-c0c"
    },{
        id: "31",
        title: "cambio olio bis",
        date: new Date(dataOdierna.getTime() + 3 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "123abc",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"acm0-c0c"
    },
    {
        id: "2",
        title: "cambio olio 1",
        date: new Date(dataOdierna.getTime() + 2 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "iji788",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"acm0-cec"
    },
    {
        id: "3",
        title: "cambio olio2",
        date: new Date(dataOdierna.getTime() + 1 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "85eu93",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:""
    },
    {
        id: "4",
        title: "cambio olio3",
        date: new Date(dataOdierna.getTime() + 0 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "85eu93",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"acm0-e01"
    },
    {
        id: "12",
        title: "cambio olio3",
        date: new Date(dataOdierna.getTime() - 1 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "85eu93",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"prova meccanica"
    },

    {
        id: "13",
        title: "cambio olio3",
        date: new Date(dataOdierna.getTime() - 2 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "123abc",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"verifica qualcosa"
    },
    {
        id: "17",
        title: "cambio olio3",
        date: new Date(dataOdierna.getTime() - 2 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "123abc",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"verifica qualcosa"
    },
    {
        id: "14",
        title: "cambio olio3",
        date: new Date(dataOdierna.getTime() - 3 * giorno).toISOString().slice(0, 10),
        durata: "3h 15m",
        impianto: "A1",
        reparto: "laminazioni",
        idMachinery: "123abc",
        backgroundColor: "orange",
        borderColor: "orange",
        operatore: "Franco",
        creatoreEvento: "Benedetto",
        note: "cambio olio importante",
        livelloEvento: "medio",
        idDataFault:"",
        fault:"test non distruttivo"
    }
    */
]



export const ticketList = [
    /*
    {
        id: "1",
        title: "controllo cuscinetti",
        data: new Date(dataOdierna.getTime() - 1 * giorno).toISOString().slice(0, 10),
        fault: "acm0-gff",
        idMachinery: "123abc",
        note: "verifica del fault segnalato dalla piattaforma",
        operatore: "Benedetto"
    },
    {
        id: "2",
        title: "controllo cuscinetti",
        data: new Date(dataOdierna.getTime() - 3 * giorno).toISOString().slice(0, 10),
        fault: "acm0-gff",
        idMachinery: "85eu93",
        note: "verifica del fault segnalato dalla piattaforma",
        operatore: "Benedetto"
    },
    */
]


/* export const listaSegnalazioniAlgoritmi = [
    {
        id: "77b2-4172-b0a9",
        plantId:"ade31f4c-9195-4ba5",
        idMacchine:"",
        fault: "Inner race fault",
        avviso: "Livello medio",
        active: true
    },
    {
        id: "77b3-4172-b0a9",
        titolo: "Disallineamento asse",
        avviso: "Livello alto",
        link: "/exploreplant/123abc",
        active: false
    },
    {
        id: "77b4-4172-b0a9",
        titolo: "Prossimo intervento",
        avviso: "16 Feb",
        link: "/planning",
        active: false
    },
    {
        id: "77b5-4172-b0a9",
        titolo: "Inner race fault",
        avviso: "Livello medio",
        link: "/exploreplant/85eu93",
        active: true
    },
    {
        id:"77b6-4172-b0a9",
        titolo: "Disallineamento asse",
        avviso: "Livello alto",
        link: "/exploreplant/123abc",
        active: false
    },
    {
        id:"77b7-4172-b0a9",
        titolo: "Prossimo intervento",
        avviso: "16 Feb",
        link: "/planning",
        active: false
    },
    {
        id:"77b8-4172-b0a9",
        titolo: "Inner race fault",
        avviso: "Livello medio",
        link: "/exploreplant/85eu93",
        active: true
    },
    {
        id:"77b9-4172-b0a9",
        titolo: "Disallineamento asse",
        avviso: "Livello alto",
        link: "/exploreplant/123abc",
        active: false
    },
    {
        id:"77b2-4172-b2a9",
        titolo: "Prossimo intervento",
        avviso: "16 Feb",
        link: "/planning",
        active: false
    }
]
 */

//questi poi sono gli interventi che faranno parte dei report per singolo macchinario
/**
 * tipoOperazione: sostituzione, ispezione, riparazione
 */

export const listaReport =
    [
        /*
        {
            id: "1",
            idMachinery: "123abc",
            dataIntervento: "2022-11-15",
            operatore: "Franco Pannella",
            Fault: "acm0-c0c",
            operazione: "sostituzione Rotore",
            durataIntervento: "",
            Note: "Sostituzione del rotore a valle di una verifica acustica e a test di vibrazioni"
        },
        {
            id: "2",
            idMachinery: "123abc",
            dataIntervento: "2022-05-15",
            operatore: "Edoardo De Lisi",
            Fault: "Rottura ghiera",
            operazione: "Sostituzione cuscinetto",
            durataIntervento: "",
            Note: "cuscinetto usurato ed inviato al reparto di ripristino per tentare il recupero del pezzo, proviamo a scrivere tanta roba per vedere se in qualche modo si riesce a farlo uscire dallo spazio a diposizione"
        },
        {
            id: "3",
            idMachinery: "85eu93",
            dataIntervento: "2022-01-15",
            operatore: "Franco Pannella",
            Fault: "acm0-c0c",
            operazione: "rifasamento degli avvolgimenti",
            durataIntervento: "",
            Note: "cuscinetto usurato ed inviato al reparto di ripristino per tentare il recupero del pezzo, proviamo a scrivere tanta roba per vedere se in qualche modo si riesce a farlo uscire dallo spazio a diposizione"
        },
        {
            id: "4",
            idMachinery: "85eu93",
            dataIntervento: "2021-01-15",
            operatore: "Benedetto Di Martino",
            Fault: "Disallineamento",
            operazione: "Sostituzione cuscinetto",
            durataIntervento: "",
            Note: "cuscinetto usurato ed inviato al reparto di ripristino per tentare il recupero del pezzo, proviamo a scrivere tanta roba per vedere se in qualche modo si riesce a farlo uscire dallo spazio a diposizione"
        }
        */
    ]