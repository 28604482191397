import { mesiAnno } from "../../../../functions/dateTime"

const TitleHomeMadeCalendar = ({ dateCalendar, setDate }) => {
    return (
        <div className="boxTitleCalendar">
            <div className="boxMonthYearTitleCalendar">
                <h3
                    className="elementMonthTitleCalendar"
                >{mesiAnno[dateCalendar.getMonth()] + " " + dateCalendar.getFullYear()}</h3>


            </div>

            <div
                className="boxAngularParentesisForChangeMonth"
            >
                <span
                    className={`elementAngularHomeMadeCalendarLeft `}
                    //style={{ marginRight: "1vw" }}
                    onClick={() => setDate(new Date(dateCalendar.getFullYear(), dateCalendar.getMonth() - 1, dateCalendar.getDate()))}
                >
                    <h6
                        className="elementTextAngularHomeMadeCalendarLeft "


                    >{"<"}</h6>
                </span>

                <span
                    className="elementAngularHomeMadeCalendarRigth"
                    onClick={() => setDate(new Date(dateCalendar.getFullYear(), dateCalendar.getMonth() + 1, dateCalendar.getDate()))}
                >

                    <h6
                        className="elementTextAngularHomeMadeCalendarRigth "

                    >{">"}</h6>
                </span>

            </div>




        </div>
    )
}

export default TitleHomeMadeCalendar