import PersSliderOnOff from "./subElements/persSliderOnOff"
import './mainSliderOnOff.css'

const SliderOnOff =({returnIsActive})=>{

    return(
        <PersSliderOnOff 
        returnIsActive={returnIsActive? returnIsActive:""}
        />
    )
}

export default SliderOnOff