import React from "react";
import { Link } from "react-router-dom";

const ListNotification = ({ link, indice, active, avviso, titolo, deactiveElementNotifica }) => {
   
    
    return (
        <Link
            className="desktopRowNotificheUnActiveStyleLink"
            to={link}
        >
            <div
                className={active ? "desktopRowElement desktopColorBackActive" : "desktopRowElement desktopColorBackUnActive"}
                onClick={() => deactiveElementNotifica({id:indice})}
            >

                <h1
                    className="desktopTitoloRowNotifiche"
                >
                    {titolo}
                </h1>
                <h1
                    className="desktopAvvisoRowNotifiche"
                >
                    {avviso}
                </h1>

            </div>
        </Link>
    )
}


export default ListNotification