import React, {useReducer} from "react";
import Input from "../input/Input";
import Select from "../select/Select";
import RadioButtonGroup from "../radioButton/RadioButtonGroup";
import "./Filter.css";
import Button from "../button/Button";

/**
 * Componente Filters per la gestione dei filtri.
 *
 * @param {Object[]} plants - Opzioni per la selezione della pianta.
 * @param {Object[]} departments - Opzioni per la selezione del dipartimento.
 * @param {Object[]} lines - Opzioni per la selezione della linea.
 * @param {Object[]} technicians - Opzioni per la selezione del tecnico.
 */

export default function Filters({plants, departments, lines, technicians}) {

    const [filters, updateFilters] = useReducer(
        (prev, next) => {
            return {...prev, ...next};
        },
        {
            Tplant: "",
            Tdepartment: "",
            Tline: "",
            Ttechnician: "",
            TtechnicianLabel: "",
            Tstatus: "",
            plant: "",
            department: "",
            line: "",
            technician: "",
            technicianLabel: "",
            status: "",
            moreFilters: false,

        }
    );

    const options = [
        {value: 'option1', label: 'Plant 1'},
        {value: 'option2', label: 'Plant TWO'},
        {value: 'option3', label: 'Plant TWO'},
        {value: 'option4', label: 'Opzione 4'},
        {value: 'option5', label: 'Plant 1'},
        {value: 'option6', label: 'Plant TWO'},
        {value: 'option7', label: 'Plant TWO'},
        {value: 'option8', label: 'Opzione 4'},
        {value: 'option9', label: 'Plant TWO'},
        {value: 'option10', label: 'Plant TWO'},
        {value: 'option11', label: 'Opzione 4'},

        // Aggiungi le tue opzioni secondo necessità
    ];


    const options2 = [
        {value: 'option1', label: 'Opzione 1'},
        {value: 'option2', label: 'Opzione 2'},
        {value: 'option3', label: 'Opzione 3'},


        // Aggiungi le tue opzioni secondo necessità
    ];

    return (
        <>
            <div className="filters-bar">
                <div className="filters-bar-dropdown">


                    {filters.plant && <Button className={"filter x "}
                                              onClick={() => updateFilters({plant: ''})}>{`${filters.plant}`} </Button>}
                    {filters.department && <Button className={"filter x"}
                                                   onClick={() => updateFilters({department: ''})}>{`${filters.department}`}</Button>}
                    {filters.line && <Button className={"filter x "}
                                             onClick={() => updateFilters({line: ''})}>{filters.line}</Button>}
                    {filters.technician && <Button className={"filter x"}
                                                   onClick={() => updateFilters({technician: ''})}>{filters.technician}</Button>}
                    {filters.status && <Button className={"filter x "}
                                               onClick={() => updateFilters({status: ''})}>{filters.status}</Button>}
                    {!filters.moreFilters &&
                        <Button className={"filter base"} onClick={() => updateFilters({moreFilters: true})}>More
                            filters</Button>}
                </div>
                <div className="filters-bar-search">
                    <Input className="input search" type={"text"} placeholder={"Search"}/>
                </div>
            </div>

            {filters.moreFilters && <div className="mainFiltersBody">
                <div className="mainFiltersHeader">
                    <div className="contentHeaderFilters">Choose Filters</div>
                    <div className="dividerFilters"/>
                </div>
                <form className="mainFormFilters">
                    <div className="form-groupFilters">
                        <label htmlFor="email" className="form-labelFilters">Plant</label>
                        <RadioButtonGroup options={plants ?? options} onChange={(e) => updateFilters({Tplant: e.value})}
                                          selected={filters.plant}></RadioButtonGroup>


                    </div>
                    <div className="dividerFilters"/>

                    <div class="form-groupFilters">
                        <label className="form-labelFilters">Department</label>
                        <RadioButtonGroup options={departments ?? options2}
                                          onChange={(e) => updateFilters({Tdepartment: e.value})}
                                          selected={filters.department}></RadioButtonGroup>
                    </div>
                    <div className="dividerFilters"/>

                    <div class="form-groupFilters">
                        <label className="form-labelFilters">Line</label>
                        <RadioButtonGroup options={lines ?? options2} onChange={(e) => updateFilters({Tline: e.value})}
                                          selected={filters.line}></RadioButtonGroup>

                    </div>
                    <div className="dividerFilters"/>
                    <div class="form-groupFilters">
                        <label className="form-labelFilters">Technician</label>
                        <div className="form-valueFilters">
                            <Select options={technicians ?? options} placeholder={"role type"}
                                    onChange={(e) => updateFilters({Ttechnician: e.value, technicianLabel: e.label})}
                                    selected={{"value": filters.technician, "label": filters.technicianLabel}}></Select>
                        </div>
                    </div>
                    <div className="dividerFilters"/>


                    <div class="form-groupFilters">
                        <label className="form-labelFilters">Status</label>
                        <RadioButtonGroup
                            options={[
                                {label: "Confermato", value: "confirm", type: "confirm"},
                                {label: "Disabilitato", value: "disabled", type: "disabled"},
                                {label: "Modifica Password", value: "change-password", type: "change-password"}
                            ]}
                            onChange={(e) => updateFilters({Tstatus: e.value})}
                            selected={filters.status}
                        />
                    </div>
                    <div className="dividerFilters"/>
                    <div className="mainFiltersBottom">

                        <Button className="button cancel" onClick={() => updateFilters({
                            moreFilters: false,
                            plant: filters.plant,
                            department: filters.department,
                            line: filters.line,
                            technician: filters.technician,
                            status: filters.status
                        })}>Cancel</Button>
                        <Button className="button save" style={{marginLeft: "2vw", marginRight: "1vw"}}
                                onClick={() => updateFilters({
                                    moreFilters: false,
                                    plant: filters.Tplant,
                                    department: filters.Tdepartment,
                                    line: filters.Tline,
                                    technician: filters.Ttechnician,
                                    status: filters.Tstatus
                                })}>Apply</Button>

                    </div>
                </form>


            </div>}

        </>
    )
}