import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { getSelectionMetrics } from './selectionForMetricheSlice'

const metricValueAdapter = createEntityAdapter({
    selectId: (metric) => metric?.id,
    sortComparer: (a, b) => a?.id ? a.id.localeCompare(b?.id) : 1
})

const metricValueSlice = createSlice({
    name: "metricValue",
    initialState: metricValueAdapter.getInitialState(),
    reducers: {
        addMoreMetrics: metricValueAdapter.upsertMany
    }

})

export const {
    addMoreMetrics
} = metricValueSlice.actions

export default metricValueSlice.reducer


export const { selectAll: selectAllMetriche } = metricValueAdapter.getSelectors(state => state.metricValue)

export const metrichePerSpecificaStruttura = createSelector(
    [selectAllMetriche, getSelectionMetrics],
    (state, struttura) => {
        return state.find(e => e.id === struttura.idStructure)
    })