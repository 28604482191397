import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { getMachineSelected } from './schedeMachineDetailSlice';

const ticketAdapter = createEntityAdapter({
    selectId: (ticket) => ticket.id
})


const ticketSlice = createSlice({
    name: "ticket",
    initialState: ticketAdapter.getInitialState(),
    reducers: {
        ticketAddMany: ticketAdapter.addMany
    }
})


export default ticketSlice.reducer


export const {
    ticketAddMany
} = ticketSlice.actions



export const {selectAll: selectAllTicket} = ticketAdapter.getSelectors(state=> state.ticket)


export const getTicketForMachinerySelected = createSelector(
    [selectAllTicket, getMachineSelected],
        (state, select)=>{
            if(select){
                return state.filter(el=>el.idMachinery=== select.idMachinery)
            }
        }

)