import './mainMachineDocument.css'
import TableMachineDocument from './subElements/tableMachineDocument'
import { getDatiTargaForMachineSelected } from '../../structures/root/slices/datiTargaMachinerySlice'
import { getDocDatiTargaForMachineSelected } from '../../structures/root/slices/docDatiTargaSlice'
import { useSelector } from 'react-redux'
import { getDataFromSelectedMachinery } from '../../structures/root/slices/listMachinerySlice'
import { selectAllFamily } from '../../structures/root/slices/traslaterFamilyMachinery'

const MachineDocument = () => {
    const datiTarga = useSelector(getDatiTargaForMachineSelected)
    const docDatiTarga = useSelector(getDocDatiTargaForMachineSelected)
    const infoMachine = useSelector(getDataFromSelectedMachinery)
    const listFamily = useSelector(selectAllFamily)
    /*
    const [DocumentListPass, setDocumentListPass] = useState([])

    useEffect(() => {
        if (DocumentList) {
            setDocumentListPass(DocumentList)
        }
        else {
            setDocumentListPass([
                {

                    titolo: "Manuale tecnico",
                    tipologia: "Manuale",
                    link: "",
                    Note: "Documentazione sull'utilizzo della macchina e sua manutenzione"
                },
                {

                    titolo: "Schema Pompa",
                    tipologia: "Schema",
                    link: "",
                    Note: "Schema elettrico della pompa"
                }
            ])
        }


    }, [DocumentList])
*/


    return (
        <TableMachineDocument
            infoMachine={infoMachine}
            DocumentList={docDatiTarga}
            datiTarga={datiTarga}
            listFamily={listFamily}
        />
    )

}

export default MachineDocument