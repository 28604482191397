export const tempoManutenzioneAsset = ({ listAsset, listReport, listIntervention, dateStart = "2024-01-01" }) => {
    return listAsset
        .map((ass) => {

            let somma = 0
            //ricerco i report collegato all'asset e ne calcolo il totale
            listReport
                //filtro per macchianrio
                .filter(el => el.idMachinery === ass.id)
                //filtro per periodo considerando gli interventi dell'ultimo anno
                //CREARE IL RIFERIEMNTO RISPETTO ALL'ANNO CORRENTE, ALL'ULTIMO MESE E ALL'ULTIMA SETTIMANA
                .filter(el => el.updatedAt.slice(0, 10) > dateStart)
                .filter(el => el.isDeleted !== true)
                .filter(el => listIntervention.filter(e => e.id === el.idIntervention).filter(e => e.isDeleted !== true).length !== 0)
                .forEach(e => somma = somma + Number(e.durata))

            return {
                id: ass.id,
                tempi: somma
            }
        })
}





export const numbreOfPeriodicInterventionAndPercentTime = (
    listAsset,
    listReport,
    listIntervention,
    listPeriodicIntervention,
    listInterventioAttuative,
    listinterventionPeriodicANDderivates,
    dateStart = "2024-01-01",
    dateEnd = "2024-12-31") => {

    let listAssTempP = []
    let listEventPeridoComplete = []

    listAsset.forEach(ass => {
        let temp = 0


        listinterventionPeriodicANDderivates
            //interventi periodici e derivati relativi alla macchina 
            .filter(el => el.idMachinery === ass.id)
            //per ogni intervento vediamo i report collegati
            .forEach(el => {
                listReport
                    .filter(e => e.idIntervention === el.id)
                    .filter(e => e.isDeleted !== true)
                    .forEach(rep => temp = temp + Number(rep.durata))
            })

        listAssTempP.push({
            id: ass.id,
            tempEff: temp
        })

        //filtro gli interventi attuativi per lo specifico macchinario

        let complete = 0

        let totInter = 0

        listInterventioAttuative
            .filter(element => element.idMachinery === ass.id)
            //verifico se l'intervento o uno dei suoi derivati abbia un report
            .forEach(element => {
                //se abbiamo un report all'intervento attuativo allora aumentiamo un complete
                if (listReport.find(rep => rep.idIntervention === element.id)) {
                    complete = complete + 1
                } else {
                    //altrimenti potrebbe esserci un intervento derivato con un report
                    //bisogna considerare che potrebbero esserci più interventi quindi mettiamo tutto in un loop
                    let succIntervention = element
                    while (succIntervention) {
                        succIntervention = listIntervention.find(interventionGeneral => interventionGeneral.idPrevIntervention === succIntervention.id)

                        if (succIntervention) {
                            if (listReport.find(rep => rep.idIntervention === succIntervention.id)) {
                                complete = complete + 1
                                succIntervention = false
                            }
                        }

                    }
                }
            })

        // per calcolare tutti gli interventi periodici bisogna calcolare quanti ce ne sarebbero stati nel periodo che vogliamo valutare

        const millisecondStart = new Date(dateStart).getTime()
        const millisecondEnd = new Date(dateEnd).getTime()
        //filtro per macchinario
        const listPerMachine = listPeriodicIntervention.filter(el => el.idMachinery === ass.id)
        listPerMachine
            .forEach(el => {

                let millisecondInterventionVirtual = new Date(el.date).getTime()

                //il while cicla solo se l'intervento periodico è stato creato prima della data finale nella quale stiamo considerando il calcolo

                while (millisecondInterventionVirtual <= millisecondEnd) {
                    if (millisecondInterventionVirtual >= millisecondStart) {
                        totInter = totInter + 1
                    }
                    millisecondInterventionVirtual = millisecondInterventionVirtual + (Number(el.period) * 86400000)
                }
            })


        listEventPeridoComplete.push({
            id: ass.id,
            allIntervention: totInter,
            completeIntervention: complete
        })

    })

    return [listEventPeridoComplete, listAssTempP]

}

