
import PersFormAddNewStabilimento from "./subElements/persFormAddNewStabilimento"
import './mainAddNewStabilimento.css'

const AddNewStabilimento = ({ close,mode,objectToModify,budgetToModify }) => {

    return <PersFormAddNewStabilimento
        close={close}
        mode={mode}
        objectToModify={objectToModify}
        budgetToModify={budgetToModify}

    />
}

export default AddNewStabilimento