import React, { useEffect, useRef, useState } from 'react';
import './Select.css';

/**
 * Componente Select personalizzato per React.
 *
 * @param {Object[]} options - Opzioni disponibili per la selezione.
 * @param {function} onChange - Funzione di callback chiamata quando viene selezionata un'opzione.
 * @param {string} className - Classe CSS aggiuntiva per il componente.
 * @param {boolean} disabled - Indica se il componente è disabilitato o meno.
 * @param {string} placeholder - Testo visualizzato quando nessuna opzione è selezionata.
 * @param {Object} selected - Opzione selezionata di default.
 * @param {Object} style - Stile inline per il componente.
 */

const Select = ({ options = [], onChange, className, disabled, placeholder, selected, style }) => {
    const [selectedOption, setSelectedOption] = useState(selected ? selected : "");
    const [showOptions, setShowOptions] = useState(false);

    const selectRef = useRef(null);
    useEffect(()=>{
        setSelectedOption(selected)
    }, [selected])
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setShowOptions(false);
        if (onChange) {
            onChange(option);
        }
    };

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };
    useEffect(() => {
        /**
         * Gestisce il click al di fuori del menu a tendina per chiudere il menu.
         *
         * @param {MouseEvent} event - Evento di click.
         */
        const handleDocumentClick = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        // Aggiunge il gestore di eventi al documento
        document.addEventListener('click', handleDocumentClick);

        // Pulisce il gestore di eventi quando il componente viene smontato
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
    return (
        <div style={style} className={`custom-select ${className}`} ref={selectRef}>
            <div className="select-selected"
                onClick={() => !disabled && toggleOptions()}
            >
                {selectedOption ? selectedOption.label : placeholder}
            </div>
            {showOptions && (
                <div className="select-items">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={selectedOption?.value === option.value ? "same-as-selected" : ""}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
            <select style={{ display: 'none' }}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    );
};

export default Select;
