/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePlants = /* GraphQL */ `
  subscription OnCreatePlants(
    $filter: ModelSubscriptionPlantsFilterInput
    $owner: String
  ) {
    onCreatePlants(filter: $filter, owner: $owner) {
      id
      name
      posGeo
      via
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePlants = /* GraphQL */ `
  subscription OnUpdatePlants(
    $filter: ModelSubscriptionPlantsFilterInput
    $owner: String
  ) {
    onUpdatePlants(filter: $filter, owner: $owner) {
      id
      name
      posGeo
      via
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePlants = /* GraphQL */ `
  subscription OnDeletePlants(
    $filter: ModelSubscriptionPlantsFilterInput
    $owner: String
  ) {
    onDeletePlants(filter: $filter, owner: $owner) {
      id
      name
      posGeo
      via
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDataPlants = /* GraphQL */ `
  subscription OnCreateDataPlants(
    $filter: ModelSubscriptionDataPlantsFilterInput
    $owner: String
  ) {
    onCreateDataPlants(filter: $filter, owner: $owner) {
      idPlant
      id
      params
      linkDataGraph
      period
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDataPlants = /* GraphQL */ `
  subscription OnUpdateDataPlants(
    $filter: ModelSubscriptionDataPlantsFilterInput
    $owner: String
  ) {
    onUpdateDataPlants(filter: $filter, owner: $owner) {
      idPlant
      id
      params
      linkDataGraph
      period
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDataPlants = /* GraphQL */ `
  subscription OnDeleteDataPlants(
    $filter: ModelSubscriptionDataPlantsFilterInput
    $owner: String
  ) {
    onDeleteDataPlants(filter: $filter, owner: $owner) {
      idPlant
      id
      params
      linkDataGraph
      period
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDepartments = /* GraphQL */ `
  subscription OnCreateDepartments(
    $filter: ModelSubscriptionDepartmentsFilterInput
    $owner: String
  ) {
    onCreateDepartments(filter: $filter, owner: $owner) {
      id
      name
      owner
      idPlant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDepartments = /* GraphQL */ `
  subscription OnUpdateDepartments(
    $filter: ModelSubscriptionDepartmentsFilterInput
    $owner: String
  ) {
    onUpdateDepartments(filter: $filter, owner: $owner) {
      id
      name
      owner
      idPlant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDepartments = /* GraphQL */ `
  subscription OnDeleteDepartments(
    $filter: ModelSubscriptionDepartmentsFilterInput
    $owner: String
  ) {
    onDeleteDepartments(filter: $filter, owner: $owner) {
      id
      name
      owner
      idPlant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLineas = /* GraphQL */ `
  subscription OnCreateLineas(
    $filter: ModelSubscriptionLineasFilterInput
    $owner: String
  ) {
    onCreateLineas(filter: $filter, owner: $owner) {
      id
      name
      owner
      idDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLineas = /* GraphQL */ `
  subscription OnUpdateLineas(
    $filter: ModelSubscriptionLineasFilterInput
    $owner: String
  ) {
    onUpdateLineas(filter: $filter, owner: $owner) {
      id
      name
      owner
      idDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLineas = /* GraphQL */ `
  subscription OnDeleteLineas(
    $filter: ModelSubscriptionLineasFilterInput
    $owner: String
  ) {
    onDeleteLineas(filter: $filter, owner: $owner) {
      id
      name
      owner
      idDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBudgets = /* GraphQL */ `
  subscription OnCreateBudgets(
    $filter: ModelSubscriptionBudgetsFilterInput
    $owner: String
  ) {
    onCreateBudgets(filter: $filter, owner: $owner) {
      idStructure
      id
      year
      value
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBudgets = /* GraphQL */ `
  subscription OnUpdateBudgets(
    $filter: ModelSubscriptionBudgetsFilterInput
    $owner: String
  ) {
    onUpdateBudgets(filter: $filter, owner: $owner) {
      idStructure
      id
      year
      value
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBudgets = /* GraphQL */ `
  subscription OnDeleteBudgets(
    $filter: ModelSubscriptionBudgetsFilterInput
    $owner: String
  ) {
    onDeleteBudgets(filter: $filter, owner: $owner) {
      idStructure
      id
      year
      value
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAssets = /* GraphQL */ `
  subscription OnCreateAssets($filter: ModelSubscriptionAssetsFilterInput) {
    onCreateAssets(filter: $filter) {
      id
      idCode
      idFamily
      idPlant
      idLinea
      idDepartment
      name
      reparto
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAssets = /* GraphQL */ `
  subscription OnUpdateAssets($filter: ModelSubscriptionAssetsFilterInput) {
    onUpdateAssets(filter: $filter) {
      id
      idCode
      idFamily
      idPlant
      idLinea
      idDepartment
      name
      reparto
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAssets = /* GraphQL */ `
  subscription OnDeleteAssets($filter: ModelSubscriptionAssetsFilterInput) {
    onDeleteAssets(filter: $filter) {
      id
      idCode
      idFamily
      idPlant
      idLinea
      idDepartment
      name
      reparto
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDataTargaAssets = /* GraphQL */ `
  subscription OnCreateDataTargaAssets(
    $filter: ModelSubscriptionDataTargaAssetsFilterInput
  ) {
    onCreateDataTargaAssets(filter: $filter) {
      idAsset
      imageURL
      yearProduction
      yearInstallation
      producer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDataTargaAssets = /* GraphQL */ `
  subscription OnUpdateDataTargaAssets(
    $filter: ModelSubscriptionDataTargaAssetsFilterInput
  ) {
    onUpdateDataTargaAssets(filter: $filter) {
      idAsset
      imageURL
      yearProduction
      yearInstallation
      producer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDataTargaAssets = /* GraphQL */ `
  subscription OnDeleteDataTargaAssets(
    $filter: ModelSubscriptionDataTargaAssetsFilterInput
  ) {
    onDeleteDataTargaAssets(filter: $filter) {
      idAsset
      imageURL
      yearProduction
      yearInstallation
      producer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDocAssets = /* GraphQL */ `
  subscription OnCreateDocAssets(
    $filter: ModelSubscriptionDocAssetsFilterInput
  ) {
    onCreateDocAssets(filter: $filter) {
      idAsset
      id
      name
      desc
      link
      note
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDocAssets = /* GraphQL */ `
  subscription OnUpdateDocAssets(
    $filter: ModelSubscriptionDocAssetsFilterInput
  ) {
    onUpdateDocAssets(filter: $filter) {
      idAsset
      id
      name
      desc
      link
      note
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDocAssets = /* GraphQL */ `
  subscription OnDeleteDocAssets(
    $filter: ModelSubscriptionDocAssetsFilterInput
  ) {
    onDeleteDocAssets(filter: $filter) {
      idAsset
      id
      name
      desc
      link
      note
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAssetsHI = /* GraphQL */ `
  subscription OnCreateAssetsHI($filter: ModelSubscriptionAssetsHIFilterInput) {
    onCreateAssetsHI(filter: $filter) {
      idAsset
      hi
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAssetsHI = /* GraphQL */ `
  subscription OnUpdateAssetsHI($filter: ModelSubscriptionAssetsHIFilterInput) {
    onUpdateAssetsHI(filter: $filter) {
      idAsset
      hi
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAssetsHI = /* GraphQL */ `
  subscription OnDeleteAssetsHI($filter: ModelSubscriptionAssetsHIFilterInput) {
    onDeleteAssetsHI(filter: $filter) {
      idAsset
      hi
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFamily = /* GraphQL */ `
  subscription OnCreateFamily($filter: ModelSubscriptionFamilyFilterInput) {
    onCreateFamily(filter: $filter) {
      id
      familyName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFamily = /* GraphQL */ `
  subscription OnUpdateFamily($filter: ModelSubscriptionFamilyFilterInput) {
    onUpdateFamily(filter: $filter) {
      id
      familyName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFamily = /* GraphQL */ `
  subscription OnDeleteFamily($filter: ModelSubscriptionFamilyFilterInput) {
    onDeleteFamily(filter: $filter) {
      id
      familyName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFaults = /* GraphQL */ `
  subscription OnCreateFaults($filter: ModelSubscriptionFaultsFilterInput) {
    onCreateFaults(filter: $filter) {
      idFamily
      id
      name
      desc
      component
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFaults = /* GraphQL */ `
  subscription OnUpdateFaults($filter: ModelSubscriptionFaultsFilterInput) {
    onUpdateFaults(filter: $filter) {
      idFamily
      id
      name
      desc
      component
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFaults = /* GraphQL */ `
  subscription OnDeleteFaults($filter: ModelSubscriptionFaultsFilterInput) {
    onDeleteFaults(filter: $filter) {
      idFamily
      id
      name
      desc
      component
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDataFaults = /* GraphQL */ `
  subscription OnCreateDataFaults(
    $filter: ModelSubscriptionDataFaultsFilterInput
  ) {
    onCreateDataFaults(filter: $filter) {
      idAsset
      id
      idFault
      hi
      RUL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDataFaults = /* GraphQL */ `
  subscription OnUpdateDataFaults(
    $filter: ModelSubscriptionDataFaultsFilterInput
  ) {
    onUpdateDataFaults(filter: $filter) {
      idAsset
      id
      idFault
      hi
      RUL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDataFaults = /* GraphQL */ `
  subscription OnDeleteDataFaults(
    $filter: ModelSubscriptionDataFaultsFilterInput
  ) {
    onDeleteDataFaults(filter: $filter) {
      idAsset
      id
      idFault
      hi
      RUL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAlgoritmsAlerts = /* GraphQL */ `
  subscription OnCreateAlgoritmsAlerts(
    $filter: ModelSubscriptionAlgoritmsAlertsFilterInput
  ) {
    onCreateAlgoritmsAlerts(filter: $filter) {
      id
      idPlant
      idDataFault
      idAsset
      risk
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAlgoritmsAlerts = /* GraphQL */ `
  subscription OnUpdateAlgoritmsAlerts(
    $filter: ModelSubscriptionAlgoritmsAlertsFilterInput
  ) {
    onUpdateAlgoritmsAlerts(filter: $filter) {
      id
      idPlant
      idDataFault
      idAsset
      risk
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAlgoritmsAlerts = /* GraphQL */ `
  subscription OnDeleteAlgoritmsAlerts(
    $filter: ModelSubscriptionAlgoritmsAlertsFilterInput
  ) {
    onDeleteAlgoritmsAlerts(filter: $filter) {
      id
      idPlant
      idDataFault
      idAsset
      risk
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTickets = /* GraphQL */ `
  subscription OnCreateTickets($filter: ModelSubscriptionTicketsFilterInput) {
    onCreateTickets(filter: $filter) {
      idAsset
      id
      operatore
      fault
      title
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTickets = /* GraphQL */ `
  subscription OnUpdateTickets($filter: ModelSubscriptionTicketsFilterInput) {
    onUpdateTickets(filter: $filter) {
      idAsset
      id
      operatore
      fault
      title
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTickets = /* GraphQL */ `
  subscription OnDeleteTickets($filter: ModelSubscriptionTicketsFilterInput) {
    onDeleteTickets(filter: $filter) {
      idAsset
      id
      operatore
      fault
      title
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInterventions = /* GraphQL */ `
  subscription OnCreateInterventions(
    $filter: ModelSubscriptionInterventionsFilterInput
  ) {
    onCreateInterventions(filter: $filter) {
      idAsset
      idPrevIntervention
      isDeleted
      id
      data
      ora
      idFault
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInterventions = /* GraphQL */ `
  subscription OnUpdateInterventions(
    $filter: ModelSubscriptionInterventionsFilterInput
  ) {
    onUpdateInterventions(filter: $filter) {
      idAsset
      idPrevIntervention
      isDeleted
      id
      data
      ora
      idFault
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInterventions = /* GraphQL */ `
  subscription OnDeleteInterventions(
    $filter: ModelSubscriptionInterventionsFilterInput
  ) {
    onDeleteInterventions(filter: $filter) {
      idAsset
      idPrevIntervention
      isDeleted
      id
      data
      ora
      idFault
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDataInterventions = /* GraphQL */ `
  subscription OnCreateDataInterventions(
    $filter: ModelSubscriptionDataInterventionsFilterInput
  ) {
    onCreateDataInterventions(filter: $filter) {
      idAsset
      id
      idPlant
      durata
      reparto
      activity
      operatore
      creatore
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDataInterventions = /* GraphQL */ `
  subscription OnUpdateDataInterventions(
    $filter: ModelSubscriptionDataInterventionsFilterInput
  ) {
    onUpdateDataInterventions(filter: $filter) {
      idAsset
      id
      idPlant
      durata
      reparto
      activity
      operatore
      creatore
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDataInterventions = /* GraphQL */ `
  subscription OnDeleteDataInterventions(
    $filter: ModelSubscriptionDataInterventionsFilterInput
  ) {
    onDeleteDataInterventions(filter: $filter) {
      idAsset
      id
      idPlant
      durata
      reparto
      activity
      operatore
      creatore
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMainteneanceReports = /* GraphQL */ `
  subscription OnCreateMainteneanceReports(
    $filter: ModelSubscriptionMainteneanceReportsFilterInput
    $owner: String
  ) {
    onCreateMainteneanceReports(filter: $filter, owner: $owner) {
      idAsset
      id
      idIntervention
      data
      operatore
      isDeleted
      valoriLettura
      activity
      costo
      owner
      operation
      durata
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMainteneanceReports = /* GraphQL */ `
  subscription OnUpdateMainteneanceReports(
    $filter: ModelSubscriptionMainteneanceReportsFilterInput
    $owner: String
  ) {
    onUpdateMainteneanceReports(filter: $filter, owner: $owner) {
      idAsset
      id
      idIntervention
      data
      operatore
      isDeleted
      valoriLettura
      activity
      costo
      owner
      operation
      durata
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMainteneanceReports = /* GraphQL */ `
  subscription OnDeleteMainteneanceReports(
    $filter: ModelSubscriptionMainteneanceReportsFilterInput
    $owner: String
  ) {
    onDeleteMainteneanceReports(filter: $filter, owner: $owner) {
      idAsset
      id
      idIntervention
      data
      operatore
      isDeleted
      valoriLettura
      activity
      costo
      owner
      operation
      durata
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
