import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pathExplorPlant, pathMachineryFaultList, pathMachineryIntervention } from "../../../configurations/setting";
import { selectAllSchede, selectScheda, addNewScheda, deleteScheda, deselectScheda, updateScheda } from "../../../structures/root/slices/schedeMachineDetailSlice";
import { selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice";
import { useNavigate } from 'react-router-dom'

import ElementTitoloScheda from "./elementTitoloScheda";
import NewScheda from "./newScheda";



const ListSchede = ({ metaDataLink }) => {

    const listSchede = useSelector(selectAllSchede)
    const listMachinery = useSelector(selectAllMachinery)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const updateSche = (a)=> dispatch(updateScheda(a))
    const addNewSchedaf = (id) => dispatch(addNewScheda(id))
    const deleteSchedaf = id => dispatch(deleteScheda(id))
    const selectSchedaf = id => dispatch(selectScheda(id))
    const deselectSchedaf = () => dispatch(deselectScheda())
    const deleteSchedaReload = (id) => {
        if (listSchede.length === 1) {
            navigate("new")
        } else {
            if (listSchede.filter(el => el.id === id)[0].active) {

                deselectScheda(id)
                selectSchedaf(listSchede.filter(el => el.id !== id)[0].id)
                navigate(pathExplorPlant + "/" + listSchede.filter(el => el.id !== id)[0].link)
            }
        }


        deleteSchedaf(id)

    }



    useEffect(() => {
        if (metaDataLink.split("/")[2] === "new") {
            //deselezioniamo la scheda
            deselectSchedaf()
        } else {
            if (listSchede.find(el => el.id === metaDataLink.split("/")[2])) {
                selectSchedaf(metaDataLink.split("/")[2])
            } else {
                if (metaDataLink.split("/")[2] === undefined) {
                    const indice = listSchede.findIndex(el => el.active === true)

                    if (indice !== -1) {

                        navigate(pathExplorPlant + "/" + listSchede[indice].link)
                    } else {
                        navigate("new")
                    }
                } else {
                    addNewSchedaf({
                        id: metaDataLink.split("/")[2],
                        link: metaDataLink.split("/")[2] + "/" + pathMachineryIntervention,
                        nome: listMachinery.filter(el => el.id === metaDataLink.split("/")[2])[0]?.nome,
                        family: listMachinery.filter(el => el.id === metaDataLink.split("/")[2])[0]?.familyId,
                        idMachinery: listMachinery.filter(el => el.id === metaDataLink.split("/")[2])[0]?.id
                    })
                }

            }
        }

        //se rientriamo da un altra posizione del dettaglio macchinario dobbiamo ricaricare la sceda selezioanta


    }, [metaDataLink])

    useEffect(() => {

        listSchede.forEach(s => {
            const temp = listMachinery.find(e => e.id === s.id)
            updateSche({
                id: s.id,
                link: s.link,
                nome: temp?.nome,
                family: temp?.familyId,
                idMachinery: temp?.id
            })
        })
    }, [listMachinery])

    useEffect(() => {

        if (listSchede.length === 0) {
            navigate("new")
        }

    }, [listSchede])

    return (
       
            <div
                className="boxTitoloSchedeExplore"
            >
                {
                    listSchede.map((el, index) => <ElementTitoloScheda
                        titolo={el.nome}
                        pathID={metaDataLink}
                        key={index}
                        indice={el.id}
                        removeScheda={deleteSchedaReload}
                        link={el.link}
                        active={el.active}
                    />)
                }
                <NewScheda />

            </div>

       
    )

}

export default ListSchede