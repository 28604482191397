import FormCreateMaintenance from "./formCreateMaintenance"
import FormUpdateMaintenance from "./formUpdateMaintenance"


const PersReportMainteneanceForm = ({ datiIntervento, closeScheda, isOpen, mode, reportToModify
}) => {
    if (mode === "add") {
        return < FormCreateMaintenance
            datiIntervento={datiIntervento}
            closeScheda={closeScheda}
            isOpen={isOpen}
        />
    } else {
        return <FormUpdateMaintenance
            closeScheda={closeScheda}
            isOpen={isOpen}
            reportToModify={reportToModify}
        />
    }
}

export default PersReportMainteneanceForm