import './mainNavigationIcon.css'
import PersIconaNavigazione from './subElements/persNavigationIcon'
const IconaNavigazione = ({ link, IconaNavigazione, IconaNavigazioneLight, titolo, activePath }) => {
    return (
        <PersIconaNavigazione

            link={link}
            IconaNavigazione={IconaNavigazione}
            IconaNavigazioneLight={IconaNavigazioneLight}
            titolo={titolo}
            activePath={activePath}
        />
    )
}

export default IconaNavigazione