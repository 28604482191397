import { personalGreen, personalOrange, personalRed } from "../configurations/generalStye";

export const selezioneColore = (valore) => {
    if (valore < 30) {
        return personalRed
    } else {
        if (valore < 65) {
            return personalOrange
        } else {
            return personalGreen
        }
    }
}

export const selzioneColoreInversa = (valore, max) => {
    let rapporto = valore / max
    if (rapporto < 0.2) {
        return personalGreen
    } else {
        if (rapporto < 0.4) {
            return personalOrange
        } else {
            return personalRed
        }
    }
}


export const pointToeconomicalValue = (value) => {
    const textTemp = String(value).replace(/[.]/g, "")

    if (value) {
        //"1000" -> "1.000"  "11.000.000.000" "14000"=> "14.000" 
        let TempResult = ""
        if (textTemp[0] !== "0") {
            if (textTemp.length > 3 && textTemp !== "null") {

                let i = textTemp.length
                do {

                    if (i <= 3) {
                        TempResult = textTemp.slice(0, i) + TempResult

                    } else {
                        TempResult = "." + textTemp.slice(i - 3, i) + TempResult

                    }

                    i = i - 3
                } while (i >= 1)



            } else {

                TempResult = textTemp === "null" ? "" : textTemp

            }
        } else {
            TempResult = ""
        }




        return TempResult
    } else {
        return 0
    }


}