/**
 * ritorna un vettore della dimensione posta con tutti false tranne la posizione passata alla funzione
 * vera
 */

export const unicoAttivo = (pos, dim) => {
    let vett =  []
    for(let i=0;i<dim;i++){
        vett.push(false)
    }

    vett[pos]= true

    return vett
}


/**
 * controllo uguaglianza vettori sia per lunghezza che per contenuto
 */


export const sonoUguali = (arr1, arr2) =>{

    if(typeof(arr1) !== "object"){
        
        return false
    }
    if(typeof(arr2) !== "object"){
        return false
    }
    if(arr1.length !== arr2.length){
        return false
    }

    let a=arr1.map((element, index) => {
        let valore = true
        if(element !== arr2[index]){
            valore= false
        }
        return valore
    })
    
    if(a.find(element => element === false) === undefined){
        return true
    }else{
        return false
    }

}

export const allInactive = (vett)=>{
    if(vett.find(el=>el.active===true)){
        return true
    }else{
        return false
    }
}