import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/userManagement/breadcrumb/Breadcrumb";
import Button from "../../../components/userManagement/button/Button";
import { pathImpostazioni, serverStatusForLable, subPathNewUser, subPathUsers } from "../../../configurations/setting";
import PaginatedTable from "../../../components/userManagement/table/PaginatedTable";
import olivia from "../../../icon/olivia.png"
import phoenix from "../../../icon/phoenix.png"
import galileo from "../../../icon/galileo.png"
import demi from "../../../icon/demi.png"
import drew from "../../../icon/drew.png"

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getListUsers, selectAllUsers } from "../../root/slices/usersSlice";

const UsersTable = ({ showUser }) => {


    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            href: `${pathImpostazioni}/${subPathUsers}`,
            title: 'Users',
        }
    ];

    const sampleColumnsFix = [
        {
            key: "date",
            title: "Hire Date",
            width: "8vw",
            ordered: true
        },
        {
            key: "name",
            title: "Name",
            width: "21vw"
        },
        {
            key: "role",
            title: "Role",
            width: "19vw",
            ordered: false
        },
        {
            key: "status",
            title: "Status",
            width: "10vw",
            ordered: true
        },
        {
            key: "details",
            title: "",
            width: "5vw"
        },
    ];

    const users = useSelector(selectAllUsers).map(el => {

        if (el.role?.value === "Admin") {
            return {
                ...el,
                role: { ...el.role, value: "Administrator" }
            }
        } else {
            return { ...el }
        }
    })
    const dispatch = useDispatch()
    const getList = () => dispatch(getListUsers())

    useEffect(() => {

        getList()
    }, [])


    const sampleRowsFix = [
        {
            id: 1,
            date: { value: "23/10/2023" },
            name: { value: "Olivia Rhye", subValue: "@olivia", icon: olivia, boldValue: true },
            role: { value: "Mechanic", subValue: "Plant and line" },
            status: { value: "Confermato", badge: "success" },
        },
        {
            id: 2,
            date: { value: "23/10/2023" },
            name: { value: "Phoenix Baker", subValue: "@phoenix", icon: phoenix, boldValue: true },
            role: { value: "Technician", subValue: "Plant and line" },
            status: { value: "Modifica password", badge: "default" },
        },
        {
            id: 3,
            date: { value: "23/10/2023" },
            name: {
                value: "Galileo Assistenza s.r.l",
                subValue: "Company contact person",
                icon: galileo,
                boldValue: true
            },
            role: { value: "External Partner", subValue: "Plant and line" },
            status: { value: "Confermato", badge: "success" }
        },
        {
            id: 4,
            date: { value: "23/10/2023" },
            name: { value: "Demi Wilkinson", subValue: "@demi", icon: demi, boldValue: true },
            role: { value: "Admin", subValue: "Plant and line" },
            status: { value: "Disabilitato", badge: "error" }
        },
        {
            id: 5,
            date: { value: "23/10/2023" },
            name: { value: "Candice Wu", subValue: "@candice", icon: "default", boldValue: true },
            role: { value: "Technician", subValue: "Plant and line" },
            status: { value: "operating", badge: "success" }
        },
        {
            date: { value: "23/10/2023" },
            name: { value: "Natali Craig", subValue: "@natali", icon: "default", boldValue: true },
            role: { value: "Mechanic", subValue: "Plant and line" },
            status: { value: "Modifica password", badge: "default" }
        },
        {
            date: { value: "23/10/2023" },
            name: { value: "Drew Cano", subValue: "@drew", icon: drew, boldValue: true },
            role: { value: "Mechanic", subValue: "Plant and line" },
        },
        {
            date: { value: "23/10/2023" },
            name: { value: "Olivia Rhye", subValue: "@olivia" },
            role: { value: "Mechanic", subValue: "Plant and line" },
            status: { value: "Confermato" }
        },
        {
            date: { value: "23/10/2023" },
            name: { value: "Olivia Rhye", subValue: "@olivia" },
            role: { value: "Mechanic", subValue: "Plant and line" },
            status: { value: "Confermato" }
        }
    ];


    return (
        <>
            <div className={"mainSettingsUsersHeader"}>
                <Breadcrumb items={breadcrumbItems} />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // marginLeft: "2vw"
                    }}
                >
                    <h1
                        style={{
                            margin: 0,
                            fontSize: "2vw"
                        }}
                    >Users</h1>
                    <Button className="addUser"
                        onClick={() => navigate(`${pathImpostazioni}/${subPathNewUser}`)}>New
                        User</Button>
                </div>
            </div>
            <div className="mainSettingsUsersSection">
                <div className="mainSettingsUsersFiltersBar">
                </div>
                <div className="mainSettingsUsersTable">
                    <PaginatedTable rows={users}
                        columns={sampleColumnsFix}
                        filters={true}
                        showUser={showUser}
                        pagination={7}
                    />
                </div>
            </div>
        </>
    )
};

export default UsersTable;