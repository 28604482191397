import React, {useState} from "react";
import './mainUsers.css'
import Tabs from "../../components/userManagement/tabs/Tabs";
import UserSettings from "./subElements/mainUserSettings";
import UserProfile from "./subElements/mainUserProfile";

const Users = () => {

    const [tabPanel, setTabPanel] = useState(0)
    const tabsItems = [
        {
            key: 1,
            label: 'Details',
            content: <UserProfile/>,
        },
        {
            key: 2,
            label: 'Settings',
            content: <UserSettings/>,
        }
    ];


    const onChange = (key) => {
        const tabIndex = tabsItems.findIndex(tab => tab.key === key);

        // Verifica se l'indice è valido prima di impostare il TabPanel
        if (tabIndex !== -1) {
            setTabPanel(tabIndex);
        } else {
            // Gestisci il caso in cui non viene trovato alcun elemento con la chiave corrispondente
            console.warn(`Tab with key ${key} not found`);
        }
    };


    return (
        <div className="mainUser">
            <div className={"mainUsersHeader"}>
                <div className={"mainUsersHeaderTab"}>
                    <Tabs items={tabsItems} onChange={onChange} defaultActiveKey={1}/>
                </div>
            </div>
            <div className="mainUserSection">
                {tabsItems[tabPanel].content}
            </div>
        </div>
    )
};

export default Users;