import './mainLogo.css'
import logoAIM from '../../icon/logo.svg'
import { Link } from "react-router-dom";
import React from 'react';

const Logo = () => {
    return (
        <Link
            to="/overview"
            className="logoMargin"
        >
            <img

                src={logoAIM}
                //width={80}
                style={{ width: '100%',height:'100%'}}
            >

            </img>
        </Link>)
}

export default Logo