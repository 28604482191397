import React, { useEffect, useState } from "react";
//import InfoAlert from "../../SingleElement/infoAlert/mainInfoAlert";
import ExtendAlertList from '../../components/extandAlertList/mainExtendAlertList'
import './mainAlertView.css'

import { API, graphqlOperation } from 'aws-amplify'
import { listPlants, } from '../../graphql/queries'
import { createPlants } from '../../graphql/mutations'
import LinearGraph from "../../components/lineargraph/mainLinearGraph";
const AlertViews = () => {

    /*
    const putNewPlant = async () => await API.graphql(graphqlOperation(createPlants, {
        input: {

            name: "A1",
            posGeo: [42.55, 12.6],
            linkDataGraph:"www"
        }
    }))
    */

    //const readListPlant = ()=> async ()=> await API.graphql(graphqlOperation(listPlants))

    return (
        <div className="bodyAlertviews">
            {/* <ExtendAlertList
                row={rows}
                letturaRowId={selectedRowId}
            />
            */}
            {/*<h1>Work in progress</h1>*/}
            <div
                style={{width:"50%", height:"20vw"}}
            >
                <LinearGraph

                    fontFamily={'-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
                        '\'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
                        'sans-serif'}

                    title={{ "active": true, "text": "Grafico Esempio con due serie", "color": '#495672', fontSize: "1.3vw" }}
                    legend={true}

                    fontSize={"1vw"}
                    lineWidth={1.5}
                    legendSize={"1vw"}

                    percent={false}


                    asseX={["A", "B", "C", "D"]}
                    series={[
                        {
                            data: [10, 12, 1, 5],
                            color: '#4287f5',
                            name: 'Test 1'
                        },
                        {
                            data: [5, 20, 25, 10],
                            color: '#f5425d',
                            name: 'Test 2'
                        },
                        {
                            data: [20, 50, 15, 1],
                            color: '#0082ff',
                            name: 'Test 3'
                        }
                    ]}
                />
            </div>

        </div>
    )

}

export default AlertViews