import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { pathExplorPlant, pathMachineryFaultList } from '../../../configurations/setting'
import { StatusFilters } from './filterNotificationSlice';

const alertAlgoritmAdapter = createEntityAdapter({
    selectId: (alertAlgoritm) => alertAlgoritm.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
})
    

const alertAlgoritmSlice = createSlice({
    name: "alertAlgoritm",
    initialState: alertAlgoritmAdapter.getInitialState(),
    reducers: {
        alertAlgoritmAddMany: alertAlgoritmAdapter.addMany,
        alertAlgoritmLoad(state, action) {
            /**
             * funzione per l'aggiornamento dei componenti che sono stati scaricati alla quale vengono
             * aggiunti una serie di campi utili alla gestione del frontend
             * 
             * successivamente questi campi devono essere verificati con i dati salvati localmente
             */

            const loaded = action.payload.map(el => {
                el.link = `${pathExplorPlant}/${el.idMacchinario}/${pathMachineryFaultList}`
                return el
            })
           
            alertAlgoritmAdapter.addMany(state, loaded)
        },
        deactiveAlerAlgoritmForNotification(state, action) {
            const { id } = action.payload

            
            const oldObject = Object.values(state.entities)[Object.values(state.entities).findIndex(el => el.id === id.id)].active = false

            alertAlgoritmAdapter.updateOne(state, oldObject)
        }
    }
})

export const {
    alertAlgoritmAddMany,
    alertAlgoritmLoad,
    deactiveAlerAlgoritmForNotification
} = alertAlgoritmSlice.actions

export default alertAlgoritmSlice.reducer

export const { selectAll: selectAlerAlgoritm } = alertAlgoritmAdapter.getSelectors(state => state.alertAlgoritm)

export const getAlertAlgoritmForSelectedPlant = createSelector([selectAlerAlgoritm, (state, plantId) => plantId], (state, id) => state.filter(el => el.idImpianto === id))

export const getAlertAlgoritmFilter = createSelector(
    //passiamo lo stato delle notifiche 
    selectAlerAlgoritm,
    //passiamo lo stato delle notifiche
    state=>state.notificationFilter,  
    //definiamo la funzione che restituisce gli alert in funzioen dello stato delle notifiche
    (state, filter) => {
        if(filter.status===StatusFilters.All){
            return state
        }
        else{
            return state.filter(el=> el.active===true)
        }
    })