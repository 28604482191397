import { Outlet, useNavigate, useParams } from "react-router-dom";
import "./mainMacchineDetail.css";
import SynopticMachinery from "../../components/synopticMachinery/mainSynopticMachinery";
import LoadDonutChart from "../../components/multipleVariableDonut/mainMultiDonut";
import LinearGraph from "../../components/lineargraph/mainLinearGraph";
/*
import MachineFaultList from '../../components/machineFaultList/mainMachineFaultList';
import MachineryInteventions from '../../components/machineryInterventions/mainMachineryInterventions'
import MachineDocument from '../../components/machineDocument/mainMachineDocument'
*/
import { Link } from "react-router-dom";
import {
    pathExplorPlant,
    pathMachineryIntervention,
    pathMachineryMetriche,
} from "../../configurations/setting";

import { titleColor } from "../../configurations/generalStye";
import SchedaMachinery from "../../components/schedeMachinery/mainSchedeMachinery";
import SchedaMachineryProgrammatic from '../../components/schedaMachineryProgrammatic/mainSchedaMachineryProgrammatic'
import { getDatiTargaForMachineSelected } from "../root/slices/datiTargaMachinerySlice";
import { useSelector } from "react-redux";
import { getDataFromSelectedMachinery } from "../root/slices/listMachinerySlice";
import { getDatiFaultForMachineSelected } from "../root/slices/dataFaultMachinerySlice";
import { selectFaultFamilyForSelectedMachienry } from "../root/slices/faultSlice";
import {
    getDataSpecificMachineInterventio,
    getInterventionForMachinerySelected,
} from "../root/slices/interventionSlice";
import { getNamePlantForSelectedMachinery } from "../root/slices/plantSlice";
import React, { useEffect, useState, useRef } from "react";
import MachineDocument from "../../components/machineDocument/mainMachineDocument";
import { getDatiTargaAsset } from "../root/slices/datiTargaMachinerySlice";

import { useDispatch } from "react-redux";
import { accise, distanza_cumulativa, p_battery } from "../../configurations/strutturaDati/datiMacchine";
import { getReportForMachineSelected } from "../root/slices/reportSlice";
import { costoPerAsset } from "../../functions/metriche/budget";

import { selectAllLine } from '../root/slices/listLineSlice'

import { selectAllDepartment } from '../root/slices/listDepartmentSlice'
import { OTDForPeriodicIntervention } from "../../functions/metriche/OTD";
import { getDerivatesInterForAPeriod, getFirstLevelActuative, getPeriodicInterventions } from "../../functions/metriche/filterList";
import { RTSTEAttivitaIntervento } from "../../functions/metriche/RTSTE";
import { numbreOfPeriodicInterventionAndPercentTime, tempoManutenzioneAsset } from "../../functions/metriche/tempiMan-Number";
import { pointToeconomicalValue } from "../../functions/generiche";


const DataCard = ({ title, value }) => {

    return <div style={{
        //border: "solid 1px",
        //borderRadius: "5px",
        padding: "0vw 1vw",
        margin: "1vw 1vw",
        alignItems: "center"
    }}>
        <h3
            style={{
                margin: "0",
                color: `${titleColor}`,
                textAlign: "center",
                fontSize: "1.5vw"
            }}
        >{title}</h3>
        <h2
            style={{
                margin: "0",
                textAlign: "center",
                fontSize: "1.6vw"
            }}
        >{value}</h2>
    </div>
}



const MachineDetail = () => {
    const dataOdierna = new Date()
    const { id } = useParams();
    const navigator = useNavigate();
    const datiTarga = useSelector(getDatiTargaForMachineSelected);
    const nomePlant = useSelector(getNamePlantForSelectedMachinery);
    const infoMachine = useSelector(getDataFromSelectedMachinery);
    const listLine = useSelector(selectAllLine)
    const listDep = useSelector(selectAllDepartment)
    const dispatch = useDispatch();
    const [resolution, setResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const resizeObserverRef = useRef(null);
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;

        setResolution({ width, height });
    };



    //METRICHE

    const dateStart = "2023-01-01"
    const intervention = useSelector(getInterventionForMachinerySelected);
    const report = useSelector(getReportForMachineSelected)


    const [costi, setcosti] = useState({})

    useEffect(() => {
        if (infoMachine) {
            setcosti(costoPerAsset({ listAsset: [infoMachine], listReport: report, dateStart: dateStart }))
        }
    }, [report, infoMachine])

    const [listPeriodicIntervention, setListPer] = useState([])
    useEffect(() => {
        setListPer(getPeriodicInterventions(intervention))
    }, [intervention, infoMachine])



    const [listInterventioAttuative, setListAtt] = useState([])
    useEffect(() => {
        setListAtt(getFirstLevelActuative(
            intervention,
            listPeriodicIntervention,
            dateStart,
            dataOdierna.toISOString().slice(0, 10)))
    }, [listPeriodicIntervention, infoMachine])

    const [listInterPandDeriv, setListInterPandDeriv] = useState([])
    useEffect(() => {
        setListInterPandDeriv(getDerivatesInterForAPeriod(listPeriodicIntervention, listInterventioAttuative, intervention))
    }, [listInterventioAttuative, infoMachine])



    const [odtInt, setOdtInt] = useState()
    useEffect(() => {
        setOdtInt(OTDForPeriodicIntervention(listPeriodicIntervention, intervention, listInterventioAttuative, report))
    }, [listInterventioAttuative, infoMachine])

    const [otdManAss, setotdManAss] = useState([])
    useEffect(() => {
        let odtAssetTemp = 0
        let count = 0
        listPeriodicIntervention
            .filter(ip => ip.idMachinery === infoMachine.id)
            .forEach(el => {
                if (!isNaN(odtInt.find(e => e.id === el.id)?.rapportoODT)) {
                    odtAssetTemp = odtAssetTemp + odtInt.find(e => e.id === el.id).rapportoODT
                    count = count + 1
                }
            })
        setotdManAss(Math.round(odtAssetTemp / count))
    }, [odtInt, infoMachine])

    const [tseMainIp, settseManIp] = useState([])
    useEffect(() => {
        if (listInterventioAttuative.length !== 0) {
            settseManIp(RTSTEAttivitaIntervento(intervention, intervention, listInterventioAttuative, report))
        }
    }, [listInterventioAttuative, infoMachine])


    const [tseManAss, settseManAss] = useState([])
    useEffect(() => {

        const listInterventionForMachine = listPeriodicIntervention.filter(i => i.idMachinery === infoMachine.id)
        const DataForInterventionMachine = listInterventionForMachine.map(i => tseMainIp.find(e => e?.id === i.id))

        const value = DataForInterventionMachine.reduce((a, i) => {

            if (a === undefined) {
                return {
                    id: infoMachine.id,
                    name: infoMachine.nome,
                    sRa: i?.RTSTEa
                        ?
                        i.RTSTEa >= 1
                            ?
                            1
                            :
                            i.RTSTEa
                        :
                        0,

                    nRa: i?.RTSTEa ? 1 : 0,
                    sR: i?.RTSTE
                        ?
                        i.RTSTE >= 1
                            ?
                            1
                            :
                            i.RTSTE
                        :
                        0,
                    nR: i?.RTSTE ? 1 : 0
                }
            } else {
                return {
                    id: infoMachine.id,
                    name: infoMachine.nome,
                    sRa: i?.RTSTEa
                        ?
                        i.RTSTEa >= 1
                            ?
                            a.sRa + 1
                            :
                            a.sRa + i.RTSTEa
                        :
                        a.sRa,

                    nRa: i?.RTSTEa ? a.nRa + 1 : a.nRa,
                    sR: i?.RTSTE
                        ?
                        i.RTSTE >= 1
                            ?
                            a.sR + 1
                            :
                            a.sR + i.RTSTE
                        :
                        a.sRa,
                    nR: i?.RTSTE ? a.nR + 1 : a.nR
                }
            }
        }, undefined)

        settseManAss(value)

    }, [tseMainIp, infoMachine])

    const [tManAss, setTManAss] = useState([])
    useEffect(() => {
        if (infoMachine) {
            setTManAss(tempoManutenzioneAsset({ listAsset: [infoMachine], listReport: report, listIntervention: intervention, dateStart: dateStart }))
        }
    }, [report, infoMachine])

    const [ieManAss, setIeManAss] = useState([])
    const [tpManAss, setTpManAss] = useState([])

    useEffect(() => {
        if (infoMachine) {
            const [listEventPeridoComplete, listAssTempP] = numbreOfPeriodicInterventionAndPercentTime(
                [infoMachine],
                report,
                intervention,
                listPeriodicIntervention,
                listInterventioAttuative,
                listInterPandDeriv, dateStart, dataOdierna.toISOString().slice(0, 10))

            setIeManAss(listEventPeridoComplete)
            setTpManAss(listAssTempP)
        }

    }, [listInterPandDeriv, infoMachine])













    const [lin, setLin] = useState("")
    const [dep, setDep] = useState("")
    useEffect(() => {

        setLin(listLine.filter(e => e.id === infoMachine?.idLinea)[0]?.name)

        setDep(listDep.filter(e => e.id === infoMachine?.idDepartment)[0]?.name)
    }, [infoMachine, listLine, listDep])


    useEffect(() => {
        if (infoMachine) {
            dispatch(getDatiTargaAsset({ idAsset: infoMachine.id }));
            dispatch(getDataSpecificMachineInterventio({ idAsset: infoMachine.id }));
            if (infoMachine.hi) {
            }
        } else {
            navigator(`${pathExplorPlant}/${id}/${pathMachineryIntervention}`);
        }
    }, [infoMachine]);

    useEffect(() => {
        resizeObserverRef.current = new ResizeObserver(handleResize);
        // Aggiungo l'elemento da osservare (in questo caso, l'intera finestra)
        resizeObserverRef.current.observe(document.documentElement);
    }, []);

    const isMachineryPredictiveRigthSynoptic = (infoMachine) => {
        if (infoMachine) {
            if (infoMachine.hi || infoMachine.familyId === "rmh0p") {
                return (
                    <div style={{ display: "flex" }}>
                        <div className="boxDoubleDonutDettaglioMacchinario">
                            <div className="singleMultipleDonut">
                                <h4 className="elementTitleSingleDonut" style={{ fontSize: "1vw" }} >Ore di lavoro</h4>
                                <LoadDonutChart

                                    val1={infoMachine.familyId === "rmh0p" ? 600 : infoMachine.hi}
                                    max1={5000}
                                    colorCircle1={['#99CBFF', '#FF00FF', '#EE82EE']}

                                    fontSizeValue={"1vw"}
                                />
                            </div>


                            <div className="singleMultipleDonut">
                                <h4 className="elementTitleSingleDonut"
                                    style={{ fontSize: "1vw" }}
                                >Cicli di carica</h4>
                                <LoadDonutChart

                                    val1={infoMachine.familyId === "rmh0p" ? 10 : infoMachine.hi}
                                    max1={1000}
                                    colorCircle1={['#99CBFF', '#FF00FF', '#EE82EE']}

                                    fontSizeValue={"1vw"}
                                />
                            </div>
                        </div>
                        <div className="boxDoubleGraphDettaglioMacchinario">

                            <LinearGraph

                                fontFamily={"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu','Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif"}

                                height={"50%"}

                                title={{ "active": true, "text": "Carica batteria", "color": '#495672', fontSize: "1vw" }}

                                legend={false}

                                percent={false}
                                fontSize={"0.7vw"}
                                asseX={accise}
                                series={[
                                    {
                                        data: p_battery,
                                        color: '#4287f5',
                                        name: 'Carica batteria'
                                    }
                                ]}
                            />


                            <LinearGraph

                                fontFamily={"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu','Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif"}


                                height={"50%"}

                                title={{ "active": true, "text": "Distanza cumulativa", "color": '#495672', fontSize: "1vw" }}

                                legend={false}

                                percent={false}
                                fontSize={"0.7vw"}
                                asseX={accise}
                                series={[
                                    {
                                        data: distanza_cumulativa,
                                        color: '#4287f5',
                                        name: 'Distanza cumulativa'
                                    }
                                ]}
                            />

                        </div>
                    </div>
                );
            } else {
                return <div style={{ display: "flex", flexDirection: "column" }}>
                    <MachineDocument />
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <DataCard
                            title={"Costi complessivi"}
                            value={`${pointToeconomicalValue(costi[0]?.costiInterventi)} €`}

                        />
                        <DataCard
                            title={"Tempo totale di manutenzione"}
                            value={`${tManAss[0]?.tempi} min`}

                        />

                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <DataCard
                            title={"PMP"}
                            value={`${isNaN(tpManAss[0]?.tempEff
                                /
                                tManAss[0]?.tempi)
                                ?
                                0
                                :
                                Math.round((tpManAss[0]?.tempEff
                                    /
                                    tManAss[0]?.tempi) * 100)

                                } %`}

                        />
                        <DataCard
                            title={"PMC"}
                            value={`${ieManAss[0]?.completeIntervention} / ${ieManAss[0]?.allIntervention}`}

                        />
                        <DataCard
                            title={"OTD"}
                            value={`${!isNaN(otdManAss) ? `${otdManAss} %` : "no data"}`}

                        />

                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <DataCard
                        title={"RTSTE"}
                        value={`${isNaN(tseManAss?.sR
                            /
                            tseManAss?.nR)
                            ?
                            " no data"
                            :
                            `${Math.round((tseManAss?.sR
                                /
                                tseManAss?.nR) * 100)} %`
                            }`}
                    />
                    <DataCard
                        title={"RTSTE-attività"}
                        value={`${isNaN(tseManAss?.sRa / tseManAss?.nRa) ?
                            " no data"
                            :
                            `${Math.round((tseManAss?.sRa / tseManAss?.nRa) * 100)}  %`
                            }`}

                    />
                </div>

                </div>
            }
        } else {
            return <div style={{ display: "flex", flexDirection: "column" }}>
                <MachineDocument />
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <DataCard
                        title={"Costi complessivi"}
                        value={`${pointToeconomicalValue(costi[0]?.costiInterventi)} €`}

                    />
                    <DataCard
                        title={"Tempo totale di manutenzione"}
                        value={`${tManAss[0]?.tempi} min`}

                    />

                </div>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <DataCard
                        title={"PMP"}
                        value={`${isNaN(tpManAss[0]?.tempEff
                            /
                            tManAss[0]?.tempi)
                            ?
                            0
                            :
                            Math.round((tpManAss[0]?.tempEff
                                /
                                tManAss[0]?.tempi) * 100)

                            } %`}

                    />
                    <DataCard
                        title={"PMC"}
                        value={`${ieManAss[0]?.completeIntervention} / ${ieManAss[0]?.allIntervention}`}

                    />
                     <DataCard
                            title={"OTD"}
                            value={`${!isNaN(otdManAss) ? `${otdManAss} %` : "no data"}`}

                        />

                </div>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <DataCard
                        title={"RTSTE"}
                        value={`${isNaN(tseManAss?.sR
                            /
                            tseManAss?.nR)
                            ?
                            " no data"
                            :
                            `${Math.round((tseManAss?.sR
                                /
                                tseManAss?.nR) * 100)} %`
                            }`}
                    />
                    <DataCard
                        title={"RTSTE-attività"}
                        value={`${isNaN(tseManAss?.sRa / tseManAss?.nRa) ?
                            " no data"
                            :
                            `${Math.round((tseManAss?.sRa / tseManAss?.nRa) * 100)}  %`
                            }`}

                    />
                </div>

            </div>
        }
    };




    const isMachineryPredictiveSchede = (infoMachine) => {
        if (infoMachine) {
            if (infoMachine.hi || infoMachine.familyId === "rmh0p") {
                return (
                    <div style={{ flex: "1", width: "100%" }}>
                        <SchedaMachinery id={id} />
                        <Outlet />
                    </div>
                );
            } else {
                return (
                    <div style={{ flex: "1", width: "100%" }}>
                        <SchedaMachineryProgrammatic id={id} />
                        <Outlet />
                    </div>
                );
            }
        } else {
            return (
                <div style={{ flex: "1", width: "100%" }}>
                    <SchedaMachineryProgrammatic id={id} />
                    <Outlet />
                </div>
            );
        }
    };

    return (
        <div className="bodyDesktopDettaglioMacchinario">
            <div className="rowDettagliMacchinario">
                <h5 className="elementLayerMachine">{`${nomePlant ? nomePlant : ""} > ${dep} > ${lin} > ${infoMachine ? infoMachine.nome : ""}`}</h5>
            </div>

            <div className="rowDettagliMacchinario">
                <SynopticMachinery
                    // da studiare come gestire il salvataggio in locale dell'immagine e successivamente il suo reperimento senza doverla riscaricare ogni volta
                    linkImage={datiTarga ? datiTarga.imageURL : ""}
                    linkFile={""}
                />
                <div>
                    {isMachineryPredictiveRigthSynoptic(infoMachine)}
                </div>


                {/** elemento schede macchinario alla quale passare la path /exploreplant/:id/ */}
                {/**
                 * fine condizione nella quale se un dettaglio machinario è per un macchinario
                 * senza preditiva allora deve comparire i dati di targa
                 */}
            </div>
            <div className="rowDettagliMacchinario">
                {isMachineryPredictiveSchede(infoMachine)}
            </div>
        </div>
    );
};

export default MachineDetail;
