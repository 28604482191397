import React, { useEffect, useState } from "react";
import MainLoadDonutChart from "../../loadDonatChart/mainLoadDonutChart";
import { titleColor } from '../../../configurations/generalStye';
import { unicoAttivo, sonoUguali } from '../../../functions/controlloVettori'
import MainMultiDonut from "../../multipleVariableDonut/mainMultiDonut";
import { useDispatch, useSelector } from "react-redux";
import { getSelectionMetrics, selectPeriod } from "../../../structures/root/slices/selectionForMetricheSlice";
import { metrichePerSpecificaStruttura } from "../../../structures/root/slices/metricValueSlice";
import { pointToeconomicalValue } from "../../../functions/generiche";

const DesktopTripleDonut = ({ plant, title1, title2, title3, value1, value2, value3 }) => {


    const [buttoActive, setButtActive] = useState([false, false, true])
    const periodSelected = useSelector(getSelectionMetrics).period
    const metriche = useSelector(metrichePerSpecificaStruttura)
    const [spese, setSpese] = useState(0)
    const [budget, setBudget] = useState(0)
    const [allInter, setAllInter] = useState(0)
    const [complInter, setCompleInter] = useState(0)
    const [percPeriodic, setPercPeriodic] = useState(0)
    const [odt, setOdt] = useState(0)
    const [rtste, setRtste] = useState(0)

    const dispatch = useDispatch()
    const selectPer = (a) => dispatch(selectPeriod(a))
    useEffect(() => {
        setButtActive(unicoAttivo(periodSelected, 3))

    }, [periodSelected])


    useEffect(() => {
        //controlli budget
        if (metriche?.value) {
            setBudget(metriche.value)
        } else {
            setBudget(0)
        }

        if (metriche?.costiInterventi) {
            setSpese(metriche.costiInterventi)
        } else {
            setSpese(0)
        }

        //controlli PMC PMP

        if (metriche?.completeIntervention) {

           
            if (metriche.completeIntervention === 0) {
                setCompleInter("-")
            } else{
                setCompleInter(metriche.completeIntervention)
            }
        } else {

            setCompleInter("-")
        }

        if (metriche?.allIntervention) {
            setAllInter(metriche.allIntervention)
        } else if (metriche?.allIntervention === 0) {
            setAllInter(metriche.allIntervention)
        } else {
            setAllInter(0)
        }

        if (metriche?.tempEff && metriche?.tempi) {
            setPercPeriodic(Math.floor((metriche.tempEff / metriche.tempi) * 100))
        } else {
            setPercPeriodic(0)
        }


        // controlli ODT RTSTE
        /**
         *  val1={metriche?.rapportoODT}
                        val2={Math.floor((metriche?.sR / metriche?.nR) * 100)}
         */
        if (metriche?.rapportoODT) {
            if (isNaN(metriche?.rapportoODT)) {
                setOdt(0)
            } else {
                setOdt(metriche.rapportoODT)
            }
        } else {
            setOdt(0)
        }

        if (metriche?.sR && metriche?.nR) {
            setRtste(Math.floor((metriche.tempEff / metriche.tempi) * 100))
        } else {
            setRtste(0)
        }

    }, [metriche])

   
    return (
        <div className="bodyTripleDonut">
            <div className="titleTripleDonut">
                <div>
                    <h3
                        className="elementTitleTripleDonut"
                        style={{ color: `${titleColor}` }}
                    >
                        Overview {plant?.nome}
                    </h3>
                </div>
                <div className="bodyButtonTripleDonut">
                    <button
                        className={`buttonTripleDonutLeft ${buttoActive[0] ? "activeButton" : ""}`}
                        onClick={() => selectPer({ period: 0 })}                        >
                        week
                    </button>
                    <button
                        className={`buttonTripleDonutCenter ${buttoActive[1] ? "activeButton" : ""}`}
                        onClick={() => selectPer({ period: 1 })}
                    >
                        month
                    </button>
                    <button
                        className={`buttonTripleDonutRight ${buttoActive[2] ? "activeButton" : ""}`}
                        onClick={() => selectPer({ period: 2 })}

                    >
                        year
                    </button>



                </div>
            </div>
            <div className="singleDonut">


                <div className="singleMultipleDonut">
                    <h4 className="elementTitleSingleDonut" >{"budget"}</h4>
                    <MainMultiDonut
                        val1={spese}
                        max1={budget}
                        colorCircle1={['#ff6347', '#ffd700', '#7fff00'].reverse()}

                        threshold1={[0.2, 0.8]}



                        fontSizeTitle={'0.8vw'}
                        fontSizeValue={'1vw'}


                    />
                </div>
                <div className="singleMultipleDonut">
                    <h4 className="elementTitleSingleDonut">{"PMP / PMC"}</h4>

                    <MainMultiDonut
                        val1={complInter}
                        max1={allInter}
                        val2={percPeriodic}
                        colorCircle1={['#ff6347', '#ffd700', '#7fff00']}
                        colorCircle2={['#ff6347', '#ffd700', '#7fff00']}
                        threshold1={[50, 80]}
                        threshold2={[50, 80]}
                        labelsCenter={["PMC", "PMP"]}
                        fontSizeTitle={'0.8vw'}
                        fontSizeValue={'1vw'}

                    />
                </div>

                <div className="singleMultipleDonut">
                    <h4 className="elementTitleSingleDonut" >{"OTD / RTSTE"}</h4>


                    <MainMultiDonut
                        val1={odt}
                        val2={rtste}
                        colorCircle1={['#ff6347', '#ffd700', '#7fff00']}
                        colorCircle2={['#ff6347', '#ffd700', '#7fff00']}
                        threshold1={[50, 80]}
                        threshold2={[50, 80]}
                        labelsCenter={["One Time Delivery", "RTSTE"]}
                        fontSizeTitle={'0.8vw'}
                        fontSizeValue={'1vw'}


                    />
                </div>


            </div>
        </div>
    )

}

export default DesktopTripleDonut