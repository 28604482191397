import React from "react";
import { titleColor } from "../../../configurations/generalStye";

import RowListAlertOverview from "./rowAlertListOverview";

const TableListAlertOverview = ({ listAlert, plant }) => {
  return (
    <div className="bodyListAlertOverview">
      <div className="titleListAlertOverview">
        <div>
          <h3
            className="elementTitleAlertList"
            style={{ color: `${titleColor}` }}
          >
            Alert Insight {plant}
          </h3>
        </div>

        {/* SVILUPPI NUOVI: Inserisco come da Figma il bottone per la settimana, il mese e l'anno, uguale a come fatto su triple donut */}
        {/* commento le parti funzionali */}
        
      </div>
      <div className="bodyGridListaAlertOverview">
        <div className="headerGridListAlertOverview">
          <h4
            className="elementTextRowGridListAlertOverview"
            style={{ color: `${titleColor}` }}
          >
            Date
          </h4>
          <h4
            className="elementTextRowGridListAlertOverview"
            style={{ color: `${titleColor}` }}
          >
            Name
          </h4>
          <h4
            className="elementTextRowGridListAlertOverview"
            style={{ color: `${titleColor}` }}
          >
            ID Macchina
          </h4>
          {/*
                        <h4
                        className="elementTextRowGridListAlertOverview"
                        style={{ color: `${titleColor}` }}
                    >
                        Source
                    </h4>
                    */}
          <h4
            className="elementHealthRowGridListAlertOverview"
            style={{ color: `${titleColor}` }}
          >
            Risk
          </h4>
          {/*<h4
                        className="elementTextRowGridListAlertOverview"
                        style={{ color: `${titleColor}` }}
                    >
                        Reporting
</h4>*/}
        </div>
        <div className="bodyRowsListAlertOverview">
          {listAlert.map((element, index) => {
            return (
              <RowListAlertOverview
                Data={new Date(element.timeStamp).toISOString().slice(0, 10)}
                Name={element.idPrevisionFault}
                TypeAlert={element.typeAlert}
                Id={element.idMacchinario}
                Health={element.risk}
                Reporting={element.note}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TableListAlertOverview;
