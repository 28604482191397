import { useEffect, useState } from "react"
import ReportMainteneanceForm from "../../reportMainteneaceForm/mainReportMainteneanceForm"
import { useDispatch, useSelector } from "react-redux"
import { getDataSpecificIntervention, selectAllIntervention } from "../../../structures/root/slices/interventionSlice"
import { selectAllDepartment } from "../../../structures/root/slices/listDepartmentSlice"
import { selectAllLine } from "../../../structures/root/slices/listLineSlice"
import { selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice"
import { selectPlant } from "../../../structures/root/slices/plantSlice"

const ElementListReportIncomplete = ({ report }) => {
    const [isOpenAction, setIsOpen] = useState(false)
    //const intervent = useSelector(selectAllIntervention).find(el => el.id === report.idIntervention)
    const setSingleIsOpenActionComponent = () => {
        setIsOpen(!isOpenAction)
    }

    //download dei dati intervento  se non presenti
    const dispatch = useDispatch()
    useEffect(() => {
        /**
         * se manca il campo operatore vuol dire che non sono stati scaricati i dati dell'intervento di conseguenza efffettuo una chiamata al back end per recuperarli
         */
        if (report ? !report.operatore : false) {
            // dispatch(getDataSpecificIntervention({ id: intervent.id, idAsset: intervent.idMachinery }))
        }
    }, [])


    const [plant, setDataPlant] = useState({})
    const [depart, setDepart] = useState({})
    const [linea, setLinea] = useState()

    const dataDepartment = useSelector(selectAllDepartment)
    const dataLinee = useSelector(selectAllLine)
    const listMachine = useSelector(selectAllMachinery)
    const listaImpianti = useSelector(selectPlant)
    const [datiMachine, setDatiMachine] = useState({})



    useEffect(() => {

        setDatiMachine(listMachine.find(el => el.id === report.idMachinery
        ))
    }, [report])

    useEffect(() => {
        setDataPlant(listaImpianti.find(el => el.id === datiMachine.idPlant))
        setLinea(dataLinee.find(el => el.id === datiMachine.idLinea))

    }, [datiMachine])

    useEffect(() => {
        if (linea) { setDepart(dataDepartment.find(el => el.id === linea.idDepartment)) }
    }, [linea])




    return (
        <li

            className="boxLiElementListStandard elementReportIncomplateListActivity"
            key={report.id}
        >
            <div style={{ "flex": "3" }}>
                <div className="boxTitleEventList">
                    <h5
                        className="titleEventListTimeEvent ">
                        {report ? report.title : "report incompleto"}
                    </h5>


                </div>



                <div
                    className="corpoEvent"
                >
                    <span className="elementSpecificDataToInterventionLittle">Impianto: {plant?.nome}</span>
                    <span className="elementSpecificDataToInterventionLittle">Reparto: {depart?.name}</span>
                    <span className="elementSpecificDataToInterventionLittle">Linea: {linea?.name}</span>
                    <span className="elementSpecificDataToInterventionLittle">Macchina: {report?.operatore}</span>
                </div>
            </div>
            <div
                style={{ "flex": "1" }}
            >
                <button
                    onClick={() => setSingleIsOpenActionComponent()}
                >Risolvi</button>
                {
                    isOpenAction ?
                        //va modificata la pagina del report affinche si veda lo storico degli interventi relativo allo specifico report e anche quelli passati
                        <ReportMainteneanceForm
                            datiIntervento={report}
                            closeScheda={setIsOpen}
                            mode={"add"}
                        />
                        :
                        <span></span>
                }
            </div>

        </li>
    )
}

export default ElementListReportIncomplete