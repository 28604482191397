
import PersFromAddNewAsset from "./subElements/persFormAddNewAsset"
import './mainAddNewAsset.css'

import { useState } from 'react'

const AddNewAsset = ({ close, plant,line,department,listPlant,listDepartment,listLine,listBudgets,listDatiTarga,mode,objectToModify,budgetToModify }) => {



    return <PersFromAddNewAsset
        close={close}

        plant={plant}
        line={line}
        department={department}

        listPlant={listPlant}
        listDepartment={listDepartment}
        listLine={listLine}
        listBudgets={listBudgets}
        listDatiTarga={listDatiTarga}

        mode={mode}
        objectToModify={objectToModify}
        budgetToModify={budgetToModify}
    />
}

export default AddNewAsset