import { createSelector, createSlice } from '@reduxjs/toolkit'


const initialState = {
    date:new Date().toISOString().slice(0,10)
}
const dayCalendarSlice = createSlice({
    name: 'dayCalendar',
    initialState,
    reducers: {
        selectDayCalendar(state, action) {
            state.date = action.payload
        }
    }
})

export const { selectDayCalendar } = dayCalendarSlice.actions

export default dayCalendarSlice.reducer

export const getselectedDayCalendar = createSelector(state =>state.dayCalendar, state => state.date)