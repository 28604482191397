import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { getMachineSelected } from './schedeMachineDetailSlice';
import { newDataTargaAssets } from './datiTargaMachinerySlice';
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import {createAssets, deleteDepartments, deletePlants, updateDepartments} from '../../../graphql/mutations'
import {listAssets, listDepartments, listLineas} from '../../../graphql/queries'
import { Amplify } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { createDepartments } from '../../../graphql/mutations'
import * as subscriptions from "../../../graphql/subscriptions";
import {addPlant, deletePlant, plantAdded, updateOnePlant, updatePlant} from "./plantSlice";
Amplify.configure(awsconfig);


const listDepartmentAdapter = createEntityAdapter({
    selectId: (listDepartment) => listDepartment?.id
})

export const subscribeCreateDepartment = createAsyncThunk(
    'listDepartment/subscriptionCreateDepartment',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onCreateDepartments,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({provider, value}) => {
                    dispatch(addOneDepartment({
                        id: value.data.onCreateDepartments.id,
                        name: value.data.onCreateDepartments.name,
                        idPlant: value.data.onCreateDepartments.idPlant,
                        createdAt: value.data.onCreateDepartments.createdAt,
                        updatedAt: value.data.onCreateDepartments.updatedAt,
                    }))
                },
                error: (error) => console.warn(error)
            })

        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);
export const subscribeModifyDepartment = createAsyncThunk(
    'listDepartment/subscriptionModifyDepartment',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onUpdateDepartments,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {


                    dispatch(
                        updateOneDepartment({
                            id:value.data.onUpdateDepartments.id,
                            //    changes:{
                            name: value.data.onUpdateDepartments.name,
                            idPlant: value.data.onUpdateDepartments.idPlant,
                            createdAt: value.data.onUpdateDepartments.createdAt,
                            updatedAt: value.data.onUpdateDepartments.updatedAt,
                            //  }
                        })
                    )
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);
export const subscribeDeleteDepartment = createAsyncThunk(
    'listDepartment/subscriptionDeleteDepartment',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onDeleteDepartments,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    dispatch(deleteOneDepartment(value.data.onDeleteDepartments.id))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);

export const newDepartment = createAsyncThunk('listDepartment/putDepartment', async ({ name, idPlant }) => {
        const response = await API.graphql({
            query: createDepartments,
            variables: {
                input: {
                    name,
                    idPlant,
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        return {
            id: response.data.createDepartments.id,
            name: response.data.createDepartments.name,
            idPlant: response.data.createDepartments.idPlant,
            createdAt: response.data.createDepartments.createdAt,
            updatedAt: response.data.createDepartments.updatedAt,
        };
    }
)

export const updateDepartment = createAsyncThunk('listDepartment/updateDepartment', async ({id, name, idPlant }) => {
        const response = await API.graphql({
            query: updateDepartments,
            variables: {
                input: {
                    id,
                    name,
                    idPlant,
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        return {
            id: response.data.updateDepartments.id,
            name: response.data.updateDepartments.name,
            idPlant: response.data.updateDepartments.idPlant,
            createdAt: response.data.updateDepartments.createdAt,
            updatedAt: response.data.updateDepartments.updatedAt,
        };
    }
)
export const deleteDepartment = createAsyncThunk("listDepartment/deleteDepartment", async ({id}) => {

    const response = await API.graphql({
        query: deleteDepartments,
        variables: {
            input: {id: id}
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return response.data.deleteDepartments.id
})
export const getUpdateDepartments = createAsyncThunk('listDepartments/getUpdateDepartments', async (a, { getState }) => {

    const response = await API.graphql({
            query: listDepartments,
            variables: {
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }
    )

    
    return response.data.listDepartments.items.map(el => {
        return {
            id: el.id,
            name: el.name,
            idPlant: el.idPlant,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt
        }
    })

})


const listDepartmentSlice = createSlice({
    name: "listDepartment",
    initialState: listDepartmentAdapter.getInitialState(),
    reducers: {
        addDepartment: listDepartmentAdapter.upsertMany,
        addOneDepartment: listDepartmentAdapter.upsertOne,
        deleteOneDepartment: listDepartmentAdapter.removeOne,
        deleteAllDep:listDepartmentAdapter.removeAll,
        updateOneDepartment:listDepartmentAdapter.upsertOne,
    },
    extraReducers: bulder => {
        bulder
            .addCase(newDepartment.fulfilled, listDepartmentAdapter.upsertOne)
            .addCase(newDepartment.rejected, (state, action) => alert("Non hai i permessi per creare un reparto oppure hai problemi con la connessione"))
            .addCase(updateDepartment.fulfilled, listDepartmentAdapter.upsertOne)
            .addCase(updateDepartment.rejected, (state, action) => alert("Non hai i permessi per aggiornare un reparto oppure hai problemi con la connessione"))
            .addCase(deleteDepartment.fulfilled, listDepartmentAdapter.removeOne)
            .addCase(deleteDepartment.rejected, (state, action) => alert("Non hai i permessi per eliminare un reparto oppure hai problemi con la connessione"))
            .addCase(getUpdateDepartments.fulfilled, listDepartmentAdapter.upsertMany)
            .addCase(getUpdateDepartments.rejected, (state, action) => console.log(state, action))
    }
})

export default listDepartmentSlice.reducer

export const {
    addDepartment,
    addOneDepartment,
    deleteOneDepartment,
    updateOneDepartment,
    deleteAllDep
} = listDepartmentSlice.actions

export const { selectAll: selectAllDepartment } = listDepartmentAdapter.getSelectors(state => state.listDepartment)



