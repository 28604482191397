import React, { useEffect, useState } from 'react';
import './PaginatedTable.css';
import Button from '../button/Button';
import AvatarProfileTable from "../avatarProfile/AvatarProfileTable";
import Filters from '../filters/Filters';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../structures/root/slices/datiUtenteSlice';

/**
 * Componente PaginatedTable per la visualizzazione di una tabella paginata con possibilità di filtraggio.
 *
 * @param {Array} rows - Righe della tabella.
 * @param {Array} columns - Colonne della tabella.
 * @param {boolean} filters - Flag per abilitare o disabilitare i filtri.
 * @param {Function} showUser - Funzione per visualizzare i dettagli di un utente.
 * @param {number} pagination - Numero di righe per pagina.
 */

const PaginatedTable = ({ rows = [], columns = [], filters = false, showUser, pagination }) => {
    const rowsPerPage = pagination;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending
    const user = useSelector(selectUser)
    useEffect(() => {
        // Assicura che la pagina corrente sia valida
        if (rows.length !== 0) {
            if (currentPage < 1) {
                setCurrentPage(1);
            } else if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
        }
    }, [currentPage, totalPages]);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const handleSort = (columnKey, ordered) => {
        if (ordered !== undefined && ordered) {
            if (sortColumn === columnKey) {
                // Cambia l'ordine se la stessa colonna è cliccata di nuovo
                setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
            } else {
                // Imposta la nuova colonna di ordinamento e l'ordine come ascendente
                setSortColumn(columnKey);
                setSortOrder('asc');
            }
        }
    };

    const sortedRows = [...rows].sort((a, b) => {
        const aValue = a[sortColumn]?.value || '';
        const bValue = b[sortColumn]?.value || '';

        if (sortOrder === 'asc') {
            return aValue.localeCompare(bValue);
        } else {
            return bValue.localeCompare(aValue);
        }
    });

    const currentRows = sortedRows.slice(startIndex, endIndex);

    const handlePrevious = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNext = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <div>
            {rows.length > 0 && columns.length > 0 ?
                (
                    <div className="section-table">
                        <Filters />

                        <div className="table">

                            <table className="table-body">
                                <thead className="tbl-header">
                                    <tr>
                                        {columns.map((col, index) =>
                                        (
                                            <th
                                                key={index}
                                                style={{ width: col.width }}
                                                onClick={() => handleSort(col.key, col.ordered)}
                                                className={sortColumn === col.key ? `sorted` : ''}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: "0.5vw"
                                                    }}
                                                >
                                                    {col.title}
                                                </div>
                                                {sortColumn === col.key && (
                                                    <div>
                                                        {sortOrder === 'asc' ?
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6 1.33333V10.6667M6 10.6667L10.6667 6M6 10.6667L1.33333 6"
                                                                    stroke="#475467" strokeWidth="1.33333"
                                                                    strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>

                                                            :
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6 10.6667V1.33333M6 1.33333L1.33333 6M6 1.33333L10.6667 6"
                                                                    stroke="#475467" strokeWidth="1.33333"
                                                                    strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>

                                                        }

                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows.map((row, rowIndex) =>
                                    (
                                        <tr key={rowIndex} className="table-row">
                                            {columns.map((column, columnIndex) => {
                                                const cellData = row[column.key];
                                                return (
                                                    <td key={columnIndex}>
                                                        <div className={"table-cell"}>
                                                            {cellData && cellData.icon && cellData.icon !== 'default' ?
                                                                // {column.key === "name" &&
                                                                <AvatarProfileTable img={cellData.icon} />
                                                                : cellData && cellData.icon && cellData.icon === 'default' &&
                                                                <AvatarProfileTable img={''} />

                                                            }
                                                            <>
                                                                {cellData && cellData.value && !cellData.subValue ? (
                                                                    <>
                                                                        {cellData.badge ?
                                                                            <>
                                                                                {cellData.badge === "success" ?
                                                                                    (
                                                                                        <div
                                                                                            className={"table-row-badge-success"}>
                                                                                            {cellData.value}
                                                                                        </div>
                                                                                    )
                                                                                    :
                                                                                    cellData.badge === "error" ?
                                                                                        (
                                                                                            <div
                                                                                                className={"table-row-badge-error"}>
                                                                                                {cellData.value}
                                                                                            </div>
                                                                                        )
                                                                                        :
                                                                                        cellData.badge === "default" ?
                                                                                            (
                                                                                                <div
                                                                                                    className={"table-row-badge-default"}>
                                                                                                    {cellData.value}
                                                                                                </div>
                                                                                            )
                                                                                            :
                                                                                            null}
                                                                            </>
                                                                            :
                                                                            <div style={{
                                                                                fontWeight: cellData.boldValue === true ? "bold" : ""
                                                                            }}
                                                                            >
                                                                                {cellData.value}
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                                    :
                                                                    cellData && cellData.value && cellData.subValue ?
                                                                        (
                                                                            <div className="table-cell-multiple-value">
                                                                                <div
                                                                                    style={{
                                                                                        marginBottom: "0.3vw",
                                                                                        fontWeight: cellData.boldValue === true ? "bold" : ""
                                                                                    }}
                                                                                >
                                                                                    {cellData.value}
                                                                                </div>
                                                                                <div
                                                                                >
                                                                                    {cellData.subValue.indexOf("@") !== -1 ? cellData.subValue : ""}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        :
                                                                        null}
                                                            </>
                                                            {column.key === "details" &&
                                                                <div onClick={() => showUser(row)}
                                                                    className='cursorPointer'
                                                                >
                                                                    <svg width="2vw" height="1vw" viewBox="0 0 20 14"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M2.01677 7.59431C1.90328 7.41461 1.84654 7.32476 1.81477 7.18618C1.79091 7.08208 1.79091 6.91791 1.81477 6.81382C1.84654 6.67523 1.90328 6.58538 2.01677 6.40568C2.95461 4.9207 5.74617 1.16666 10.0003 1.16666C14.2545 1.16666 17.0461 4.9207 17.9839 6.40568C18.0974 6.58538 18.1541 6.67523 18.1859 6.81382C18.2098 6.91791 18.2098 7.08208 18.1859 7.18618C18.1541 7.32476 18.0974 7.41461 17.9839 7.59431C17.0461 9.0793 14.2545 12.8333 10.0003 12.8333C5.74617 12.8333 2.95461 9.0793 2.01677 7.59431Z"
                                                                            stroke="#475467" strokeWidth="1.66667"
                                                                            strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path
                                                                            d="M10.0003 9.5C11.381 9.5 12.5003 8.38071 12.5003 7C12.5003 5.61929 11.381 4.5 10.0003 4.5C8.61962 4.5 7.50034 5.61929 7.50034 7C7.50034 8.38071 8.61962 9.5 10.0003 9.5Z"
                                                                            stroke="#475467" strokeWidth="1.66667"
                                                                            strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                )
                                                    ;
                                            })}
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <div className={"table-header"}>
                                <Button
                                    style={{
                                        marginLeft: "1.5vw"
                                    }}
                                    className="table-button" onClick={handlePrevious}
                                    disabled={currentPage === 1}>
                                    Previous
                                </Button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <Button
                                    style={{
                                        marginRight: "1.5vw"
                                    }}
                                    className="table-button" onClick={handleNext}
                                    disabled={currentPage === totalPages}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <div style={{ textAlign: 'center' }}>
                        <p>{user[0]?.group[0]==="Admin" ? "Download data" :"No Permission"}</p>
                    </div>
                )}
        </div>
    );
};

export default PaginatedTable;
