//prendo in ingresso un numero di telefono e lo splitto in prefisso paese e numero
/**
 * 
 * countryPreFix=[
    { country: 'Italy (+39)', label: 'IT', value: '(+39)' },
    { country: 'USA (+1)', label: 'US', value: '(+1)' },
    { country: 'UK (+44)', label: 'GB', value: '(+44)' }] 
 */
export const splitPhoneNumber = (numero, countryPreFix) => {
    if (numero !== undefined) {
        let indexCountry = -1
        countryPreFix.forEach((prefix, index) => {
            const tempPre = prefix.value.slice(1, prefix.value.length - 1)
           
            if (tempPre === numero.slice(0, tempPre.length)) {
                indexCountry = index
            } else {
               
            }
        })

        if (indexCountry !== -1) {
            return [countryPreFix[indexCountry], numero.slice(countryPreFix[indexCountry].value.length - 1)]
        } else {
            return ["", numero]
        }
    }

}