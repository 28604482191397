import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';


const schedeMachineDetailAdapter = createEntityAdapter({
    selectId: (schedaMachineDetail) => schedaMachineDetail.id
})

const schedeMachineDetailSlice = createSlice({
    name: "schedaMachineDetail",
    initialState: schedeMachineDetailAdapter.getInitialState(),
    reducers: {
        //addNewScheda: schedeMachineDetailAdapter.addOne,
        deleteScheda: schedeMachineDetailAdapter.removeOne,
        selectScheda(state, action) {

            if (Object.values(state.entities).length === 0) {

            } else {
                const value = Object.values(state.entities)[Object.values(state.entities).findIndex(el => el.active === true)]
                if (value) {
                    value.active = false
                }
            }

            const value = state.entities[action.payload]
            value.active = true
        },
        addNewScheda(state, action) {
            const { data } = action.payload
            if (Object.values(state.entities).length === 0) {

            } else {
                const value = Object.values(state.entities)[Object.values(state.entities).findIndex(el => el.active === true)]
                if (value) {
                    value.active = false
                }
            }
            schedeMachineDetailAdapter.upsertOne(state, {
                ...action.payload,
                active: true
            })

        },
        deselectScheda(state, action) {
            if (Object.values(state.entities).length === 0) {

            } else {
                const value = Object.values(state.entities)[Object.values(state.entities).findIndex(el => el.active === true)]
                if (value) {
                    value.active = false
                }
            }
        },
        updateScheda: schedeMachineDetailAdapter.upsertOne
    }
})


export default schedeMachineDetailSlice.reducer

export const {
    deleteScheda,
    selectScheda,
    addNewScheda,
    deselectScheda,
    updateScheda
} = schedeMachineDetailSlice.actions


export const { selectAll: selectAllSchede } = schedeMachineDetailAdapter.getSelectors(state => state.schedaMachineDetail)


/** 
 *  selector per restituire la sceda del macchinario selezionato per dettaglio macchinario, questa
 * viene passata agli slicer :
 * dati targa
 * fault data
 * elenco manutenzione
 * doc dati targa
 * dati sensori 
*/


export const getMachineSelected = createSelector(selectAllSchede, state => state.filter(el => el.active === true)[0])