import Symbol_observable from 'symbol-observable';


import { RouterProvider } from 'react-router-dom'
import { router } from './browserRouter'
import { Provider } from 'react-redux';
import store from './store'

import { Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { useEffect } from 'react';

import { datiUtenteAddOne } from './slices/datiUtenteSlice';

Amplify.configure(awsconfig);



function MainMain({ user }) {

  useEffect(() => {
    if (user !== null) {
      const dati = {
        ...user.attributes,
        group: user.signInUserSession.idToken.payload["cognito:groups"],
        id: `0`,
        refStruct: undefined
      }
      store.dispatch(datiUtenteAddOne(dati))

    }

  }, [user])
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  )
}


export default MainMain