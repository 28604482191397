import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const PersIconaNavigazione = ({link, IconaNavigazione, IconaNavigazioneLight, titolo, activePath}) => {

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (link.split("/")[1] === activePath.split("/")[1]) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [activePath, link])

    return (
        <div
            className="boxDesktopIconaNavigazione"
        >
            <Link
                className="linkDesktopIconaNavigazione"
                to={link}
            >
                <img
                    src={isActive ? IconaNavigazione : IconaNavigazioneLight}
                    //width={23}
                    style={{ width: '2vw',height:'2vw' }}
                    alt="icona di navigazione"
                />
                {
                    titolo ?

                        <div
                            className={isActive ? "boxDesktopIconaNavigazioneActive" : "boxTitleDesktopIconaNavigazione"}
                        >
                            {titolo}
                        </div>

                        :
                        <span></span>


                }

            </Link>
        </div>
    )
}


export default PersIconaNavigazione





