import React from 'react';
import "./mainMultipleVariableDonut.css"
import DesktopMultiDonut from './subElement/desktopMultiDonut';
import MainMultiDonut from './mainMultiDonut';

const ProvaResponsive = () => {

    //ho rinchiuso il componente in un div con classe provaResponsiveMultipleDonut
    //In questo modo vado a fissare un'altezza/larghezza massima (vedi css) 
    //Il grafico si prenderà il 100% del div e scalerà proporzionalmente in larghezza (ho impostato le misure in vw)
    //Nel caso in cui l'istanza non la dovessi inserire in un div con misure proporzionali, il componente non scalerebbe

    return (

        <div className="provaResponsiveMultipleDonut">
            <MainMultiDonut
                val1={3}
                max1={9}
                val2={1}
                max2={3}
                colorCircle1={['#99CBFF', '#FF00FF', '#EE82EE']}
                colorCircle2={['#99cbff', '#ffff00', '#800080']}
                labelsCenter={['Average Health', 'responsive']}
                fontSizeTitle={'2vw'}
                fontSizeValue={'3vw'}
            />
        </div>



    )

}

export default ProvaResponsive