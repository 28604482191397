import { Auth } from '@aws-amplify/auth'
import { useEffect, useState } from "react";
import { API } from 'aws-amplify';


export const listaAtttributi= async ()=>{
    const user = await Auth.currentAuthenticatedUser()
    const response = await Auth.userAttributes(user)

}





const ComponeteProvaUtenti=()=>{

    const apiName = 'users';
    const path = `/users`;
    const [risposte, setRisposte] = useState([])
    const [inp, setInp] = useState([])

    const funzione = (testo) => {
        API.get(
            apiName,
            path
           
        ).then((ris) => setRisposte(ris)).catch(err => console.log("err: ", err))

    }


    return <div>
        <input
                list="lista"
                onInput={(e) => setInp(e.target.value)}
            />


          <button
          onClick={()=>funzione(inp)}
          >prova</button>
    </div>
}


export default ComponeteProvaUtenti