import { useDispatch, useSelector } from "react-redux"
import { selectDayCalendar, getselectedDayCalendar } from "../../../../structures/root/slices/dayCalendarSlice"
import { ElementCalendarIntervention, ElementCalendarReportComplete, ElementCalendarReportIncomplete } from "./elementsInternalDays"
import { useEffect, useState } from "react"
import { ordinamentoInterventiArricchiti, ricercaInterventiFogliaEArricchimento } from "../../../../functions/interventiPeriodici"
import { selectAllReport } from "../../../../structures/root/slices/reportSlice"
import { selectAllIntervention } from "../../../../structures/root/slices/interventionSlice"




const DayHomeMadeCalendar = ({ day, interventionForDay }) => {
    const dispatch = useDispatch()
    const selectedDay = useSelector(getselectedDayCalendar)

    const numberEvent = interventionForDay.length //+ reportCompleted.length + reportIncomlete.length
    const [allEvent, setAllEvent] = useState([]) //, ...reportIncomlete, ...reportCompleted]

    //la lista interventi va ordinata mettendo al primo posto gli interventi che non hanno un report poi quelli con i report parziali e alla fine quelli interventi che hanno un report completo
    useEffect(() => {
        setAllEvent(ordinamentoInterventiArricchiti(
            interventionForDay,

        ))
    }, [interventionForDay, selectedDay])

    return (
        <div
            className={`bodyDayHomeMadeCalendar ${selectedDay === `${day.year}-${day.month < 10 ? `0${day.month}` : day.month}-${day.day < 10 ? `0${day.day}` : day.day}` ? "selectedDay" : ""}`}
            onClick={() => {
                dispatch(selectDayCalendar(`${day.year}-${day.month < 10 ? `0${day.month}` : day.month}-${day.day < 10 ? `0${day.day}` : day.day}`))
            }
            }
            style={{ backgroundColor: `${day.colorBackground ? day.colorBackground : "white"}` }}
        >
            <h6
                className="elementDayDayHomeMadeCalendar"
                style={{ color: `${day.colorDayNumber ? day.colorDayNumber : "lightgray"}` }}
            >{day.day}</h6>
            <div className="boxEventDayHomeMadeCalendar">
                {
                    numberEvent < 3 ?
                        /**
                         * elemento per un solo evento nella giornata
                         */
                        <div
                            className="boxListEventDayCalendarHomeMadeCalendar"

                        >  {
                                allEvent.map((el, index) => {

                                    if (el.id.slice(0, 7) === "virtual") {
                                        return <ElementCalendarIntervention
                                            key={index}
                                            intervent={el}
                                        />
                                    } else {
                                        if (el.attivita) {
                                            if (el.attivita[0].id) {
                                                //ci troviamo nel caso in cui l'intervento ha delle attività e queste possono essere complete o meno
                                                //nel caso in cui è false possiamo avere due casi o non ha trovato un elementi 
                                                if (el.attivita.find(e => e.complated === false)) {

                                                    //esiste almeno un attività falsa

                                                    if (el.attivita.find(e => e.complated === true)) {
                                                        return <ElementCalendarReportIncomplete
                                                            key={index}
                                                            relativeIntervention={el}
                                                        />
                                                    } else {
                                                        return <ElementCalendarIntervention
                                                            key={index}
                                                            intervent={el}
                                                        />
                                                    }

                                                } else {


                                                    //tutte le attività sono complete allora è stato completato
                                                    return <ElementCalendarReportComplete
                                                        key={index}
                                                        relativeIntervention={el}
                                                    />
                                                }
                                            } else {
                                                //un intervento che ha un unica attività senza la variabile id allora è un intervento senza attività che possiede un report e di conseguenza è un report completo

                                                if (!el.attivita[0].complated) {
                                                    return <ElementCalendarIntervention
                                                        key={index}
                                                        intervent={el}
                                                    />
                                                } else {
                                                    return <ElementCalendarReportComplete
                                                        key={index}
                                                        relativeIntervention={el}
                                                    />
                                                }

                                            }
                                        } else {
                                            //è un intervento che non possiede attività e che non ha un report collegato
                                            return <ElementCalendarIntervention
                                                key={index}
                                                intervent={el}
                                            />
                                        }
                                    }

                                })
                            }
                        </div>

                        :
                        /**
                         * Elemento che gestisce 0, 2 o più eventi nella giornata
                         */
                        <div className="boxListEventDayCalendarHomeMadeCalendar">
                            {
                                allEvent.map((el, index) => {

                                    if (el.id.slice(0, 7) === "virtual") {
                                        return <ElementCalendarIntervention
                                            key={index}
                                            intervent={el}
                                        />
                                    } else {
                                        if (el.attivita) {
                                            if (el.attivita[0].id) {
                                                //ci troviamo nel caso in cui l'intervento ha delle attività e queste possono essere complete o meno
                                                //nel caso in cui è false possiamo avere due casi o non ha trovato un elementi 
                                                if (el.attivita.find(e => e.complated === false)) {

                                                    //esiste almeno un attività falsa

                                                    if (el.attivita.find(e => e.complated === true)) {
                                                        return <ElementCalendarReportIncomplete
                                                            key={index}

                                                            relativeIntervention={el}
                                                        />
                                                    } else {
                                                        return <ElementCalendarIntervention
                                                            key={index}
                                                            intervent={el}
                                                        />
                                                    }

                                                } else {


                                                    //tutte le attività sono complete allora è stato completato
                                                    return <ElementCalendarReportComplete
                                                        key={index}
                                                        relativeIntervention={el}
                                                    />
                                                }
                                            } else {
                                                //un intervento che ha un unica attività senza la variabile id allora è un intervento senza attività che possiede un report e di conseguenza è un report completo

                                                if (!el.attivita[0].complated) {
                                                    return <ElementCalendarIntervention
                                                        key={index}
                                                        intervent={el}
                                                    />
                                                } else {
                                                    return <ElementCalendarReportComplete
                                                        key={index}
                                                        relativeIntervention={el}
                                                    />
                                                }

                                            }
                                        } else {
                                            //è un intervento che non possiede attività e che non ha un report collegato
                                            return <ElementCalendarIntervention
                                                key={index}
                                                intervent={el}
                                            />
                                        }
                                    }

                                }).slice(0, 2)
                            }
                            <span
                                className="elementEventForDayCalendar"
                                style={{ marginBottom: "10px" }}
                            /**CLICCANDO SULL'ELEMENTO DEVE APRIRSI IL DETTAGLIO DELLA GIORNATA */
                            >altri{numberEvent - 2}</span>

                        </div>

                }

            </div>

        </div >
    )

}

export default DayHomeMadeCalendar