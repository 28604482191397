import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit'


const translaterFamilyMachineryAdapter= createEntityAdapter({
    selectId: (family) => family.id,
    sortComparer: (a, b)=> a.id.localeCompare(b.id)
})


const translaterFamilyMachinerySlice= createSlice({
    name:"translater",
    initialState:translaterFamilyMachineryAdapter.getInitialState(),
    reducers: {
        translaterAddMany: translaterFamilyMachineryAdapter.addMany,
    }
})


export default translaterFamilyMachinerySlice.reducer

export const {
    translaterAddMany
}= translaterFamilyMachinerySlice.actions


export const {selectAll: selectAllFamily}= translaterFamilyMachineryAdapter.getSelectors(state=>state.family)

