import './mainManteneanceStory.css'
import PersMainteneanceStory from "./subElements/persMainteneanceStory"

const MainMainteneanceStory = ({ isOpen,datiIntervento, closeScheda }) => {
    return (
        <PersMainteneanceStory
            datiIntervento={datiIntervento}
            closeScheda={closeScheda}
            isOpen={isOpen}
        />
    )
}
export default MainMainteneanceStory