import { useEffect, useState } from "react"
import { titleColor } from "../../../configurations/generalStye"
import AddTecnicDocumentation from "../../addTecnicDocumentation/mainAddTecnicDocumentation"
import MultinputFile from "../../multinputFile/mainMultinputFile"
import { modifyAssets, newAsset, selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice"
import {
    newDataTargaAssets,
    selectAllDatiTarga,
    updateDataTarga
} from "../../../structures/root/slices/datiTargaMachinerySlice"
import { useDispatch, useSelector } from "react-redux"
import { selectAllFamily } from "../../../structures/root/slices/traslaterFamilyMachinery"
import { ContentPasteSearchOutlined } from "@mui/icons-material"
import { selectAllDepartment } from "../../../structures/root/slices/listDepartmentSlice";

const PersFromAddNewAsset = ({ close, plant, line, department, listPlant, listDepartment, listLine, listBudgets, listDatiTarga, mode, objectToModify, budgetToModify }) => {

    const dispatch = useDispatch()
    // dati di famiglie macchinari ed estrarre dalla lista attuale dei macchinari le possibili linee di lavorazione già aggiunte
    const famiglie = useSelector(selectAllFamily)
    const machine = useSelector(selectAllMachinery)

    //REFRESH AUTOMATICO QUANDO SI TROVANO SULLA STESSA MODIFICA
    useEffect(() => {


        setTipoMachine(mode === "add" ?
            ""
            :
            famiglie.filter((famiglia) => famiglia.id === objectToModify.familyId).length > 0 ?
                famiglie.filter((famiglia) => famiglia.id === objectToModify.familyId)[0].famiglia
                :
                objectToModify.familyId)

        setName(mode === "add" ? "" : objectToModify.nome)
        setIdMachine(mode === "add" ? "" : objectToModify.identityCode)
        setAnnoProd(mode === "add" ?
            ""
            :
            listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id).length > 0 ?
                listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id)[0].annoProd
                :
                ""
        )
        setAnnoInst(mode === "add" ?
            ""
            :
            listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id).length > 0 ?
                listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id)[0].annoInst
                :
                "")
        setProd(mode === "add" ?
            "" :
            listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id).length > 0 ?
                listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id)[0].produttore
                :
                "")

    }, [objectToModify, listDatiTarga])

    useEffect(() => {

        let linee = machine.map(el => el.reparto)
        const tipiMacchine = machine.map(el => el.familyId)
        const tipiMacchinaSenzaRipetizioni = tipiMacchine.sort().filter((item, pos, ary) => !pos || item != ary[pos - 1])
        const tipiMacchinaSenzaFamigglieDefault = []
        tipiMacchinaSenzaRipetizioni.forEach(el => {
            if (famiglie.find(elemento => elemento.id === el)) {

            } else {
                tipiMacchinaSenzaFamigglieDefault.push(el)
            }
        })
        setLineeEsistenti(linee.sort().filter((item, pos, ary) => !pos || item != ary[pos - 1]))
        setFamiglieMacchine(tipiMacchinaSenzaFamigglieDefault)
    }, [machine])

    const [lineeEsisteni, setLineeEsistenti] = useState([])
    const [famiglieMacchineInstallate, setFamiglieMacchine] = useState([])

    //DATI TARGA

    const [tipoMachine, setTipoMachine] = useState(mode === "add" ? "" : famiglie.filter((famiglia) => famiglia.id === objectToModify.familyId).length > 0 ? famiglie.filter((famiglia) => famiglia.id === objectToModify.familyId)[0].famiglia : objectToModify.familyId)
    const [idMachine, setIdMachine] = useState(mode === "add" ? "" : objectToModify.identityCode)
    const [name, setName] = useState(mode === "add" ? "" : objectToModify.nome)
    const [annoProd, setAnnoProd] = useState(mode === "add" ? "" : listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id).length > 0 ? listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id)[0].annoProd : "")
    const [annoInst, setAnnoInst] = useState(mode === "add" ? "" : listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id).length > 0 ? listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id)[0].annoInst : "")
    const [prod, setProd] = useState(mode === "add" ? "" : listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id).length > 0 ? listDatiTarga.filter((datiTarga) => datiTarga.id === objectToModify.id)[0].produttore : "")
    const [listImage, setListImage] = useState([])

    //DATI IMPIANTO
    const [plantsId, setPlantsId] = useState(mode === "add" ? plant.id : objectToModify.idPlant);
    const [departmentsId, setDepartmentsId] = useState(mode === "add" ? department.id : objectToModify.idDepartment);
    const [lineId, setLineId] = useState(mode === "add" ? line.id : objectToModify.idLinea)

    //INFORMAZIONI MANUNTENTIVE
    const [listMainteneancePlan, setListMainteneancePlan] = useState([])
    const [listAltro, setListAltro] = useState([])




    const validField = (fieldValue) => {
        return fieldValue != null && fieldValue.length > 0 && fieldValue !== undefined
    }
    const handleChangePlants = (e) => {
        setPlantsId(e.target.value)
        setDepartmentsId("")
        setLineId("")
    }
    const handleChangeDepartment = (e) => {
        setDepartmentsId(e.target.value)
        setLineId("")
        if (plantsId === undefined || plantsId.length === 0) {
            const platsOfDepartment = listDepartment.filter((department) => department.id === e.target.value)[0].idPlant
            setPlantsId(platsOfDepartment)
        }
    }
    const handleChangeLine = (e) => {
        setLineId(e.target.value)
        if (plantsId === undefined || plantsId.length === 0) {
            const plantOfLineId = listDepartment.filter((department) => department.id === listLine.filter((line) => line.id === e.target.value)[0].idDepartment)[0].idPlant
            setPlantsId(plantOfLineId)
        }
        if (departmentsId === undefined || departmentsId.length === 0) {
            const departmentOfLineId = listLine.filter((line) => line.id === e.target.value)[0].idDepartment
            setDepartmentsId(departmentOfLineId)
        }
    }


    return (
        <div
            className="bodyAddNewAsset"
        >
            <div
                className="boxAllAddNewAsset"
            >
                <div
                    className="boxTitleAddNewAsset"
                >
                    <h1
                        className="elementTitleAddNewAsset"
                    >{mode === "add" ? `Aggiunta di un nuovo macchinario` : `Modifica macchinario`}</h1>
                    <h1
                        className="elementCloseSchedaAddNewAsset"
                        onClick={() => close()}
                    >x</h1>
                </div>

                <div
                    className="boxFormAddNewAsset"
                >
                    <div
                        className="boxColumnFromAddNewAsset boxBlockFormAddNewAsset"

                    >
                        <h2
                            style={{ color: `${titleColor}` }}
                            className="elementSubTitleFormAddNewAsset"
                        >
                            Dati di targa
                        </h2>
                        <span className="elementoLableForm">Tipologia di macchina</span>
                        <input
                            className={`elementInputAddNewAsset`}
                            required
                            placeholder="Tipo macchinario"
                            value={tipoMachine}
                            onChange={(e) => setTipoMachine(e.target.value)}
                            list="listFamily"
                        />
                        <datalist
                            id="listFamily"
                        >
                            {
                                famiglie.map((el) => <option key={el.id} value={el.famiglia} />)

                            }
                            {
                                famiglieMacchineInstallate.map((el, index) => <option key={index} value={el} />)
                            }
                        </datalist>
                        <span className="elementoLableForm">Codice identificativo</span>
                        <input
                            className="elementInputAddNewAsset"
                            required
                            placeholder="ID"
                            value={idMachine}
                            onChange={(e) => setIdMachine(e.target.value)}
                        />
                        <span className="elementoLableForm">Nome macchinario</span>
                        <input
                            className="elementInputAddNewAsset"
                            required
                            placeholder="Nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <div
                            className="boxInputAllineatiAddNewAsset"
                        >
                            <div>
                                <span className="elementoLableForm" style={{ marginLeft: "0" }}>Anno produzione</span>
                                <input
                                    className="elementInputAddNewAsset elementInputAddNewAssetHandleYear"
                                    placeholder="Anno produzione"
                                    value={annoProd}
                                    onChange={(e) => setAnnoProd(e.target.value)}
                                    type="number"
                                    min={1990}
                                    max={2050}

                                />
                            </div>
                            <div>
                                <span className="elementoLableForm" style={{ marginLeft: "0" }}>Anno installazione</span>
                                <input
                                    className="elementInputAddNewAsset elementInputAddNewAssetHandleYear"
                                    placeholder="Anno installazione"
                                    value={annoInst}
                                    onChange={(e) => setAnnoInst(e.target.value)}
                                    type="number"
                                    min={1990}
                                    max={2050}
                                />
                            </div>

                        </div>
                        <span className="elementoLableForm">Produttore</span>
                        <input
                            className="elementInputAddNewAsset"
                            placeholder="Produttore"
                            value={prod}
                            onChange={(e) => setProd(e.target.value)}
                        />



                        {/*
                        <MultinputFile
                            placeholder={"Immagini"}
                            multi={true}
                            setList={setListImage}
                        />
                        */}


                    </div>
                    <div
                        className="elementSpaceBetweenAddNewAsset"
                    ></div>
                    <div
                        className="boxColumnFromAddNewAsset"

                    >
                        <div
                            className="boxBlockFormAddNewAsset correctionelementPrimaryRightboxBlockFormAddNewAsset"
                        >
                            <h2
                                className="elementSubTitleFormAddNewAsset"
                                style={{ color: `${titleColor}` }}
                            >
                                Dati impianto
                            </h2>
                            <span className="elementoLableForm">Stabilimento</span>
                            <select
                                className="selectDatiImpianto"
                                placeholder="Stabilimenti"
                                required
                                onChange={(e) => handleChangePlants(e)}
                                value={plantsId}
                            >
                                <option value="" selected>
                                    Stabilimenti
                                </option>
                                {listPlant.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.nome}
                                    </option>
                                ))}
                            </select>
                            <span className="elementoLableForm">Reparto</span>
                            <select
                                className="selectDatiImpianto"
                                required
                                placeholder="Reparti"
                                onChange={(e) => handleChangeDepartment(e)}
                                value={departmentsId}
                            >
                                <option value="" selected>
                                    Reparti
                                </option>

                                {(validField(plantsId))
                                    ? listDepartment
                                        .filter((department) => department.idPlant === plantsId)
                                        .map((el) => (
                                            <option key={el.id} value={el.id}>
                                                {el.name}
                                            </option>
                                        ))
                                    : listDepartment.map((el) => (
                                        <option key={el.id} value={el.id}>
                                            {el.name}
                                        </option>
                                    ))}
                            </select>
                            <span className="elementoLableForm">Liena</span>
                            <select
                                className="selectDatiImpianto"
                                required
                                placeholder="Linee"
                                onChange={(e) => handleChangeLine(e)}
                                value={lineId}
                            >
                                <option value="" selected>
                                    Linee
                                </option>
                                {(validField(departmentsId))
                                    ?
                                    listLine
                                        .filter((line) => line.idDepartment === departmentsId)
                                        .map((el) => (
                                            <option key={el.id} value={el.id}>
                                                {el.name}
                                            </option>
                                        ))
                                    :
                                    (validField(plantsId))
                                        ?
                                        listLine
                                            .filter(linea => listDepartment.filter(department => department.idPlant === plantsId)
                                                .some(department => department.id === linea.idDepartment))
                                            .map((el) => (
                                                <option key={el.id} value={el.id}>
                                                    {el.name}
                                                </option>
                                            ))
                                        :
                                        listLine.map((el) => (
                                            <option key={el.id} value={el.id}>
                                                {el.name}
                                            </option>
                                        ))}
                            </select>
                        </div>
                        {/*
                        <div
                            className="boxBlockFormAddNewAsset correctionelementSecondaryRightboxBlockFormAddNewAsset"

                        >
                            <h2
                                className="elementSubTitleFormAddNewAsset"
                                style={{ color: `${titleColor}` }}
                            >
                                Informazioni manutentive
                            </h2>

                            <MultinputFile
                                placeholder={"Piani manutentivi"}
                                setList={setListMainteneancePlan}
                                multi={true}
                            />
                            <MultinputFile
                                placeholder={"Altro"}
                                multi={true}
                                setList={setListAltro}
                            />
                        </div>
                                        */}

                        {mode === "add" ?
                            <button
                                className="elementButtonSubmitFormAddNewAsset"
                                onClick={() => {

                                    if (name !== "" && idMachine !== "" && tipoMachine !== "" && validField(plantsId) && validField(departmentsId) && validField(lineId)) {
                                        const response = dispatch(newAsset(
                                            {
                                                idCode: idMachine,
                                                idFamily: famiglie.find((el) => el.famiglia === tipoMachine) ? famiglie.find((el) => el.famiglia === tipoMachine).id : tipoMachine,
                                                name: name,
                                                //idPlant: plantid,
                                                //reparto: linea,
                                                idLinea: lineId,
                                                idPlant: plantsId,
                                                idDepartment: departmentsId
                                            }
                                        ))
                                        /**
                                         * sulla base della risposta devo effettuare il caricamento dei file ( immagini e documenti del macchinario), una volta caricati ed attesa delle risposte si effettua il caricamento dei dati di targa
                                         *
                                         * Ad ora non effettuiamo il caricamento dei file ma solo dei dati di targa e successivamente implementeremo un ulteriore layer
                                         */
                                        /**
                                         * il blocco caricamento file va qui in una promise.all con tutti i caricamenti asincroni e se vengono tutti eseguiti allora effettuaimo il caricamento
                                         */
                                        Promise.all([response])
                                            .then(ris => {
                                                if (ris[0].meta.requestStatus !== "rejected") {
                                                    dispatch(newDataTargaAssets({
                                                        idAsset: ris[0].payload.id,
                                                        imageURL: "",
                                                        yearProduction: annoProd,
                                                        yearInstallation: annoInst,
                                                        producer: prod
                                                    }))
                                                    close()

                                                } else {
                                                    //alert("errore di rete, riprovare")
                                                }
                                            }
                                            )


                                    } else {
                                        alert("inserisci tutti i campi obbligatori")
                                    }
                                }
                                }
                            >
                                Aggiungi macchinario
                            </button>
                            :
                            <button
                                className="elementButtonSubmitFormAddNewAsset"
                                onClick={() => {

                                    if (name !== "" && idMachine !== "" && tipoMachine !== "" && validField(plantsId) && validField(departmentsId) && validField(lineId)) {

                                        const response = dispatch(modifyAssets(
                                            {
                                                id: objectToModify.id,
                                                idCode: idMachine,
                                                idFamily: famiglie.find((el) => el.famiglia === tipoMachine) ? famiglie.find((el) => el.famiglia === tipoMachine).id : tipoMachine,
                                                name: name,
                                                idLinea: lineId,
                                                idPlant: plantsId,
                                                idDepartment: departmentsId
                                            }
                                        ))
                                        Promise.all([response])
                                            .then(ris => {
                                                if (ris[0].meta.requestStatus !== "rejected") {
                                                    dispatch(updateDataTarga({
                                                        idAsset: objectToModify.id,
                                                        imageURL: "",
                                                        yearProduction: annoProd,
                                                        yearInstallation: annoInst,
                                                        producer: prod
                                                    }))
                                                    close()
                                                } else {
                                                    //alert("errore di rete, riprovare")
                                                }
                                            }
                                            )



                                    } else {
                                        alert("inserisci tutti i campi obbligatori")
                                    }
                                }
                                }
                            >
                                Modifica macchinario
                            </button>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PersFromAddNewAsset