import React from "react";
import * as d3 from 'd3';
import { titleColor } from "../../../configurations/generalStye";
import { selezioneColore, selzioneColoreInversa } from '../../../functions/generiche'

export default class DesktopLoadDonutChart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            width: 100,
            height: 100

        }
    }



    componentDidMount() {



        //costruzione del valore
        let grafActive = [{
            color: this.props.max ? selzioneColoreInversa(this.props.value, this.props.max) : selezioneColore(this.props.value),
            value: this.props.value
        },
        {
            color: "transparent",
            value: this.props.max ? this.props.max - this.props.value
                : 100 - this.props.value
        }
        ]
        let radius = Math.min(this.state.width, this.state.height) / 2
        let pie = d3.pie()
            .sort(null)
            .value((d) => {
                return d.value;
            })


        let arc = d3.arc()
            .innerRadius(radius - 20)
            .outerRadius(radius - 10);


        let svg = d3.select(`.graf${this.props.id}`)
            .attr("width", this.state.width)
            .attr("height", this.state.height)
            .attr("class", `graf${this.props.id}`)
            .select("g")
            .attr("transform", "translate(" + this.state.width / 2 + "," + this.state.height / 2 + ")");

        let path1 = svg.selectAll(`.background${this.props.id}`)
            .data(pie([{
                color: "lightgray",
                value: 1
            }]))
            .enter().append("path")
            .attr("class", `.background${this.props.id}`)
            .attr("fill", function (d, i) {
                return d.data.color;
            })
            .attr("d", arc);

        let path = svg.selectAll(`.foreground${this.props.id}`)
            .data(pie(grafActive))
            .enter().append("path")
            .attr("class", `.foreground${this.props.id}`)
            .attr("fill", function (d, i) {
                return d.data.color;
            })
            .attr("d", arc);


        svg.select("text").attr("class", `testoGraficoDonut${this.props.id}`)
            .text(this.props.max ? `${this.props.value} / ${this.props.max}` : this.props.value + "%")
            .attr("class", `testoGraficoDonut${this.props.id}`)
            .style("font-weight", "bolder")
            .style("font-size", this.props.max ? "small" : "normal")
            .style("fill", this.props.max ? selzioneColoreInversa(this.props.value, this.props.max) : selezioneColore(this.props.value))
            .attr("x", this.props.max ? radius / 12 - 20 : radius / 12 - 20)
            .attr("y", this.props.max ? radius / 10 : radius / 8);
    }

    
    componentDidUpdate(){


        //costruzione del valore
        let grafActive = [{
            color: this.props.max ? selzioneColoreInversa(this.props.value, this.props.max) : selezioneColore(this.props.value),
            value: this.props.value
        },
        {
            color: "transparent",
            value: this.props.max ? this.props.max - this.props.value
                : 100 - this.props.value
        }
        ]
        let radius = Math.min(this.state.width, this.state.height) / 2
        let pie = d3.pie()
            .sort(null)
            .value((d) => {
                return d.value;
            })


        let arc = d3.arc()
            .innerRadius(radius - 20)
            .outerRadius(radius - 10);


        let svg = d3.select(`.graf${this.props.id}`)
            .attr("width", this.state.width)
            .attr("height", this.state.height)
            .attr("class", `graf${this.props.id}`)
            .select("g")
            .attr("transform", "translate(" + this.state.width / 2 + "," + this.state.height / 2 + ")");

        let path1 = svg.selectAll(`.background${this.props.id}`)
            .data(pie([{
                color: "lightgray",
                value: 1
            }]))
            .enter().append("path")
            .attr("class", `.background${this.props.id}`)
            .attr("fill", function (d, i) {
                return d.data.color;
            })
            .attr("d", arc);

        let path = svg.selectAll(`.foreground${this.props.id}`)
            .data(pie(grafActive))
            .enter().append("path")
            .attr("class", `.foreground${this.props.id}`)
            .attr("fill", function (d, i) {
                return d.data.color;
            })
            .attr("d", arc);


        svg.select("text").attr("class", `testoGraficoDonut${this.props.id}`)
            .text(this.props.max ? `${this.props.value} / ${this.props.max}` : this.props.value + "%")
            .attr("class", `testoGraficoDonut${this.props.id}`)
            .style("font-weight", "bolder")
            .style("font-size", this.props.max ? "small" : "normal")
            .style("fill", this.props.max ? selzioneColoreInversa(this.props.value, this.props.max) : selezioneColore(this.props.value))
            .attr("x", this.props.max ? radius / 12 - 20 : radius / 12 - 20)
            .attr("y", this.props.max ? radius / 10 : radius / 8);
    }
    
    render() {
        return (
            <div className="bodyLoadDonutChart">
                <h4
                    className="titleLoadDonutChart"
                    style={{ color: `${titleColor}` }}
                >
                    {this.props.title}
                </h4>
                <svg className={`graf${this.props.id}`} >
                    <g>
                        <text className={`testoGraficoDonut${this.props.id}`}></text>
                    </g>
                </svg>
            </div>
        )
    }
}