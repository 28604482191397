import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify';
import { serverStatusForLable } from '../../../configurations/setting';





const usersAdapter = createEntityAdapter({
    selectId: (users) => users.id,

})

export const getListUsers = createAsyncThunk("users/getUsers", async () => {
    const apiName = 'users';
    const path = `/users`;
    let resp = ""
    let listAdm = []
    let listMan = []
    let listOp = []
    let listOther = []
    try {
        resp = await API.get(
            apiName,
            path

        )
        listAdm = resp.listAdmin.map(e => {

            return {
                id: e.Username,
                phoneNumber: { value: e.Attributes.find(el => el.Name === "phone_number")?.Value },
                date: { value: e.UserCreateDate.slice(0, 10) },
                name: { value: e.Attributes.find(el => el.Name === "name")?.Value, subValue: e.Attributes.find(el => el.Name === "email")?.Value, boldValue: true },
                role: { value: "Admin", subValue: e.Attributes.find(el => el.Name === 'custom:_id_structure')?.Value },
                status: { value: e.UserStatus, badge: serverStatusForLable.find(el => e.UserStatus === el.UserStatus)?.badge ? serverStatusForLable.find(el => e.UserStatus === el.UserStatus).badge : "error" },
                enabled: e.Enabled
            }
        })
        listMan = resp.listManager
            .map(e => {
                return {
                    id: e.Username,
                    phoneNumber: { value: e.Attributes.find(el => el.Name === "phone_number")?.Value },
                    date: { value: e.UserCreateDate.slice(0, 10) },
                    name: { value: e.Attributes.find(el => el.Name === "name")?.Value, subValue: e.Attributes.find(el => el.Name === "email")?.Value, boldValue: true },
                    role: { value: "Manager", subValue: e.Attributes.find(el => el.Name === 'custom:_id_structure')?.Value },
                    status: { value: e.UserStatus, badge: serverStatusForLable.find(el => e.UserStatus === el.UserStatus)?.badge ? serverStatusForLable.find(el => e.UserStatus === el.UserStatus).badge : "error" },
                    enabled: e.Enabled
                }
            })

        listOp = resp.listOperator.map(e => {
            return {
                id: e.Username,
                phoneNumber: { value: e.Attributes.find(el => el.Name === "phone_number")?.Value },
                date: { value: e.UserCreateDate.slice(0, 10) },
                name: { value: e.Attributes.find(el => el.Name === "name")?.Value, subValue: e.Attributes.find(el => el.Name === "email")?.Value, boldValue: true },
                role: { value: "Operator", subValue: e.Attributes.find(el => el.Name === 'custom:_id_structure')?.Value },
                status: { value: e.UserStatus, badge: serverStatusForLable.find(el => e.UserStatus === el.UserStatus)?.badge ? serverStatusForLable.find(el => e.UserStatus === el.UserStatus).badge : "error" },
                enabled: e.Enabled
            }
        })

        listOther = resp.listOtherUser.map(e => {
            return {
                id: e.Username,
                phoneNumber: { value: e.Attributes.find(el => el.Name === "phone_number")?.Value },
                date: { value: e.UserCreateDate.slice(0, 10) },
                name: { value: e.Attributes.find(el => el.Name === "name")?.Value ? e.Attributes.find(el => el.Name === "name")?.Value : "-", subValue: e.Attributes.find(el => el.Name === "email")?.Value, boldValue: true },
                role: { value: "Un role ", subValue: e.Attributes.find(el => el.Name === 'custom:_id_structure')?.Value },
                status: { value: e.UserStatus, badge: serverStatusForLable.find(el => e.UserStatus === el.UserStatus)?.badge ? serverStatusForLable.find(el => e.UserStatus === el.UserStatus).badge : "error" },
                enabled: e.Enabled
            }
        }
        )

    } catch (err) {

    }





    return [...listAdm, ...listMan, ...listOp, ...listOther]
})


export const createNewUser = createAsyncThunk("users/createNewUser", async (
    { name, email, roleUser, idStructure, phone_number }
) => {
    const apiName = 'users';
    const path = `/users`;
    let resp = ""

    try {
        resp = await API.post(
            apiName,
            path,
            {
                body: {
                    name,
                    email,
                    roleUser,
                    idStructure,
                    phone_number,

                }
            }

        )
    } catch (err) {
        resp = err
    }

    console.log("risposta creazione utente", resp)
    return resp

})


export const updateUser = createAsyncThunk("users/updateUser", async ({
    name, email, roleUser, idStructure, phone_number
}) => {
    const apiName = 'users';
    const path = `/users`;
    let resp = ""
    try {
        resp = await API.put(
            apiName,
            path,
            {
                body: {
                    name,
                    email,
                    roleUser,
                    idStructure,
                    phone_number,

                }
            }

        )
    } catch (err) {
        resp = err
    }

    console.log("risposta modifica utente", resp)
    return resp

})


export const deleteUser = createAsyncThunk("users/deleteUser", async ({ email }) => {
    const apiName = 'users';
    const path = `/users`;
    let resp = ""
    try {
        resp = await API.del(
            apiName,
            path,
            {
                body: {

                    email

                }
            }

        )
    } catch (err) {
        resp = err
    }


    console.log("eliminazione utente", resp)
    return resp.rispUsr.Username
})


const usersSlice = createSlice({
    name: "users",
    initialState: usersAdapter.getInitialState(),
    reducers: {

    },
    extraReducers: bulder =>
        bulder
            .addCase(getListUsers.fulfilled, usersAdapter.upsertMany)
            .addCase(createNewUser.fulfilled, (state, action) => console.log("risposta positiva create user", state, action))
            .addCase(createNewUser.rejected, (state, action) => console.log("risposta negativa create user", state, action))
            .addCase(updateUser.fulfilled, (state, action) => console.log("modificato", state, action))
            .addCase(updateUser.rejected, (state, action) => console.log("risposta negativa modifica user", state, action))
            .addCase(deleteUser.fulfilled, usersAdapter.removeOne)
})


export default usersSlice.reducer

export const { selectAll: selectAllUsers } = usersAdapter.getSelectors(state => state.users)