
import PersFormAddNewLinea from "./subElements/persFormAddNewLinea"
import './mainAddNewLinea.css'

const AddNewLinea = ({ close, listPlant, listDepartment, listLine,listMachinery,listBudgets,reparto,stabilimento,mode,objectToModify,budgetToModify}) => {

    return <PersFormAddNewLinea
        close={close}

        plantsList={listPlant}
        departmentsList={listDepartment}
        listLine={listLine}
        listMachinery={listMachinery}
        listBudgets={listBudgets}

        stabilimento={stabilimento}
        reparto={reparto}

        mode={mode}
        objectToModify={objectToModify}
        budgetToModify={budgetToModify}
    />
}

export default AddNewLinea