import React, { useState } from "react";
import './mainNewUser.css'
import Breadcrumb from "../../../components/userManagement/breadcrumb/Breadcrumb";
import Tabs from "../../../components/userManagement/tabs/Tabs";
import UserForm from "./mainUserForm";
import { useNavigate } from "react-router-dom";
import { splitPhoneNumber } from "../../../functions/phoneNumber";
import { countries } from "../../../components/userManagement/Phoneprefixes";
import { pathImpostazioni, subPathUsers } from "../../../configurations/setting";
import Button from "../../../components/userManagement/button/Button";
import { createNewUser, selectAllUsers } from "../../root/slices/usersSlice";
import { useDispatch, useSelector } from "react-redux";

const NewUser = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [tabPanel, setTabPanel] = useState(0)
    const [userState, setUserState] = useState({
        name: "",
        email: "",
        roleUser: { value: "", lable: "" },
        idStructure: "",
        phone_number: ""
    })

    const listUsers = useSelector(selectAllUsers)

    const breadcrumbItems = [
        {
            href: `${pathImpostazioni}/${subPathUsers}`,
            title: 'Users',
        },
        {
            href: '',
            title: 'New User',
        },
    ];

    const tabsItems = [
        {
            key: 1,
            label: 'Details',
            content: <UserForm
                setUserState={setUserState}
            />,
        }
    ];

    const onChange = (key) => {
        const tabIndex = tabsItems.findIndex(tab => tab.key === key);

        // Verifica se l'indice è valido prima di impostare il TabPanel
        if (tabIndex !== -1) {
            setTabPanel(tabIndex);
        } else {
            // Gestisci il caso in cui non viene trovato alcun elemento con la chiave corrispondente
            console.warn(`Tab with key ${key} not found`);
        }
    };

    return (
        <div className="mainSettingsUsers">
            <div className="sidebarSettings">
                <div className="sidebarSettingsContent">
                    <svg width="1.5vw" height="1.4vw" viewBox="0 0 22 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21 19V17C21 15.1362 19.7252 13.5701 18 13.126M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5C17 6.67869 15.9659 8.11585 14.5 8.70924M16 19C16 17.1362 16 16.2044 15.6955 15.4693C15.2895 14.4892 14.5108 13.7105 13.5307 13.3045C12.7956 13 11.8638 13 10 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
                            stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span
                        className={"sidebar"}
                        style={{ fontSize: "1.5vw", fontWeight: "bold", margin: "0.5vw 1vw" }}
                    >Users</span>
                </div>
            </div>
            <div className="mainSettingsUsersBody">

                <div className="mainNewUserSection">
                    <div className={"mainNewUserSectionHeader"}>
                        <Breadcrumb items={breadcrumbItems} />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                // marginLeft: "2vw"
                            }}
                        >
                            <h1
                                style={{
                                    margin: 0,
                                    fontSize: "2vw",
                                    marginBottom: "2vw"
                                }}
                            >New User</h1>
                        </div>
                        <Tabs items={tabsItems} onChange={onChange} defaultActiveKey={1} />
                    </div>

                    <div className="mainNewUserSectionBody">
                        {tabsItems[tabPanel].content}

                    </div>
                    <div className="mainNewUserFooter">
                        <div className="divider" />
                        <div className="mainNewUserFooterContent">
                            <Button
                                style={{
                                    marginRight: "1vw"
                                }}
                                onClick={() => navigate(`${pathImpostazioni}/${subPathUsers}`)}
                                className={"button cancel"}>
                                Cancel
                            </Button>
                            <Button
                                className={"button save"}
                                onClick={() => {
                                    if (!listUsers.find(el => el.name.subValue === userState.email)) {
                                        if (userState.email.match(
                                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        )) {
                                            dispatch(createNewUser({
                                                name: userState.name,
                                                email: userState.email,
                                                roleUser: userState.roleUser,
                                                idStructure: userState.idStructure,
                                                phone_number: userState.phone_number
                                            })).then(e => navigate(`${pathImpostazioni}/${subPathUsers}`))
                                        }
                                        else{
                                            alert("inserire mail")
                                        }
                                    } else {
                                        alert("email utente già esistente")
                                    }
                                }
                                }
                            >
                                Save
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

};

export default NewUser;