

import React from "react";


import './mainLoadDonutChart.css'
//import { mediaQuery } from "../../generalStye";
import DesktopLoadDonutChart from "./subElement/desktopLoadDonutChart";

export default class LoadDonutChart extends React.Component {



    render() {
        return (
            <DesktopLoadDonutChart
                id={this.props.id}
                value={this.props.value}
                title={this.props.title}
                max={this.props.max}
            />
        )
    }
}