import React, { useEffect, useState } from "react";

import tornio from '../../../icon/r300.jpg';
import accumulatore from '../../../icon/Accumulatore.png'
import autotrasformatore from '../../../icon/autotraformatoreMT-BT1.jpeg'
import macchinaTaglio from '../../../icon/Macchinedataglio.jpg'
import quadroEletticoGenerale from '../../../icon/quadrogenerale.jpeg'
import laminatore from '../../../icon/Macchinedilaminazione.jpg'
import serbatorio from '../../../icon/serbatoio.JPG'
import inverter from '../../../icon/inverter.jpeg'
import compressore from '../../../icon/Compressore.jpeg'
import motore from '../../../icon/sinotticoMotore.png'
import panelFotovoltaico from '../../../icon/Panelli-fotovoltaici.jpeg'
import distArea from '../../../icon/distribuzione aria.png'
import estrusore from '../../../icon/estrusore.jpg'
import robotMobileH from '../../../icon/robotMobileH.jpg'
import fresatrice from '../../../icon/DA300-1.jpg'
import senzaImmagine from "../../../icon/default.jpg";
import dobot from '../../../icon/dobot_1.png'

import SensorWidget from "./sensorWidget";
import { useSelector } from "react-redux";
import { getMachineSelected } from "../../../structures/root/slices/schedeMachineDetailSlice";
//DatiSensori

const PerSynopticMachinery = ({ DatiSensori }) => {


    const [sensorListName, setSensorList] = useState(["a", "b"])
    const machineSelect = useSelector(getMachineSelected)

    useEffect(() => {
        let listaNomiSensori = DatiSensori ? Object.keys(DatiSensori) : ["a", "b"]
        setSensorList(listaNomiSensori)
    }, [DatiSensori])
    useEffect(() => {
    }, [machineSelect])

    const selezioneImmagine = (famiglia) => {
        switch (famiglia) {
            case "atr0":
                return autotrasformatore
            case "acf0":
                return accumulatore
            case "mdt0":
                return macchinaTaglio
            case 'qeg0':
                return quadroEletticoGenerale
            case "lmn0":
                return laminatore
            case "srb0":
                return serbatorio
            case 'inv0':
                return inverter
            case "cmp0":
                return compressore
            case "acm0":
                return motore
            case "pft0":
                return panelFotovoltaico
            case "dst0":
                return distArea
            case 'est0':
                return estrusore
            case 'rmh0p':
                return robotMobileH
            case 'frs0':
                return fresatrice
            case 'dbt0':
                return dobot
            default:
                return senzaImmagine
        }
    }


    return (
        <div
            className="bodySinottico"
            style={{ "backgroundImage": `url(${selezioneImmagine(machineSelect?.family)})` }}
        >



            {/*
                <div className="boxDesktopSensoriSinottico">
                {
                    sensorListName.map(
                        (el, index) => <SensorWidget
                            key={index}
                            titoloSensore={el}
                            valoreAttuale={DatiSensori[el] ? DatiSensori[el][0] : 2}
                        />)
                }


            </div>
            */}
        </div>
    )
}


export default PerSynopticMachinery