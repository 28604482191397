
import PersFormAddNewReparto from "./subElements/persFormAddNewReparto"
import './mainAddNewReparto.css'
import PersFormAddNewLinea from "../addNewLinea/subElements/persFormAddNewLinea";

const AddNewReparto = ({ close, listPlant, listDepartment, listBudgets,stabilimento,mode,objectToModify,budgetToModify }) => {

    return <PersFormAddNewReparto
        close={close}
        plantsList={listPlant}
        departmentsList={listDepartment}
        listBudgets={listBudgets}
        stabilimento={stabilimento}

        mode={mode}
        objectToModify={objectToModify}
        budgetToModify={budgetToModify}
    />



}

export default AddNewReparto