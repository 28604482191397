import React, { useEffect, useRef, useState } from "react";


import logoBell from '../../../icon/bell.png';
import ListNotification from "./listNotification";
import { allInactive } from '../../../functions/controlloVettori'
import ButtonTwoState from "../../buttonTwoState/mainButtonTwoState";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUpdateDataInterventions, getUpdateInterventions } from "../../../structures/root/slices/interventionSlice";
import { getListReport } from "../../../structures/root/slices/reportSlice";


const ElementNotification = ({ listIntervention, listReport, listAlert, deactiveReport, deactiveAlert, deactiveIntervention, filter, changeFilterAll, changeFilterNew }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUpdateInterventions())
        dispatch(getUpdateDataInterventions())
        dispatch(getListReport())
    }, [])
    const elementReport = listReport.map(el => {
        return <ListNotification
            key={el.id}
            indice={el.id}
            titolo={el.operazione ? el.operazione : "Report"}
            avviso={el.dataIntervento}
            date={el.date}
            link={el.link}
            active={el.active}
            deactiveElementNotifica={deactiveReport}
        />
    })
    const elementIntervention = listIntervention.map((el, index) => {
        return <ListNotification
            key={el.id}
            indice={el.id}
            titolo={el.title}
            avviso={el.date}
            date={el.date}
            link={el.link}
            active={el.active}
            deactiveElementNotifica={deactiveIntervention}
        />
    })



    const elementAlert = listAlert.map((el, index) => {
        return <ListNotification
            key={el.id}
            indice={el.id}
            titolo={el.title}
            avviso={el.avviso}
            date={new Date(el.timeStamp).toISOString().slice(0, 10)}
            link={el.link}
            active={el.active}
            deactiveElementNotifica={deactiveAlert}
        />
    })

    /**
     * con questa soluzione appariamo l'ordinamento delle notifiche nel menu a tendina, le ultime create vengono mostrate come prime nella lista
     */
    const fullList = [...elementIntervention, ...elementAlert]//.sort((a, b) => b.props.date.localeCompare(a.props.date))
    const [isOpenNotifiche, setIsOpenNotifiche] = useState(false)
    const [filterNotification, setFilterNotification] = useState(() => {
        if (filter.status === "all") {
            return [true, false]
        }
        else {
            return [false, true]
        }
    })

    useEffect(() => {
        if (filter === "all") {
            activeTuttiFilter()
        }
        else {
            activeNuoveFilter()
        }
    }, [filter])


    const activeTuttiFilter = () => {
        setFilterNotification([true, false])
    }
    const activeNuoveFilter = () => {
        setFilterNotification([false, true])
    }

    const refLoseFocusElement = useRef(null)

    useEffect(() => {
        /*
        per dare il focus all'elemento appena creato poiche essendo un div
         non ha delle caratte come quelle del tag input*/
        if (isOpenNotifiche) { refLoseFocusElement.current.focus() }
    }, [isOpenNotifiche])


    const defineIsElementLoseFocus = (evento) => {
        /**
         * quando perdiamo il focus dall'elemento div che richiama la funzione il campo relatedTarget
         * se puntiamo ad un elemento per cui non è definito il focus il campo diviene null. 
         * in quel caso dobbiamo chiudere il popup
         */
        if (evento.relatedTarget === null) {

            setTimeout(() => setIsOpenNotifiche(false), 300)
        } else {
            /**
             * chiudiamo il popup anche nel caso puntiamo ad un elmento diverso dal pulsante
             * definito tramite la classe elementDisactiveButtonTwoState boxFiltroTutteNonLetteNotification
             */
            if (evento.relatedTarget.className.trim() !== "elementDisactiveButtonTwoState" &&
                evento.relatedTarget.className.trim() !== "boxFiltroTutteNonLetteNotification" &&
                evento.relatedTarget.className !== "elementDisactiveButtonTwoState  activeButtonTwoState" &&
                evento.relatedTarget.className.trim() !== "logoNotificheElement") {


                setTimeout(() => setIsOpenNotifiche(false), 300)


            }
        }

    }


    /*
    return (
        <div
            className="boxNotificheElement"

        >
            {
                /**
                 * va approfondito verificando che non ci siano nuovi elementi attivi
                 
                allInactive([...listReport, ...listAlert, ...listIntervention]) ?

                    <div
                        className="notifichePresentiNotificheElement"
                    >

                    </div>
                    :
                    <div></div>
            }


            <input
                className="logoNotificheElement "
                type="image"
                alt=""
                src={logoBell}
                onClick={() => setIsOpenNotifiche(!isOpenNotifiche)}

            >

            </input>

            {
                isOpenNotifiche ?
                    <div
                        className="boxElencoNotifiche"

                    >
                        <div


                        >
                            <div>
                                <h4 className="elementTilteNotification">Notification</h4>
                            </div>
                            <div
                                ref={refLoseFocusElement}
                                tabIndex={0}
                                autoFocus
                                className="boxFiltroTutteNonLetteNotification"
                                onBlur={(e) => defineIsElementLoseFocus(e)}
                            >

                                <ButtonTwoState
                                    nameButton={"tutto"}
                                    activeButton={filterNotification[0]}
                                    changeState={changeFilterAll}
                                />
                                <ButtonTwoState
                                    nameButton={"nuovi"}
                                    activeButton={filterNotification[1]}
                                    changeState={changeFilterNew}

                                />
                                {  <Link
                                    className="elementLinkElenecoNotificheCompletoNotification"
                                    to={"/notifiche"}
                                >mostra tutto</Link> }
                            </div>
                        </div>

                        <div
                            className="boxlistNotification"
                        >
                            {
                                elementReport
                            }
                            {
                                fullList

                            }

                        </div>
                    </div>
                    :
                    <span></span>
            }

        </div>
    )
    */

}

export default ElementNotification