


export const ElementCalendarIntervention = ({intervent})=> {
    
    return <span
    className={`elementEventForDayCalendar ${intervent.date > new Date().toISOString().slice(0,10)? `elementInterventionCalendarTodayNext`: `elementInterventionCalendarOld`}`}
    >
        {intervent.title}
    </span>
}

export const ElementCalendarReportIncomplete= ({reportIncomplete, relativeIntervention})=>{
    return <span
    className={`elementEventForDayCalendar elementReportIncomplateCalendarOld`}
    >
       { relativeIntervention? relativeIntervention.title : "Report Incompleto"}
    </span>
}

export const ElementCalendarReportComplete = ({reportComplete, relativeIntervention})=>{
    return <span
    className="elementReportComplete elementEventForDayCalendar"
    >
        {relativeIntervention? relativeIntervention.title : "Report Completo"}
    </span>
}