import Tree from "react-d3-tree";
import './mainDendogram.css'
import { useDispatch, useSelector } from "react-redux";
import { getSelectPlant, selectPlant } from "../../structures/root/slices/plantSlice";
import { useEffect, useState } from "react";
import { selectAllDepartment } from "../../structures/root/slices/listDepartmentSlice";
import { selectAllLine } from "../../structures/root/slices/listLineSlice";
import { titleColor } from "../../configurations/generalStye";
import { selectStructure } from "../../structures/root/slices/selectionForMetricheSlice";
const Dendogram = () => {

    const dispatch = useDispatch();

    const [dendogramStructure, setDendogramStructure] = useState({})

    const selectedPlantMap = useSelector(getSelectPlant)
    const department = useSelector(selectAllDepartment)
    const line = useSelector(selectAllLine)
    const selezioneImpiantoMetriche = (idStructure) => dispatch(selectStructure(idStructure))

    const [selectedNode, setNode] = useState()

    useEffect(() => {
        //cambio dati 
        selezioneImpiantoMetriche({
            idStructure: selectedNode?.id
        })
    }, [selectedNode])

    useEffect(() => {

        //estraiamo i dati dellimpianto selezionato


        setDendogramStructure(
            {
                name: selectedPlantMap?.nome,
                id: selectedPlantMap?.id,
                children: department.filter(el => el.idPlant === selectedPlantMap?.id).map(dep => {
                    return {
                        name: dep.name,
                        id: dep.id,
                        children: line.filter(lin => lin.idDepartment === dep.id).map(lin => {
                            return {
                                name: lin.name,
                                id: lin.id
                            }
                        })
                    }
                }
                )
            }
        )

        setNode({ name: selectedPlantMap?.nome, id: selectedPlantMap?.id })
    }, [selectedPlantMap])







    const findPathToNode = (
        root,
        target,
        path = []
    ) => {

        if (root.name === target.name) {
            path.push(root.name);
            return path;
        }

        if (root.children) {

            for (const child of root.children) {

                const foundPath = findPathToNode(child, target, [...path, root.name]);
                if (foundPath) {
                    return foundPath;
                }
            }
        }
        return "";
    };



    const renderCustomNodeElement = ({ nodeDatum, toggleNode, onNodeClick }) => {

        const pathToSelectedNode = selectedNode
            ? findPathToNode(dendogramStructure, selectedNode)
            : [];


        const isSelected = selectedNode?.name === nodeDatum.name;
        const isParentNode = pathToSelectedNode.includes(nodeDatum.name)
        /*
        let nodeColor = "";
        switch (selectedIndicator) {
            case "average_health_indicator":
                nodeColor = "avgHealthCircle";
                break;
            case "efficiency":
                nodeColor = 'efficiencyCircle';
                break;
            case "saved_intervention":
                nodeColor = 'savedInterventionCircle';
                break;
            default:
                nodeColor = "avgHealthCircle";
        }
        */
        return (
            <g
                onClick={onNodeClick}


            >
                <circle
                    onClick={onNodeClick}
                    r="20"
                    className={`${isSelected || isParentNode ? "nodeSelected" : ""}`}
                />

                <text x="-20" y="-40" className='nodeText'>
                    {nodeDatum.name}
                </text>
            </g>
        );
    };


    return (
        <div id="treeWrapper" className="dendrogram">
             <div>
                    <h3
                        className="elementTitleTripleDonut"
                        style={{ color: `${titleColor}`, margin:"0" }}
                    >
                     {selectedPlantMap?.nome} 
                    </h3>
                </div>
            <Tree
                data={dendogramStructure}
                collapsible={false}
                onNodeClick={(node) => setNode({ name: node.data.name, id: node.data.id })}
                depthFactor={500}
                separation={{ nonSiblings: 3, siblings: 3 }}
                renderCustomNodeElement={renderCustomNodeElement}
                zoom={0.3}
                translate={{ x: 50, y: 100 }}
                nodeSize={{ x: 400, y: 100 }}
            />
        </div>

    )
}


export default Dendogram