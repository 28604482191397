import { useSelector } from 'react-redux'
import './mainUserIcon.css'
import PersIconaUtente from './subElements/persUserIcon'
import { selectUser } from '../../structures/root/slices/datiUtenteSlice'
import UtenteLogo from '../../icon/user.svg'

const UserIcon = ({ logo, ruolo }) => {
    const datiUtente = useSelector(selectUser)
    
    return (
        <PersIconaUtente
            logo={UtenteLogo}
            nomeUtente={datiUtente["0"] ? datiUtente["0"].name : "utente"}
            ruolo={datiUtente["0"] ?
                datiUtente["0"].group[0] === "Admin" ?
                    "Administrator"
                    :
                    datiUtente["0"].group[0]
                :
                ""}
        />
    )
}

export default UserIcon