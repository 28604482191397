import {statiElaborazioneIntervento} from "../../../configurations/setting"

const PersOpenTicket = ({stateTicket, changeState}) => {


    return (

        <h6 className="ElemntStandardDesktopMachineFaultList testoStandardDesktopMachineFaultList">
            <span
                onClick={() => {
                    changeState()
                }}
                className={` elementStateTicket-${stateTicket}`}
            >{statiElaborazioneIntervento[stateTicket]}</span>
        </h6>


    )
}

export default PersOpenTicket