import './mainResearchMachine.css'

import MaterialTableResearchMachine from './subElements/materialTableResearchMachine'
import {getSelectPlant, selectPlant} from '../../structures/root/slices/plantSlice'
import {slectedDataPlant} from '../../structures/root/slices/dataPlantSlice'
import {selectAllMachinery} from '../../structures/root/slices/listMachinerySlice'
import {selectAllFamily} from '../../structures/root/slices/traslaterFamilyMachinery'


import {useSelector} from 'react-redux'
import {selectAllLine} from "../../structures/root/slices/listLineSlice";
import {selectAllDepartment} from "../../structures/root/slices/listDepartmentSlice";
import {selectAllBudget} from '../../structures/root/slices/budgetSlice'
import {API} from "aws-amplify";
import {
    deleteAssets,
    deleteBudgets,
    deleteDataTargaAssets,
    deleteDepartments,
    deleteLineas,
    deletePlants
} from "../../graphql/mutations";
import {GRAPHQL_AUTH_MODE} from "@aws-amplify/api";
import {selectAllDatiTarga} from "../../structures/root/slices/datiTargaMachinerySlice";


const ResearchMachine = () => {

    const dataPlant = useSelector(slectedDataPlant)
    const listFamily = useSelector(selectAllFamily)

    /* STABILIMENTI E LINE MACCHINARI*/
    const listPlant = useSelector(selectPlant)
    // const selectedPlant = useSelector(getSelectPlant)
    const listLine = useSelector(selectAllLine)
    const listDepartment = useSelector(selectAllDepartment)
    const listMachinery = useSelector(selectAllMachinery)
    const listBudgets = useSelector(selectAllBudget)
    const listDatiTarga = useSelector(selectAllDatiTarga)
  

    return<MaterialTableResearchMachine
                listPlant={listPlant}
                listDepartment={listDepartment}
                listLine={listLine}
                listMachinery={listMachinery}
                listDatiTarga={listDatiTarga}

                listBudgets={listBudgets}

                dataPlant={dataPlant}
                listFamily={listFamily}
    />

}

export default ResearchMachine