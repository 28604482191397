import PersSchedeMachinery from "./subElements/persSchedeMachinery";
import './mainSchedeMachinery.css'


const SchedaMachinery = ({ id }) => {
    /**
     * aggiungere la modifica della scheda macchinario quando cambiamo la sceda che viene selezionata
     * lista fault dati-targa elenco manutenzioni
     */
    return (
        <PersSchedeMachinery
            id={id}
        />
    )
}

export default SchedaMachinery