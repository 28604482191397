import React from 'react';
import './Input.css';
import './Form.css';

/**
 * Componente Input per l'input di testo.
 *
 * @param {string} placeholder - Testo di esempio visualizzato quando il campo è vuoto.
 * @param {string} value - Valore attuale dell'input.
 * @param {string} type - Tipo di input (ad esempio, "text", "password", "email", ecc.).
 * @param {Function} onChange - Funzione di callback chiamata quando il valore dell'input cambia.
 * @param {string} className - Classi aggiuntive per lo stile CSS.
 * @param {boolean} disabled - Flag che indica se l'input è disabilitato.
 * @param {Object} style - Stili CSS personalizzati per l'input.
 */

const Input = ({ placeholder, value, type, onChange, onBlur, onFocus, className = "input", disabled, style, error = true }) => {
    return (
        <>
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                style={style}
                onChange={onChange}
                className={className}
                disabled={disabled}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            {error ?? <span style={{ color: "#ff0000", fontSize: "1vw" }}>This is an error message</span>}
        </>
    );
};

// Esporta il componente per l'utilizzo in altri file
export default Input;
