import MaterialExtendAlert from "./subElement/materialExtendAlertList"
import './mainExtendAlertList.css'
const ExtendAlertList = ({ rows, letturaRowId }) => {


    


    return <MaterialExtendAlert
        rows={rows ? rows : [{ id: 1, name: 'Motor fault', description: 'Detected a possible rotor unbalancing', stat: "to schedule", date: "2022-10-13", risk: 60, machineId: "THX567", note: "------" },
        { id: 2, name: 'Trasmission fault', description: 'Possible losing on belt', stat: "plan", date: "2022-10-15", risk: 50, machineId: "YH907O", note: "------" }]}
        //letturaRowId={letturaRowId} //ritorna l'id della riga selezionata tramite click con il mouse
    />

}


export default ExtendAlertList