import './mainFormNewEvent.css'

import DefFormNewEvent from './subElements/formNewEvent'

const FormNewEvent = ({ location, businesUnit, machineIdentificator, closeScheda, closingNumber,mode,inteventionToModify }) => {

    return <DefFormNewEvent
        mode={mode}
        inteventionToModify={inteventionToModify}

        location={location}
        businesUnit={businesUnit}
        machineIdentificator={machineIdentificator}
        closeScheda={closeScheda}
        closingNumber={closingNumber}
    />
}

export default FormNewEvent