import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SchedePageSelect from "../../components/schedePageSelect/mainSchedePageSelect";
import './mainSchedeExplorerPlant.css'


const SchedeExplorePlant = () => {
    let path = useLocation()
    

    return (
        <div className="bodySchedeExplorer">
            <SchedePageSelect 
                metaDataLink={path.pathname}
            />
            <Outlet></Outlet>
        </div>
    )
}

export default SchedeExplorePlant