import React from "react";

import PersCalendar from "./subElements/homeMadeCalendar/homeMadeCalendar";
import './mainCalendar.css'

const Calendar = ({ Event }) => {
    return (
        <PersCalendar
            listEvent={Event}
        />
    )

}

export default Calendar