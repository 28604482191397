import React from "react";
import { Link } from "react-router-dom";
import { pathExplorPlant } from "../../../configurations/setting";


const NewScheda = () => {

    return (
        <Link
            to={`${pathExplorPlant}/new`}
        >
            <div
                className="boxElementTitoloSchede bodyElementTitoloSchede"
            >
                +
            </div>
        </Link>
    )
}

export default NewScheda