import './mainMenu.css'
import Logo from '../../components/logo/mainLogo'
import BarMachine from '../../components/barMachine/mainBarMachine';
import Notification from '../../components/notification/mainNotification';
import UserIcon from '../../components/userIcon/mainUserIcon';
import iconaUtente from '../../icon/UserLogo.png'
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteAllPlant,
    getlistPlants,
    plantAdded,
    selectPlant,
    subscribeCreatePlant,
    subscribeDeletePlant,
    subscribeModifyPlant
} from '../root/slices/plantSlice';
import { useEffect } from 'react';
import {
    addDepartment,
    deleteAllDep,
    getUpdateDepartments,
    selectAllDepartment,
    subscribeCreateDepartment,
    subscribeDeleteDepartment,
    subscribeModifyDepartment
} from "../root/slices/listDepartmentSlice";
import {
    getUpdateBudget,
    subscribeCreateBudget,
    subscribeDeleteBudget,
    subscribeModifyBudget
} from '../root/slices/budgetSlice'
import {
    subscribeCreateDataIntervention,
    subscribeCreateIntervention,
    subscribeModifyDataIntervention,
    subscribeModifyIntervention
} from '../root/slices/interventionSlice';
import {
    addMachinery,
    deleteAllMachine,
    selectAllMachinery,
    subscribeCreateAsset,
    subscribeDeleteAsset,
    subscribeModifyAsset
} from '../root/slices/listMachinerySlice';
import {
    addLine,
    deleteAllLine,
    selectAllLine,
    subscribeCreateLine,
    subscribeDeleteLine,
    subscribeModifyLine
} from "../root/slices/listLineSlice";
import {
    getDatiTargaAsset,
    getlistDatiTargaAsse,
    getlistDatiTargaAssets,
    subscribeCreateDataTargaAssets,
    subscribeDeleteDataTargaAssets,
    subscribeUpdateDataTargaAssets
} from "../root/slices/datiTargaMachinerySlice";
import {
    subscribeCrteateMainteneanceReport,
    subscribeModifyMainteneanceReport
} from "../root/slices/reportSlice";

import Metriche from './subElement/pageMetricCalculation';

import { listaAtttributi } from '../../componentiTest/testUtenti/listaUtenti';
import { datiUtenteAddOne, selectUser } from '../root/slices/datiUtenteSlice';

const Menu = () => {
    const dispatch = useDispatch()
    const userData = useSelector(selectUser)
    const listPlant = useSelector(selectPlant)
    const listDep = useSelector(selectAllDepartment)
    const listLin = useSelector(selectAllLine)
    const listMac = useSelector(selectAllMachinery)
    useEffect(() => {
        if (userData[0]?.sub && !userData[0]?.refStruct) {
            if (
                userData[0]["custom:_id_structure"]

                &&
                listDep.length !== 0
                &&
                listLin.length !== 0
                &&
                listPlant.length !== 0
                &&
                listMac.length !== 0
            ) {
                if (listPlant.find(e => e.id === userData[0]["custom:_id_structure"])) {

                    dispatch(datiUtenteAddOne({
                        ...userData[0],
                        refStruct: "plant"
                    }))

                } else if (listDep.find(e => e.id === userData[0]["custom:_id_structure"])) {

                    dispatch(datiUtenteAddOne({
                        ...userData[0],
                        refStruct: "dep"
                    }))


                } else if (listLin.find(e => e.id === userData[0]["custom:_id_structure"])) {

                    dispatch(datiUtenteAddOne({
                        ...userData[0],
                        refStruct: "lin"
                    }))

                } else {
                    dispatch(datiUtenteAddOne({
                        ...userData[0],
                        refStruct: ""
                    }))
                }
            } else {

                dispatch(datiUtenteAddOne({
                    ...userData[0],
                    refStruct: ""
                }))
            }
        } else {
            if (userData[0]) {

            }
        }

    }, [listLin, listDep, listPlant, listMac])


    useEffect(() => {
        if (userData[0] ? userData[0]["custom:_id_structure"] ? true : false : false) {
            if (userData[0].refStruct === "plant") {

                const plant = listPlant.find(e => e.id === userData[0]["custom:_id_structure"])
                dispatch(deleteAllPlant())
                console.log("lista impianti dopo eliminazione", listPlant)
                dispatch(plantAdded(plant))

                const listDepFilt = listDep.filter(e => e.idPlant === plant.id)

                dispatch(deleteAllDep())
                dispatch(addDepartment(listDepFilt))

                const listLineFilter = listLin.filter(lin => listDepFilt.find(dep => lin.idDepartment === dep.id))

                dispatch(deleteAllLine())
                dispatch(addLine(listLineFilter))

                const listMachine = listMac.filter(m => listLineFilter.find(l => l.id === m.idLinea))

                dispatch(deleteAllMachine())
                dispatch(addMachinery(listMachine))

            } else if (userData[0].refStruct === "dep") {
                const dep = listDep.find(e => e.id === userData[0]["custom:_id_structure"])

                dispatch(deleteAllDep())
                dispatch(addDepartment([dep]))

                const plt = listPlant.find(p => p.id === dep.idPlant)
                dispatch(deleteAllPlant())
                dispatch(plantAdded(plt))

                const listLineFilter = listLin.filter(lin => lin.idDepartment === dep.id)

                dispatch(deleteAllLine())
                dispatch(addLine(listLineFilter))

                const listMachine = listMac.filter(m => listLineFilter.find(l => l.id === m.idLinea))

                dispatch(deleteAllMachine())
                dispatch(addMachinery(listMachine))


            } else if (userData[0].refStruct === "lin") {
                const lin = listLin.find(e => e.id === userData[0]["custom:_id_structure"])
                dispatch(deleteAllLine())
                dispatch(addLine([lin]))

                const dep = listDep.filter(d => d.id === lin.idDepartment)
                dispatch(deleteAllDep())
                dispatch(addDepartment([dep]))


                const plt = listPlant.find(p => p.id === dep.idPlant)

                dispatch(deleteAllPlant())
                dispatch(plantAdded(plt))

                const listMachine = listMac.filter(m => listLin.find(l => l.id === m.idLinea))

                dispatch(deleteAllMachine())
                dispatch(addMachinery(listMachine))


            } else {

            }
        } else {
            dispatch(deleteAllPlant())
        }
    }, [userData])



    listaAtttributi()

    useEffect(() => {

        /**
         * CON L'AGGIORNAMENTO DEI NUOVI IMPIANTI BISOGNA SCARICARE ANCHE I DATI AGGIORNATI PER GLI IMPIANTI
         */
        dispatch(getlistPlants())
        dispatch(getUpdateDepartments())
        dispatch(getUpdateBudget())
        dispatch(getlistDatiTargaAssets())

        //INTERVENTI E REPORT SUBSCRIPTION
        dispatch(subscribeCreateIntervention())
        dispatch(subscribeModifyIntervention())
        dispatch(subscribeCreateDataIntervention())
        dispatch(subscribeModifyDataIntervention())

        dispatch(subscribeCrteateMainteneanceReport())
        dispatch(subscribeModifyMainteneanceReport())
        //PLANT DEPARTMENT LINE ASSET SUBSCRIPTION
        //CREATE SUBSCRIPTION
        dispatch(subscribeCreateBudget())
        dispatch(subscribeCreatePlant())
        dispatch(subscribeCreateDepartment())
        dispatch(subscribeCreateLine())
        dispatch(subscribeCreateAsset())
        dispatch(subscribeCreateDataTargaAssets())
        //DELETE SUBSCRIPTION
        dispatch(subscribeDeletePlant())
        dispatch(subscribeDeleteDepartment())
        dispatch(subscribeDeleteLine())
        dispatch(subscribeDeleteAsset())
        dispatch(subscribeDeleteBudget())
        dispatch(subscribeDeleteDataTargaAssets())
        //MODIFY SUBSCRIPTION
        dispatch(subscribeModifyBudget())
        dispatch(subscribeModifyPlant())
        dispatch(subscribeModifyDepartment())
        dispatch(subscribeModifyLine())
        dispatch(subscribeModifyAsset())
        dispatch(subscribeUpdateDataTargaAssets())


        return () => {
            //subscriptionCreateInterventionData.then((sub) => sub.unsubscribe());
            //subscribeModifyInterventionData.then((sub) => sub.unsubscribe());
        };
    }, [dispatch])

    

    return (
        <div
            className='mainMenuStylGeneral'
        >   <Metriche />
            <div
                className='menuPrimaParteSinistra'
            >
                <Logo />
                {/**
                 * <MainRicercaElemento
                 dimDesktop={this.props.dimDesktop ? this.props.dimDesktop : 1201}
                 logo={this.props.logo}
                 funzioneSetStateFromInput={(inp) => this.setState({
                 testoRicerca: inp
                 })}
                 dataList={[

                 {dato1:"dato1", dato2:"dato2", dato3:"dato3"},
                 {dato1:"dato1", dato2:"dato2", dato3:"dato3"}
                 ]}
                 />
                 */


                }

                <BarMachine

                    ValoreAttuale={2}
                    ValoreMassimo={7}
                />



            </div>
            <div
                className='menuPrimaParteDestra'
            >

                <Notification />
                
                <UserIcon

                    logo={iconaUtente}
                    nomeUtente="Fiorentino Giusi"
                    ruolo="Administrator"
                />

            </div>
        </div>
    )

}

export default Menu