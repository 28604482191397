import { useEffect, useState } from "react";


export const HeaderRulPersMachineFaultList = ({ selectedDate, funProssW, funPassW }) => {
    const [lunediSuccessivi, setLunediSuccessivi] = useState(["2023-01-30"])

    useEffect(() => {
        if (selectedDate) {
            let vettTemp = []
            for (let i = 0; i < 7; i++) {

                vettTemp.push(new Date(new Date(JSON.parse(selectedDate)).getTime() + (i * 7 * 86400000)).toISOString().slice(0, 10))
            }

            setLunediSuccessivi(vettTemp)
        }
    }, [selectedDate])


    return (<div className="boxHeaderRulPersMachineFaultList">
        <span
            className="headerClickAngularParentheses"
            onClick={() => funPassW(selectedDate)}
        >{">"}</span>
        {
            lunediSuccessivi.map((el, index) => <span
                className="elementDateHeaderRulPersMachineFaultList"
                key={index}
            >{el.slice(-2) + "-" + el.slice(5, 7)}
            </span>)
        }

        <span
            className="headerClickAngularParentheses"
            //style={{ marginRight: "1vw" }}
            onClick={() => funProssW(selectedDate)}
        >{"<"}</span>
    </div>)
}

export const ElementRulPersMachineFaultList = ({ selectedDate, defeatDate }) => {

    const [weeksVisible, setWeeksVisible] = useState([])

    useEffect(() => {
        if (selectedDate) {
            let vettTemp = []
            let selDa = new Date(JSON.parse(selectedDate)).getTime()
            let defDa = new Date(Date.parse(defeatDate)).getTime()

            for (let i = 0; i < 7; i++) {
                /**
                 * se la differenza tra il gionro selezionato e quello di rottura è > di 4 settimane
                 * allora lo pongo a verde
                 * se è inferiore a 4 settimane ma maggiore o uguale a 2 allora lo pongo a giallo
                 * se è inferiore a 2 allora lo pongo rosso
                 * se la differenza è negativo lo pongo trasparente
                 */
                if (((defDa - (selDa + (i * 7 * 86400000))) / (7 * 86400000)) > 4) {
                    vettTemp.push("#4DB26F")
                } else {
                    if (2 < ((defDa - (selDa + (i * 7 * 86400000))) / (7 * 86400000)) && ((defDa - (selDa + (i * 7 * 86400000))) / (7 * 86400000)) < 4) {
                        vettTemp.push("#EEF149")
                    } else {
                        if (((defDa - (selDa + (i * 7 * 86400000))) / (7 * 86400000)) < 2 && ((defDa - (selDa + (i * 7 * 86400000))) / (7 * 86400000)) > 0) {
                            vettTemp.push("#FF5C00")
                        } else {
                            if (((defDa - (selDa + (i * 7 * 86400000))) / (7 * 86400000)) < 0) {
                                vettTemp.push("transparent")
                            }
                        }
                    }
                }
            }

            setWeeksVisible(vettTemp)
        }
    }, [selectedDate])

    return (<div className="boxHeaderRulPersMachineFaultList">
        <span
            className="headerClickAngularParentheses"

        ></span>
        {
            weeksVisible.map((el, index) => <div
                className="elementDateHeaderRulPersMachineFaultList"
                key={index}
                style={{ width: "50%", height: "100%", backgroundColor: `${el}`, color: `${el}`, margin:"0 2px", borderRadius:"3px" }}
            >a
            </div>
            )

            /*  
            lunediSuccessivi.map((el, index) => ) 
             s*/
        }

        <span
            className="headerClickAngularParentheses"
        //style={{ marginRight: "1vw" }}

        ></span>
    </div>)
}
