import React from "react";
import { titleColor } from "../../../configurations/generalStye";


const TableMachineDocument = ({ DocumentList, datiTarga, infoMachine, listFamily }) => {

    return (
        <div className="bodyDatiTarga">
            <div className="bodyTableMachineDocument">
                <h3
                    className="elementTitoloTableMachineDocument"
                    style={{ "color": titleColor }}
                >Dati Macchinario
                </h3>
                <ul className="elementDatiTargaList">
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName">id :</h6>
                        <h6 className="elementDatiTargaElementListValue">{infoMachine ? infoMachine.identityCode : ""}</h6>
                    </li>
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName">Famiglia macchinario :</h6>
                        <h6 className="elementDatiTargaElementListValue">{infoMachine ?
                            listFamily.find(el => el.id === infoMachine.familyId) ?
                                listFamily.find(el => el.id === infoMachine.familyId).famiglia
                                :
                                infoMachine.familyId
                            :
                            ""}</h6>
                    </li>
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName">Produttore :</h6>
                        <h6 className="elementDatiTargaElementListValue">{datiTarga ? datiTarga.produttore : ""}</h6></li>
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName">Anno produzione :</h6>
                        <h6 className="elementDatiTargaElementListValue">{datiTarga ? datiTarga.annoProd : ""} </h6>
                    </li>
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName"> Anno installazione :</h6>
                        <h6 className="elementDatiTargaElementListValue">{datiTarga ? datiTarga.annoInst : ""}</h6>

                    </li>
                </ul>

            </div>
           
                  
                
            
        </div>
    )

}

export default TableMachineDocument