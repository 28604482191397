import React, { useEffect, useState } from 'react';
import Select from '../select/Select';
import Input from './Input';
import { countries } from '../Phoneprefixes';
import { splitPhoneNumber } from '../../../functions/phoneNumber';
import { BorderColor } from '@mui/icons-material';

const PhoneNumberInput = ({ defaultValue, onChange }) => {



    const [number, setValue] = useState(defaultValue ? defaultValue : '');
    const [prefix, setPrefix] = useState({ ...countries[0] })
    const [prefixValue, setPrefixValue] = useState(countries[0].value)
    //countries[0].value 

    useEffect(() => {
        onChange((prefixValue.slice(1, prefixValue.length - 1) + number).replace(' ', ''))
    }, [prefixValue, number])


    useEffect(() => {
        if (defaultValue) {
            const [cauntry, numberShort] = splitPhoneNumber(defaultValue, countries)
            setValue(numberShort)
            setPrefix(cauntry)
            setPrefixValue(prefix.value)

        }
    }, [defaultValue])

    useEffect(() => {

        setPrefix(countries.find(e => e.value === prefixValue.slice(0, prefixValue.length)))
    }, [prefixValue])

    // Funzione per gestire il cambiamento della select
    const handleSelectChange = (selectedOption) => {

        setPrefixValue(selectedOption.value); // Imposta il prefisso come valore iniziale dell'input
    };



    // Funzione per gestire il cambiamento dell'input
    const handleInputChange = (e) => {

        setValue(e.target.value.slice(prefixValue.length + 2));
    };

    return (
        <div style={{
            width: "100%",
            border: "0.0694vw solid #D0D5DD",
            borderRadius: "0.5vw",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start"
        }}>
            {/* Select per selezionare il paese */}
            <Select selected={prefix} options={countries} onChange={handleSelectChange}
                style={{ width: "20%", border: "none", borderRadius: " 0.5vw 0 0  0.5vw", padding: "0.4vw" }} />

            {/* Input per inserire il numero di telefono */}
            <Input
                type="text"
                value={prefixValue + "  " + number}
                onChange={handleInputChange}
                placeholder="Inserisci il numero di telefono"
                style={{ borderRadius: "0  0.5vw  0.5vw 0", padding: "1.7vw", flex: "1", border: `${number.length === 0 ? "red" : "none"}` }}
            />
        </div>
    );

};

export default PhoneNumberInput;
