import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { getMachineSelected } from './schedeMachineDetailSlice';


const dataFaultMachineryAdapter = createEntityAdapter({
    selectId: (dataFaultMachinery) => dataFaultMachinery.id,
    sortComparer: (a, b) => a.RUL.localeCompare(b.RUL)
})

const dataFaultMachinerySlice = createSlice({
    name: "dataFaultMachinery",
    initialState: dataFaultMachineryAdapter.getInitialState(),
    reducers: {
        dataFaultMachineryAddMany: dataFaultMachineryAdapter.addMany
    }
})


export default dataFaultMachinerySlice.reducer

export const {
    dataFaultMachineryAddMany
} = dataFaultMachinerySlice.actions


export const {selectAll : selectAllDataFault} = dataFaultMachineryAdapter.getSelectors(state=> state.dataFaultMachinery)


export const getDatiFaultForMachineSelected = createSelector([selectAllDataFault, getMachineSelected],
    (state, select) => {
        /**
         * inserito l'if poiché tentava di richiamare i dati di targa anche quando non selezionavo un 
         * macchinario
         */
        if (select) {
            return state.filter(el => el.idMachinery === select.id)
        }

    })