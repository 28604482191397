import { useRef, useState } from "react"



const PersSliderOnOff = ({returnIsActive}) => {

    const refSliderOnOff = useRef(null)
    const [isActive, setIsActive] = useState(false)

    return (
        
        <input
            readOnly
            className="elementSliderOnOff"
            type="range"
            min={0}
            max={1}
            ref={refSliderOnOff}
            value={isActive ? 1 : 0}
            onClick={() => {
                returnIsActive(!isActive)
                setIsActive(!isActive)
            }}
        
        />
    )
}

export default PersSliderOnOff