import Login from "./components/login/mainLogin";
import MainMain from "./structures/root/mainMain";

function App() {
  return (
    <Login />
  )
}

export default App;
