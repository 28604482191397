import React, { useEffect } from "react";
import { personalGreen } from '../../configurations/generalStye'
import './mainBarMachine.css'
import { useSelector, useDispatch } from "react-redux";
import { getUpdateAssets, selectAllMachinery } from '../../structures/root/slices/listMachinerySlice'
import {getUpdateLines} from "../../structures/root/slices/listLineSlice";

const BarMachine = () => {
    const dispatch = useDispatch()
    const numberAllMachine = useSelector(selectAllMachinery).length
    const machenryPredictive = useSelector(selectAllMachinery).filter((el => el.hi)).length
    useEffect(() => {
        dispatch(getUpdateAssets())
        dispatch(getUpdateLines())
    }, [])

    return (
        <div className="bodyBarMachine">
            <div className="elementTitleBarMachine">
                Monitored machinery
            </div>
            <div
                className="boxNumberBarMachine"
            >
                <div className="elementFirstValueBarMachine">{machenryPredictive}</div>
                <div className="elementSecondValueBarMachine">di {numberAllMachine}</div>
            </div>
            <div className="boxBarBarMachine">

                <div
                    className="elementBarBarMachine"
                    style={{ width: `${machenryPredictive ? machenryPredictive / numberAllMachine * 100 + "%" : "0%"}`, backgroundColor: `${personalGreen}` }}
                ></div>
                <div className="elementCompleteBarMachine"></div>
            </div>
        </div>
    )
}


export default BarMachine