import React, { useEffect, useState } from "react";
import { titleColor } from "../../../configurations/generalStye";
import { useDispatch } from "react-redux";
import { getDataSpecificMachineInterventio } from "../../../structures/root/slices/interventionSlice";
import { dataFormatoGMA } from "../../../functions/dateTime";


const PersMachineryInterventions = ({ assetSelected, listIntervention, listReport, listTicket, dataFault }) => {

    const dispatch = useDispatch()
    const [listaInterventi, setListaInterventi] = useState([])

    /**
     * dati degli interventi per specifico macchinario
     */
    useEffect(() => {
        //dispatch(getDataSpecificMachineInterventio({ idAsset: assetSelected.id }))

    }, [assetSelected])
    /**
     * aggionrno la lista degli interveti rimuovendo quelli che hanno un report collegato
     */
    useEffect(() => {
        if (listIntervention) {
            if (listIntervention.length !== 0) {
                setListaInterventi(listIntervention.sort((a, b) => b.date.localeCompare(a.date)))
            }

        }

    }, [listReport, listIntervention])



    return (
        <div className="bodyDesktopInterventoPerMacchinario">
            <h3
                className="TitoloDesktopInterventoPerMacchinario"
                style={{ "color": titleColor }}
            >Elenco manutenzioni</h3>
            <div className="headTableDesktopInterventoPerMacchinario">
                <h5 className="ElemntStandardDesktopInterventoPerMacchinario">Data</h5>
                <h5 className="ElemntStandardDesktopInterventoPerMacchinario">Operatore</h5>
                {
                    /**
                     <h5 className="ElemntStandardDesktopInterventoPerMacchinario">Fault</h5>
                     */
                }
                <h5 className="ElemntStandardDesktopInterventoPerMacchinario">Status</h5>
                <h5 className="ElemntStandardDesktopInterventoPerMacchinario">Operazione</h5>
                <h5 className="ElemntLongDesktopInterventoPerMacchinario">Note</h5>

            </div>
            <div className="RowDesktopMachineFaultList">
                {
                    /*
                    listTicket ?
                        listTicket.map((el, index) => {
                            return (
                                <div
                                    className={`RowTableDesktopInterventoPerMacchinario ${index % 2 ? "backgroundWhiteDesktopInterventoPerMacchinario" : "backgroundBlueDesktopInterventoPerMacchinario"}`}
                                    key={index}
                                >
                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.data}</h6>
                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.operatore}</h6>
                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{
                                        dataFault.filter(elemento => {
                                            return elemento.id === el.fault
                                        }).length !== 0 ?
                                            dataFault.filter(elemento => elemento.id === el.fault)[0].nome
                                            :
                                            el.fault
                                    }</h6>
                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario " >
                                        <span className="elementInterventionReportStatusDaPianificato">Da pianificare</span>
                                    </h6>
                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.title}</h6>
                                    <h6 className="ElemntLongDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.note}</h6>
                                </div>)

                        })
                        :

                        <div
                            className={`RowTableDesktopInterventoPerMacchinario `}

                        >
                            <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                            <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                            <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                            <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                            <h6 className="ElemntLongDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                        </div>
                        */
                }
                {

                    listaInterventi ?
                        listaInterventi.map((el, index) => {
                            return (
                                <div
                                    className={`RowTableDesktopInterventoPerMacchinario ${index % 2 ? "backgroundWhiteDesktopInterventoPerMacchinario" : "backgroundBlueDesktopInterventoPerMacchinario"}`}
                                    key={index}
                                >
                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{dataFormatoGMA(el.date)}</h6>
                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.operatore}</h6>
                                    {
                                        /* 
                                        <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{
                                        dataFault.filter(elemento => elemento.id === el.fault).length !== 0 ?
                                            dataFault.filter(elemento => elemento.id === el.fault)[0].nome
                                            :
                                            el.fault

                                    }</h6>
                                        */
                                    }

                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario " >

                                        {

                                            el.attivita ?
                                                el.attivita[0].id ?
                                                    //ci troviamo nel caso in cui l'intervento ha delle attività e queste possono essere complete o meno
                                                    //nel caso in cui è false possiamo avere due casi o non ha trovato un elementi 
                                                    el.attivita.find(e => e.complated === false) ?

                                                        //esiste almeno un attività falsa

                                                        el.attivita.find(e => e.complated === true) ?
                                                            <span className="elementInterventionReportStatusIncompleto">Incompleto</span>

                                                            :
                                                            <span className="elementInterventionReportStatusPianificato">Pianificato</span>


                                                        :


                                                        //tutte le attività sono complete allora è stato completato
                                                        <span className="elementInterventionReportStatusEseguito">Completo</span>


                                                    :
                                                    //un intervento che ha un unica attività senza la variabile id allora è un intervento senza attività che possiede un report e di conseguenza è un report completo

                                                    !el.attivita[0].complated ?
                                                        <span className="elementInterventionReportStatusPianificato">Pianificato</span>
                                                        :
                                                        <span className="elementInterventionReportStatusEseguito">Completo</span>
                                                :
                                                //è un intervento che non possiede attività e che non ha un report collegato
                                                <span className="elementInterventionReportStatusPianificato">Pianificato</span>


                                        }

                                    </h6>
                                    <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.title}</h6>
                                    <h6 className="ElemntLongDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.note}</h6>
                                </div>)

                        })
                        :

                        <div
                            className={`RowTableDesktopInterventoPerMacchinario `}

                        >
                            <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                            <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                            <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                            <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                            <h6 className="ElemntLongDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                        </div>

                }
                {
                    /*
                listReport ?
                    listReport.map((el, index) => {
                        return (
                            <div
                                className={`RowTableDesktopInterventoPerMacchinario ${index % 2 ? "backgroundWhiteDesktopInterventoPerMacchinario" : "backgroundBlueDesktopInterventoPerMacchinario"}`}
                                key={index}
                            >
                                <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.dataIntervento}</h6>
                                <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.operatore}</h6>
                                <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{
                                    dataFault.filter(elemento => elemento.id === el.Fault).length !== 0 ?
                                        dataFault.filter(elemento => elemento.id === el.Fault)[0].nome
                                        :
                                        el.Fault
                                }</h6>
                                <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario " >
                                    <span className="elementInterventionReportStatusEseguito">Eseguito</span>
                                </h6>
                                <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.operazione}</h6>
                                <h6 className="ElemntLongDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{el.note}</h6>
                            </div>)

                    })
                    :

                    <div
                        className={`RowTableDesktopInterventoPerMacchinario `}

                    >
                        <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                        <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                        <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                        <h6 className="ElemntStandardDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                        <h6 className="ElemntLongDesktopInterventoPerMacchinario testoStandardDesktopInterventoPerMacchinario" >{ }</h6>
                    </div>

                */}
            </div>
        </div >
    )

}


export default PersMachineryInterventions