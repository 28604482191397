import PerSynopticMachinery from "./subElments/synopticMachinery";
import './mainSynopticMachinery.css'

const SynopticMachinery = ({sensorData}) => {
    
    return <PerSynopticMachinery
        DatiSensori={sensorData? sensorData : "sensore esempio"}
    />
}

export default SynopticMachinery