export const DEFAULT_COLOR = '#3e82f7';

export const getChartDefaultOption = (lineWidth,onLegendClick) => {

    return  {
        chart: {
            events: {
                legendClick:
                    function(chartContext, seriesIndex, config) {
                        onLegendClick(chartContext,seriesIndex,config)
                    }
            },
            type: 'line',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
            fontFamily:undefined

        },
        responsive: [
            {
                breakpoint: 4097,  // Schermi molto larghi (es. desktop ultra wide)
                options: {
                    stroke: {
                        width: 8*(lineWidth/3),  // Larghezza dello stroke per schermi molto larghi
                    },

                    title:{
                        offsetY: 37,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 20,
                        }
                    }
                },
            },
            {
                breakpoint: 3840,  // Schermi molto larghi (es. desktop ultra wide)
                options: {
                    stroke: {
                        width: 8*(lineWidth/3),  // Larghezza dello stroke per schermi molto larghi
                    },

                    title:{
                        offsetY: 35,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 8,
                        }
                    }
                },
            },
            {
                breakpoint: 2560,  // Schermi molto larghi (es. desktop ultra wide)
                options: {
                    stroke: {
                        width: 8*(lineWidth/3),  // Larghezza dello stroke per schermi molto larghi
                    },

                    title:{
                        offsetY: 25,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 5,
                        }
                    }
                },
            },
            {
                breakpoint: 1920,  // Schermi molto larghi (es. desktop widescreen)
                options: {
                    stroke: {
                        width: 5*(lineWidth/3),  // Larghezza dello stroke per schermi molto larghi
                    },

                    title:{
                        offsetY: 20,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 5,
                        }
                    }
                },
            },
            {
                breakpoint: 1440,  // Schermi larghi (es. desktop)
                options: {
                    stroke: {
                        width: 5*(lineWidth/3),  // Larghezza dello stroke per schermi larghi
                    },

                    title:{
                        offsetY: 10,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 0,
                        }
                    }
                },
            },
            {
                breakpoint: 1280,  // Laptop con schermi larghi
                options: {

                    stroke: {
                        width: 3*(lineWidth/3),  // Larghezza dello stroke per laptop con schermi larghi
                    },

                    title:{
                        offsetY: 10,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 0,
                        }
                    }
                },
            },
            {
                breakpoint: 1024,  // Tablet landscape
                options: {
                    stroke: {
                        width: 3*(lineWidth/3),  // Larghezza dello stroke per tablet landscape
                    },

                    title:{
                        offsetY: 10,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 0,
                        }
                    }
                },

            },
            {
                breakpoint: 768,  // Tablet portrait o schermi più piccoli
                options: {
                    stroke: {
                        width: 2.5*(lineWidth/3),  // Larghezza dello stroke per tablet portrait
                    },

                    title:{
                        offsetY: 10,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 0,
                        }
                    }

                },
            },
            {
                breakpoint: 480,  // Schermi più piccoli
                options: {
                    stroke: {
                        width: 2*(lineWidth/3),  // Larghezza dello stroke per schermi più piccoli
                    },

                    title:{
                        offsetY: 0,
                    },
                    xaxis:{
                        labels: {
                            offsetY: 0,
                        }
                    }
                },
            },
        ],

        legend:{
            onItemClick: {
                toggleDataSeries: false
            },
            onItemHover: {
                highlightDataSeries: true
            },
        },
        stroke: {

            curve: 'smooth',
            lineCap: 'round',
        },
        dataLabels: {
            enabled: false,
        },
        yaxis:{
            labels: {
                style: {

                    fontFamily: undefined
                },
            }
        },
        xaxis:{
            categories:[],
            tooltip: {
                enabled: false
            },
            labels: {
                style: {
                    fontFamily: undefined
                },
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },

        tooltip: {
            style: {
                fontFamily: undefined
            },

        }
    };


};
export const getChartOptions = (options,title,legend,asseX,series,fontSize,fontFamily,percent) => {

    options.chart.fontFamily=fontFamily

    options.colors =  series.map(item => item.color !== undefined ? item.color : DEFAULT_COLOR);

    options.yaxis.labels.style.fontSize=fontSize!=undefined?fontSize:"1vw"
    options.xaxis.labels.style.fontSize=fontSize!=undefined?fontSize:"1vw"
    options.tooltip.style.fontSize=fontSize!=undefined?fontSize:"1vw"


    if(asseX!=undefined) {
        options.xaxis.categories = asseX
    }
    options.legend.show = legend!=undefined ? legend : false
    options.legend.showForSingleSeries = legend!=undefined ? legend : false
    options.legend.showForNullSeries = legend!=undefined ? legend : false
    options.legend.showForZeroSeries = legend!=undefined ? legend : false
    //options.legend.fontSize="15vw"
    if(percent!=undefined && percent===true) {
        options.yaxis.labels.formatter = function (val) {
            return (val * 100).toFixed(1) + '%';
        }
    }

    if (title.active) {
        options.title = {
            text: title.text !== undefined ? title.text : '',
            align: 'left',

            style: {
                fontWeight: 'bold',
                color: title.color !== undefined ? title.color : 'black',
                fontSize:   title.fontSize !== undefined ? title.fontSize : '1vw',
                fontFamily:  undefined,
            },
        };
    }
    return options;
};