/**
 * VERIFICA DEI CAMPI COM RELATIVO EFFETTO DI CAMPO NON CORRETTO
 * CONTROLLARE COSA ACCADE QUANDO UN CAMPO NON VIENE INSERITO
 *
 */



import React, { useState, useRef, useEffect } from "react";
import SliderOnOff from "../../sliderOnOff/mainSliderOnOff";
import { useSelector, useDispatch } from "react-redux";

import {
    modifyDataIntervention,
    modifyIntervention,
    newIntervention,
    createDataIntervention
} from "../../../structures/root/slices/interventionSlice";
import { selectPlant } from "../../../structures/root/slices/plantSlice";
import { selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice";
import { selectUser } from "../../../structures/root/slices/datiUtenteSlice";

import { NuovaAttivita, AttivitaRegistrata } from './subElement'
import { periodiSelezionabiliInterventoPeriodico } from "../../../configurations/setting";
import Modal from "../../DialogModal/mainDialogModal";
import { selectAllLine } from "../../../structures/root/slices/listLineSlice";
import { selectAllDepartment } from "../../../structures/root/slices/listDepartmentSlice";

const FormNewEvent = ({ location, machineIdentificator, closeScheda, closingNumber, openCreateEvent, mode, inteventionToModify }) => {




    const refPeriodicPeriod = useRef(null)
    const [periodicPeriod, setPeriodicPeriod] = useState(mode === "add" ? "" : inteventionToModify.period)

    const listPlant = useSelector(selectPlant)
    const listLine = useSelector(selectAllLine)
    const listDepartment = useSelector(selectAllDepartment)
    let listPlantOBJ = listPlant.map((el) => <option key={el.id} value={el.id} >{el.nome}</option>)

    listPlantOBJ.push(<option
        style={{ color: "grey" }}
        key={0} value={0} >seleziona un stabilimento</option>)
    listPlantOBJ = listPlantOBJ.reverse()

    const listMache = useSelector(selectAllMachinery)
    const dispatch = useDispatch()
    const utente = useSelector(selectUser)


    const [title, setTitleEvent] = useState(mode === "add" ? "" : inteventionToModify.title)
    const rifTitleEvent = useRef(null)

    //QUESTA VARIABILE DEVE ESSERE PRESA DALL'UTENTE CHE HA EFFETTUATO IL LOGIN
    const [referentName, setreferentName] = useState(mode === "add" ? "" : inteventionToModify.operatore)
    const rifReferentName = useRef(null)

    const [dayEvent, setdayEvent] = useState(mode === "add" ? "" : inteventionToModify.date)
    const rifDayEvent = useRef(null)

    const [hourEvent, setHourEvent] = useState(mode === "add" ? "" : inteventionToModify.ora)
    const rifHourEvent = useRef(null)

    const [timeForEvent, setTimeForEvent] = useState(mode === "add" ? "" : inteventionToModify.durata)
    const rifTimeForEvent = useRef(null)


    const [plant, setPalant] = useState(mode === "add" ? (location ? location : "") : (listMache.filter((asset) => asset.id === inteventionToModify.idMachinery)[0].idPlant))
    const [departmentsId, setDepartmentsId] = useState(mode === "add" ? "" : listMache.filter((asset) => asset.id === inteventionToModify.idMachinery)[0].idDepartment);
    const [lineId, setLineId] = useState(mode === "add" ? "" : listMache.filter((asset) => asset.id === inteventionToModify.idMachinery)[0].idLinea)
    const [machineId, setMachineId] = useState(mode === "add" ? (machineIdentificator ? machineIdentificator : "") : (inteventionToModify.idMachinery))
    const rifLocationPlant = useRef(null)
    const rifLocationMachineId = useRef(null)


    //const [mailReferent, setMailReferent] = useState("")
    //const [timeBeforeAlert, setTimeBeforeAlert] = useState(0)
    const rifMailReferent = useRef(null)
    const rifTimeBeforeAlert = useRef(null)

    const [note, setNote] = useState(mode === "add" ? "" : inteventionToModify.note)
    const rifNote = useRef(null)

    const [isPeriodicEvent, setIsPeriodicEvent] = useState(mode === "add" ? false : (inteventionToModify.period === "" ? false : true))

    const [attivita, setAttivita] = useState(mode === "add" ? [] : inteventionToModify.attivita)


    const [verificaPresenzaAttivita, setPresenzaAttivita] = useState(false)
    const [verificaPresenzaAttivitaPost, setPresenzaAttivitaPost] = useState(false)



    useEffect(() => {
        if (!isPeriodicEvent) {
            setPeriodicPeriod(mode === "add" ? "" : inteventionToModify.period)
        }
    }, [isPeriodicEvent])

    useEffect(() => {
        if (!verificaPresenzaAttivita) {
            setPresenzaAttivitaPost(false)
        }
    }, [verificaPresenzaAttivita])

    /*useEffect(() => {
        //QUI ANDRA IMPLEMENTATO IL SETTAGGIO AUTOMATICO PER REPARTO E LINEA
        let temp = machineId
        if (machineId !== "") {
            setPalant(listMache.find(e => e.id === machineId).idPlant ? listMache.find(e => e.id === machineId).idPlant : "")

        }

    }, [machineId])*/
    /* 
        const [listTimeEvent, setListTimeEvent] = useState([])
        useEffect(() => {
            let listTimeTemp = []
            let ora = 0
            for (let i = 1; i < 33; i++) {
                if (i % 4 === 0) {
                    ora++
                    listTimeTemp.push(`${ora}h 0m`)
                } else {
                    if (ora === 0) {
                        listTimeTemp.push(`${i % 4 * 15}m`)
                    } else {
                        listTimeTemp.push(`${ora}h ${i % 4 * 15}m`)
                    }
                }
            }
            setListTimeEvent(listTimeTemp)
    
    
        }, [sendData])
    
     */
    useEffect(() => {

        let temp = 0
        attivita.forEach(el => {
            if (el.tempoStimato) {
                temp = temp + el.tempoStimato
            }
        })
        if (temp !== 0) {
            setTimeForEvent(temp)
        } else {
            setTimeForEvent("")
        }


    }, [attivita])


    useEffect(() => {

        if (listMache.find(el => el.id === machineId)?.idPlant !== plant) {
            rifLocationMachineId.current.value = ""
            setMachineId("")
        }


    }, [plant])

    const listaMacchineDaMostrare = (idImpianto = "0") => {

        let listMacchinariTemp = []
        listMacchinariTemp.push(<option style={{ color: "grey" }} key={0} value={""}  >seleziona un macchinario</option>)
        if (idImpianto === "0") {

            listMache.map((el) => listMacchinariTemp
                .push(<option
                    style={{ color: "black" }}
                    key={el.id}
                    value={el.id}>
                    {el.nome + "\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0ID:\xA0\xA0" + el.identityCode}
                </option>))
        } else {

            if (validField(departmentsId) && !validField(lineId)) {

                listMache
                    .filter(el => idImpianto === el.idPlant && departmentsId === el.idDepartment)
                    .map((el) => listMacchinariTemp
                        .push(<option
                            style={{ color: "black"}}
                            key={el.id}
                            value={el.id}>
                             {el.nome + "\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0ID:\xA0\xA0" + el.identityCode}
                        </option>))
            } else if (validField(departmentsId) && validField(lineId)) {

                listMache
                    .filter(el => idImpianto === el.idPlant && departmentsId === el.idDepartment && lineId === el.idLinea)
                    .map((el) => listMacchinariTemp
                        .push(<option
                            style={{ color: "black" }}
                            key={el.id}
                            value={el.id}>
                             {el.nome + "\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0ID:\xA0\xA0" + el.identityCode}
                        </option>))
            } else {

                listMache
                    .filter(el => idImpianto === el.idPlant)
                    .map((el) => listMacchinariTemp
                        .push(<option
                            style={{ color: "black" }}
                            key={el.id}
                            value={el.id}>
                            {el.nome + "\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0ID:\xA0\xA0" + el.identityCode}
                        </option>))
            }
        }
        return listMacchinariTemp
    }
    //REFRESH AUTOMATICO QUANDO DUE UTENTI TROVANO SULLA STESSA FORM DI MODIFICA SULLO STESSO EVENTO
    useEffect(() => {

        setPeriodicPeriod(mode === "add" ? "" : inteventionToModify.period)
        setTitleEvent(mode === "add" ? "" : inteventionToModify.title)
        setreferentName(mode === "add" ? "" : inteventionToModify.operatore)
        setdayEvent(mode === "add" ? "" : inteventionToModify.date)
        setHourEvent(mode === "add" ? "" : inteventionToModify.ora)
        setTimeForEvent(mode === "add" ? "" : inteventionToModify.durata)
        setPalant(mode === "add" ? (location ? location : "") : (listMache.filter((asset) => asset.id === inteventionToModify.idMachinery)[0].idPlant))
        setDepartmentsId(mode === "add" ? "" : listMache.filter((asset) => asset.id === inteventionToModify.idMachinery)[0].idDepartment);
        setLineId(mode === "add" ? "" : listMache.filter((asset) => asset.id === inteventionToModify.idMachinery)[0].idLinea)
        setMachineId(mode === "add" ? (machineIdentificator ? machineIdentificator : "") : (inteventionToModify.idMachinery))
        setNote(mode === "add" ? "" : inteventionToModify.note)
        setIsPeriodicEvent(mode === "add" ? false : (inteventionToModify.period === "" ? false : true))
        setAttivita(mode === "add" ? [] : inteventionToModify.attivita)
    }, [inteventionToModify])
    const validField = (fieldValue) => {
        return fieldValue !== undefined && fieldValue != null && fieldValue.length > 0 && fieldValue !== "0"
    }
    const handleChangePlant = () => {
        setDepartmentsId("")
        setLineId("")
        setPalant(rifLocationPlant.current.value)
    }
    const handleChangeDepartment = (e) => {
        setDepartmentsId(e.target.value)
        setLineId("")
        setMachineId("0")
        if (plant === undefined || plant.length === 0 || plant === "0") {
            const platsOfDepartment = listDepartment.filter((department) => department.id === e.target.value)[0].idPlant
            rifLocationPlant.current.value = platsOfDepartment
            setPalant(platsOfDepartment)
        }
    }
    const handleChangeLine = (e) => {
        setLineId(e.target.value)
        setMachineId("0")
        if (plant === undefined || plant.length === 0 || plant === "0") {
            const plantOfLineId = listDepartment.filter((department) => department.id === listLine.filter((line) => line.id === e.target.value)[0].idDepartment)[0].idPlant
            rifLocationPlant.current.value = plantOfLineId
            setPalant(plantOfLineId)
        }
        if (departmentsId === undefined || departmentsId.length === 0) {
            const departmentOfLineId = listLine.filter((line) => line.id === e.target.value)[0].idDepartment
            setDepartmentsId(departmentOfLineId)
        }
    }

    const handleChangeMach = (e) => {
        setMachineId(rifLocationMachineId.current.value)
        const assets = listMache.filter((asset) => asset.id === rifLocationMachineId.current.value)[0]
        if (plant === undefined || plant.length === 0 || plant === "0") {
            const plantOfAssetId = assets.idPlant
            rifLocationPlant.current.value = plantOfAssetId
            setPalant(plantOfAssetId)
        }
        if (departmentsId === undefined || departmentsId.length === 0) {
            const departmentOfAssetId = assets.idDepartment
            setDepartmentsId(departmentOfAssetId)
        }
        if (lineId === undefined || lineId.length === 0) {
            const lineOfAssetId = assets.idLinea
            setLineId(lineOfAssetId)
        }

    }



    return (
        <Modal
            show={!openCreateEvent}
        >
            <div className="bodyDesktopFormInserimetoEvento">

                <div
                    className="boxTitleClosedFormInserimentoEvento"
                >
                    <h4
                        className="elementTitleFormNewEvent"
                    >{mode === "add" ? "Nuovo intervento" : "Modifica Intervento"}</h4>
                    <h4
                        className="elementCloseFromNewEvent"
                        onClick={() => closeScheda(closingNumber)}
                    >x</h4>
                </div>


                <h5
                    className="elementSecondaryTitleFormNewEvent"
                >Intervento</h5>
                <input
                    className="elementInputFormNewEvent"
                    required
                    ref={rifTitleEvent}
                    value={title}
                    onInput={() => setTitleEvent(rifTitleEvent.current.value)}
                ></input>
                <div
                    className="boxPeriodicFormNewEvent"
                >
                    <h5
                        className="elementSecondaryTitleFormNewEvent"
                    >Intervento periodico</h5>
                    <SliderOnOff
                        value={isPeriodicEvent}
                        returnIsActive={setIsPeriodicEvent}
                    />
                </div>
                {
                    isPeriodicEvent ?
                        <div
                            className="boxPeriodicFormNewEvent"
                            style={{ margin: "1vw 0" }}

                        >
                            <h5
                                className="elementSecondaryTitleFormNewEvent"

                            >Periodo</h5>

                            {
                                /*
                                <div className="boxFormNewEventArrow" >
                                <span className="elementFormNewEventArrowUp">&#129169;</span>
                                <span className="elementFormNewEventArrowDown">&#129171;</span>
                            </div>
                                */
                            }


                            <select
                                ref={refPeriodicPeriod}
                                className="elementInputFormNewEventFlex"
                                required
                                value={periodicPeriod}
                                onInput={() => setPeriodicPeriod(refPeriodicPeriod.current.value)}
                            >
                                {
                                    periodiSelezionabiliInterventoPeriodico.map((el, index) => <option key={index}
                                        value={el.value}> {el.label} </option>)
                                }
                            </select>

                        </div>
                        :
                        <span></span>
                }

                <h5
                    className="elementSecondaryTitleFormNewEvent"
                >Data</h5>
                <div
                    className="boxDateFormNewElement"

                >
                    <div className="boxFlexColumnFormNewElement"
                    >
                        <span style={{ "marginLeft": "0", fontSize: "1vw" }}>{/* &#x1F4C5;*/}Giorno </span>
                        <input
                            className="elementInputFormNewEventFlex"
                            type="date"
                            required
                            value={dayEvent}
                            onInput={() => setdayEvent(rifDayEvent.current.value)}
                            ref={rifDayEvent}
                            disabled={mode !== "add"}
                        ></input>
                    </div>
                    <div
                        className="boxFlexColumnFormNewElement"
                    >
                        <span style={{ "marginLeft": "0", fontSize: "1vw" }}>{/* &#128337;*/} Ora  </span>
                        <input
                            className="elementInputFormNewEventFlex"
                            type="time"
                            value={hourEvent}
                            onInput={() => setHourEvent(rifHourEvent.current.value)}
                            ref={rifHourEvent}
                            disabled={mode !== "add"}
                        ></input>
                    </div>
                    <div className="boxFlexColumnFormNewElement">
                        {
                            /*
                             <div className="boxFormNewEventArrow" >
                            <span className="elementFormNewEventArrowUp">&#129169;</span>
                            <span className="elementFormNewEventArrowDown">&#129171;</span>
                        </div>
    
                             */
                        }


                        {/*
                        <select
                        ref={rifTimeForEvent}
                        className="elementInputFormNewEventFlex"

                        onInput={() => setTimeForEvent(rifTimeForEvent.current.value)}
                    >
                        {
                            listTimeEvent.map((el, index) => <option key={index} value={index}> {el} </option>)
                        }
                    </select>
                    */}
                        <span style={{ "marginLeft": "0", fontSize: "1vw" }}>{/* &#128337;*/} Durata (min) </span>
                        <input
                            ref={rifTimeForEvent}
                            className="elementInputFormNewEvent"
                            placeholder="minuti"
                            value={timeForEvent}

                            onChange={() => setTimeForEvent(rifTimeForEvent.current.value)}
                        />

                    </div>

                </div>

                <h5
                    className="elementSecondaryTitleFormNewEvent"
                >Identificazione macchinario</h5>

                <select

                    className="elementInputFormNewEvent"
                    ref={rifLocationPlant}
                    onInput={() => handleChangePlant()}
                    placeholder="Plant"
                    style={rifLocationPlant === null && rifLocationPlant.current.value === "0" ? { color: "gray" } : {}}
                    disabled={location ? true : (mode !== "add")}
                    value={plant ? plant : ""}
                    list="listPlants"

                >

                    {listPlantOBJ}
                </select>
                <select
                    className="elementInputFormNewEvent"
                    placeholder="Reparti"
                    onChange={(e) => handleChangeDepartment(e)}
                    value={departmentsId}
                    disabled={location ? true : (mode !== "add")}
                >
                    <option value="" selected>
                        seleziona un reparto
                    </option>
                    {(validField(plant))
                        ? listDepartment
                            .filter((department) => department.idPlant === plant)
                            .map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))
                        : listDepartment.map((el) => (
                            <option key={el.id} value={el.id}>
                                {el.name}
                            </option>
                        ))}
                </select>

                <select
                    className="elementInputFormNewEvent"
                    placeholder="Linee"
                    onChange={(e) => handleChangeLine(e)}
                    value={lineId}
                    disabled={location ? true : (mode !== "add")}
                >
                    <option value="" selected>
                        seleziona una linea
                    </option>
                    {(validField(departmentsId))
                        ?
                        listLine
                            .filter((line) => line.idDepartment === departmentsId)
                            .map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))
                        :
                        (validField(plant))
                            ?
                            listLine
                                .filter(linea => listDepartment.filter(department => department.idPlant === plant)
                                    .some(department => department.id === linea.idDepartment))
                                .map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.name}
                                    </option>
                                ))
                            :
                            listLine.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))}
                </select>
                <div style={{ "height": "5px" }}></div>

                <select
                    className="elementInputFormNewEvent"
                    ref={rifLocationMachineId}
                    style={rifLocationMachineId === null && rifLocationMachineId.current.value === "0" ? { color: "gray" } : {}}
                    onInput={(e) => handleChangeMach()}
                    placeholder="machine id"
                    required
                    disabled={machineIdentificator ? true : (mode !== "add")}
                    value={machineId}
                >
                    {
                        plant === "" ?

                            listaMacchineDaMostrare()
                            :

                            listaMacchineDaMostrare(mode === "add" ? rifLocationPlant.current.value : plant)

                    }
                </select>
                <h5
                    className="elementSecondaryTitleFormNewEvent"
                >Attività</h5>
                <NuovaAttivita
                    verificaPresenzaAttivita={verificaPresenzaAttivitaPost}
                    setPresenzaAttivita={setPresenzaAttivita}
                    listaAttivita={attivita}
                    aggiungiAttivita={setAttivita}
                />
                {
                    attivita ?
                        attivita.map((el, index) => <AttivitaRegistrata
                            key={index}
                            indice={index}
                            nomeAttivita={el.nomeAttivita}
                            tempoStimato={el.tempoStimato}
                            modificaAttività={setAttivita}
                        />)
                        :
                        <span></span>
                }
                <div className="boxFlexColumnFormNewElement">

                </div>
                <h5
                    className="elementSecondaryTitleFormNewEvent"
                >Operatore</h5>
                <input
                    className="elementInputFormNewEvent"
                    ref={rifReferentName}
                    required
                    value={referentName}
                    onInput={() => setreferentName(rifReferentName.current.value)}
                />

                {/*
             <div className="boxDateFormNewElement">
                <div >
                    <h5
                        className="elementSecondaryTitleFormNewEvent"
                    >Notification</h5>
                    <input
                        className="elementInputFormNewEventPartial"
                        ref={rifMailReferent}
                        onInput={() => setMailReferent(rifMailReferent.current.value)}
                    /></div>
                <div >
                    <h5
                        className="elementSecondaryTitleFormNewEvent"
                    >Reminder</h5>
                    <select
                        ref={rifTimeBeforeAlert}
                        className="elementInputFormNewEventFlex"
                        onInput={() => setTimeBeforeAlert(rifTimeBeforeAlert.current.value)}
                    >
                        {
                            listTimeEvent.map((el, index) => <option key={index} value={index}> {el} </option>)
                        }
                    </select>

                </div>

            </div>
             */}

                <h5
                    className="elementSecondaryTitleFormNewEvent correctionelementSecondaryTitleFormNewEventNote"
                >Note</h5>
                <textarea
                    className="elementInputFormNewEvent correctionHeightelementInputFormNewEventNote"

                    spellCheck="false"
                    ref={rifNote}
                    value={note}
                    onInput={() => setNote(rifNote.current.value)}
                />
                {mode === "add" ?
                    <button
                        className="elementButtonSubmitFormNewEvent"
                        onClick={(e) => {

                            if (title !== "" && dayEvent !== "" && referentName !== "" && machineId !== "" && !verificaPresenzaAttivita) {
                                if (isPeriodicEvent) {
                                    /**
                                     * funziona nel senso che non invia l'intervento se periodicPeriod ==="" 
                                     * questo viene mostrato
                                     *
                                     * va verificata la richiesta poiché è probabile che l'intervento non viene creato per problemi di parametro mancante
                                     */
                                    if (periodicPeriod !== "") {
                                        e.target.disabled = true
                                        const risp = dispatch(newIntervention({
                                            idAsset: machineId,
                                            data: dayEvent,
                                            idFault: title,
                                            period: periodicPeriod,
                                            ora: hourEvent,
                                            idPrevInterventio: "",
                                            isDeleted: false,
                                        }))

                                        Promise.all([risp])
                                            .then(ris => {


                                                dispatch(createDataIntervention({
                                                    idAsset: ris[0].payload.idMachinery,
                                                    id: ris[0].payload.id,
                                                    activity: JSON.stringify(attivita),
                                                    idPlant: plant,
                                                    durata: timeForEvent,

                                                    operatore: referentName,
                                                    /**
                                                     * qui poi andranno i dati dell'account alla quale è stato assegnato il compito e chi ha creato l'evento
                                                     */
                                                    creatore: utente[0].email, //al momento mettiamo il nome ma in futuro va messo l'id utente
                                                    note
                                                })).then(el => {
                                                    e.target.disabled = false

                                                    closeScheda(closingNumber)

                                                }).catch(err => {
                                                    e.target.disabled = false
                                                    alert("errore dati intervento non caricati, eliminare l'intervento", err)
                                                })

                                            }).catch(err => {
                                                e.target.disabled = false
                                                alert("errore intervento non creato, ripetere l'operazione", err)
                                            })
                                    }


                                } else {
                                    e.target.disabled = true

                                    const risp = dispatch(newIntervention({
                                        idAsset: machineId,
                                        data: dayEvent,
                                        idFault: title,
                                        period: periodicPeriod,
                                        ora: hourEvent,
                                        isDeleted: false,
                                    }))

                                    Promise.all([risp])
                                        .then(ris => {
                                            if (ris[0].meta.requestStatus !== "rejected") {
                                                dispatch(createDataIntervention({
                                                    idAsset: ris[0].payload.idMachinery,
                                                    id: ris[0].payload.id,
                                                    activity: JSON.stringify(attivita),
                                                    idPlant: plant,
                                                    durata: timeForEvent,

                                                    operatore: referentName,
                                                    /**
                                                     * qui poi andranno i dati dell'account alla quale è stato assegnato il compito e chi ha creato l'evento
                                                     */
                                                    creatore: utente[0].email, //al momento mettiamo il nome ma in futuro va messo l'id utente
                                                    note
                                                })).then(el => {

                                                    e.target.disabled = false

                                                    closeScheda(closingNumber)
                                                }).catch(err => {
                                                    e.target.disabled = false
                                                    alert("errore dati intervento non caricati, eliminare l'intervento", err)
                                                })
                                            } else {
                                                e.target.disabled = false
                                                alert("errore intervento non creato, ripetere l'operazione")
                                            }

                                        })
                                }

                            } else {
                                alert(`Inserire: \n${title !== "" ? "" : "\ntitolo intervento "} ${dayEvent !== "" ? "" : "\ndata intervento"}  ${referentName !== "" ? "" : "\noperatore "} ${!verificaPresenzaAttivita ? "" : "\nattività"}`)

                                if (verificaPresenzaAttivita) {
                                    setPresenzaAttivitaPost(true)
                                }
                            }


                        }}
                    >Crea</button>
                    :
                    <button
                        className="elementButtonSubmitFormNewEvent"
                        onClick={(e) => {
                            if (title !== "" && dayEvent !== "" && referentName !== "" && machineId !== "" && !verificaPresenzaAttivita) {
                                if (inteventionToModify.id.slice(0, 7) === "virtual") {
                                    //GESTIONE EVENTO VIRTUALE
                                    e.target.disabled = true
                                    //creiamo l'intervento che ad ora è virtuale
                                    dispatch(newIntervention({
                                        idAsset: machineId,
                                        data: dayEvent,
                                        idFault: title,
                                        period: "",
                                        //da verificare l'ora come viene salvata
                                        ora: hourEvent ? hourEvent : "",
                                        idPrevIntervention: inteventionToModify.idPrevIntervention,
                                        isDeleted: false,

                                    }))
                                        .then(ris => {
                                            if (ris[0].meta.requestStatus !== "rejected") {
                                                dispatch(createDataIntervention({
                                                    idAsset: ris.payload.idMachinery,
                                                    id: ris.payload.id,
                                                    activity: JSON.stringify(attivita),
                                                    idPlant: plant,
                                                    durata: timeForEvent,

                                                    operatore: referentName,
                                                    creatore: utente[0].email, //al momento mettiamo il nome ma in futuro va messo l'id utente
                                                    note
                                                })).then(el => {
                                                    e.target.disabled = false

                                                    closeScheda(closingNumber)
                                                })
                                            }
                                            else {
                                                e.target.disabled = false
                                                alert("errore intervento non modificato, ripetere l'operazione")
                                            }


                                        }
                                        )

                                } else {
                                    //GESTIONE EVENTO NON VIRTUALE
                                    if ((isPeriodicEvent && periodicPeriod !== "") || !isPeriodicEvent) {
                                        //if (periodicPeriod !== "") {
                                        e.target.disabled = true
                                        const risp = dispatch(modifyIntervention({
                                            id: inteventionToModify.id,
                                            idAsset: machineId,
                                            data: dayEvent,
                                            idFault: title,
                                            period: periodicPeriod,
                                            ora: hourEvent,
                                        }))
                                        Promise.all([risp])
                                            .then(ris => {
                                                if (ris[0].meta.requestStatus !== "rejected") {
                                                    dispatch(modifyDataIntervention({
                                                        id: ris[0].payload.id,
                                                        idAsset: ris[0].payload.idMachinery,
                                                        activity: JSON.stringify(attivita),
                                                        idPlant: plant,
                                                        durata: timeForEvent,

                                                        operatore: referentName,

                                                        creatore: utente[0].email, //al momento mettiamo il nome ma in futuro va messo l'id utente
                                                        note
                                                    })).then(el => {
                                                        e.target.disabled = false

                                                        closeScheda(closingNumber)
                                                    }).catch(err => {
                                                        e.target.disabled = false
                                                        alert("errore dati intervento non modificato, eliminare l'intervento", err)
                                                    })
                                                } else {
                                                    e.target.disabled = false
                                                    alert("errore intervento non modificato, ripetere l'operazione")
                                                }

                                            })
                                    } else {
                                        alert("inserire un periodo")
                                    }


                                }

                            } else {
                                alert(`Inserire: \n${title !== "" ? "" : "\ntitolo intervento "} ${dayEvent !== "" ? "" : "\ndata intervento"}  ${referentName !== "" ? "" : "\noperatore "} ${!verificaPresenzaAttivita ? "" : "\nattività"}`)

                                if (verificaPresenzaAttivita) {
                                    setPresenzaAttivitaPost(true)
                                }
                            }


                        }}
                    >Modifica</button>}

            </div>
        </Modal>
    )

}


export default FormNewEvent