export const countries = [
    { country: 'Italy (+39)', label: 'IT', value: '(+39)' },
    { country: 'USA (+1)', label: 'US', value: '(+1)' },
    { country: 'UK (+44)', label: 'GB', value: '(+44)' }
    // { country: 'Algeria (+213)', label: 'DZ', value: '(+213)' },
    // { country: 'Andorra (+376)', label: 'AD', value: '(+376)' },
    // { country: 'Angola (+244)', label: 'AO', value: '(+244)' },
    // { country: 'Anguilla (+1264)', label: 'AI', value: '(+1264)' },
    // { country: 'Antigua & Barbuda (+1268)', label: 'AG', value: '(+1268)' },
    // { country: 'Argentina (+54)', label: 'AR', value: '(+54)' },
    // { country: 'Armenia (+374)', label: 'AM', value: '(+374)' },
    // { country: 'Aruba (+297)', label: 'AW', value: '(+297)' },
    // { country: 'Australia (+61)', label: 'AU', value: '(+61)' },
    // { country: 'Austria (+43)', label: 'AT', value: '(+43)' },
    // { country: 'Azerbaijan (+994)', label: 'AZ', value: '(+994)' },
    // { country: 'Bahamas (+1242)', label: 'BS', value: '(+1242)' },
    // { country: 'Bahrain (+973)', label: 'BH', value: '(+973)' },
    // { country: 'Bangladesh (+880)', label: 'BD', value: '(+880)' },
    // { country: 'Barbados (+1246)', label: 'BB', value: '(+1246)' },
    // { country: 'Belarus (+375)', label: 'BY', value: '(+375)' },
    // { country: 'Belgium (+32)', label: 'BE', value: '(+32)' },
    // { country: 'Belize (+501)', label: 'BZ', value: '(+501)' },
    // { country: 'Benin (+229)', label: 'BJ', value: '(+229)' },
    // { country: 'Bermuda (+1441)', label: 'BM', value: '(+1441)' },
    // { country: 'Bhutan (+975)', label: 'BT', value: '(+975)' },
    // { country: 'Bolivia (+591)', label: 'BO', value: '(+591)' },
    // { country: 'Bosnia Herzegovina (+387)', label: 'BA', value: '(+387)' },
    // { country: 'Botswana (+267)', label: 'BW', value: '(+267)' },
    // { country: 'Brazil (+55)', label: 'BR', value: '(+55)' },
    // { country: 'Brunei (+673)', label: 'BN', value: '(+673)' },
    // { country: 'Bulgaria (+359)', label: 'BG', value: '(+359)' },
    // { country: 'Burkina Faso (+226)', label: 'BF', value: '(+226)' },
    // { country: 'Burundi (+257)', label: 'BI', value: '(+257)' },
    // { country: 'Cambodia (+855)', label: 'KH', value: '(+855)' },
    // { country: 'Cameroon (+237)', label: 'CM', value: '(+237)' },
    // { country: 'Canada (+1)', label: 'CA', value: '(+1)' },
    // { country: 'Cape Verde Islands (+238)', label: 'CV', value: '(+238)' },
    // { country: 'Cayman Islands (+1345)', label: 'KY', value: '(+1345)' },
    // { country: 'Central African Republic (+236)', label: 'CF', value: '(+236)' },
    // { country: 'Chile (+56)', label: 'CL', value: '(+56)' },
    // { country: 'China (+86)', label: 'CN', value: '(+86)' },
    // { country: 'Colombia (+57)', label: 'CO', value: '(+57)' },
    // { country: 'Comoros (+269)', label: 'KM', value: '(+269)' },
    // { country: 'Congo (+242)', label: 'CG', value: '(+242)' },
    // { country: 'Cook Islands (+682)', label: 'CK', value: '(+682)' },
    // { country: 'Costa Rica (+506)', label: 'CR', value: '(+506)' },
    // { country: 'Croatia (+385)', label: 'HR', value: '(+385)' },
    // { country: 'Cuba (+53)', label: 'CU', value: '(+53)' },
    // { country: 'Cyprus North (+90392)', label: 'CY', value: '(+90392)' },
    // { country: 'Cyprus South (+357)', label: 'CY', value: '(+357)' },
    // { country: 'Czech Republic (+42)', label: 'CZ', value: '(+42)' },
    // { country: 'Denmark (+45)', label: 'DK', value: '(+45)' },
    // { country: 'Djibouti (+253)', label: 'DJ', value: '(+253)' },
    // { country: 'Dominica (+1809)', label: 'DM', value: '(+1809)' },
    // { country: 'Dominican Republic (+1809)', label: 'DO', value: '(+1809)' },
    // { country: 'Ecuador (+593)', label: 'EC', value: '(+593)' },
    // { country: 'Egypt (+20)', label: 'EG', value: '(+20)' },
    // { country: 'El Salvador (+503)', label: 'SV', value: '(+503)' },
    // { country: 'Equatorial Guinea (+240)', label: 'GQ', value: '(+240)' },
    // { country: 'Eritrea (+291)', label: 'ER', value: '(+291)' },
    // { country: 'Estonia (+372)', label: 'EE', value: '(+372)' },
    // { country: 'Ethiopia (+251)', label: 'ET', value: '(+251)' },
    // { country: 'Falkland Islands (+500)', label: 'FK', value: '(+500)' },
    // { country: 'Faroe Islands (+298)', label: 'FO', value: '(+298)' },
    // { country: 'Fiji (+679)', label: 'FJ', value: '(+679)' },
    // { country: 'Finland (+358)', label: 'FI', value: '(+358)' },
    // { country: 'France (+33)', label: 'FR', value: '(+33)' },
    // { country: 'French Guiana (+594)', label: 'GF', value: '(+594)' },
    // { country: 'French Polynesia (+689)', label: 'PF', value: '(+689)' },
    // { country: 'Gabon (+241)', label: 'GA', value: '(+241)' },
    // { country: 'Gambia (+220)', label: 'GM', value: '(+220)' },
    // { country: 'Georgia (+7880)', label: 'GE', value: '(+7880)' },
    // { country: 'Germany (+49)', label: 'DE', value: '(+49)' },
    // { country: 'Ghana (+233)', label: 'GH', value: '(+233)' },
    // { country: 'Gibraltar (+350)', label: 'GI', value: '(+350)' },
    // { country: 'Greece (+30)', label: 'GR', value: '(+30)' },
    // { country: 'Greenland (+299)', label: 'GL', value: '(+299)' },
    // { country: 'Grenada (+1473)', label: 'GD', value: '(+1473)' },
    // { country: 'Guadeloupe (+590)', label: 'GP', value: '(+590)' },
    // { country: 'Guam (+671)', label: 'GU', value: '(+671)' },
    // { country: 'Guatemala (+502)', label: 'GT', value: '(+502)' },
    // { country: 'Guinea (+224)', label: 'GN', value: '(+224)' },
    // { country: 'Guinea - Bissau (+245)', label: 'GW', value: '(+245)' },
    // { country: 'Guyana (+592)', label: 'GY', value: '(+592)' },
    // { country: 'Haiti (+509)', label: 'HT', value: '(+509)' },
    // { country: 'Honduras (+504)', label: 'HN', value: '(+504)' },
    // { country: 'Hong Kong (+852)', label: 'HK', value: '(+852)' },
    // { country: 'Hungary (+36)', label: 'HU', value: '(+36)' },
    // { country: 'Iceland (+354)', label: 'IS', value: '(+354)' },
    // { country: 'India (+91)', label: 'IN', value: '(+91)' },
    // { country: 'Indonesia (+62)', label: 'ID', value: '(+62)' },
    // { country: 'Iran (+98)', label: 'IR', value: '(+98)' },
    // { country: 'Iraq (+964)', label: 'IQ', value: '(+964)' },
    // { country: 'Ireland (+353)', label: 'IE', value: '(+353)' },
    // { country: 'Israel (+972)', label: 'IL', value: '(+972)' },
    // { country: 'Italy (+39)', label: 'IT', value: '(+39)' },
    // { country: 'Jamaica (+1876)', label: 'JM', value: '(+1876)' },
    // { country: 'Japan (+81)', label: 'JP', value: '(+81)' },
    // { country: 'Jordan (+962)', label: 'JO', value: '(+962)' },
    // { country: 'Kazakhstan (+7)', label: 'KZ', value: '(+7)' },
    // { country: 'Kenya (+254)', label: 'KE', value: '(+254)' },
    // { country: 'Kiribati (+686)', label: 'KI', value: '(+686)' },
    // { country: 'Korea North (+850)', label: 'KP', value: '(+850)' },
    // { country: 'Korea South (+82)', label: 'KR', value: '(+82)' },
    // { country: 'Kuwait (+965)', label: 'KW', value: '(+965)' },
    // { country: 'Kyrgyzstan (+996)', label: 'KG', value: '(+996)' },
    // { country: 'Laos (+856)', label: 'LA', value: '(+856)' },
    // { country: 'Latvia (+371)', label: 'LV', value: '(+371)' },
    // { country: 'Lebanon (+961)', label: 'LB', value: '(+961)' },
    // { country: 'Lesotho (+266)', label: 'LS', value: '(+266)' },
    // { country: 'Liberia (+231)', label: 'LR', value: '(+231)' },
    // { country: 'Libya (+218)', label: 'LY', value: '(+218)' },
    // { country: 'Liechtenstein (+417)', label: 'LI', value: '(+417)' },
    // { country: 'Lithuania (+370)', label: 'LT', value: '(+370)' },
    // { country: 'Luxembourg (+352)', label: 'LU', value: '(+352)' },
    // { country: 'Macao (+853)', label: 'MO', value: '(+853)' },
    // { country: 'Macedonia (+389)', label: 'MK', value: '(+389)' },
    // { country: 'Madagascar (+261)', label: 'MG', value: '(+261)' },
    // { country: 'Malawi (+265)', label: 'MW', value: '(+265)' },
    // { country: 'Malaysia (+60)', label: 'MY', value: '(+60)' },
    // { country: 'Maldives (+960)', label: 'MV', value: '(+960)' },
    // { country: 'Mali (+223)', label: 'ML', value: '(+223)' },
    // { country: 'Malta (+356)', label: 'MT', value: '(+356)' },
    // { country: 'Marshall Islands (+692)', label: 'MH', value: '(+692)' },
    // { country: 'Martinique (+596)', label: 'MQ', value: '(+596)' },
    // { country: 'Mauritania (+222)', label: 'MR', value: '(+222)' },
    // { country: 'Mayotte (+269)', label: 'YT', value: '(+269)' },
    // { country: 'Mexico (+52)', label: 'MX', value: '(+52)' },
    // { country: 'Micronesia (+691)', label: 'FM', value: '(+691)' },
    // { country: 'Moldova (+373)', label: 'MD', value: '(+373)' },
    // { country: 'Monaco (+377)', label: 'MC', value: '(+377)' },
    // { country: 'Mongolia (+976)', label: 'MN', value: '(+976)' },
    // { country: 'Montserrat (+1664)', label: 'MS', value: '(+1664)' },
    // { country: 'Morocco (+212)', label: 'MA', value: '(+212)' },
    // { country: 'Mozambique (+258)', label: 'MZ', value: '(+258)' },
    // { country: 'Myanmar (+95)', label: 'MM', value: '(+95)' },
    // { country: 'Namibia (+264)', label: 'NA', value: '(+264)' },
    // { country: 'Nauru (+674)', label: 'NR', value: '(+674)' },
    // { country: 'Nepal (+977)', label: 'NP', value: '(+977)' },
    // { country: 'Netherlands (+31)', label: 'NL', value: '(+31)' },
    // { country: 'New Caledonia (+687)', label: 'NC', value: '(+687)' },
    // { country: 'New Zealand (+64)', label: 'NZ', value: '(+64)' },
    // { country: 'Nicaragua (+505)', label: 'NI', value: '(+505)' },
    // { country: 'Niger (+227)', label: 'NE', value: '(+227)' },
    // { country: 'Nigeria (+234)', label: 'NG', value: '(+234)' },
    // { country: 'Niue (+683)', label: 'NU', value: '(+683)' },
    // { country: 'Norfolk Islands (+672)', label: 'NF', value: '(+672)' },
    // { country: 'Northern Marianas (+670)', label: 'MP', value: '(+670)' },
    // { country: 'Norway (+47)', label: 'NO', value: '(+47)' },
    // { country: 'Oman (+968)', label: 'OM', value: '(+968)' },
    // { country: 'Palau (+680)', label: 'PW', value: '(+680)' },
    // { country: 'Panama (+507)', label: 'PA', value: '(+507)' },
    // { country: 'Papua New Guinea (+675)', label: 'PG', value: '(+675)' },
    // { country: 'Paraguay (+595)', label: 'PY', value: '(+595)' },
    // { country: 'Peru (+51)', label: 'PE', value: '(+51)' },
    // { country: 'Philippines (+63)', label: 'PH', value: '(+63)' },
    // { country: 'Poland (+48)', label: 'PL', value: '(+48)' },
    // { country: 'Portugal (+351)', label: 'PT', value: '(+351)' },
    // { country: 'Puerto Rico (+1787)', label: 'PR', value: '(+1787)' },
    // { country: 'Qatar (+974)', label: 'QA', value: '(+974)' },
    // { country: 'Reunion (+262)', label: 'RE', value: '(+262)' },
    // { country: 'Romania (+40)', label: 'RO', value: '(+40)' },
    // { country: 'Russia (+7)', label: 'RU', value: '(+7)' },
    // { country: 'Rwanda (+250)', label: 'RW', value: '(+250)' },
    // { country: 'San Marino (+378)', label: 'SM', value: '(+378)' },
    // { country: 'Sao Tome & Principe (+239)', label: 'ST', value: '(+239)' },
    // { country: 'Saudi Arabia (+966)', label: 'SA', value: '(+966)' },
    // { country: 'Senegal (+221)', label: 'SN', value: '(+221)' },
    // { country: 'Serbia (+381)', label: 'RS', value: '(+381)' },
    // { country: 'Seychelles (+248)', label: 'SC', value: '(+248)' },
    // { country: 'Sierra Leone (+232)', label: 'SL', value: '(+232)' },
    // { country: 'Singapore (+65)', label: 'SG', value: '(+65)' },
    // { country: 'Slovak Republic (+421)', label: 'SK', value: '(+421)' },
    // { country: 'Slovenia (+386)', label: 'SI', value: '(+386)' },
    // { country: 'Solomon Islands (+677)', label: 'SB', value: '(+677)' },
    // { country: 'Somalia (+252)', label: 'SO', value: '(+252)' },
    // { country: 'South Africa (+27)', label: 'ZA', value: '(+27)' },
    // { country: 'Spain (+34)', label: 'ES', value: '(+34)' },
    // { country: 'Sri Lanka (+94)', label: 'LK', value: '(+94)' },
    // { country: 'St. Helena (+290)', label: 'SH', value: '(+290)' },
    // { country: 'St. Kitts (+1869)', label: 'KN', value: '(+1869)' },
    // { country: 'St. Lucia (+1758)', label: 'LC', value: '(+1758)' },
    // { country: 'Sudan (+249)', label: 'SD', value: '(+249)' },
    // { country: 'Suriname (+597)', label: 'SR', value: '(+597)' },
    // { country: 'Swaziland (+268)', label: 'SZ', value: '(+268)' },
    // { country: 'Sweden (+46)', label: 'SE', value: '(+46)' },
    // { country: 'Switzerland (+41)', label: 'CH', value: '(+41)' },
    // { country: 'Syria (+963)', label: 'SY', value: '(+963)' },
    // { country: 'Taiwan (+886)', label: 'TW', value: '(+886)' },
    // { country: 'Tajikstan (+7)', label: 'TJ', value: '(+7)' },
    // { country: 'Thailand (+66)', label: 'TH', value: '(+66)' },
    // { country: 'Togo (+228)', label: 'TG', value: '(+228)' },
    // { country: 'Tonga (+676)', label: 'TO', value: '(+676)' },
    // { country: 'Trinidad & Tobago (+1868)', label: 'TT', value: '(+1868)' },
    // { country: 'Tunisia (+216)', label: 'TN', value: '(+216)' },
    // { country: 'Turkey (+90)', label: 'TR', value: '(+90)' },
    // { country: 'Turkmenistan (+7)', label: 'TM', value: '(+7)' },
    // { country: 'Turkmenistan (+993)', label: 'TM', value: '(+993)' },
    // { country: 'Turks & Caicos Islands (+1649)', label: 'TC', value: '(+1649)' },
    // { country: 'Tuvalu (+688)', label: 'TV', value: '(+688)' },
    // { country: 'Uganda (+256)', label: 'UG', value: '(+256)' },
    // { country: 'UK (+44)', label: 'GB', value: '(+44)' },
    // { country: 'Ukraine (+380)', label: 'UA', value: '(+380)' },
    // { country: 'United Arab Emirates (+971)', label: 'AE', value: '(+971)' },
    // { country: 'Uruguay (+598)', label: 'UY', value: '(+598)' },
    // { country: 'USA (+1)', label: 'US', value: '(+1)' },
    // { country: 'Uzbekistan (+7)', label: 'UZ', value: '(+7)' },
    // { country: 'Vanuatu (+678)', label: 'VU', value: '(+678)' },
    // { country: 'Vatican City (+379)', label: 'VA', value: '(+379)' },
    // { country: 'Venezuela (+58)', label: 'VE', value: '(+58)' },
    // { country: 'Vietnam (+84)', label: 'VN', value: '(+84)' },
    // { country: 'Virgin Islands - British (+1284)', label: 'VG', value: '(+1284)' },
    // { country: 'Virgin Islands - US (+1340)', label: 'VI', value: '(+1340)' },
    // { country: 'Wallis & Futuna (+681)', label: 'WF', value: '(+681)' },
    // { country: 'Yemen (North)(+969)', label: 'YE', value: '(+969)' },
    // { country: 'Yemen (South)(+967)', label: 'YE', value: '(+967)' },
    // { country: 'Zambia (+260)', label: 'ZM', value: '(+260)' },
    // { country: 'Zimbabwe (+263)', label: 'ZW', value: '(+263)' }
  ];
  