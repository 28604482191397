import { useEffect, useState } from "react"
import ReportMainteneanceForm from "../../reportMainteneaceForm/mainReportMainteneanceForm"
import { useDispatch, useSelector } from "react-redux"
import { getDataSpecificIntervention } from "../../../structures/root/slices/interventionSlice"
import { selectAllDepartment } from "../../../structures/root/slices/listDepartmentSlice"
import { selectAllLine } from "../../../structures/root/slices/listLineSlice"
import { selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice"
import { selectPlant } from "../../../structures/root/slices/plantSlice"

const ElementListStandard = ({ elemento }) => {
    const [isOpenAction, setIsOpen] = useState(false)

    const setSingleIsOpenActionComponent = () => {
        setIsOpen(!isOpenAction)
    }

    //download dei dati intervento  se non presenti
    const dispatch = useDispatch()
    useEffect(() => {

        if (!elemento.operatore) {
            if (elemento.id.slice(0, 7) === "virtual") {
                dispatch(getDataSpecificIntervention({ id: elemento.idPrevIntervention, idAsset: elemento.idMachinery }))
            } else {
                dispatch(getDataSpecificIntervention({ id: elemento.id, idAsset: elemento.idMachinery }))
            }

        }
    }, [])



    const [plant, setDataPlant] = useState({})
    const [depart, setDepart] = useState({})
    const [linea, setLinea] = useState()

    const dataDepartment = useSelector(selectAllDepartment)
    const dataLinee = useSelector(selectAllLine)
    const listMachine = useSelector(selectAllMachinery)
    const listaImpianti = useSelector(selectPlant)
    const [datiMachine, setDatiMachine] = useState({})



    useEffect(() => {

        setDatiMachine(listMachine.find(el => el.id === elemento.idMachinery
        ))
    }, [elemento])

    useEffect(() => {
        setDataPlant(listaImpianti.find(el => el.id === datiMachine.idPlant))
        setLinea(dataLinee.find(el => el.id === datiMachine.idLinea))

    }, [datiMachine])

    useEffect(() => {
        if (linea) { setDepart(dataDepartment.find(el => el.id === linea.idDepartment)) }
    }, [linea])


    return (
        <li
            style={{ "color": `${elemento.backgroundColor}` }}
            className="boxLiElementListStandard"
            key={elemento.id}
        >
            <div style={{ "flex": "3" }}>
                <div className="boxTitleEventList">
                    <h5
                        className="titleEventListTimeEvent"
                        style={{ "color": `${elemento.backgroundColor}` }}
                    >
                        {elemento.title}
                    </h5>


                </div>
                <div
                    className="corpoEvent"
                >
                    <span className="elementSpecificDataToInterventionLittle">Impianto: {plant?.nome}</span>
                    <span className="elementSpecificDataToInterventionLittle">Reparto: {depart?.name}</span>
                    <span className="elementSpecificDataToInterventionLittle">Linea: {linea?.name}</span>
                    <span className="elementSpecificDataToInterventionLittle">Macchina: {elemento?.operatore}</span>
                </div>
            </div>
            <div
                style={{ "flex": "1" }}
            >
                <button
                    onClick={() => setSingleIsOpenActionComponent()}
                >Risolvi</button>
                {
                    isOpenAction ?
                        <ReportMainteneanceForm
                            mode={"add"}
                            datiIntervento={elemento}
                            isOpen={!isOpenAction}
                            closeScheda={setSingleIsOpenActionComponent}

                        />
                        :
                        <span></span>
                }
            </div>

        </li>
    )
}

export default ElementListStandard