import React, { useEffect, useState } from 'react';
import './Modal.css';
import Button from "../button/Button";

/**
 * Componente Modal per la visualizzazione di contenuti in una finestra modale.
 *
 * @param {boolean} isOpen - Stato che indica se la modale è aperta o chiusa.
 * @param {Function} onClose - Funzione per chiudere la modale.
 * @param {JSX.Element} children - Contenuto della modale.
 */

const Modal = ({ isOpen, onClose, children, funzione }) => {
    const [isClosing, setIsClosing] = useState(false);


    useEffect(() => {
        if (isOpen) {
            //document.body.style.overflow = 'hidden'; // Disabilita lo scroll verticale del body
        } else {
            //document.body.style.overflow = ''; // Riabilita lo scroll verticale del body quando la modale è chiusa
        }
    }, [isOpen]);

    /**
     * Funzione per gestire la chiusura della modale.
     */
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose(); // Chiama la funzione di chiusura passata dal componente genitore
        }, 300); // Aggiungi il tempo di transizione in millisecondi del tuo stile di chiusura
    };

    return (
        <>
            {isOpen && (
                <div className={`modal-overlay ${isClosing ? 'closing' : ''}`} onClick={handleClose}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 24C0 10.7452 10.7452 0 24 0V0C37.2548 0 48 10.7452 48 24V24C48 37.2548 37.2548 48 24 48V48C10.7452 48 0 37.2548 0 24V24Z"
                                    fill="#FEF0C7" />
                                <path
                                    d="M24 20V24M24 28H24.01M34 24C34 29.5228 29.5228 34 24 34C18.4772 34 14 29.5228 14 24C14 18.4772 18.4772 14 24 14C29.5228 14 34 18.4772 34 24Z"
                                    stroke="#DC6803" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span className="close-btn" onClick={handleClose}>&times;</span>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        <div className="modal-actions">
                            <Button
                                className={"button cancel"}
                                style={{
                                    width: "10vw",
                                    marginRight: "1vw"
                                }}
                                onClick={handleClose}
                            >Annulla</Button>
                            <Button
                                className={"button save"}
                                style={{
                                    width: "10vw"
                                }}
                                onClick={funzione}
                            >Conferma
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
