import React, { useEffect, useState } from "react";
import HeaderHomeMadeCalendar from "./headerHomeMadeCalendar";
import BodyHomeMadeCalendar from "./bodyHomeMadeCalendar";
import TitleHomeMadeCalendar from "./titleHomeMadeCalendar";
const HomeMadeCalendar = ({ listEvent }) => {
    /**
     * dateSelect rappresenta la data selezionata per la visualizzazione del calendario
     * questa viene aggiornata tramite le freccie nell'header del calendario e sposta la data 
     * attuale al mese successivo o precedente
     */
    const [dateSelect, setDateSelect] = useState(new Date())

    

    return (
        <div className="bodyCalendar">
            <TitleHomeMadeCalendar
                dateCalendar={dateSelect}
                setDate={setDateSelect}
            />

            <HeaderHomeMadeCalendar />

            <BodyHomeMadeCalendar
                dateCalendar={dateSelect}
                listEvent={listEvent}
            />
        </div>
    )



}

export default HomeMadeCalendar