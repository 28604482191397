import React, { useEffect, useState } from "react";


import './mainTripleDonatChart.css'
import PerTripleDonatChart from './subElements/persTripleDonatChart'

const TripleDonatChart = ({ plant,title1, title2, title3, value1, value2, value3, funzioneCambioStato }) => {

    const [internalValue1, setInternalValue1] = useState(value1)
    const [internalValue2, setInternalValue2] = useState(value2)
    const [internalValue3, setInternalValue3] = useState(value3)

    useEffect(() => {
        setInternalValue1(value1)
        setInternalValue2(value2)
        setInternalValue3(value3)
    }, [value1, value2, value3])


    return <PerTripleDonatChart
        plant={plant}
        dimDesktop={1201}
        funzioneCambioStato={funzioneCambioStato}
        title1={title1}
        title2={title2}
        title3={title3}
        value1={internalValue1}
        value2={internalValue2}
        value3={internalValue3}
    />
}

export default TripleDonatChart