import React, { useEffect, useRef, useState } from "react";
import './mainUserDetails.css'
import Breadcrumb from "../../../components/userManagement/breadcrumb/Breadcrumb";
import Tabs from "../../../components/userManagement/tabs/Tabs";
import Button from "../../../components/userManagement/button/Button";
import Modal from "../../../components/userManagement/modal/Modal";
import UserForm from "./mainUserForm";
import { useNavigate, useParams } from "react-router-dom";
import { pathImpostazioni, subPathUsers } from "../../../configurations/setting";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, selectAllUsers, updateUser } from "../../root/slices/usersSlice";

const UserDetails = () => {
    const { iduser } = useParams();
    const datiUser = useSelector(selectAllUsers).find(e => e.id === iduser)
    const [userState, setUserState] = useState(datiUser)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [tabPanel, setTabPanel] = useState(0)
    const [name, setName] = useState('')

    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    const [deleteOk, setDeleteOk] = useState()

    if (deleteOk) {
        navigate(-1)
    }




    useEffect(() => {

        let breadcrumbItems = [
            {
                href: `${pathImpostazioni}/${subPathUsers}`,
                title: 'Users',
            }
        ];



        breadcrumbItems.push({
            title: datiUser?.name.value,
            badge: true
        })

        setName(datiUser?.name.Value)
        setBreadcrumbs(breadcrumbItems)
    }, [datiUser])

    const tabsItems = [
        {
            key: 1,
            label: 'Details',
            content: <UserForm
                user={datiUser}
                setUserState={setUserState}
            />,
        }
    ];


    const onChange = (key) => {
        const tabIndex = tabsItems.findIndex(tab => tab.key === key);

        // Verifica se l'indice è valido prima di impostare il TabPanel
        if (tabIndex !== -1) {
            setTabPanel(tabIndex);
        } else {
            // Gestisci il caso in cui non viene trovato alcun elemento con la chiave corrispondente
            console.warn(`Tab with key ${key} not found`);
        }
    };


    const closeModal = () => {
        setIsOpen(false); // Imposta isOpen a false per chiudere la modale
    };

    return (
        <div className="mainSettingsUsers">
            <div className="sidebarSettings">
                <div className="sidebarSettingsContent">
                    <svg width="1.5vw" height="1.4vw" viewBox="0 0 22 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21 19V17C21 15.1362 19.7252 13.5701 18 13.126M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5C17 6.67869 15.9659 8.11585 14.5 8.70924M16 19C16 17.1362 16 16.2044 15.6955 15.4693C15.2895 14.4892 14.5108 13.7105 13.5307 13.3045C12.7956 13 11.8638 13 10 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
                            stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span
                        className={"sidebar"}
                        style={{ fontSize: "1.5vw", fontWeight: "bold", margin: "0.5vw 1vw" }}
                    >Users</span>


                </div>
            </div>
            <div className="mainSettingsUsersBody">
                <div className={"mainSettingsUserDetailsSection"}>
                    <div className={"mainUserDetailsHeader"}>

                        <Breadcrumb items={breadcrumbs} />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                // marginLeft: "2vw"
                            }}
                        >

                        </div>
                        <Tabs items={tabsItems} onChange={onChange} defaultActiveKey={1} />
                    </div>
                    <div className="mainUserDetailsFormBody">
                        {tabsItems[tabPanel].content}
                        <div className="mainUserDetailsFooter">
                            <div className="divider" />
                            <div className="mainUserDetailsFooterContent">
                                <div className="mainUserDetailsFooterContentLeft">
                                    <Button
                                        style={{
                                            marginRight: "1vw"
                                        }}
                                        className={"button disabilita"}
                                        onClick={() => setIsOpen(true)}
                                    >
                                        {!datiUser?.enabled ? "Elimina" : "Disabilita"}
                                    </Button>
                                </div>
                                <div className="mainUserDetailsFooterContentRight">

                                    <Button
                                        style={{
                                            marginRight: "1vw"
                                        }}
                                        className={"button cancel"}
                                        onClick={() => navigate(`${pathImpostazioni}/${subPathUsers}`)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={"button save"}
                                        onClick={() => dispatch(updateUser({
                                            name: userState.name,
                                            email: userState.email,
                                            roleUser: userState.roleUser,
                                            idStructure: userState.idStructure,
                                            phone_number: userState.phone_number
                                        })).then(e => navigate(`${pathImpostazioni}/${subPathUsers}`))
                                        }
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Modal
                            isOpen={isOpen}
                            onClose={closeModal}
                            funzione={() => dispatch(
                                deleteUser({ email: userState.email, }))
                                .then(e => {
                                    setDeleteOk(true)



                                })
                                .catch(err => {
                                    alert(err)
                                })
                            }
                        >
                            <div
                                style={{
                                    fontWeight: "bold"
                                }}>
                                Vuoi
                                {!datiUser?.enabled ? " eliminare " : " disabilitare "}
                                l'accesso all'utente {datiUser?.name.subValue}?
                            </div>
                        </Modal>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default UserDetails;