import {useEffect, useState} from "react";
import {API} from 'aws-amplify'
import {
    deleteAssets,
    deleteBudgets,
    deleteDataTargaAssets,
    deleteDepartments,
    deleteLineas,
    deletePlants
} from "../../../graphql/mutations";
import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api'
import {useDispatch, useSelector} from "react-redux";
import {deleteDataTarga, selectAllDatiTarga} from "../../../structures/root/slices/datiTargaMachinerySlice";
import {deletePlant, updatePlant} from "../../../structures/root/slices/plantSlice";
import {deleteDepartment} from "../../../structures/root/slices/listDepartmentSlice";
import {deleteLine} from "../../../structures/root/slices/listLineSlice";
import {deleteBudget} from "../../../structures/root/slices/budgetSlice";
import {deleteAsset} from "../../../structures/root/slices/listMachinerySlice";

const DeleteObject = ({ close,object,type,listPlant,listDepartment,listLine,listMachinery,listBudgets}) => {
    const dispatch = useDispatch()
    const [tree,setTree] = useState(null);
    const listDatiTarga = useSelector(selectAllDatiTarga)
    const [objectsToBeEliminated,setObjectsToBeEliminated] = useState([]);

    const iconMap = {
        plant: 'circle',
        department: 'square',
        line: 'disc',
        machinery: 'square'
    };

    useEffect(() => {

        let idDaEliminare=[]
        //COSTRUZIONE TREE
        let tree={}
        if (type === "plant") {
            const idPlant = object.id;

            //idDaEliminare.push(idPlant)

            idDaEliminare.push({"id":idPlant,"type":"plant"})

            const departments = listDepartment.filter((department) => department.idPlant === idPlant);

            tree["plant"] = {
                "name": object.nome,
                "id": object.id,
                "departments": []
            };

            departments.forEach((department) => {
                const lines = listLine.filter((line) => line.idDepartment === department.id);



                const departmentObj = {
                    "name": department.name,
                    "id": department.id,
                    "lines": []
                };

                lines.forEach((line) => {
                    const machinery = listMachinery.filter((machinery) => machinery.idLinea === line.id);

                    const lineObj = {
                        "name": line.name,
                        "id": line.id,
                        "machinery": machinery
                    };
                    departmentObj["lines"].push(lineObj);

                    //idDaEliminare.push(line.id)
                    idDaEliminare.push({"id":line.id,"type":"line"})
                    machinery.map((assets,index)=>{
                        //idDaEliminare.push(assets.id)
                        idDaEliminare.push({"id":assets.id,"type":"machinery"})
                    })
                });

                //idDaEliminare.push(department.id)
                idDaEliminare.push({"id":department.id,"type":"department"})

                tree["plant"]["departments"].push(departmentObj);
            });
        }
        else if(type === "department"){
            const idPlant=object.idPlant
            const namePlant = listPlant.filter((plant,index)=>plant.id===idPlant)[0].nome
            const nameDepartment = object.name
            const idDepartment=object.id

            //idDaEliminare.push(idDepartment)
            idDaEliminare.push({"id":idDepartment,"type":"department"})

            tree["plant"] = {
                "name": namePlant,
                "id": idPlant,
                "departments": [
                    {
                        "name": nameDepartment,
                        "id":idDepartment,
                        "lines": []
                    }
                ]
            };
            const lines = listLine.filter((line) => line.idDepartment === idDepartment);
            lines.forEach((line) => {
                const machinery = listMachinery.filter((machinery) => machinery.idLinea === line.id);
                const lineObj = {
                    "name": line.name,
                    "id": line.id,
                    "machinery": machinery
                };

                tree["plant"]["departments"][0]["lines"].push(lineObj);

                //idDaEliminare.push(line.id)
                idDaEliminare.push({"id":line.id,"type":"line"})
                machinery.map((assets,index)=>{
                    //idDaEliminare.push(assets.id)
                    idDaEliminare.push({"id":assets.id,"type":"machinery"})
                })
            });

        }
        else if(type==="line"){


            const idLine=object.id
            const nameLine=object.name

            //idDaEliminare.push(idLine)
            idDaEliminare.push({"id":idLine,"type":"line"})
            const idDepartment=object.idDepartment
            const nameDepartment = listDepartment.filter((department)=>department.id===idDepartment)[0].name


            const idPlant=listDepartment.filter((department)=>department.id===idDepartment)[0].idPlant
            const namePlant = listPlant.filter((plant)=>plant.id===idPlant)[0].nome

            const machinery = listMachinery.filter((machinery) => machinery.idLinea === idLine);

            machinery.map((assets,index)=>{
                //idDaEliminare.push(assets.id)
                idDaEliminare.push({"id":assets.id,"type":"machinery"})
            })

            tree["plant"] = {
                "name": namePlant,
                "id": idPlant,
                "departments": [
                    {
                        "name": nameDepartment,
                        "id":idDepartment,
                        "lines": [
                            {
                                "name": nameLine,
                                "id":idLine,
                                "machinery": machinery
                            }
                        ]
                    }
                ]
            };
        }
        else if(type==="machinery"){

            const idMachinery=object.id
            const nameMachinery=object.nome

            //idDaEliminare.push(idMachinery)
            idDaEliminare.push({"id":idMachinery,"type":"machinery"})
            const idLine=object.idLinea
            const nameLine=listLine.filter((line)=>line.id===idLine)[0].name

            const idDepartment=object.idDepartment
            const nameDepartment = listDepartment.filter((department)=>department.id===idDepartment)[0].name


            const idPlant=object.idPlant
            const namePlant = listPlant.filter((plant)=>plant.id===idPlant)[0].nome

            tree["plant"] = {
                "name": namePlant,
                "id": idPlant,
                "departments": [
                    {
                        "name": nameDepartment,
                        "id":idDepartment,
                        "lines": [
                            {
                                "name": nameLine,
                                "id":idLine,
                                "machinery": [
                                    {
                                        "id": idMachinery,
                                        "nome": nameMachinery,
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };

        }
        setTree(tree)
        setObjectsToBeEliminated(idDaEliminare)


    }, [])

    const getTitle = (type, object) => {
        switch (type) {
            case "plant":
                return `Sei sicuro di voler cancellare lo stabilimento "${object.nome}" ?`;
            case "department":
                return `Sei sicuro di voler cancellare il reparto  "${object.name}" ?`;
            case "line":
                return `Sei sicuro di voler cancellare la linea  "${object.name}" ?`;
            case "machinery":
                return `Sei sicuro di voler cancellare il macchinario  "${object.nome}" ?`
            default:
                return "";
        }
    };

    const visualizeTree = () => {
        return <div className="treeObject">
            {tree!=null &&
                <ul style={{ listStyleType: iconMap['plant'] }}>
                    <li> <div className={objectsToBeEliminated.map(item => item.id).includes(tree.plant.id) && "text-red"}> {tree.plant.name} </div>
                        {tree.plant.departments.length > 0 &&
                            <ul style={{paddingLeft: '4vw'}}>
                                <ul style={{ listStyleType: iconMap['department'] }}>
                                    {tree.plant.departments.map((department, index) => (
                                        <li key={index}>
                                            <div className={objectsToBeEliminated.map(item => item.id).includes(department.id) && "text-red"}>
                                                {department.name}
                                            </div>
                                            {tree.plant.departments[index].lines.length>0 &&
                                                <ul style={{paddingLeft: '20px', marginLeft: '4vw',listStyleType: iconMap['line']}}>
                                                    {tree.plant.departments[index].lines.map((line,indexLine)=>(
                                                        <li>
                                                            <div className={objectsToBeEliminated.map(item => item.id).includes(line.id) && "text-red"}>
                                                                {line.name}
                                                            </div>
                                                            {tree.plant.departments[index].lines[indexLine].machinery.length>0 &&
                                                                <ul style={{paddingLeft: '20px', marginLeft: '4vw',listStyleType: iconMap['machinery']}}>
                                                                    {tree.plant.departments[index].lines[indexLine].machinery.map((asset,indexAsset)=>(
                                                                        <li>
                                                                            <div className={objectsToBeEliminated.map(item => item.id).includes(asset.id) && "text-red"}>
                                                                                {asset.nome}
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            }
                                                        </li>
                                                    ))}

                                                </ul>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </ul>

                        }

                    </li>

                </ul>}
        </div>
    }

    const deleteObject = () => {


        const plantIdsToDelete = objectsToBeEliminated.filter(item => item.type === "plant").map(item => item.id);
        const lineIdsToDelete = objectsToBeEliminated.filter(item => item.type === "line").map(item => item.id);
        const machineryIdsToDelete = objectsToBeEliminated.filter(item => item.type === "machinery").map(item => item.id);
        const departmentIdsToDelete = objectsToBeEliminated.filter(item => item.type === "department").map(item => item.id);
        const budgetsToDelete = listBudgets.filter((budget)=>objectsToBeEliminated.map(item => item.id).includes(budget.idStructure))
        const datiTargaToDelete = listDatiTarga.filter((datiTarga)=>machineryIdsToDelete.includes(datiTarga.id))

        let allRequest =[]
        //PLANTS
        plantIdsToDelete.map((plantIdToDelete,index)=>{
            allRequest.push(dispatch(deletePlant(
                {
                    id: plantIdToDelete,
                }
            )))
        })
        //DEPARTMENT
        departmentIdsToDelete.map((departmentIdToDelete,index)=>{

            allRequest.push(dispatch(deleteDepartment(
                {
                    id: departmentIdToDelete,
                }
            )))
        })
        //LINE
        lineIdsToDelete.map((lineIdToDelete,index)=>{

            allRequest.push(dispatch(deleteLine(
                {
                    id: lineIdToDelete,
                }
            )))
        })
        //ASSETS
        machineryIdsToDelete.map((machineryIdToDelete,index)=>{


            allRequest.push(dispatch(deleteAsset(
                {
                    id: machineryIdToDelete,
                }
            )))
        })

        //BUDGET
        budgetsToDelete.map((budgetToDelete,index)=>{


            allRequest.push(dispatch(deleteBudget(
                {
                    id: budgetToDelete.id,
                    idStructure:budgetToDelete.idStructure
                }
            )))
        })

        //DATI TARGA
        datiTargaToDelete.map((datiTargaId,index)=>{


            allRequest.push(dispatch(deleteDataTarga(
                {
                    id: datiTargaId.id,
                }
            )))
        })

        Promise.all([allRequest])
            .then(ris => {
                    close()
                }
            )

    }

    return (
        <div className="bodydeleteObject">
            <div className="boxAllDeleteObject">
                <div className="boxTitleDeleteObject">
                    <h1 className="elementTitledeleteObject">
                        {getTitle(type, object)}
                    </h1>

                </div>
                <div>
                    <div>Gli oggetti che saranno eliminati sono evidenziati con il colore rosso</div>

                    {visualizeTree()}


                </div>

                <div className="elementSpaceBetweenDeleteObject"></div>

                <div className="deleteObjectButton">
                    <button className="elementButtonSubmitDeleteObject" onClick={()=>deleteObject()}>
                        Conferma
                    </button>
                    <button className="elementButtonSubmitDeleteObject" onClick={() => close()}>
                        Annulla
                    </button>
                </div>

            </div>
        </div>
    );
}

export default DeleteObject