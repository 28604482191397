import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';


const datiUtenteAdapter = createEntityAdapter({
    selectId: (datiUtente) => datiUtente.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
})


const datiUtenteSlice = createSlice({
    name: "datiUtente",
    initialState: datiUtenteAdapter.getInitialState(),
    reducers: {
        datiUtenteAddOne(state, action) {
            //datiUtenteAdapter.removeAll()
            datiUtenteAdapter.upsertOne(state, action.payload)
        }
    }
})

export const { datiUtenteAddOne } = datiUtenteSlice.actions

export default datiUtenteSlice.reducer

export const { selectAll: selectUser } = datiUtenteAdapter.getSelectors(state => state.datiUtente)