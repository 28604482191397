import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { pathExplorPlant, pathMachineryDocumentation, pathMachineryIntervention, pathMachineryFaultList } from '../../../configurations/setting'


const PersSchedeMachinery = ({ id }) => {
    const path = useLocation().pathname.split("/")[3]
    const [activeLink, setActiveLink] = useState([])


    useEffect(() => {
        /**
         * va portata la condizione per la quale se un macchinario è senza predittiva deve comparire
         * fissa la sola scheda relativa alle manutenzioni portando un if nella condizione seguente 
         * e successivamente anche nel corpo dell'oggetto.
         * questa situazione va anche verificata in funzione del path che inviamo poichè se questo 
         * prensenta fault list deve cmq rimandare a dettaglio macchinario
         */
        switch (path) {
            case pathMachineryFaultList:
                setActiveLink([true, false, false])
                break;
            case pathMachineryDocumentation:
                setActiveLink([false, true, false])
                break;
            case pathMachineryIntervention:
                setActiveLink([false, false, true])
                break;
            default:
                setActiveLink([false, false, false])
                break;

        }
    }, [path])




    return (
        /**
         * condizione da riportare anche in questo blocco
         */
        <div className="bodySchedeMachinery">
            <Link
                to={`${pathExplorPlant}/${id}/${pathMachineryFaultList}`}
                className={`elementSchedaMachinery ${activeLink[0] ? `elementActiveSchedaMachinery` : ''}`}
            >Lista fault</Link>
            <Link
                to={`${pathExplorPlant}/${id}/${pathMachineryDocumentation}`}
                className={`elementSchedaMachinery ${activeLink[1] ? `elementActiveSchedaMachinery` : ''}`}
            >Dati targa</Link>
            <Link
                to={`${pathExplorPlant}/${id}/${pathMachineryIntervention}`}
                className={`elementSchedaMachinery ${activeLink[2] ? `elementActiveSchedaMachinery` : ''}`}
            >Elenco manutenzioni</Link>

        </div>
    )
}

export default PersSchedeMachinery