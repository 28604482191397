import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { pathExplorPlant,  pathMachineryIntervention,  pathMachineryMetriche } from '../../../configurations/setting'


const PersSchedaMachineryProgrammatic = ({ id }) => {
    const path = useLocation().pathname.split("/")[3]
    const [activeLink, setActiveLink] = useState([])


    useEffect(() => {
        /**
         * va portata la condizione per la quale se un macchinario è senza predittiva deve comparire
         * fissa la sola scheda relativa alle manutenzioni portando un if nella condizione seguente 
         * e successivamente anche nel corpo dell'oggetto.
         * questa situazione va anche verificata in funzione del path che inviamo poichè se questo 
         * prensenta fault list deve cmq rimandare a dettaglio macchinario
         */
        switch (path) {
            case pathMachineryIntervention:
                setActiveLink([true, false])
                break;
            case pathMachineryMetriche:
                setActiveLink([false, true])
                break;
            
            default:
                setActiveLink([false, false])
                break;

        }
    }, [path])




    return (
        /**
         * condizione da riportare anche in questo blocco
         */
        <div className="bodySchedeMachinery">
            
            
            <Link
                to={`${pathExplorPlant}/${id}/${pathMachineryIntervention}`}
                className={`elementSchedaMachinery ${activeLink[0] ? `elementActiveSchedaMachinery` : ''}`}
            >Elenco manutenzioni</Link>
            <Link
                to={`${pathExplorPlant}/${id}/${pathMachineryMetriche}`}
                className={`elementSchedaMachinery ${activeLink[1] ? `elementActiveSchedaMachinery` : ''}`}
            >Metriche</Link>

        </div>
    )
}

export default PersSchedaMachineryProgrammatic