import { useEffect, useState } from "react"
import { newLine, updateLine } from "../../../structures/root/slices/listLineSlice";
import { useDispatch } from "react-redux";
import { newBudget, updateBudget } from "../../../structures/root/slices/budgetSlice";
import { parse } from "uuid";
import { listDepartments, listPlants } from "../../../graphql/queries";
import { modifyAssets } from "../../../structures/root/slices/listMachinerySlice";
import { pointToeconomicalValue } from "../../../functions/generiche";

const PersFormAddNewLinea = ({ close, plantsList, departmentsList, listLine, listMachinery, listBudgets, stabilimento, reparto, mode, objectToModify, budgetToModify }) => {

    const dispatch = useDispatch()

    const [name, setName] = useState(mode === "add" ? null : objectToModify.name);
    const [plantsId, setPlantsId] = useState(mode === "add" ? stabilimento.id : departmentsList.filter((department) => department.id === objectToModify.idDepartment)[0].idPlant);
    const [departmentsId, setDepartmentsId] = useState(mode === "add" ? reparto.id : objectToModify.idDepartment);
    const [budget, setBudget] = useState(mode === "add" ? null : budgetToModify.value);

    const [maxBudget, setMaxBudget] = useState(null)


    //Gestione per il calcolo del Budget Massimo per il reparto lavorato
    useEffect(() => {

        if (departmentsId != null && departmentsId.length != 0 && departmentsId != "") {

            calculateMaxBudget(departmentsId)
        }
    }, [listLine, listBudgets, departmentsList, plantsList])
    //REFRESH AUTOMATICO QUANDO SI TROVANO SULLA STESSA MODIFICA
    useEffect(() => {
        setName(mode === "add" ? null : objectToModify.name)
        setBudget(mode === "add" ? null : budgetToModify.value)
        setPlantsId(mode === "add" ? stabilimento.id : objectToModify.idPlant)
        setDepartmentsId(mode === "add" ? reparto.id : objectToModify.idDepartment)

    }, [objectToModify, budgetToModify])
    const validField = (fieldValue) => {
        return fieldValue != null && fieldValue.toString().length > 0
    }

    const calculateMaxBudget = (idDepartment) => {


        let maxBudgetTmp = null
        if (listBudgets.filter(budget => budget.idStructure === idDepartment).length === 1) {
            maxBudgetTmp = listBudgets.filter(budget => budget.idStructure === idDepartment)[0].value
            let linesOfDepartment = listLine.filter(line => line.idDepartment === idDepartment);
            if (mode === "modify") {
                linesOfDepartment = linesOfDepartment.filter((line) => line.id !== objectToModify.id)
            }
            for (const line of linesOfDepartment) {
                const currentLineId = line.id;
                const matchingBudget = listBudgets.find(budget => budget.idStructure === currentLineId);

                if (matchingBudget) {
                    const linesBudget = matchingBudget.value;
                    maxBudgetTmp = parseFloat(parseFloat(maxBudgetTmp).toFixed(2) - parseFloat(linesBudget).toFixed(2)).toFixed(2)
                } else {

                    setMaxBudget(null);
                    break; // Esci dal ciclo quando viene eseguito l'else
                }
            }

        } else {

            setMaxBudget(null)
        }

        if (maxBudgetTmp != null) {
            setMaxBudget(parseFloat(maxBudgetTmp))
        }
        else {
            setMaxBudget(null)
        }

    }


    const handleCreateLine = () => {
        // Add your validation logic here

        if (validField(budget) && validField(name) && validField(departmentsId)) {

            //if(parseFloat(budget)>0 && parseFloat(budget)<=parseFloat(maxBudget)) {

            const response = dispatch(newLine(
                {
                    name: name,
                    idDepartment: departmentsId,

                }
            ))

            Promise.all([response])
                .then(ris => {
                    if (ris[0].meta.requestStatus !== "rejected") {
                        let idStructure = ris[0].payload.id


                        const response = dispatch(newBudget(
                            {
                                idStructure: idStructure,
                                value: parseFloat(budget).toFixed(2),
                                year: new Date().getFullYear()
                            }
                        ))

                        close()
                    } else {
                        //alert("errore di rete, riprovare")
                    }
                }
                )



            /*}
            else{
                alert("il campo budget è errato")
            }*/
        }
        else {
            alert("tutti i campi sono obbligatori")
        }
    };
    const handleUpdateLine = () => {
        // Add your validation logic here


        if (validField(budget) && validField(name) && validField(departmentsId)) {

            //if(parseFloat(budget)>0 && parseFloat(budget)<=parseFloat(maxBudget)) {
            const response = dispatch(updateLine(
                {
                    id: objectToModify.id,
                    name: name,
                    idDepartment: departmentsId,
                }
            ))
            //AGGIORNARE TUTTI I MACCHINARI CHE SI TROVANO SOTTO QUESTA LINEA
            //RECUPERARE TUTTI I MACCHINARI CHE HANNO COME IDLINEA QUESTA IN QUESTONE
            const assestToUpdate = listMachinery.filter((machinery) => machinery.idLinea === objectToModify.id)
            //AGGIORNARE ID PLANT E ID DEPARTMENT
            let assestsToModify = []
            const idPlants = departmentsList.filter((department) => department.id === departmentsId)[0].idPlant

            assestToUpdate.map((singleAssets, index) => {
                assestsToModify.push(dispatch(modifyAssets(
                    {
                        id: singleAssets.id,
                        idLinea: objectToModify.id,
                        idPlant: idPlants,
                        idDepartment: departmentsId
                    }
                )))
            })

            Promise.all([...[response], ...assestsToModify])
                .then(ris => {
                    if (ris[0].meta.requestStatus !== "rejected") {
                        dispatch(updateBudget(
                            {
                                idStructure: budgetToModify.idStructure,
                                id: budgetToModify.id,
                                value: parseFloat(budget).toFixed(2),
                                year: new Date().getFullYear()
                            }
                        ))

                        close()
                    } else {
                        //alert("errore di rete, riprovare")
                    }
                }
                )



            /* }
             else{
                 alert("il campo budget è errato")
             }*/
        }
        else {
            alert("tutti i campi sono obbligatori")
        }
    };
    const handleChangePlants = (e) => {
        setPlantsId(e.target.value)
        setDepartmentsId("")
        setMaxBudget(null)
    }
    const handleChangeDepartment = (e) => {
        calculateMaxBudget(e.target.value)
        setDepartmentsId(e.target.value)
    }

    const handleChangeBudget = (e) => {
        setBudget(e.target.value.length === 0 ? null : e.target.value
            //rimuove i punti quando presenti
            .replace(/[.]/g, "")
            //rimuove qualsiasi cosa non sia un numero
            .replace(/[^0-9]/g, ""))

    }


    return (
        <div className="bodyAddNewLinea">
            <div className="boxAllAddNewLinea">
                <div className="boxTitleAddNewLinea">
                    <h1 className="elementTitleAddNewLinea">{mode === "add" ? `Nuova linea` : `Modifica linea`}</h1>
                    <h1
                        className="elementCloseSchedaAddNewAsset"
                        onClick={() => close()}
                    >x</h1>
                </div>
                <div className="boxFormAddNewLinea">

                    <div className="boxColumnFromAddNewAsset boxBlockFormAddNewAsset">
                        <span className="elementoLableForm">Nome linea</span>
                        <input
                            className="elementInputAddNewLine"
                            required
                            placeholder="Nome Linea"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <span className="elementoLableForm">Stabilimento</span>
                        <select
                            className="elementInputAddNewLine"
                            placeholder="Stabilimenti"
                            onChange={(e) => handleChangePlants(e)}
                            value={plantsId}
                        >
                            <option value="" disabled selected>
                                Stabilimenti
                            </option>
                            {plantsList.map((el, index) => (
                                <option key={index} value={el.id}>
                                    {el.nome}
                                </option>
                            ))}
                        </select>
                        <span className="elementoLableForm">Reparto</span>
                        <select
                            className="elementInputAddNewLine"
                            required
                            placeholder="Reparti"
                            onChange={(e) => handleChangeDepartment(e)}
                            value={departmentsId}
                        >
                            <option value="" disabled selected>
                                Reparti
                            </option>
                            {(validField(plantsId))
                                ? departmentsList
                                    .filter((department) => department.idPlant === plantsId)
                                    .map((el) => (
                                        <option key={el.id} value={el.id}>
                                            {el.name}
                                        </option>
                                    ))
                                : departmentsList.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.name}
                                    </option>
                                ))}
                        </select>
                        <span className="elementoLableForm">Budget {"(EUR)"}</span>
                        <div className="boxBudget">

                            <input
                                className="elementInputAddNewLineBudget"
                                placeholder="Budget di manutenzione"
                                type="text"

                                required
                                value={pointToeconomicalValue(budget)}
                                onChange={(e) => handleChangeBudget(e)}
                            />

                            <div className={`budgetText 
                                    ${(budget != null && (maxBudget - budget) > 0) || (budget != null && (maxBudget - budget) === 0) ? 'correctBudget' :
                                    ((budget != null && (maxBudget - budget) < 0) || (budget === null && maxBudget < 0)) ? 'errorBudget'
                                        : ''}`}>

                                {/*CASO IN CUI NON E' STATO POSSIBILE CACOLARE IL BUDGET A CASA DI UN ERRORE*/}
                                {maxBudget === null && `Non è stato possibile calcolare il budget massimo`}

                                {/*CASO IN CUI NON E' STATO ANCORA DIGITATO NESSUN BUDGET*/}
                                {budget === null && maxBudget >= 0 && `Il budget massimo per questa linea è ${pointToeconomicalValue(parseFloat(maxBudget))}€`}
                                {budget === null && maxBudget < 0 && `Il budget massimo del reparto è stato ecceduto di ${pointToeconomicalValue(Math.abs(maxBudget))}€`}

                                {/*CASO IN CUI NON E' STATO DIGITATO UN BUDGET*/}
                                {budget != null && (maxBudget - budget) < 0 && `Il budget massimo del reparto è stato ecceduto di ${pointToeconomicalValue(Math.abs(parseFloat(maxBudget) - parseFloat(budget)))}€`}
                                {budget != null && (maxBudget - budget) === 0 && `Budget massimo raggiunto! (Budget reparto rimanente: ${pointToeconomicalValue(parseFloat(maxBudget) - parseFloat(budget))}€)`}
                                {budget != null && (maxBudget - budget) > 0 && `Il budget inserito è nel limite consentito (Budget reparto rimanente: ${pointToeconomicalValue(parseFloat(maxBudget) - parseFloat(budget))}€)`}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="elementSpaceBetweenAddLinea"></div>
                <div className="boxColumnFromAddNewLinea">

                    {mode === "add" ?
                        <button
                            className="elementButtonSubmitFormAddNewLinea"
                            onClick={handleCreateLine}
                        >
                            Aggiungi Linea
                        </button>
                        :
                        <button
                            className="elementButtonSubmitFormAddNewStabilimento"
                            onClick={handleUpdateLine}

                        >
                            Modifica Linea
                        </button>
                    }
                </div>


            </div>
        </div>
    )
}

export default PersFormAddNewLinea
