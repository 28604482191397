import { useEffect, useState } from "react"
import { titleColor } from "../../../configurations/generalStye"


const PersListActivityTime = ({ list, nameList, isDate, children }) => {

    const [isOpen, setIsOpen] = useState(true)



    
    return (<div>
        <div className="bloccoTitoloListEvent">
            <h4
                onClick={() => setIsOpen(!isOpen)}
                className="elementTitleListTimeEvent unselectable"
            >
                {nameList}
            </h4>

            {
                isOpen ?
                    <h4
                        className="elementArrowListTimeEvent"
                    > &#x23F6; </h4>
                    :
                    <h4 className="elementArrowListTimeEvent">&#x23F7;</h4>
            }

            <h4

                style={{ "color": titleColor, visibility: `${isDate ? "visible" : "hidden"}` }}>{isDate ? isDate : ''}</h4>
        </div>
        {isOpen ?
            <ul
            className="ElementUlListActivityTime" 
            >
                {
                   children
                }
            </ul>
            :
            <span></span>
        }
    </div>)
}

export default PersListActivityTime