import PersSchedeMachineryProgrammatic from "./subElements/persSchedaMachineryProgrammatic";
import '../schedeMachinery/mainSchedeMachinery.css'


const SchedaMachineryProgrammatic = ({ id }) => {
    /**
     * aggiungere la modifica della scheda macchinario quando cambiamo la sceda che viene selezionata
     * lista fault dati-targa elenco manutenzioni
     */
    return (
        <PersSchedeMachineryProgrammatic
            id={id}
        />
    )
}

export default SchedaMachineryProgrammatic