import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice"
import { selectPlant } from "../../../structures/root/slices/plantSlice"
import {
    deleteIntervention,
    newIntervention,
    selectAllIntervention,
    createDataIntervention
} from "../../../structures/root/slices/interventionSlice"
import { dataFormatoGMA } from "../../../functions/dateTime"
import LogoModifica from "../../../icon/update.svg";
import LogoElimina from "../../../icon/basket.svg";
import { selectAllLine, updateLine } from "../../../structures/root/slices/listLineSlice";
import Modal from "../../DialogModal/mainDialogModal";
import { deleteReport } from "../../../structures/root/slices/reportSlice";
import { selectAllDepartment } from "../../../structures/root/slices/listDepartmentSlice"
import FormNewEvent from "../../formNewEvent/mainFormNewEvent";
import ReportMainteneanceForm from "../../reportMainteneaceForm/mainReportMainteneanceForm";
import { pointToeconomicalValue } from "../../../functions/generiche"
import { selectUser } from "../../../structures/root/slices/datiUtenteSlice"

export const ElementGeneralInfoIntervention = ({ intervento, index, closeBackground }) => {
    const listMachine = useSelector(selectAllMachinery)
    const listaImpianti = useSelector(selectPlant)
    const dispatch = useDispatch()
    const [datiMachine, setDatiMachine] = useState({})
    const [plant, setDataPlant] = useState({})
    const [depart, setDepart] = useState({})
    const [linea, setLinea] = useState()

    const dataDepartment = useSelector(selectAllDepartment)
    const dataLinee = useSelector(selectAllLine)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showModifyModal, setShowModifyModal] = useState(false)

    const datiUser = useSelector(selectUser)


    useEffect(() => {

        setDatiMachine(listMachine.find(el => el.id === intervento.idMachinery
        ))
    }, [intervento])


    useEffect(() => {
        setDataPlant(listaImpianti.find(el => el.id === datiMachine.idPlant))
        setLinea(dataLinee.find(el => el.id === datiMachine.idLinea))

    }, [datiMachine])


    useEffect(() => {
        if (linea) { setDepart(dataDepartment.find(el => el.id === linea.idDepartment)) }
    }, [linea])

    const handleDeleteIntervention = (intervento, close) => {

        if (intervento.id.slice(0, 7) === "virtual") {
            //GESTIONE EVENTO VIRTUALE

            //creiamo l'intervento che ad ora è virtuale
            dispatch(newIntervention({
                idAsset: intervento.idAsset,
                data: intervento.date,
                idFault: intervento.title,
                period: intervento.period,
                ora: intervento.ora,
                idPrevIntervention: intervento.idPrevIntervention,
                isDeleted: true
            }))
                .then(ris => {
                    if (ris.meta.requestStatus !== "rejected") {
                        dispatch(createDataIntervention({
                            idAsset: ris.payload.data.createInterventions
                                .idAsset,
                            id: ris.payload.data.createInterventions
                                .id,
                            activity: JSON.stringify(intervento.attivita),
                            idPlant: intervento.idPlant,
                            durata: intervento.durata,
                            reparto: listMachine.find(el => el.id === ris.payload.data.createInterventions
                                .idAsset).reparto, //da recuperare tra i dati macchina
                            operatore: intervento.operatore,
                            creatore: intervento.creatoreEvento, //al momento mettiamo il nome ma in futuro va messo l'id utente
                            note: intervento.note
                        })).then(el => {
                            if (el.meta.requestStatus !== "rejected") {
                                close(false)
                            } else {
                                alert("errore di eliminazione dati intervento")
                                close(false)
                            }


                        })
                    } else {
                        alert("impossibile eliminare l'intervento, problemi di connessione")
                        close(false)
                    }
                })

        } else {
            const response = dispatch(deleteIntervention(
                {
                    id: intervento.id,
                    isDeleted: true,
                    idAsset: intervento.idAsset,
                }
            ))
            Promise.all([response])
                .then(ris => {
                    if (ris[0].meta.requestStatus !== "rejected") {
                        close(false)
                    } else {
                        alert("impossibile eliminare l'intervento, problemi di connessione")
                        close(false)
                    }
                }
                )
        }
    }

    return <li
        key={index}
    >
        <div
            className="elementReportInterventionBody"
        >
            <div
                className="elementReportColumnLeft"
            >
                <span
                    className="elementReportDateLeftColumn"
                >{dataFormatoGMA(intervento.createdAt.slice(0, 10))}</span>
                <span
                    className="elementReportHourLeftColumn"
                >{intervento.createdAt.slice(11, 16)}</span>

                {
                    datiUser[0].group[0] === "Operator" ?
                        <span></span>
                        :
                        <span
                            className="elementButtonModifyDelete"
                        > <img
                                src={LogoModifica}
                                style={{ cursor: "pointer", "color": `red`, width: '1vw' }}
                                onClick={() => setShowModifyModal(true)}
                            /> <img
                                src={LogoElimina}
                                style={{ cursor: "pointer", "color": `green`, width: '1vw' }}
                                onClick={() => setShowDeleteModal(true)}
                            /></span>

                }


            </div>
            <div
                className="elementReportColumnRight"

            > <div className="boxRowReportCulimnRight">
                    <div
                        className="boxSubReportCulimnRight"

                    >
                        <h5>Tipo di intervento: {intervento.discendente === "p" ?
                            "attuativo di un periodico eliminato"
                            :
                            intervento.period === "" || intervento.period === "0" ?
                                "straordinario"
                                :
                                "periodico"}</h5>
                        {
                            intervento.period === "" || intervento.period === "0" ?
                                <span></span>
                                :
                                <h5>con periodo: {intervento.period} gg</h5>
                        }
                        <h5>Prima programmazione: {dataFormatoGMA(intervento.date)}</h5>
                        <h5>Durata preventivata: {intervento.durata} minuti</h5>
                        <h5>Operatore: {intervento.operatore}</h5>
                        <h5>Note:</h5>
                        <h5

                        >{intervento.note}</h5>

                    </div>
                    <div
                        className="boxSubReportCulimnRight"

                    >
                        <h5>Impianto: {plant ? plant.nome : ""}</h5>
                        <h5>Reparto: {depart.name}</h5>
                        <h5>Linea: {linea?.name}</h5>
                        <h5>Tipologia macchina: {datiMachine.familyId}</h5>
                        <h5>Nome: {datiMachine.nome}</h5>
                        <h5>Identificativo macchinario: {datiMachine.identityCode}</h5>

                    </div>
                </div>

            </div>

            {showDeleteModal ?
                <Modal show={showDeleteModal}>
                    <div className="bodydeleteModalIntervention">
                        <div className="boxAllDeleteModalIntervention">
                            <div className="boxTitleDeleteModalIntervention">
                                <h1> Sei sicuro di voler cancellare l'intervento?</h1>
                            </div>
                            <div className="deleteModalInterventionObjectButton">
                                <button className="elementButtonSubmitDeleteModalInterventionObjectButton" onClick={(e) => {
                                    e.target.disabled = true
                                    handleDeleteIntervention(intervento, setShowDeleteModal)
                                }
                                }>
                                    Conferma
                                </button>
                                <button className="elementButtonSubmitDeleteModalInterventionObjectButton" onClick={() => setShowDeleteModal(!showDeleteModal)}>
                                    Annulla
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                :
                null
            }{
                showModifyModal ?
                    <FormNewEvent
                        mode="modify"
                        closeScheda={(key) => setShowModifyModal(false)}
                        inteventionToModify={intervento}
                    />
                    :
                    <span></span>
            }
        </div>
    </li>
}

export const ElementoIntervento = ({ intervento, primoIntervento, index, }) => {

    const dispatch = useDispatch()
    const listMachine = useSelector(selectAllMachinery)
    const listIntervention = useSelector(selectAllIntervention)
    const [datiMachine, setDatiMachine] = useState({})

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showModifyModal, setShowModifyModal] = useState(false)

    const datiUser = useSelector(selectUser)

    useEffect(() => {
        setDatiMachine(listMachine.find(el => el.id === intervento.idMachinery
        ))
    }, [intervento])

    const handleDeleteIntervention = (intervento, close) => {

        if (intervento.id.slice(0, 7) === "virtual") {
            //GESTIONE EVENTO VIRTUALE

            //creiamo l'intervento che ad ora è virtuale
            dispatch(newIntervention({
                idAsset: intervento.idAsset,
                data: intervento.date,
                idFault: intervento.title,
                period: intervento.period,
                ora: intervento.ora,
                idPrevIntervention: intervento.idPrevIntervention,
                isDeleted: true
            }))
                .then(ris => {
                    if (ris.meta.requestStatus !== "rejected") {
                        dispatch(createDataIntervention({
                            idAsset: ris.payload.data.createInterventions
                                .idAsset,
                            id: ris.payload.data.createInterventions
                                .id,
                            activity: JSON.stringify(intervento.attivita),
                            idPlant: intervento.idPlant,
                            durata: intervento.durata,
                            reparto: listMachine.find(el => el.id === ris.payload.data.createInterventions
                                .idAsset).reparto, //da recuperare tra i dati macchina
                            operatore: intervento.operatore,
                            creatore: intervento.creatoreEvento, //al momento mettiamo il nome ma in futuro va messo l'id utente
                            note: intervento.note
                        })).then(el => {
                            if (el.meta.requestStatus !== "rejected") {
                                close(false)
                            } else {
                                alert("errore di eliminazione dati intervento")
                                close(false)
                            }


                        })
                    } else {
                        alert("impossibile eliminare l'intervento, problemi di connessione")
                        close(false)
                    }
                })

        } else {
            const response = dispatch(deleteIntervention(
                {
                    id: intervento.id,
                    isDeleted: true,
                    idAsset: intervento.idAsset,
                }
            ))
            Promise.all([response])
                .then(ris => {
                    if (ris[0].meta.requestStatus !== "rejected") {
                        close(false)
                    } else {
                        alert("impossibile eliminare l'intervento, problemi di connessione")
                        close(false)
                    }
                }
                )
        }
    }

    return <li
        key={index}
    >
        <div
            className="elementReportInterventionBody"
        >

            <div
                className="elementReportColumnLeft"
            >
                <span
                    className="elementReportDateLeftColumn"
                >{dataFormatoGMA(intervento.createdAt.slice(0, 10))}</span>
                <span
                    className="elementReportHourLeftColumn"
                >{intervento.createdAt.slice(11, 16)}</span>
                {
                    datiUser[0].group[0] === "Operator" ?
                        <span></span>
                        :
                        <span
                            className="elementButtonModifyDelete"
                        > <img
                                src={LogoModifica}
                                style={{ cursor: "pointer", "color": `red`, width: '1vw' }}
                                onClick={() => setShowModifyModal(true)}
                            /> <img
                                src={LogoElimina}
                                style={{ cursor: "pointer", "color": `green`, width: '1vw' }}
                                onClick={() => setShowDeleteModal(true)}
                            /></span>
                }


            </div>
            <div
                className="elementReportColumnRight"
            >
                <div className="boxRowReportCulimnRight">
                    <div
                        className="boxSubReportCulimnRight"
                    >
                        <h5>Tipo di intervento: {
                            intervento.id.slice(0, 7) === "virtual" ?
                                "intervento attuativo del periodico"
                                :
                                listIntervention.find(el => intervento.idPrevIntervention === el.id)?.period === "" ?
                                    /*
                                     primoIntervento.period === "" ?
                                         "intervento straordinario "
                                         :
                                         */
                                    "riprogrammato"
                                    :
                                    "intervento attuativo del periodico"
                        }</h5>
                    </div>

                    <div
                        className="boxSubReportCulimnRight"
                    >
                        <h5>Data: {dataFormatoGMA(intervento.date)}</h5>
                    </div>

                </div>

                <div className="boxSubReportCulimnRight">
                    <h5>Note: {intervento.note}</h5>
                </div>




            </div>
            {showDeleteModal ?
                <Modal show={showDeleteModal}>
                    <div className="bodydeleteModalIntervention">
                        <div className="boxAllDeleteModalIntervention">
                            <div className="boxTitleDeleteModalIntervention">
                                <h1> Sei sicuro di voler cancellare l'intervento?</h1>
                            </div>
                            <div className="deleteModalInterventionObjectButton">
                                <button className="elementButtonSubmitDeleteModalInterventionObjectButton" onClick={(e) => {
                                    e.target.disabled = true
                                    handleDeleteIntervention(intervento, setShowDeleteModal)
                                }}>
                                    Conferma
                                </button>
                                <button className="elementButtonSubmitDeleteModalInterventionObjectButton" onClick={() => setShowDeleteModal(!showDeleteModal)}>
                                    Annulla
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                :
                null
            }
            {
                showModifyModal ?
                    <FormNewEvent
                        mode="modify"
                        closeScheda={(key) => setShowModifyModal(false)}
                        inteventionToModify={intervento}
                    />
                    :
                    <span></span>
            }


        </div>
    </li>
}


export const ElementoReportVisualizzazione = ({ report, primoIntervento, index, listElement }) => {
    const dispatch = useDispatch()

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showModifyModal, setShowModifyModal] = useState(false)
    const interventoReport = useSelector(selectAllIntervention).find(el => el.id === report.idIntervention)
    const [repPre, setRepPre] = useState([])

    const [listAttPrevCompl, setListAttPrevComlet] = useState([])
    const [durCompl, setDurCompl] = useState(0)
    const [costCompl, setCostCompl] = useState(0)

    useEffect(() => {
        const temp = [...listElement.slice(0, index).filter(e => e?.idIntervention), report]
        let cost = 0
        let dura = 0
        temp.forEach(e => {
            if (e.costo !== undefined) {
                cost = Number(e.costo) + cost

            }

            if (e.durata !== undefined) {
                dura = dura + Number(e.durata)
            }
        })
        setCostCompl(cost)
        setDurCompl(dura)

        const vettAtt = temp.map(e => e.attivita)
        //dobbiamo solo trovare le attività completate

        if (vettAtt.length !== 0) {
            setListAttPrevComlet(
                vettAtt[vettAtt.length - 1].map(attiv => {
                    //presa la prima attività presentata nell'ultimo report che stiamo visualizzando bisogna verificare che sia stata eseguita nei report precedenti o nell'attuale
                    let completeAtt = false
                    vettAtt.forEach(el => {
                        if (el.find(e => e.id === attiv.id)?.complated) {
                            completeAtt = true
                        }
                    })

                    return {
                        ...attiv,
                        complated: completeAtt
                    }

                })
            )
        }
        setRepPre(temp)


    }, [listElement])

    const handleDeleteReport = (report, close) => {

        const response = dispatch(deleteReport(
            {
                id: report.id,
                isDeleted: true,
                idMachinery: report.idMachinery,
            }
        ))
        Promise.all([response])
            .then(ris => {
                if (ris[0].meta.requestStatus !== "rejected") {
                    close(false)
                } else {
                    alert("impossibile eliminare il report, problemi di connessione")
                    close(false)
                }

            }
            )
    }

    return <li
        key={index}
    >
        <div
            className="elementReportInterventionBody"
        >
            <div
                className="elementReportColumnLeft"
            > <span
                className="elementReportDateLeftColumn"
            >{dataFormatoGMA(report.createdAt.slice(0, 10))}</span>
                <span
                    className="elementReportHourLeftColumn"
                >{report.createdAt.slice(11, 16)}</span>
                <span
                    className="elementButtonModifyDelete"
                > <img
                        src={LogoModifica}
                        style={{ cursor: "pointer", "color": `red`, width: '1vw' }}
                        onClick={() => setShowModifyModal(true)}
                    /> <img
                        src={LogoElimina}
                        style={{ cursor: "pointer", "color": `green`, width: '1vw' }}
                        onClick={() => setShowDeleteModal(true)}
                    /></span>

            </div>
            <div
                className="elementReportColumnRight"
            >
                <div
                    className="boxSubReportCulimnRight"
                >
                    {
                        report.attivita[0]?.id ?
                            <ul>
                                {
                                    report?.attivita.map((el, index) => <li
                                        className={`${listAttPrevCompl.find(e => e.id === el.id)?.complated ? "elementAttivitaMainteneanceStoriCompleted" : "elementAttivitaMainteneanceStoriIncompleted"}`}
                                        key={index}
                                    >
                                        <h5>
                                            {interventoReport.attivita?.find(e => e.id === el.id)?.nomeAttivita}
                                            {
                                                interventoReport.attivita?.find(e => e.id === el.id)?.tempoStimato !== '' ?
                                                    "  (tempo stimato  " + interventoReport.attivita?.find(e => e.id === el.id)?.tempoStimato
                                                    + " min)"
                                                    :
                                                    ""
                                            }
                                            {
                                                el.timeEff !== '' && el.timeEff !== undefined ?
                                                    "  (tempo effettivo " + el.timeEff + "min)"
                                                    :
                                                    ""
                                            }

                                        </h5>

                                    </li>)
                                }
                            </ul>
                            :
                            <span></span>
                    }

                    <div
                        className="boxSubReportCulimnRight"
                    >
                        <h5>Eseguito in data: {dataFormatoGMA(report.dataIntervento)}</h5>
                        {
                            report.valoriLettura ?
                                <h5>Valori di lettura: {report.valoriLettura}</h5>
                                :
                                ""
                        }
                        {
                            durCompl !== 0 ?
                                <h5>Durata totale: {durCompl + " min"}</h5>
                                :
                                <span></span>
                        }
                        {
                            costCompl !== 0 ?
                                <h5>Costo intervento: {pointToeconomicalValue(costCompl)} €</h5>
                                :
                                <span></span>
                        }

                        <h5>Operatore: {report.operatore}</h5>
                        <h5>Nota: {report.note}</h5>
                    </div>

                </div>

            </div>
            {showDeleteModal ?
                <Modal show={showDeleteModal}>
                    <div className="bodydeleteModalIntervention">
                        <div className="boxAllDeleteModalIntervention">
                            <div className="boxTitleDeleteModalIntervention">
                                <h1> Sei sicuro di voler cancellare questo report?</h1>
                            </div>
                            <div className="deleteModalInterventionObjectButton">
                                <button className="elementButtonSubmitDeleteModalInterventionObjectButton" onClick={(e) => {
                                    e.target.disabled = true
                                    handleDeleteReport(report, setShowDeleteModal)
                                }}>
                                    Conferma
                                </button>
                                <button className="elementButtonSubmitDeleteModalInterventionObjectButton" onClick={() => setShowDeleteModal(!showDeleteModal)}>
                                    Annulla
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                :
                null
            }
            {
                showModifyModal ?
                    //va modificata la pagina del report affinche si veda lo storico degli interventi relativo allo specifico report e anche quelli passati
                    <ReportMainteneanceForm
                        //datiIntervento={report}
                        closeScheda={() => {
                            setShowModifyModal(false)
                        }}
                        mode={"modify"}
                        reportToModify={report}
                    />
                    :
                    <span></span>
            }
        </div>
    </li>
}


export const ElementoReportCompilare = () => {

    /**
     * in questo elemento passiamo un componente, barra azione che permette la riprogrammazione del report precedente
     */


}


