import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit'

const dataPlantAdapter = createEntityAdapter({
    selectId: (dataPlant) => dataPlant.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
})


const dataPlantSlice = createSlice({
    name: "dataPlant",
    initialState: dataPlantAdapter.getInitialState(),
    reducers: {
        dataPlantAddMany: dataPlantAdapter.addMany,
        dataPlantAddOne: dataPlantAdapter.addOne,
        dataPlantUpdateMany: dataPlantAdapter.updateMany,
        selectDataPlant(state, action) {
            const { plantId, period } = action.payload

            // prelevo i due elementi, il primo è quello che devo deselezionare mentre il secondo è quello da selezionare
            let deselect = Object.values(state.entities)[Object.values(state.entities).findIndex(el => el.selected === true)]

            /*TOLTO PERCHE' NON ESISTE DATA PLANT*/
            //deselect = deselect.selected = false
            deselect = false
            let select = Object.values(state.entities)[Object.values(state.entities).findIndex(el => el.idImpianto === plantId && el.period === period)]
            /*TOLTO PERCHE' NON ESISTE DATA PLANT*/
            //select = select.selected = true
            select = true
            // in questo modo faccio un unico update già ottimizzato da redux
            dataPlantAdapter.updateMany(state, [deselect, select])

        }
    }
})

export const {
    selectDataPlant,
    dataPlantAddMany
} = dataPlantSlice.actions

export default dataPlantSlice.reducer

export const { selectAll: slectedDataPlant } = dataPlantAdapter.getSelectors(state => state.dataPlant)

//selector con i dati passati in funzione dell'impianto selezionato
export const getSelectedDataPlant = createSelector(slectedDataPlant, state => state.filter(el => el.selected === true)[0])