import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { getMachineSelected } from './schedeMachineDetailSlice';



const faultAdapter = createEntityAdapter({
    selectId: (fault) => fault.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
})


const faultSlice = createSlice({
    name: "fault",
    initialState: faultAdapter.getInitialState(),
    reducers: {
        addManyFault: faultAdapter.addMany

    }
})


export default faultSlice.reducer

export const {
    addManyFault
} = faultSlice.actions


export const { selectAll: selectAllFault } = faultAdapter.getSelectors(state => state.fault)

export const selectFaultFamilyForSelectedMachienry = createSelector(
    [selectAllFault, getMachineSelected],
    (state, select) => {
        
        if (select) {
            
            return state.filter(el => el.famigliaId === select.family)
        }
    }
) 