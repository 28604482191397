import { titleColor } from "../../../configurations/generalStye"
import FormNewEvent from '../../formNewEvent/mainFormNewEvent'
import { useState } from "react"

const ElementListToSchedule = ({ id, backgroundColor, title, idMacchinario, nomeResponsabile, note, key,  saveFunction,  machineLocation, MachineBusinessUnit }) => {
    const [isOpenAction, setIsOpen] = useState(false)

    const setSingleIsOpenActionComponent = () => {
        setIsOpen(!isOpenAction)
    }
    
    return (
        <li
            style={{ "color": `${backgroundColor}` }}
            className="boxLiElementListStandard"
            key={id}
        >
            <div style={{ "flex": "3" }}>
                <div className="boxTitleEventList">
                    <h5
                        className="titleEventListTimeEvent"
                        style={{ "color": `${backgroundColor}` }}
                    >
                        {title}
                    </h5>

                    <h5
                        style={{ "fontWeight": "lighter", "marginLeft": "80px", "color": titleColor }}
                        className="titleEventListTimeEvent"
                    >{idMacchinario}
                    </h5>
                </div>
                <div
                    className="corpoEvent"
                >
                    <span style={{ "fontSize": "10px" }}>Responsabile: {nomeResponsabile}</span>
                    <br></br>
                    <span style={{ "fontSize": "10px" }}>Note: {note}</span>
                </div>
            </div>
            <div
                style={{ "flex": "1" }}
            >
                <button
                    onClick={() => setSingleIsOpenActionComponent(key)}
                >schedule</button>
                {
                    isOpenAction ?
                        <FormNewEvent
                        mode="add"
                        location={machineLocation}
                        machineIdentificator={idMacchinario}
                        businesUnit={MachineBusinessUnit}
                        closeScheda= {setSingleIsOpenActionComponent}
                        closingNumber={key}
                        />
                        :
                        <span></span>
                }
            </div>

        </li>
    )
}

export default ElementListToSchedule