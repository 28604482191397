import React from "react";
import { Link } from "react-router-dom";
import { selzioneColoreInversa } from '../../../functions/generiche'
import { titleColor } from "../../../configurations/generalStye";
import { pathExplorPlant, pathMachineryFaultList } from "../../../configurations/setting";

const RowListAlertOverview = ({
    Data, Name, Id, TypeAlert, Health, Reporting
}) => {



    return (
        <div className="rowGridListAlertOverview">
            <div
                className="elementTextRowGridListAlertOverview"
                style={{ color: `${titleColor}` }}
            >
                {Data}
            </div>
            <div
                className="elementTextRowGridListAlertOverview"
                style={{ color: `${titleColor}` }}
            >
                {Name}
            </div>
            <div
                className="elementTextRowGridListAlertOverview"
                style={{ color: `${titleColor}` }}
            >
                <Link
                    to={`${pathExplorPlant}/${Id}/${pathMachineryFaultList}`}
                >
                    {Id}
                </Link>
            </div>
            {/* <div
                className="elementTextRowGridListAlertOverview"
                style={{ color: `${titleColor}` }}
            >
                {TypeAlert}
            </div>
    */}
            <div
                className="elementHealthRowGridListAlertOverview"

            >
                <div
                    className="dimensionBackgrond"
                    style={{ backgroundColor: `${selzioneColoreInversa(Health)}` }}
                >
                    {`${Health}%`}
                </div>

            </div>
            {/*<div
                className="elementTextRowGridListAlertOverview"
                style={{ color: `${titleColor}` }}
            >
                {Reporting}
</div>*/}
        </div>
    )

}

export default RowListAlertOverview