import './mainListActivityTime.css'
import PersListActivityTime from './subElements/persListActivityTime'

/**
 * list: lista da mostrare 
 * nameList:nome della lista
 * isDate: data da mostrare numero giorno e mese
 * elementList:elemento della lista
 */


const ListActivityTime =({ nameList, isDate, children})=>{
    return(
        <PersListActivityTime 
            
            nameList={nameList}
            isDate={isDate}
            
        >
            {children}
        </PersListActivityTime> 
    )
}

export default ListActivityTime