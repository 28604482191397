import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { listDepartment, listLineas } from "../../../graphql/queries";
import { newBudget, updateBudget } from "../../../structures/root/slices/budgetSlice";
import { newDepartment, updateDepartment } from "../../../structures/root/slices/listDepartmentSlice"
import { modifyAssets, selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice";
import { selectAllLine } from "../../../structures/root/slices/listLineSlice";
import { pointToeconomicalValue } from "../../../functions/generiche";

const PersFormAddNewDepartment = ({ close, plantsList, departmentsList, listBudgets, stabilimento, mode, objectToModify, budgetToModify }) => {

    const dispatch = useDispatch()
    const listLine = useSelector(selectAllLine)
    const listMachinery = useSelector(selectAllMachinery)
    const [name, setName] = useState(mode === "add" ? null : objectToModify.name);
    const [plantsId, setPlantsId] = useState(mode === "add" ? stabilimento.id : objectToModify.idPlant);
    const [budget, setBudget] = useState(mode === "add" ? null : budgetToModify.value);
    const [maxBudget, setMaxBudget] = useState(null)

    //Gestione per il calcolo del Budget Massimo per il reparto lavorato
    useEffect(() => {

        if (plantsId != null && plantsId.length != 0 && plantsId != "") {

            calculateMaxBudget(plantsId)
        }
    }, [listBudgets, departmentsList, plantsList])
    //REFRESH AUTOMATICO QUANDO SI TROVANO SULLA STESSA MODIFICA
    useEffect(() => {
        setName(mode === "add" ? null : objectToModify.name)
        setBudget(mode === "add" ? null : budgetToModify.value)
        setPlantsId(mode === "add" ? stabilimento.id : objectToModify.idPlant)

    }, [objectToModify, budgetToModify])
    //Utility per il calcolo del budget massimo
    const calculateMaxBudget = (idPlant) => {


        let maxBudgetTmp = null
        if (listBudgets.filter(budget => budget.idStructure === idPlant).length === 1) {
            maxBudgetTmp = listBudgets.filter(budget => budget.idStructure === idPlant)[0].value

            let departmentsOfPlant = departmentsList?.filter(department => department.idPlant === idPlant);
            if (mode === "modify") {
                departmentsOfPlant = departmentsOfPlant.filter((department) => department.id !== objectToModify.id)
            }
            for (const department of departmentsOfPlant) {
                const currentDepartmentId = department.id;
                const matchingBudget = listBudgets.find(budget => budget.idStructure === currentDepartmentId);
                if (matchingBudget) {
                    const departmentsBudget = matchingBudget.value;

                    maxBudgetTmp = maxBudgetTmp - departmentsBudget
                } else {
                    setMaxBudget(null);
                    break; // Esci dal ciclo quando viene eseguito l'else
                }
            }

        } else {
            setMaxBudget(null)
        }
        if (maxBudgetTmp != null) {
            setMaxBudget(maxBudgetTmp)
        }
        else {
            setMaxBudget(null)
        }

    }




    //Gestione Creazione di un nuovo reparto
    const handleCreateDepartment = () => {
        // Add your validation logic here


        //Utility per validare un campo
        const validField = (fieldValue) => {
            return fieldValue != null && fieldValue.length > 0
        }



        if (validField(budget) && validField(name) && validField(plantsId)) {

            //if (budget <= maxBudget) {

            const response = dispatch(newDepartment(
                {
                    name: name,
                    idPlant: plantsId,
                }
            ))
            Promise.all([response])
                .then(ris => {
                    if (ris[0].meta.requestStatus !== "rejected") {
                        let idStructure = ris[0].payload.id


                        const response = dispatch(newBudget(
                            {
                                idStructure: idStructure,
                                value: budget,
                                year: new Date().getFullYear()
                            }
                        ))

                        close()
                    }else{
                        //alert("errore di rete, riprovare")
                    }
                }
                

                )
            /*}
            else {
                alert("il campo budget è errato")
            }*/
        }
        else {
            alert("tutti i campi sono obbligatori")
        }
    };
    const handleModifyReparto = () => {

        //Utility per validare un campo
        const validField = (fieldValue) => {
            return fieldValue != null && fieldValue.toString().length > 0
        }



        if (validField(budget) && validField(name) && validField(plantsId)) {

            //if (budget <= maxBudget) {
            //AGGIORNO DEPARTMENT
            const response = dispatch(updateDepartment(
                {
                    id: objectToModify.id,
                    name: name,
                    idPlant: plantsId,
                }
            ))
            //AGGIORNARE TUTTI I MACCHINARI CHE SI TROVANO SOTTO LE LINEE DI QUESTO DEPARTMENT CON IDPLANT E ID DEPARTMENT
            //MI SERVONO TUTTE LE LINEE CHE HANNO ID DEPARMENT QUESTO QUI
            const lineUnderDepartment = listLine.filter((line) => line.idDepartment === objectToModify.id)
            //TUTTI I MACCHINARI CHE HANNO COME ID LINE UNA DI QUELLE TROVATE SOPRA
            const assestToUpdate = listMachinery.filter((machinery) => lineUnderDepartment.map(item => item.id).includes(machinery.idLinea))
            let assestsToModify = []
            //UPDATE MASSIVO SUI MACCHINARI CON ID PLANT E ID DEPARTMENT
            assestToUpdate.map((singleAssets, index) => {
                assestsToModify.push(dispatch(modifyAssets(
                    {
                        id: singleAssets.id,
                        idLinea: listMachinery.filter((machinery) => machinery.id === singleAssets.id)[0].idLinea,
                        idPlant: plantsId,
                        idDepartment: objectToModify.id
                    }
                )))
            })

            Promise.all([...[response], ...assestsToModify])
                .then(ris => {
                    if (ris[0].meta.requestStatus !== "rejected") {
                        dispatch(updateBudget(
                            {
                                idStructure: budgetToModify.idStructure,
                                id: budgetToModify.id,
                                value: budget,
                                year: new Date().getFullYear()
                            }
                        ))

                        close()
                    } else {
                        //alert("errore di rete, riprovare")
                    }
                }
                )
            /*}
            else {
                alert("il campo budget è errato")
            }*/
        }
        else {
            alert("tutti i campi sono obbligatori")
        }
    }


    //Gestione Select degli stabilimenti
    const handleChangePlants = (e) => {

        calculateMaxBudget(e.target.value)
        setPlantsId(e.target.value)
    }

    //Gestione Select del budget
    const handleChangeBudget = (e) => {

        setBudget(e.target.value.length === 0 ? null : e.target.value
            //rimuove i punti quando presenti
            .replace(/[.]/g,"")
            //rimuove qualsiasi cosa non sia un numero
            .replace(/[^0-9]/g,""))
            
    }

    //Calcolo max budget per specifico Reparto



    useEffect(() => {

    }, [])

    return (
        <div className="bodyAddNewDepartment">
            <div className="boxAllAddNewDepartment">
                <div className="boxTitleAddNewDepartment">
                    <h1 className="elementTitleAddNewDepartment">{mode === "add" ? `Nuovo reparto` : `Modifica reparto `}</h1>
                    <h1
                        className="elementCloseSchedaAddNewAsset"
                        onClick={() => close()}
                    >x</h1>
                </div>

                <div className="boxFormAddNewDepartment">

                    <div className="boxColumnFromAddNewAsset boxBlockFormAddNewAsset">
                        <span className="elementoLableForm">Nome reparto</span>
                        <input
                            className="elementInputAddNewDepartment"
                            required
                            placeholder="Nome Reparto"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <span className="elementoLableForm">Stabilimento</span>
                        <select
                            className="elementInputAddNewDepartment"
                            required
                            placeholder="Stabilimenti"
                            onChange={(e) => handleChangePlants(e)}
                            value={plantsId}


                        >
                            <option value="" disabled>
                                Stabilimenti
                            </option>
                            {plantsList.map((el, index) => (
                                <option key={index} value={el.id}>
                                    {el.nome}
                                </option>
                            ))}
                        </select>
                        <span className="elementoLableForm">Budget {"(EUR)"}</span>
                        <div className="boxBudget">
                            <input
                                className="elementInputAddNewDepartmentBudget"
                                placeholder="Budget di manutenzione"
                                type="text"
                                required
                                value={pointToeconomicalValue(budget) }
                                onChange={(e) => handleChangeBudget(e)}
                            />

                            <div className={`budgetText 
                                    ${(budget != null && (maxBudget - budget) > 0) || (budget != null && (maxBudget - budget) === 0) ? 'correctBudget' :
                                    ((budget != null && (maxBudget - budget) < 0) || (budget === null && maxBudget < 0)) ? 'errorBudget'
                                        : ''}`}>

                                {/*CASO IN CUI NON E' STATO POSSIBILE CACOLARE IL BUDGET A CASA DI UN ERRORE*/}
                                {maxBudget === null && `Non è stato possibile calcolare il budget massimo`}

                                {/*CASO IN CUI NON E' STATO ANCORA DIGITATO NESSUN BUDGET*/}
                                {budget === null && maxBudget >= 0 && `Il budget massimo per questo reparto è ${pointToeconomicalValue(parseFloat(maxBudget))}€`}
                                {budget === null && maxBudget < 0 && `Il budget massimo dello stabilimento è stato ecceduto di ${pointToeconomicalValue(Math.abs(maxBudget))}€`}

                                {/*CASO IN CUI NON E' STATO DIGITATO UN BUDGET*/}
                                {budget != null && (maxBudget - budget) < 0 && `Il budget massimo dello stabilimento è stato ecceduto di ${pointToeconomicalValue(Math.abs(parseFloat(maxBudget) - parseFloat(budget)))}€`}
                                {budget != null && (maxBudget - budget) === 0 && `Budget massimo raggiunto! (Budget stabilimento rimanente: ${pointToeconomicalValue(parseFloat(maxBudget) - parseFloat(budget))}€)`}
                                {budget != null && (maxBudget - budget) > 0 && `Il budget inserito è nel limite consentito (Budget stabilimento rimanente: ${pointToeconomicalValue(parseFloat(maxBudget) - parseFloat(budget))}€)`}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="elementSpaceBetweenAddDepartment"></div>
                <div className="boxColumnFromAddNewDepartment">

                    {mode === "add" ?
                        <button
                            className="elementButtonSubmitFormAddNewDepartment"
                            onClick={handleCreateDepartment}
                        >
                            Aggiungi Reparto
                        </button>
                        :
                        <button
                            className="elementButtonSubmitFormAddNewStabilimento"
                            onClick={handleModifyReparto}

                        >
                            Modifica Reparto
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default PersFormAddNewDepartment