import React, { useRef,useEffect,useState } from 'react';
import ApexChart from 'react-apexcharts';
import { getChartDefaultOption, getChartOptions} from "./subElements/defaultOption";

/**
 * Componente LinearGraph
 *
 * @param {string} fontFamily - Famiglia del font per gli elementi di testo. [obbligatorio]
 * @param {Object} title - Oggetto di configurazione del titolo. [obbligatorio]
 *   @param {boolean} title.active - Indica se il titolo è attivo.
 *   @param {string} title.text - Contenuto testuale del titolo.
 *   @param {string} title.color - Colore del testo del titolo.
 *   @param {string} title.fontSize - Dimensione del font del titolo. Il default è '1vw'. [non obbligatorio]
 * @param {boolean} legend - Indica se mostrare la legenda. Il default è false. [non obbligatorio]
 * @param {boolean} percent - Indica se mostrare le percentuali. Il default è false. [non obbligatorio]
 * @param {Array<string>} asseX - Valori per l'asse X. [obbligatorio]
 * @param {Array<Object>} series - Array di oggetti serie. [obbligatorio]
 *   @param {Array<number>} series.data - Punti dati per la serie. [obbligatorio]
 *   @param {string} series.color - Colore della serie. [obbligatorio]
 *   @param {string} series.name - Nome della serie. [obbligatorio]
 *  NEW
 * @param {number} lineWidth - Numero intero che rappresenta lo spessore della linea, con valori compresi tra 1 e 5.
 * @param {string} fontSize - Font size dei valori sull'asse delle x, sull'asse delle y, del tooltip (impostato di default a 1vw)
 * @param {string} legendSize - Font size della leggenda (impostato di default a 1vw)
 */
const LinearGraphics = ({height,fontSize,legendSize,fontFamily,title,legend,asseX,series,percent,lineWidth}) => {

    const chartRef = useRef();
    const selectedSeries = useRef([])
    const onLegendClick = (chartContext, seriesIndex, config) =>{
        if (chartContext) {
            const isPresent = selectedSeries.current.includes(seriesIndex);

            if(!isPresent){
                selectedSeries.current=[...selectedSeries.current,seriesIndex]
            }else{
                let indice = selectedSeries.current.indexOf(seriesIndex);
                selectedSeries.current.splice(indice, 1);
            }

            const seriesElements = chartContext.el.querySelectorAll('.apexcharts-series');
            seriesElements.forEach((seriesElement, index) => {
                const opacity = seriesIndex === null || selectedSeries.current.includes(index) ? 1 : 0.3; // Adjust opacity as needed
                seriesElement.style.opacity = opacity;
            });

            const legendElement = chartContext.el.querySelector('.apexcharts-legend');
            const markerLegendElementApex = legendElement!=null ? legendElement.querySelectorAll('.apexcharts-legend-marker') : null
            markerLegendElementApex.forEach((markerLegendElementApexSingle, index) => {
                const opacity = seriesIndex === null || selectedSeries.current.includes(index) ? 1 : 0.3; // Adjust opacity as needed
                markerLegendElementApexSingle.style.opacity = opacity;
            });
        }
    }
    const lineWidthInput = lineWidth<=5 && lineWidth>=1?lineWidth:(lineWidth>=5)?5:1
    const defaultOptionsChart = getChartDefaultOption(lineWidthInput,onLegendClick);
    const optionsChart = getChartOptions(defaultOptionsChart,title,legend,asseX,series,fontSize,fontFamily,percent);
    const seriesChart = series.map(item => ({
        name: item.name,
        data: item.data
    }));

    useEffect(() => {

        setTimeout(() => {
            setDynamicValue()
        }, 200);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });


    const setDynamicValue = () => {


        if(chartRef.current) {
            const chartInstance = chartRef.current.chart;

            //LEGEND
            const legendElement = chartInstance.el.querySelector('.apexcharts-legend');
            const legendElementApex = legendElement!=null ? legendElement.querySelectorAll('.apexcharts-legend-text') : null
            const markerLegendElementApex = legendElement!=null ? legendElement.querySelectorAll('.apexcharts-legend-marker') : null

            if (legendElementApex != null) {
                const legendFontSize = legendSize!==undefined ? parseFloat(legendSize) : 1

                const legendFontPx=(legendFontSize* window.innerWidth)/100


                legendElementApex.forEach(function(legendElementApexSingle) {
                    legendElementApexSingle.style.cursor = "pointer";
                    legendElementApexSingle.style.fontSize = legendFontPx+"px";
                });
                markerLegendElementApex.forEach(function(markerLegendElementApexSingle) {
                    markerLegendElementApexSingle.style.width = legendFontPx+"px";
                    markerLegendElementApexSingle.style.height = legendFontPx+"px";
                });
            }

        }

    }
    const onResize = () => {
        setTimeout(() => {
            setDynamicValue()
        }, 200);
    }




    return (
        <>
            <ApexChart
                options={optionsChart}
                series={seriesChart}

                type={'line'}
                ref={chartRef}
                height={height !== undefined ? height : '100%'}
            />


        </>
    );
};

export default LinearGraphics;
