import './mainMap.css'
import React from 'react';
import LeafletMap from './subElements/leafletMap';
import { selectPlants, selectedPlant } from '../../structures/root/slices/plantSlice'
import { useSelector, useDispatch } from 'react-redux';
import { selectStructure } from '../../structures/root/slices/selectionForMetricheSlice';

const Mappa = () => {
    const plants = useSelector(selectPlants)
    console.log("lista impianti dal main ", plants)
    const dispatch = useDispatch()
    const selezioneImpianto = (plantid) => dispatch(selectedPlant(plantid))
    const selezioneImpiantoMetriche = (idStructure) => dispatch(selectStructure(idStructure))
    return (


        <LeafletMap
            plants={plants}
            selezioneImpianto={selezioneImpianto}
            selezioneImpiantoMetriche={selezioneImpiantoMetriche}
        />

    )
}

export default Mappa