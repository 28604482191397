


/***
 * DA FARE ANCORA IN FORMA DI BOZZA
 * 
 * DOC UTILE
 * https://redux.js.org/usage/writing-custom-middleware
 * https://stackoverflow.com/questions/68421040/local-storage-using-redux-toolkit
 */





/**
 * per gestire al meglio i dati in locale creare un middleware che quando avviene una nuova action salva i dati in locale e quando carichiamo la pagina prelevi i dati in locare prima di effettuare le richieste al back end va implementato tramite un middleware di redux
 */

/**
 * MIDDLEWARE PERSONALI 
 * 
 * Definiamo un middleware 
 * in redux un middleware è una metodo che si interpone nelle fasi del dispatch spesso utilizzato nelle chiamate asincrone. 
 * Questo metodo riceve in automatico il metodo dispatch e getState e restituisce una funzione.
 * viene utilizzato per effettuare azioni durante la creazione dello store e prima e dopo una dispatch  
 */
// function logger({getState, dispatch}){

//   /**
//    * viene invocato il middleware dopo la creazione dello store e non solo quando si avvia la catena dei middleware 
//    * richiama il prossimo middleware e a seguito tutti gli altri in pratica si genera una catena di richiami di funzioni
//    * middleware in funzione dell'ordine nella quale li inseriamo nella funzione applyMiddleware
//    * una volta avviato in la app in questo stato non passiamo più
//    * 
//    * possiamo eseguire azioni che prevedono l'inserimento di nuovi dati nella store o dei controlli sulla stessa
//    */
//   console.log('PRIMANE DI RICHIAMARE IL MIDDLEWARE');
//   return function (prossimo) {

//     /**
//      * viene invocato quando viene richiesta un azione ma quando ancora non è stata eseguita
//      * in questo stato rimaniamo in attesa di azioni che vogliono modificare lo stato
//      * 
//      * in questa fase possiamo metterci in attesa di una richiesta ad esempio di dati senza dover bloccare l'app
//      */
//     //console.log('DOPO PROSSIMO ')
//     return function (azione) {

//       /**
//        * a questo punto l'azione è stata richiesta 
//        * 
//        */
//       //console.log('PRIMA DELLA MODIFICA DELLO STATO')
//       let risultato = prossimo(azione); 
//       /**
//        * a questo punto l'azione è stata eseguita
//        * e lo store aggiornato
//        */
//       //console.log('DOPO LA MODIFICA DELLO STATO')
//       return risultato;

//     }
//   }
// }

/**
 * è possibile definire una forma breve del middleware come segue
 * 
 * la prima funzione prende in ingresso le due funzioni getState e dispatch che ritorna una funzione che richiede il 
 * prossimo middleware e dopo aver richiamato tutti i middleware esegue le action definite 
 */

//  const logger2 = ({getState, dispatch}) => prossimo => azione => {

//   //prima di richiamare il dispatch
//   //console.log('prima breve 2');
//   let risultato = prossimo(azione);
//   //console.log('dopo breve 2');
//   //dopo aver richiamato il dispatch 
//   return risultato;

//  }

//  const logger3 = ({getState, dispatch}) => prossimo => azione => {

//   //prima di richiamare il dispatch
//   //console.log('azione che si vuole eseguire', azione)

//   let risultato = prossimo(azione);

//   //console.log('effetto sullo store', risultato)
//   //dopo aver richiamato il dispatch 
//   return risultato;

//   }

/**
 * I MIDDLEWARE VENGONO RICHIAMATI COME SE FOSSERO FUNZIONI RICORSIVE CIOÈ COME NEL CASO PRECEDENTE :
 * 
 * PRIMO chiama SECONDO che chiama TERZO, TERZO esegue la action , SECONDO esegue la action e PRIMO esegue la action
 * 
 *      chiama     esegue
 * PRIMO   \        /
 *          \      /
 * SECONDO   \    /
 *            \  /
 * TERZO       \/
 */

/**
 * CREIAMO LO STORE
 * 
 * createStore prende in ingresso la funzione reducer che si occuperà di gestire l'aggiornamento dello stato,
 * uno stato preesistente da caricare nel momento della creazione dello store e un eancher cioè una funzione 
 * che permette di applicare dei servizi come l'utilizzo di plugin per il browser o dei middleware tramite applyMiddleware
 * 
 * applyMiddleware(logger , logger2, logger3)
 * 
 * aggiungiamo un middleware che permette la gestione delle chiamate asincrone
 * 
 */



export const PersistentMiddleware = (store) => (next) => (action) => {

 
  if(action.type.split("/")[0]==="schedaMachineDetail"){
    window.localStorage.setItem("schedeAperte", JSON.stringify(store.getState()["schedaMachineDetail"]));
  }
  

  return next(action);
};


