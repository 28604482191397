import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "../navBar/navBar";
import Menu from "../menu/mainMenu";
import Footer from "../../components/footer/mainFooter";


const Root = () => {
    let path = useLocation()


    return (
        <div >
            <Menu />
            <NavBar
                activePath={path.pathname}
            />
            <Outlet></Outlet>
           
        </div>
    )
}

export default Root