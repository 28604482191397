/**
 * metriche per il costo degli asset che hanno report con un costo intervento
 * 
 */

export const costoPerAsset = ({ listAsset, listReport, dateStart }) => {
    return listAsset
        .map((ass) => {

            let somma = 0
            //ricerco i report collegato all'asset e ne calcolo il totale
            listReport
                //filtro per macchianrio
                .filter(el => el.idMachinery === ass.id)
                //filtro per periodo considerando gli interventi dell'ultimo anno
                //CREARE IL RIFERIEMNTO RISPETTO ALL'ANNO CORRENTE, ALL'ULTIMO MESE E ALL'ULTIMA SETTIMANA
                .filter(el => el.dataIntervento >= dateStart)
                .filter(el => el.isDeleted !== true)
                .forEach(e => somma = somma + Number(e.costo))

            return {
                id: ass.id,
                costiInterventi: somma
            }
        })
}