/**
 * qunado si fa il reset password la nuova password si deve salvare e bisogna ritornare al loggin
 * 
 * verifica che nuova password sia diversa diversa dalla vecchia
 * 
 * verifica nel nome utente che non sia la mail inserita per l'accesso
 */


import { useEffect, useState } from 'react';
import LogoAIM from '../../../icon/logo.svg';
import MainMain from '../../../structures/root/mainMain';
import { Auth } from 'aws-amplify';
import React from 'react';

const MainLogin = () => {



    const [user, setUser] = useState(null)
    /**
     * stateType è impostato per gestire i seguenti stati: singIn, signedIn, resetPassword, changePasswd
     * singIn: utente non loggato
     * signedIn: utente loggato
     * resetPassword: quando viene fatta una richiesta di reset passwor da back end e l'utente deve reinserirla
     * changePasswd: al primo accesso viene fornita una password di default ed al primo accesso l'untente deve modificarla
     */
    const [stateType, setStateType] = useState("singIn")

    useEffect(() => {
        checkUser()
    }, [stateType])












    const checkUser = async () => {

        if (stateType === "changePasswd" || stateType === "resetPassword") {

        } else {

            try {
                const user = await Auth.currentAuthenticatedUser()
                setUser(user)

                setStateType('signedIn')


            } catch (err) {
                if (err === "The user is not authenticated") {

                    setStateType('singIn')
                }

                setUser(null)

            }
        }
    }

    const renderError = ({ mess = "" }) => {
        if (mess.length !== 0) {

            if (mess !== "Password reset required for the user") {
                if (mess === "The user is not authenticated") { }
                else { return <span style={{ color: 'red' }}>{mess}</span> }

            } else {
                return <span style={{ color: 'blue' }}>Contatta l'amministratore di sistema</span>
            }
        } else {

        }

    }


    const renderElement = () => {

        let element = null
        if (stateType === 'singIn') {
            element = <SingIn
                renderError={renderError}
                setStateType={setStateType}
                setUser={setUser}

            />
        } else {
            if (stateType === 'changePasswd') {
                element = <ChangePassword
                    setUser={setUser}
                    user={user}
                    renderError={renderError}
                    setStateType={setStateType}
                />
            } else {
                if (stateType === 'resetPassword') {

                    element = <ResetPassword

                        setUser={setUser}
                        user={user}
                        renderError={renderError}
                        setStateType={setStateType}
                    />
                } else {
                    if (stateType === 'signedIn') {
                        element = <MainMain
                            user={user}
                        />
                    }
                }
            }
        }
        return element
    }



    const SingIn = ({ renderError, setStateType, setUser }) => {
        const [userName, setUserName] = useState("")
        const [passwd, setPasswd] = useState("")
        const [messageErr, setMessageErr] = useState("")

        const singIn = async ({ userName, passwd, setStateType, setUser }) => {
            try {
                let a = await Auth.signIn(userName, passwd)

                if (a.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setUser(a)
                    setStateType('changePasswd')
                    setUserName("")
                    setPasswd("")
                } else {


                    setStateType('signedIn')

                    setUserName(a.attributes.name)

                }
            } catch (err) {

                if (err === "Password reset required for the user") {

                    setStateType('resetPassword')

                } else {
                    if (err.code === "UserNotFoundException") {
                        setMessageErr("Utente e password errati")
                    } else {
                        if (err.code === "NotAuthorizedException") {
                            setMessageErr("Utente e password errati")
                        } else {

                            if (err.name === "PasswordResetRequiredException") {
                                setStateType('resetPassword')
                            }
                        }
                    }

                }
            }




        }


        return (
            <div
                style={{ height: "100%", marginTop: "7vw" }}
                onKeyDown={ev => {
                
                    if (ev.code === "Enter") {
                        singIn({ userName, passwd, setStateType, setUser })
                    }
                }}
            >

                <div

                    id="BoxImmaForm">

                    <img
                        alt="logo login"
                        id="ImmagineTrasf"
                        src={LogoAIM}


                    />



                    <div id="FormLogin" key="login">

                        <div >
                            <h1
                                style={{ textAlign: "center", fontSize: "2.2vw" }}
                            >Predeep</h1>
                            <h4 style={{ fontSize: "1.2vw" }}>Il tuo software di manutenzione predittiva</h4>
                        </div>
                        <h1 id="StcrittaLOGIN">LOGIN</h1>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '1vw'
                            }}
                        >
                            <span className='elementLableLogin'>
                                Username
                            </span>
                            <input
                                className="FormGrupInput"
                                onChange={(e) => setUserName(e.target.value)}
                                type="email"
                                name="userName"
                                autoComplete="true"
                                value={userName}
                                placeholder="mail di accesso"

                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >

                            <span className='elementLableLogin'>
                                Password
                            </span>
                            <input
                                className="FormGrupInput"
                                onChange={e => setPasswd(e.target.value)}
                                type="password"
                                name="password"
                                autoComplete="true"
                                placeholder="password "
                                value={passwd}
                            />


                        </div>


                        {
                            renderError({ mess: messageErr })
                        }
                        <div className="buttonStyle"
                            onClick={() => singIn({ userName, passwd, setStateType, setUser })


                            }
                        >
                            Accedi

                        </div>

                    </div>


                </div>

            </div>
        )
    }

    const ChangePassword = ({ renderError, user, setUser }) => {
        const [newName, setNewName] = useState("")
        const [newPasswd, setNewPasswd] = useState("")
        const [messageErr, setMessageErr] = useState("")

        const confirmNewPassword = async (user, newPasswd, newName, setUser, setMessageErr) => {
            try {

                let b = await Auth.completeNewPassword(
                    user,
                    newPasswd,
                    {
                        name: newName
                    }
                )


                setStateType('signedIn')
                setUser(b)
            }
            catch (err) {

                setMessageErr(err.log)
            }
        }


        return (
            <div
                style={{ height: "100%", marginTop: "7vw" }}
                onKeyDown={ev => {
                    if (ev.code === "Enter") {
                        if (newPasswd.match("^(?=.*?[a-z])(?=.*?[A-Z])(?=.*[0-9]).{8,}$")) {
                            confirmNewPassword(user, newPasswd, newName, setUser, setMessageErr)
                        } else {
                            alert("La password non ha tutti i criteri:\n- Deve essere lunga almeno 8 caratteri\n- Deve contenere almeno una lettera mauiscola\n- Deve contenere almeno una lettera minuscola\n- Deve almeno contentere un numero")
                        }
                    }
                }
                }
            >
                <div

                    id="BoxImmaForm">

                    <img
                        alt="logo login"
                        id="ImmagineTrasf"
                        src={LogoAIM}


                    />



                    <div id="FormLogin">

                        <div >
                            <h1
                                style={{ textAlign: "center" }}
                            >Predeep</h1>
                            <h4>Il tuo software di manutenzione predittiva</h4>
                        </div>
                        <h1 id="StcrittaLOGIN">New Password</h1>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '1vw'
                            }}
                        >

                            <span className='elementLableLogin'>
                                Nome utente
                            </span>
                            <input
                                className="FormGrupInput"
                                onChange={(e) => setNewName(e.target.value)}
                                type="nname"
                                name="newName"
                                autoComplete="false"
                                placeholder="Nome utente"
                            />
                        </div>



                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >

                            <span className='elementLableLogin'>
                                Nuova password
                            </span>
                            <input
                                className="FormGrupInput"
                                onChange={(e) => setNewPasswd(e.target.value)}
                                type="npassword"
                                autoComplete="false"
                                name="newPassword"

                                placeholder="Nuova Password "
                            />
                        </div>


                        {
                            renderError({ mess: messageErr })
                        }

                        <div
                            className="buttonStyle"
                            onClick={() => {
                                if (newPasswd.match("^(?=.*?[a-z])(?=.*?[A-Z])(?=.*[0-9]).{8,}$")) {
                                    confirmNewPassword(user, newPasswd, newName, setUser, setMessageErr)
                                } else {
                                    alert("La password non ha tutti i criteri:\n- Deve essere lunga almeno 8 caratteri\n- Deve contenere almeno una lettera mauiscola\n- Deve contenere almeno una lettera minuscola\n- Deve almeno contentere un numero")
                                }
                            }
                            }
                        >
                            Conferma ed accedi
                        </div>



                    </div>


                </div>


            </div >
        )
    }

    const ResetPassword = ({ renderError, setUser, setStateType }) => {
        const [userName, setUserName] = useState("")
        const [newPasswd, setNewPasswd] = useState("")
        const [code, setCode] = useState("")
        const [messageErr, setMessageErr] = useState(null)

        const resetPassword = async (userName, newPasswd, code, setS) => {
            try {

                const b = await Auth.forgotPasswordSubmit(
                    userName,
                    code,
                    newPasswd
                )


                setS('signedIn')

                //let a = await Auth.signIn(userName, newPasswd)
                setUser(b)



            }
            catch (err) {
                setMessageErr(err.name)
            }
        }



        return (<div
            style={{ height: "100%", marginTop: "7vw" }}
            onKeyDown={ev => {
                if (ev.code === "Enter") {
                    if (newPasswd.match("^(?=.*?[a-z])(?=.*?[A-Z])(?=.*[0-9]).{8,}$")) {
                        resetPassword(userName, newPasswd, code, setStateType)
                    }
                } else {
                    alert("La nuova password non ha tutti i criteri:\n- Deve essere lunga almeno 8 caratteri\n- Deve contenere almeno una lettera mauiscola\n- Deve contenere almeno una lettera minuscola\n- Deve almeno contentere un numero")
                }
            }}
        >
            <div

                id="BoxImmaForm">

                <img
                    alt="logo login"
                    id="ImmagineTrasf"
                    src={LogoAIM}


                />



                <div id="FormLogin">

                    <div >
                        <h1
                            style={{ textAlign: "center" }}
                        >Predeep</h1>
                        <h4>Il tuo software di manutenzione predittiva</h4>
                    </div>
                    <h1 id="StcrittaLOGIN">Reset Password</h1>


                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '1vw'
                        }}
                    >

                        <span className='elementLableLogin'>
                            Username
                        </span>
                        <input
                            className="FormGrupInput"
                            onChange={(e) => setUserName(e.target.value)}
                            type="Newname"
                            name="NewuserName"
                            autoComplete="off"
                            placeholder="Email utente"

                        />
                    </div>



                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '1vw'

                        }}
                    >

                        <span className='elementLableLogin'>
                            Codice
                        </span>

                        <input
                            className="FormGrupInput"
                            onChange={(e) => setCode(e.target.value)}
                            type="text"
                            autoComplete="off"

                            name="codice"
                            placeholder="Codice"
                        />
                    </div>


                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >

                        <span className='elementLableLogin'>
                            Nuova password
                        </span>
                        <input
                            className="FormGrupInput"
                            onChange={(e) => setNewPasswd(e.target.value)}
                            type="Newpassword"
                            name="Newpassword"
                            autoComplete="off"

                            placeholder="Nuova Password "
                        />
                    </div>


                    {messageErr ?
                        renderError({ mess: messageErr })
                        :
                        <span></span>
                    }

                    <div
                        className="buttonStyle"

                        onClick={() => {
                            if (newPasswd.match("^(?=.*?[a-z])(?=.*?[A-Z])(?=.*[0-9]).{8,}$")) {
                                resetPassword(userName, newPasswd, code, setStateType)
                            }
                            else {
                                alert("La nuova password non ha tutti i criteri:\n- Deve essere lunga almeno 8 caratteri\n- Deve contenere almeno una lettera mauiscola\n- Deve contenere almeno una lettera minuscola\n- Deve almeno contentere un numero")
                            }
                        }}
                    >
                        Reset ed accedi
                    </div>



                </div>


            </div>


        </div>)
    }





    return (
        <React.Fragment>

            {renderElement()}
        </React.Fragment>

    )





}


export default MainLogin
