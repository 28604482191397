import React, { useEffect, useState } from "react";
import './mainUserForm.css'
import Input from "../../../components/userManagement/input/Input";
import Select from "../../../components/userManagement/select/Select";
import InputPhone from "../../../components/userManagement/input/inputPhone"

import { useSelector } from "react-redux";
import { selectPlant } from "../../root/slices/plantSlice";
import { selectAllDepartment } from "../../root/slices/listDepartmentSlice";
import { selectAllLine } from "../../root/slices/listLineSlice";
import { selectAllUsers } from "../../root/slices/usersSlice";


const UserForm = ({ user, setUserState }) => {


    const options = [
        { value: 'Admin', label: 'Administrator' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Operator', label: 'Operator' }
    ];
    const [phone_number, setPhoneNumber] = useState("")
    const [roleUser, setRoleUser] = useState(options.find(e => e.value === user?.role.value) ? options.find(e => e.value === user.role.value) : undefined)
    const [name, setName] = useState(user?.name.value ? user?.name.value : "")
    const [email, setEmail] = useState(user ? user?.name.subValue : "")
    const [errMail, setErrMail] = useState(false)
    const [activeMailVerify, setActiveMailVerify] = useState(false)
    const [idStructure, setStructure] = useState(user?.role.subValue ? user?.role.subValue : "")

    const listUsers = useSelector(selectAllUsers)
    console.log(listUsers)
    const validateEmail = (em) => {

        return em.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) && !listUsers.find(el => el.name.subValue === em)
    };

    const listAllPlant = useSelector(selectPlant)
    const [plantSelected, setPlantSelected] = useState({ value: "", label: "" })
    const listAllDep = useSelector(selectAllDepartment)
    const [filterDepList, setFilterDepList] = useState(listAllDep)
    const [depSelected, setDepSelected] = useState({ value: "", label: "" })
    const listAllLine = useSelector(selectAllLine)
    const [filterLineList, setFilterLineList] = useState(listAllLine)
    const [linSelected, setLinSelected] = useState({ value: "", label: "" })

    useEffect(() => {
        setUserState({
            name, email, roleUser, idStructure, phone_number
        })
    }, [name, email, roleUser, idStructure, phone_number])
    useEffect(() => {
        //se la struttura collegata ad un utente è un impianto
        if (listAllPlant.find(plt => plt.id === idStructure)) {
            //selezioniamo l'impianto
            setPlantSelected({ value: listAllPlant.find(plt => plt.id === idStructure).id, label: listAllPlant.find(plt => plt.id === idStructure).nome })
            //passiamo alle selezioni successive l'elenco dei dipartimenti collegati
            const tempdep = listAllDep.filter(dep => dep.idPlant === idStructure)
            setFilterDepList(tempdep)
            //passiamo alle seleizioni successive l'elenco delle line collegate

            setFilterLineList(listAllLine.filter(lin => tempdep.find(dep => lin.idDepartment === dep.id)))

            //azzeriamo le selezioni di dipsrtimento ed impianto
            setDepSelected({ value: "", label: "" })
            setLinSelected({ value: "", label: "" })


        } else if (listAllDep.find(dep => dep.id === idStructure)) {
            //se la struttura collegata ad un utente è un dipartimento
            //seleziono il dipartimento
            const tempPlant = listAllPlant.find(plt => listAllDep.find(dep => dep.id === idStructure).idPlant === plt.id)
            setDepSelected({ value: listAllDep.find(dep => dep.id === idStructure).id, label: listAllDep.find(dep => dep.id === idStructure).name })
            //seleziono l'impianto del dipartimento
            setPlantSelected({ value: tempPlant.id, label: tempPlant.nome })
            //imposto la lista dei dei dipartimenti al solo elemento selezionato
            setFilterDepList(listAllDep.filter(dep => dep.idPlant === tempPlant.id))
            //imposto la lista delle linee a solo quelle del dipartimento
            setFilterLineList(listAllLine.filter(lin => lin.idDepartment === idStructure))
            //azzero la selezione della linea
            setLinSelected({ value: "", label: "" })
        } else if (listAllLine.find(lin => lin.id === idStructure)) {
            //se la struttura collegata ad un utente è una linea
            const line = listAllLine.find(lin => lin.id === idStructure)
            const dip = listAllDep.find(e => e.id === line.idDepartment)
            const plt = listAllPlant.find(e => e.id === dip.idPlant)
            setLinSelected({ value: listAllLine.find(lin => lin.id === idStructure).id, label: listAllLine.find(lin => lin.id === idStructure).name })
            setDepSelected({ value: dip.id, label: dip.name })
            setPlantSelected({ value: plt.id, label: plt.nome })
            setFilterDepList(listAllDep.filter(dep => dep.idPlant === plt.id))
            setFilterLineList(listAllLine.filter(lin => lin.idDepartment === dip.id))
        } else {
            //se la struttura non è collegata a nulla di collegato
            setPlantSelected({ value: "", label: "" })
            setDepSelected({ value: "", label: "" })
            setLinSelected({ value: "", label: "" })
            setFilterDepList(listAllDep)
            setFilterLineList(listAllLine)
        }
    }, [idStructure])

    useEffect(() => {
        if (activeMailVerify) {
            if (validateEmail(email)) {
                setErrMail(false)
            } else {
                setErrMail(true)
            }
        }
    }, [activeMailVerify])





    return (
        <>
            <div className="mainFormUserSectionBodyHeader">
                <h1
                    style={{
                        margin: "2.2222vw 0px 1.9vw",
                        fontSize: "1.4vw",
                    }}
                > User info</h1>
                <div className="divider" />
            </div>
            <div className="mainFormUserSectionBodyContent">

                <form className={"form"}>
                    <div className="form-group">
                        <div className="form-label">
                            <label htmlFor="email">Name</label>
                        </div>
                        <div className={"form-double"}>
                            <Input type="text" placeholder="Enter name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}


                            />

                        </div>
                        <div className="divider" />
                    </div>

                    <div className="form-group">
                        <div className="form-label">
                            <label htmlFor="email">Email address</label>
                        </div>
                        <div className={"form-single"}>
                            <Input
                                type="email"
                                className={`input email ${errMail ? "errorInput" : ""}`}
                                placeholder="Enter email"
                                value={email}
                                style={{ "borderColor": `${errMail ? "red" : ""}` }}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={user ? true : false}
                                onBlur={() => setActiveMailVerify(true)}
                                onFocus={() => setActiveMailVerify(false)}


                            />
                        </div>
                        <div className="divider" />
                    </div>

                    <div className="form-group">
                        <div className="form-label">
                            <label>Phone number</label>
                        </div>
                        <div className={"form-single"}>
                            <InputPhone
                                type="tel"
                                placeholder="Enter Phone Number"
                                defaultValue={user?.phoneNumber.value}
                                onChange={setPhoneNumber}


                            />
                        </div>
                        <div className="divider" />
                    </div>

                    <div className="form-group">
                        <div className="form-label">
                            <label>Role</label>
                        </div>
                        <div className={"form-single"}>
                            <Select
                                options={options}
                                placeholder="Select Role"
                                selected={roleUser}
                                onChange={setRoleUser}
                            />
                        </div>
                        <div className="divider" />

                    </div>

                    <div className="form-group">
                        <div className="form-label">
                            <label>Plant</label>
                        </div>
                        <div className={"form-single"}>
                            <Select
                                placeholder="Select Plant"
                                options={listAllPlant.map(plt => { return { value: plt.id, label: plt.nome } })}
                                selected={plantSelected?.value === "" ? undefined : plantSelected}
                                onChange={(e) => setStructure(e.value)}
                            />
                        </div>
                        <div className="divider" />
                    </div>
                    <div className="form-group">
                        <div className="form-label">
                            <label>Department</label>
                        </div>
                        <div className={"form-single"}>
                            <Select
                                placeholder="Select Department"
                                options={filterDepList.map(dep => { return { value: dep.id, label: dep.name } })}
                                selected={depSelected?.value === "" ? undefined : depSelected}

                                onChange={(e) => setStructure(e.value)}
                            />
                        </div>
                        <div className="divider" />

                    </div>
                    <div className="form-group">
                        <div className="form-label">
                            <label>Linea</label>
                        </div>
                        <div className={"form-single"}>
                            <Select
                                placeholder="Select Linea"
                                options={filterLineList.map(lin => { return { value: lin.id, label: lin.name } })}
                                selected={linSelected?.value === "" ? undefined : linSelected}
                                onChange={(e) => setStructure(e.value)}
                            />
                        </div>
                    </div>

                </form>

            </div>
        </>
    )


}

export default UserForm;