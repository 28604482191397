import { useEffect, useState } from "react"
import { v4 as uuidv4 } from 'uuid'


export const AttivitaRegistrata = ({ nomeAttivita, tempoStimato, indice, modificaAttività }) => {
    return (
        <div
            className="boxFlexBasic"
        >
            <div
                className="boxActivityInserimentoEvento boxWidthPartial"
            >

                <h5 className="descrizioneAttivita" >{nomeAttivita}</h5>
                {tempoStimato ?
                    <h5 className="descrizioneAttivita elementCostrictInline" >{tempoStimato} min</h5>
                    :
                    <span></span>
                }

            </div>
            <div
                className="elementDeleteActivity"
                onClick={() => {
                    modificaAttività(prev => prev.filter((el, index) => indice !== index))
                }}
            >X</div>
        </div >


    )
}
export const NuovaAttivita = ({ listaAttivita, aggiungiAttivita, setPresenzaAttivita, verificaPresenzaAttivita }) => {
    const [nome, setNome] = useState("")
    const [time, setTime] = useState("")
    const [alertNome, setAlertNome] = useState(false)
    useEffect(() => {
        if (nome !== "") {
            setPresenzaAttivita(true)
        }else{
            setPresenzaAttivita(false)
        }
    }, [nome])
    return (
        <div
            className="boxActivityInserimentoEvento"
        >
            <div
                className="boxDescrizioneAttivita"

            >
                <h5 className="descrizioneAttivita" >Nome attività</h5>
                <input
                    className={`${alertNome ? "alertInput" : ""} ${verificaPresenzaAttivita ? "alertInput" : ""} elementInputFormNewEvent correctionSpaceelementInputFormNewEvent`}
                    onChange={(e) => setNome(e.target.value)}
                    value={nome}
                />
            </div>


            <div
                className="boxTempoAttivita"
            >
                <h5 className="descrizioneAttivita" >Tempo stimato</h5>

                <input
                    type="number"
                    className="elementInputFormNewEvent correctionSpaceelementInputFormNewEvent"
                    onChange={(e) => setTime(parseFloat(e.target.value))}
                    value={time}
                    placeholder="minuti"
                />
            </div>


            <span
                className="elementAddActivity"
                onClick={() => {
                    if (nome !== '') {
                        setAlertNome(false)
                        let idControl = uuidv4().slice(0, 8)
                        let index = 1
                        while (listaAttivita.find(el => el.id === idControl)) {
                            index = index + 1
                            idControl = uuidv4().slice(0, 8)

                            if (index > 30) {
                                break
                            }
                        }
                        aggiungiAttivita(prev => [{ nomeAttivita: nome, tempoStimato: time, id: idControl }, ...prev])
                        setNome("")
                        setTime("")
                    } else {
                        setAlertNome(true)
                    }
                }}
            >+</span>
        </div>
    )
}
