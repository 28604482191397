import React, { useState } from 'react';
import './RadioButtonGroup.css'; // Importa il file CSS per lo stile dei radio button

/**
 * Componente RadioButtonGroup per la gestione di gruppi di radio button.
 *
 * @param {Array} options - Opzioni disponibili per i radio button.
 * @param {Function} onChange - Funzione da chiamare quando viene selezionata un'opzione.
 * @param {string} selected - Opzione selezionata di default.

 */

const RadioButtonGroup = ({ options, onChange, selected }) => {
    const [selectedOption, setSelectedOption] = useState(selected);

    /**
     * Funzione per gestire il cambio di opzione.
     *
     * @param {Object} option - Opzione selezionata.
     */
    const handleOptionChange = (option) => {
        // Aggiorna lo stato selezionato prima di chiamare onChange
        setSelectedOption(option.value);

        // Chiama la funzione onChange se è stata fornita
        if (onChange) {
            onChange(option);
        }
    };

    return (
        <div className="radio-button-group">
            {options.map((option, index) => (
                <label key={index}
                       className={`radio-button ${selectedOption === option.value ? 'selected ' : ''}${option.type ? option.type : ""}`}>
                    <input
                        type="radio"
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={() => handleOptionChange(option)}
                    />
                    {option.label}
                </label>
            ))}
        </div>
    );
};

export default RadioButtonGroup;
