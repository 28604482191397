import React from "react";
import './Breadcrumb.css';
import { Link } from "react-router-dom";

/**
 * Componente Breadcrumb per la navigazione sequenziale.
 *
 * @param {Object[]} items - Elementi del breadcrumb.
 * @param {string} items[].href - URL di destinazione dell'elemento.
 * @param {string|JSX.Element} items[].title - Titolo dell'elemento.
 * @param {string} items[].label - Etichetta aggiuntiva per l'elemento.
 * @param {boolean} items[].badge - Indica se l'elemento è un badge.
 */

const Breadcrumb = ({ items }) => {
    return (
        <ol className="breadcrumb">
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    {index !== 0 && (
                        <li className="breadcrumb-separator">
                            <svg width="1vw" height="0.9vw" viewBox="0 0 6 10" fill="none"
                                 style={{
                                     display: "block"
                                 }}
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 9L5 5L1 1" stroke="#D0D5DD" strokeWidth="1.33333" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </li>
                    )}

                    {index === 0 && (
                        <>
                            <svg width="1.5vw" height="1.5vw" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.66667 14.1667H13.3333M9.18141 2.30333L3.52949 6.69927C3.15168 6.99312 2.96278 7.14005 2.82669 7.32405C2.70614 7.48704 2.61633 7.67065 2.56169 7.86588C2.5 8.08627 2.5 8.32558 2.5 8.80421V14.8333C2.5 15.7667 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1585 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1585 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7667 17.5 14.8333V8.80421C17.5 8.32558 17.5 8.08627 17.4383 7.86588C17.3837 7.67065 17.2939 7.48704 17.1733 7.32405C17.0372 7.14005 16.8483 6.99312 16.4705 6.69927L10.8186 2.30333C10.5258 2.07562 10.3794 1.96177 10.2178 1.918C10.0752 1.87938 9.92484 1.87938 9.78221 1.918C9.62057 1.96177 9.47418 2.07562 9.18141 2.30333Z"
                                    stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                            <li className="breadcrumb-separator"
                                style={{
                                    marginLeft: "1.4vw"
                                }}
                            >
                                <svg width="1vw" height="0.9vw" viewBox="0 0 6 10" fill="none"
                                     style={{
                                         display: "block"
                                     }}
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 9L5 5L1 1" stroke="#D0D5DD" strokeWidth="1.33333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </li>
                        </>
                    )}

                    <li className="breadcrumb-item">
                        {item.badge ? (
                            <div className={"breadcrumb-badge"}>
                                {item.href ? (
                                    <Link className="breadcrumb-link" to={item.href}>
                                        {item.title}
                                    </Link>
                                ) : (
                                    <span className="breadcrumb-link">
                                        {typeof item.title === "string" ? (
                                            item.title
                                        ) : (
                                            <>
                                                {item.title}
                                                <span>{item.label}</span>
                                            </>
                                        )}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div className={"breadcrumb-default"}>
                                {item.href ? (
                                    <Link className="breadcrumb-link" to={item.href}>
                                        {item.title}
                                    </Link>
                                ) : (
                                    <span className="breadcrumb-link">
                                        {typeof item.title === "string" ? (
                                            item.title
                                        ) : (
                                            <>
                                                {item.title}
                                                <span>{item.label}</span>
                                            </>
                                        )}
                                    </span>
                                )}
                            </div>
                        )}
                    </li>
                </React.Fragment>
            ))}
        </ol>
    );
};

export default Breadcrumb;
