import React from "react";
import './AvatarProfile.css';

const AvatarProfileTable = ({img = ''}) => {
    
    return (
        <>
            {img !== '' ? (
                <img src={img} alt="Avatar" className="avatar-table-default"/>
            ) : (
                <div className="avatar-table">
                    <svg width="3vw" height="3vw" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.45vw" cy="1.45vw" r="1.45vw" fill="rgba(242, 244, 247, 1)"
                                stroke="rgba(0, 0, 0, 0.08)" strokeWidth="0.75"/>

                        <svg x="-0.1vw" y="-0.1vw" width="3vw" height="3vw" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <svg x="25%" y="25%" width="50%" height="50%" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
                                    stroke="#667085" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                        </svg>
                    </svg>
                </div>
            )}
        </>
    );
};

export default AvatarProfileTable;
