import React, { useState } from "react";
import './verticalMenu.css'
import LogoElimina from "../../../icon/basket.svg";
import LogoModifica from "../../../icon/update.svg";
import {titleColor} from "../../../configurations/generalStye";
import AddNewReparto from "../../addNewReparto/mainAddNewReparto";
import Modal from "../../DialogModal/mainDialogModal";
import DeleteObject from "./deleteObject";
import AddNewAsset from "../../addNewAsset/mainAddNewAsset";
import AddNewStabilimento from "../../addNewStabilimento/mainAddNewStabilimento";
import AddNewLinea from "../../addNewLinea/mainAddNewLinea";
const VerticalMenu = ({ object,type,listDepartment,listLine,listMachinery,listPlant,listBudgets,listDatiTarga }) => {



    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isDeleteMode, setIsDeleteMode] = useState(false)

    const [modifyPlant, setModifyPlant] = useState(false)
    const [modifyDepartment, setModifyDepartment] = useState(false)
    const [modifyLine, setModifyLine] = useState(false)
    const [modifyAsset,setModifyAsset] = useState(false)
    const taggleOpenMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }
    const handleModifyObject = () =>{
        if(type==="plant"){
            setModifyPlant(true)
        }
        else if(type==="department"){
            setModifyDepartment(true)
        }
        else if(type==="line"){
            setModifyLine(true)
        }
        else if(type==="machinery"){
            setModifyAsset(true)
        }
    }


    return (
        <div className="">

                <div className='menu-container'
                     onClick={() => taggleOpenMenu()}
                     tabIndex="0"
                     onBlur={() => setTimeout(()=>setIsMenuOpen(false), 300)}
                >
                    <div className="ball" ></div>
                    <div className="ball" ></div>
                    <div className="ball" ></div>

            </div>
            {isMenuOpen ?

                <div className="bodyVerticalMenu">
                    <div className="menuElement">
                        <img
                            src={LogoModifica}
                            style={{"color": `${titleColor}`, width: '1vw'}}
                        />
                        <h3 onClick={(e)=>handleModifyObject() } className="testoMenuElement" style={{"color": `${titleColor}`}}>
                            Modifica
                        </h3>
                    </div>
                    <div className="menuElement">
                        <img
                            src={LogoElimina}
                            style={{"color": `${titleColor}`, width: '1vw'}}
                        />
                        <h3 onClick={(e)=>setIsDeleteMode(true) } className="testoMenuElement" style={{"color": `${titleColor}`}}>
                            Elimina
                        </h3>
                    </div>
                </div>

                :
                <span></span>
            }

            {isDeleteMode ?
                <Modal show={isDeleteMode}>
                    <DeleteObject
                        type={type}
                        object={object}
                        listPlant={listPlant}
                        listDepartment={listDepartment}
                        listLine={listLine}
                        listMachinery={listMachinery}
                        listBudgets={listBudgets}
                        close={() => setIsDeleteMode(!isDeleteMode)}
                    />
                </Modal>
                :
                null
            }
            {modifyPlant ?
                <Modal
                    show={modifyPlant}
                >

                    <AddNewStabilimento
                        mode="modify"
                        objectToModify={object}
                        budgetToModify={listBudgets.filter((budget)=>budget.idStructure===object.id)[0]}
                        close={() => setModifyPlant(!modifyPlant)}
                    />
                </Modal>
                :
                null
            }
            {
                modifyDepartment ?
                    <Modal
                        show={modifyDepartment}
                    >
                        <AddNewReparto

                            mode="modify"
                            objectToModify={object}
                            budgetToModify={listBudgets.filter((budget)=>budget.idStructure===object.id)[0]}

                            listPlant={listPlant}
                            listBudgets={listBudgets}
                            listDepartment={listDepartment}
                            stabilimento={null}
                            close={() => setModifyDepartment(!modifyDepartment)}


                        />
                    </Modal>
                    :
                    null
            }
            {
                modifyLine ?
                    <Modal
                        show={modifyLine}
                    >
                        <AddNewLinea
                            mode="modify"
                            objectToModify={object}
                            budgetToModify={listBudgets.filter((budget)=>budget.idStructure===object.id)[0]}

                            listPlant={listPlant}
                            listDepartment={listDepartment}
                            listLine={listLine}
                            listMachinery={listMachinery}
                            listBudgets={listBudgets}
                            stabilimento={null}
                            reparto={null}
                            close={() => setModifyLine(!modifyLine)}
                        />
                    </Modal>
                    :
                    null
            }
            {modifyAsset ?
                <Modal
                    show={modifyAsset}
                >
                    <AddNewAsset

                        plant={null}
                        line={null}
                        department={null}

                        listPlant={listPlant}
                        listDepartment={listDepartment}
                        listLine={listLine}
                        listBudgets={listBudgets}
                        listDatiTarga={listDatiTarga}

                        close={() => setModifyAsset(!modifyAsset)}

                        mode="modify"
                        objectToModify={object}
                        budgetToModify={listBudgets.filter((budget)=>budget.idStructure===object.id)[0]}
                    />
                </Modal>
                :
                null
            }

        </div>
    )


}

export default VerticalMenu