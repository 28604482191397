import React from "react";
import './mainUserProfile.css'
import '../../../components/userManagement/input/Form.css'
import UploadFileWithAvatar from "../../../components/userManagement/uploadFile/UploadFileWithAvatar";
import Input from "../../../components/userManagement/input/Input";
import Select from "../../../components/userManagement/select/Select";
import Button from "../../../components/userManagement/button/Button";
import { useSelector } from "react-redux";
import { selectUser } from "../../root/slices/datiUtenteSlice";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
    const userData = useSelector(selectUser)[0]
    const navigate= useNavigate()
    return (
        <div className="mainUserProfileBody">
            <div className={"mainUserProfileHeader"}>
                <div className={"mainUserProfileHeaderTitle"}> Informazioni utente</div>
                <div className="divider" />
            </div>
            <div className={"mainUserForm"}>
                <form className={"form"}>
                    <div className="form-group">
                        <div className="form-label">
                            <label>Name</label>
                        </div>
                        <div className={"form-double"}>
                            <Input type="text" disabled={true} placeholder={`${userData?.name}`} />

                        </div>
                        <div className="divider" />
                    </div>

                    <div className="form-group">
                        <div className="form-label">
                            <label>Email address</label>
                        </div>
                        <div className={"form-single"}>
                            <Input
                                disabled
                                type="email"
                                className={"input email"}
                                placeholder={`${userData?.email}`}
                            />
                        </div>
                        <div className="divider" />
                    </div>
                    {
                        /*
                         <div className="form-group">
                        <div className="form-label">
                            <label>Phone number</label>
                        </div>
                        <div className={"form-single"}>
                            <Input
                                type="tel"
                                placeholder="Enter Phone Number"
                            />
                        </div>
                        <div className="divider" />
                    </div>

                        */
                    }



                    <div className="form-group">
                        <div className="form-label">
                            <label>Role</label>
                        </div>
                        <div className={"form-single"}>
                            <Input
                                disabled
                                type="text"
                                placeholder={userData?.group[0]}
                            />
                        </div>
                       

                    </div>

                  
                </form>
                <div className="mainNewUserFooter">
                    <div className="divider" />
                    <div className="mainNewUserFooterContent">
                        <Button
                            style={{
                                marginRight: "1vw"
                            }}
                            onClick={()=>navigate(-1)}
                            className={"button cancel"}>
                            Cancel
                        </Button>
                        <Button className={"button save"}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
            {
                /*
                <div className="mainUserProfileFooter">
                    <div className="divider"/>
                    <div className="mainUserProfileContent">
                        <Button
                            style={{
                                marginRight: "1vw"
                            }}
                            className={"button cancel"}>
                            Cancel
                        </Button>
                        <Button className={"button save"}>
                            Save
                        </Button>
                    </div>
                </div>
                */
            }

        </div>
    )


}

export default UserProfile;